import { RepositoryFactory } from "repository/RepositoryFactory";
var facebook = RepositoryFactory.get("facebook");

export const getFacebookReviewsLoading = (val) => async (dispatch) => {
  dispatch({ type: "GET_FACEBOOK_REVIEWS_LOADING", payload: val });
};
export const getFacebookMetricsLoading = (val) => async (dispatch) => {
  dispatch({ type: "GET_FACEBOOK_METRICS_LOADING", payload: val });
};

export const addInitialFacebookReview = (accessToken, onSuccess=()=>{}) => async (dispatch) => {
  try {
    let { data } = await facebook.addInitialFacebookReview(accessToken);
    if(data.success){
      console.log(data.response,"RESPONSE ACTIONS");
      dispatch({
        type: "SET_FACEBOOK_TOKEN_INVALID",
        payload: false,
      });
      dispatch({
          type: "SET_FACEBOOK_TOKEN_PRESENT",
          payload: true,
        })
      onSuccess()

    }
  } catch (e) {
    console.log(e);
    // alert(e.message);
  }
};

export const getFacebookReviews = (payload) => async (dispatch) => {
  await dispatch(getFacebookReviewsLoading(true));
  if (payload.skip === 0)
    dispatch({ type: "CLEAR_FACEBOOK_REVIEWS" });
  try {
    let { data } = await facebook.getFacebookReviews(payload);

    if (data.success) {
      dispatch({ type: "GET_FACEBOOK_REVIEWS_SUCCESS", payload: data.data });
    }
    await dispatch(getFacebookReviewsLoading(false));

    // Dispatch success action with the received data
  } catch (error) {
    // Dispatch failure action with the error message
    console.log(error);
    await dispatch(getFacebookReviewsLoading(true));
  }
};

export const getFacebookMetrics = (payload) => async (dispatch) => {
  try {
    await dispatch(getFacebookMetricsLoading(true));
    let { data } = await facebook.getFacebookMetrics(payload);

    if (data.success) {
      dispatch({ type: "GET_FACEBOOK_REVIEWS_METRICS", payload: data.data });
    }
    await dispatch(getFacebookMetricsLoading(false));
  } catch (error) {
    // Dispatch failure action with the error message
    console.log(error);
    await dispatch(getFacebookMetricsLoading(false));
  }
};

export const bookmarkFacebookReview = (id) => async (dispatch) => {

  try {
    let { data } = await facebook.bookmarkFacebookReview(id);
    if (data.success) {
      await dispatch({
        type: "BOOKMARK_FACEBOOK_REVIEW",
        payload: id,
      });
    }
  } catch (e) {
    console.log(e);
    alert(e.response.message);
  }
};

export const getFacebookTokenValidity = (onSuccess = ()=>{}) => async (dispatch) =>{
  try{
    let { data } = await facebook.validateFbToken();
    // console.log("XX", data)
    if (data.success){
      await dispatch({
        type: "SET_FACEBOOK_TOKEN_PRESENT",
        payload: data.tokenPresent,
      });
      await dispatch({
        type: "SET_FACEBOOK_TOKEN_INVALID",
        payload: data.invalidToken,
      });
      onSuccess()
    }
  }
  catch(e){
     console.log(e);
     alert(e.response.message);
  }
}

export const fbLogout = () => async(dispatch)=>{
  try {
    let { data } = await facebook.logoutFacebook();
    if (data.success) {
      await dispatch({
        type: "SET_FACEBOOK_TOKEN_PRESENT",
        payload: true,
      });
      await dispatch({
        type: "SET_FACEBOOK_TOKEN_INVALID",
        payload: true,
      });
    }
  } catch (e) {
    console.log(e);
    alert(e.response.message);
  }

}
