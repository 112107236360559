const initState = { token: '', user: {}, role: 'admin' };

const authReducer = (state = initState, { payload, type }, action) => {
	switch (type) {
		case 'LOGIN_SUCCESS':
			return { ...state, token: payload.token, user: payload.admin };
		case 'LOGO_UPDATE':
			return { ...state, user: { ...state.user, logoURL: payload } };
		case 'FAV_UPDATE':
			return { ...state, user: { ...state.user, favURL: payload } };
		case 'LOGOUT_SUCCESS':
			return { ...state, token: '', user: {} };
		case 'SWITCH_ROLE':
			return {
				...state,
				role: payload
			};
		default:
			return state;
	}
};
export default authReducer;
