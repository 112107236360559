import { combineReducers } from 'redux';
import adminReducer from './adminReducer';
import authReducer from './authReducer';
import authUserReducer from './authUserReducer';
import customerReducer from './customerReducer';
import surveyAnswerReducer from './surveyAnswerReducer';
import surveyReducer from './surveyReducer';
import notificationsReducer from './notificationReducer';
import dashboardReducer from './dashboardReducer';
import statisticsReducer from './statisticsReducer';
import placesReducer from './placesReducer';
import noteReducer from './notesReducer';
import invitationReducer from './invitationReducer';
import facebookReducer from './facebookReducer';


export const appReducer = combineReducers({
	admin: adminReducer,
	auth:authReducer,
	authUser: authUserReducer,
	customer: customerReducer,
	survey:surveyReducer,
	surveyAnswer:surveyAnswerReducer,
	notification:notificationsReducer,
	dashboard:dashboardReducer,
	statistics:statisticsReducer,
	places:placesReducer,
	notes:noteReducer,
	invitation:invitationReducer,
	facebook:facebookReducer
});
const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_SUCCESS') {
		return appReducer(undefined, action);
	}
	return appReducer(state, action);
};
export default rootReducer;
