/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const CUSTOMER = "/customer";

export default {
  addCustomer(payload) {
    console.log(payload);
    return Repository.post(`${CUSTOMER}`, payload);
  },

  getAllCustomer(payload) {
    const queryParams = Object.entries(payload) //create query string 
    .filter(([key, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
    return Repository.get(
      `${CUSTOMER}?${queryParams}`
    );
  },
  getCustomerById(payload) {
    return Repository.get(`${CUSTOMER}/${payload}`);
  },
  deleteCustomer(payload) {
    return Repository.delete(`${CUSTOMER}/${payload}`, {});
  },
  updateCustomer(payload, id) {
    return Repository.put(`${CUSTOMER}/${id}`, payload);
  },
  uploadCustomerFile(payload, adminId) {
    return Repository.post(`${CUSTOMER}/uploadCustomers/${adminId}`, payload);
  },
  deleteTemporaryCustomers(adminId) {
    return Repository.delete(`${CUSTOMER}/deleteTemporaryCustomers/${adminId}`);
  },
  transferTemporaryCustomers(adminId) {
    return Repository.put(`${CUSTOMER}/transferTemporaryCustomers/${adminId}`);
  },
  downloadCustomerFile() {
    return Repository.get(`${CUSTOMER}/downloadCustomers`);
  },
  searchByName(payload) {
    return Repository.get(`${CUSTOMER}/search/${payload}`);
  },
  customerFilter(payload) {
    return Repository.post(`${CUSTOMER}`, payload);
  },
  verifyCustomerbyEmail(payload) {
    return Repository.post(`${CUSTOMER}/verifycustomerbyemail`, payload);
  },
  pushCustomerAsCollaborator(payload) {
    return Repository.put(`${CUSTOMER}/pushascollaborator`, payload);
  },
};
