/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const STATISTICS="/statistics";
const GETSTATISTICSDATA="getstatisticsdata";
const INDIVIDUAL="individual";
const NPSREVIEWS="/npsreviews";
const METRICS="/metrics";
export default {
    
    getStatisticsGraphData(payload) {
      const queryParams = Object.entries(payload) //create query string 
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      return Repository.get(`${STATISTICS}/${GETSTATISTICSDATA}?${queryParams}`);
    },
    getStatisticsGraphDataIndividualAdmin(payload) {
      const queryParams = Object.entries(payload) //create query string 
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      return Repository.get(`${STATISTICS}/${GETSTATISTICSDATA}${INDIVIDUAL}?${queryParams}`);
    },
    getNpsReviews(payload) {
      const queryParams = Object.entries(payload) //create query string 
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    
      const url = `${STATISTICS}${NPSREVIEWS}?${queryParams}`;
      console.log(url,"URL NPS REVIEWS");
      return Repository.get(url);
    },
    getNpsMetrics(payload) {
      const queryParams = Object.entries(payload) //create query string 
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    
      const url = `${STATISTICS}${METRICS}?${queryParams}`;
      return Repository.get(url);
    },
  };