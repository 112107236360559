/* eslint-disable import/no-anonymous-default-export */
import Repository from './Repository';
const PLACES = '/placesreviews';
const GOOGLEREVIEWS='/googlereviews'
const METRICS='/metrics'
const BOOKMARK='/bookmark'
export default{

    addReview(email){
        if(email)
        return Repository.get(`${PLACES}/adminReview?email=${email}`);
        else
        return Repository.get(`${PLACES}/adminReview`);
    },
    getGoogleReviews(payload) {
        const queryParams = Object.entries(payload) //create query string 
          .filter(([key, value]) => value !== undefined)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      
        const url = `${PLACES}${GOOGLEREVIEWS}?${queryParams}`;
        console.log(url,"URL NPS REVIEWS");
        return Repository.get(url);
      },
      getGoogleMetrics(payload) {
        const queryParams = Object.entries(payload) //create query string 
          .filter(([key, value]) => value !== undefined)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      
        const url = `${PLACES}${METRICS}?${queryParams}`;
        return Repository.get(url);
      },
      bookmarkGoogleReview(id) {
		return Repository.put(`${PLACES}${BOOKMARK}/${id}`);
	  },
};
