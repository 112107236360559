import { RepositoryFactory } from 'repository/RepositoryFactory';
var dashboard = RepositoryFactory.get('dashboard');

export const getGraphLoading = val => async dispatch => {
	dispatch({ type: 'GRAPH_LOADING', payload: val });
};
export const getGoogleGraphLoading = val => async dispatch => {
	dispatch({ type: 'GOOGLE_GRAPH_LOADING', payload: val });
};

export const getGraphData = payload => async dispatch => {
	try {
		// await dispatch(getGraphLoading(true));
		let { data } = await dashboard.getAllGraphData(payload);

		if (data.success) {
			await dispatch({
				type: 'FETCH_GRAPH_DATA',
				payload: data.data,
			});
		}
		// dispatch(getGraphLoading(false));
	} catch (e) {
		console.log(e,"ERROR");
		// dispatch(getGraphLoading(false));
		alert(e.message,e.response.data.error);
	}
};
export const getGoogleGraphData = payload => async dispatch => {
	try {
		// await dispatch(getGraphLoading(true));
		let { data } = await dashboard.getGoogleGraphData(payload);
        console.log("TEST DATA",data);
		if (data.success) {
			await dispatch({
				type: 'FETCH_GOOGLE_DATA',
				payload: data.data,
			});
		}
		// dispatch(getGraphLoading(false));
	} catch (e) {
		dispatch(getGraphLoading(false));
		alert(e.message);
	}
};

export const getFacebookGraphData = payload => async dispatch => {
	try {
		// await dispatch(getGraphLoading(true));
		let { data } = await dashboard.getFacebookGraphData(payload);
        console.log("FACEBOOK ACTION");
		if (data.success) {
			await dispatch({
				type: 'FETCH_FACEBOOK_DATA',
				payload: data.data,
			});
		}
		// dispatch(getGraphLoading(false));
	} catch (e) {
		dispatch(getGraphLoading(false));
		alert(e.message);
	}
};