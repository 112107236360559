import { RepositoryFactory } from 'repository/RepositoryFactory';
var admin = RepositoryFactory.get('admin');

export const setAdminLoading = val => async dispatch => {
	dispatch({ type: 'ADMIN_LOADING', payload: val });
};
export const getAdminLoading = val => async dispatch => {
	dispatch({ type: 'GET_ADMIN_LOADING', payload: val });
};
export const setLogoLoading = val => async dispatch => {
	dispatch({ type: 'ADMIN_LOGO_LOADING', payload: val });
};

export const setFavLoading = val => async dispatch => {
	dispatch({ type: 'ADMIN_FAV_LOADING', payload: val });
};
export const inviteLoading = val => async dispatch => {
	dispatch({ type: 'INVITE_LOADING', payload: val });
};
export const addAdmin =
	(payload, onSuccess = () => { }) =>
		async dispatch => {
			dispatch(setAdminLoading(true));
			try {
				console.log("TEST");
				let { data } = await admin.addAdmin(payload);

				if (data.success) {
					dispatch(setAdminLoading(false));
					onSuccess();
					alert('Admin erfolgreich hinzugefügt!');
				} else {
					console.log(data);
					alert(data.message.response)
				}
			} catch (e) {
				console.log(e);
				dispatch(setAdminLoading(false));
				alert(e.response.data.message);
			}
		};

export const getAdmins = payload => async dispatch => {
	try {
		await dispatch(getAdminLoading(true));
		let { data } = await admin.getAllAdmin(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_ALL_ADMIN',
				payload: data,
			});
		}
		dispatch(getAdminLoading(false));
	} catch (e) {
		dispatch(getAdminLoading(false));
		alert(e.message);
	}
};

export const setAdminPassword = payload => async dispatch => {
	try {
		// await dispatch(getAdminLoading(true));
		let { data } = await admin.setAdminPassword(payload);
		if (data.success) {
			alert("Passwort erfolgreich geändert");
		}
	} catch (e) {
		alert(e.message);
	}
};


export const getAdminById = payload => async dispatch => {
	try {
		await dispatch(getAdminLoading(true));
		let { data } = await admin.getAdminById(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_ADMIN',
				payload: data,
			});
		}
		dispatch(getAdminLoading(false));
	} catch (e) {
		dispatch(getAdminLoading(false));
		alert(e.message);
	}
};

export const searchByNameAdmin = payload => async dispatch => {
	try {
		await dispatch(getAdminLoading(true));
		let { data } = await admin.getAllAdmin(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_ALL_ADMIN',
				payload: data,
			});
		}
		dispatch(getAdminLoading(false));
	} catch (e) {
		dispatch(getAdminLoading(false));
		alert(e.response.data.message);
	}
};
export const deleteAdmin =
	(id, onSuccess = () => { }) =>
		async dispatch => {
			await dispatch(setAdminLoading(true));

			try {
				let { data } = await admin.deleteAdmin(id);

				if (data.success) {
					await dispatch(setAdminLoading(false));
					onSuccess();
					alert('Admin erfolgreich gelöscht!');
				}
			} catch (e) {
				dispatch(getAdminLoading(false));
				alert(e.response.data.message);
			}
		};

export const updateAdmin =
	(obj, id, onSuccess = () => { }) =>
		async dispatch => {
			await dispatch(setAdminLoading(true));
			try {
				let { data } = await admin.updateAdmin(obj, id);
				if (data.success) {
					await dispatch(setAdminLoading(false));
					alert(data.message);
					onSuccess();
				}
			} catch (e) {
				dispatch(setAdminLoading(false));
				alert(e.response.data.message);
			}
		};

export const updateAdminStatus =
	(id, onSuccess = () => { }) =>
		async dispatch => {
			await dispatch(setAdminLoading(true));
			try {
				let { data } = await admin.updateAdminStatus(id);
				if (data.success) {
					await dispatch(setAdminLoading(false));
					alert('Admin-Status geändert!');
					onSuccess();
				}
			} catch (e) {
				dispatch(setAdminLoading(false));
				alert(e.response.data.message);
			}
		};


export const uploadFav =
	(file, id, onSuccess = () => { }) =>
		async dispatch => {
			await dispatch(setFavLoading(true));
			try {
				let { data } = await admin.uploadFav(file, id);
				if (data.success) {
					console.log(data, "IMAGE");
					// await dispatch({
					// 	type: 'FAV_UPDATE',
					// 	payload: data.favURL,
					// });
					await dispatch(setFavLoading(false));
					alert('Logo erfolgreich hochgeladen!');
					onSuccess();
				}
			} catch (e) {
				dispatch(setFavLoading(false));
				alert(e.response.data.message);
			}
		};


export const uploadLogo =
	(file, id, onSuccess = () => { }) =>
		async dispatch => {
			await dispatch(setLogoLoading(true));
			try {
				let { data } = await admin.uploadLogo(file, id);
				if (data.success) {
					console.log(data, "IMAGE");
					// await dispatch({
					// 	type: 'LOGO_UPDATE',
					// 	payload: data.logoURL,
					// });
					await dispatch(setLogoLoading(false));
					alert('Logo erfolgreich hochgeladen!');
					onSuccess();
				}
			} catch (e) {
				dispatch(setLogoLoading(false));
				alert(e.response.data.message);
			}
		};

export const switchAdminRole = payload => async dispatch => {

	try {
			await dispatch({
				type: 'SWITCH_ROLE',
				payload: payload,
			});
	} catch (e) {
		alert(e.message);
	}
};

export const inviteAdminInTeam = payload => async dispatch => {
	try {
		// await dispatch(getAdminLoading(true));
		dispatch(inviteLoading(true));
		let { data } = await admin.inviteAdminInTeam(payload);
		if (data.success) {
			alert(data.message);
			dispatch(inviteLoading(false));
		}
	} catch (e) {
		dispatch(inviteLoading(false));
		alert(e.response.data.message);
	}
};

export const delinkAdminFromTeam = (payload,onSuccess = () => { }) => async dispatch => {
	try {

		// dispatch(inviteLoading(true));
		let { data } = await admin.delinkAdminfromTeam(payload);
		if (data.success) {
			alert("Admin erfolgreich aus dem Team entfernt"); //Admin removed from team successfully
			onSuccess()
		}
	} catch (e) {
		// dispatch(inviteLoading(false));
		alert(e.response.data.message);
	}
};

export const sendSummary =
	(payload, onSuccess = () => { }) =>
		async dispatch => {
			try {
				let { data } = await admin.sendSummary(payload);
				if (data.success) {
					onSuccess();
					alert(data.message);
				}
			} catch (e) {
				console.log(e);
				alert(e.response.data.message || e.message);
			}
		};