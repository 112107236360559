import { RepositoryFactory } from "repository/RepositoryFactory";
var places = RepositoryFactory.get("places");

export const getGoogleReviewsLoading = (val) => async (dispatch) => {
  dispatch({ type: "GET_GOOGLE_REVIEWS_LOADING", payload: val });
};
export const getGoogleMetricsLoading = (val) => async (dispatch) => {
  dispatch({ type: "GET_GOOGLE_METRICS_LOADING", payload: val });
};

export const addReview = (email) => async (dispatch) => {
  try {
    let { data } = await places.addReview(email);
  } catch (e) {
    console.log(e);
    // alert(e.message);
  }
};
export const getGoogleReviews = (payload) => async (dispatch) => {
  await dispatch(getGoogleReviewsLoading(true));
  if (payload.skip === 0)
    dispatch({ type: "CLEAR_GOOGLE_REVIEWS" });
  try {
    let { data } = await places.getGoogleReviews(payload);

    if (data.success) {
      dispatch({ type: "GET_GOOGLE_REVIEWS_SUCCESS", payload: data.data });
    }
    await dispatch(getGoogleReviewsLoading(false));

    // Dispatch success action with the received data
  } catch (error) {
    // Dispatch failure action with the error message
    console.log(error);
    await dispatch(getGoogleReviewsLoading(true));
  }
};

export const getGoogleMetrics = (payload) => async (dispatch) => {
  try {
    await dispatch(getGoogleMetricsLoading(true));
    let { data } = await places.getGoogleMetrics(payload);

    if (data.success) {
      dispatch({ type: "GET_GOOGLE_REVIEWS_METRICS", payload: data.data });
    }
    await dispatch(getGoogleMetricsLoading(false));
  } catch (error) {
    // Dispatch failure action with the error message
    console.log(error);
    await dispatch(getGoogleMetricsLoading(false));
  }
};

export const bookmarkGoogleReview = (id) => async (dispatch) => {

  try {
    let { data } = await places.bookmarkGoogleReview(id);
    if (data.success) {
      await dispatch({
        type: "BOOKMARK_GOOGLE_REVIEW",
        payload: id,
      });
    }
  } catch (e) {
    console.log(e);
    alert(e.response.message);
  }
};
