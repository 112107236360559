import React, { useEffect, useState } from "react";
import OnlyHeader from "components/Headers/OnlyHeader";
import ReactPaginate from "react-paginate";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  Input,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Spinner,
  Label,
  CustomInput,
} from "reactstrap";
import {
  getCustomers,
  searchByNameCustomer,
} from "store/action/customerAction";
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { addCustomer } from "store/action/customerAction";
import customersLogo from "../assets/img/icons/costumers-blue.svg";
import { getCustomerById } from "store/action/customerAction";
import { updateCustomer } from "store/action/customerAction";
import { reactSelectStyles } from "components/selectStyle";
function EditCustomer() {
  const history = useHistory();
  const { user } = useSelector((state) => state.authUser);
  const { customer, customerLoading, loading } = useSelector(
    (state) => state.customer
  );

  const dispatch = useDispatch();
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    greetings: "formal",
  });
  const [initialCustomerData, setInitialCustomerData] = useState({
    name: "",
    email: "",
    greetings: "formal",
  });
  const [isCustomerDataChanged, setIsCustomerDataChanged] = useState(false);
  // const [isEditClick, setIsEditClick] = useState(false);
  let { id } = useParams();

  const handleCustomerDataChange = (e) => {

    if (e.target.name === "greetings") {
      setCustomerData({ ...customerData, [e.target.name]: e.target.value });
    } else {
      e.preventDefault();
      setCustomerData({ ...customerData, [e.target.name]: e.target.value });
    }
  };

  const clearFields = () => {
    setCustomerData({
      name: "",
      email: "",
      greetings: "formal",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('i am here');
    if(isCustomerDataChanged){
      const tempObj = {
        email: customerData.email,
        firm: customerData.firm,
        greetings: customerData.greetings,
        lastName: customerData.lastName,
        name: customerData.name,
        salutation: customerData.salutation,
        title: customerData.title,
      };
  
      await dispatch(
        updateCustomer(tempObj, customerData._id, () => {
          history.push("/admin/customers")
        })
      );
      setIsCustomerDataChanged(false);
    }
    else{
      console.log('here');
      setIsCustomerDataChanged(true);
    }
  };
  useEffect(() => {
    dispatch(getCustomerById(id, () => {
      history.push("/admin/customers")
    }));
  }, []);
  useEffect(() => {
    console.log(customer.data);
    if (customer.data?._id){
      setCustomerData(customer.data);
      setInitialCustomerData(customer.data);
    } 
  }, [customer.data]);
//   useEffect(() => { 

//     if(isEqual(customerData, initialCustomerData))
//     setIsCustomerDataChanged(false)
//     else
//     setIsCustomerDataChanged(true)
// },[customerData,initialCustomerData])
  return (
    <>
      <OnlyHeader />
      {/* Modal */}
      {customerLoading ? (
        <Container
          fluid
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner color="primary" />
        </Container>
      ) : (
        <Container className="mt--7 main-container">
          <Row>
            <Col
              md={{
                offset: 2,
                size: 8,
              }}
            >
              <>
                <Row className="mt-5">
                  <Col
                    sm={12}
                    md={8}
                    lg={6}
                    className="d-flex  align-items-center"
                  >
                    <img
                      className="mb-3 mr-2"
                      src={customersLogo}
                      alt="customers"
                    ></img>{" "}
                    <p className="text-kunde-header mx-2">{`Kunde`}</p>{" "}
                    <p className="text-user-header"> {`${customerData.name} ${customerData.lastName}`}</p>
                  </Col>
                  <Col md={0} lg={2}></Col>
                  <Col sm={12} md={4} lg={3}></Col>
                </Row>

                <Form role="form" onSubmit={handleSubmit}>
                  {/* <h2>Add Customer</h2> */}

                  <Row>
                    <Col
                      lg={{
                        order: 1,
                        size: 2,
                      }}
                      sm={{
                        order: 1,
                        size: 3,
                      }}
                      className="my-2"
                    >
                      <p className="ss-fieldLabel">Anrede</p>
                      <div className="auth-input-container">
                      <Select
                        name="salutation"
                        required
                        isDisabled={!isCustomerDataChanged}
                        options={[
                          { value: 'Herr', label: 'Herr' },
                          { value: 'Frau', label: 'Frau' },
                          { value: 'Divers', label: 'Divers' },
                          { value: 'Keine Angabe', label: 'Keine Angabe' },
                        ]}
                        value={customerData.salutation ? { value: customerData.salutation, label: customerData.salutation } : null}
                        onChange={(selectedOption) => {
                          setCustomerData({ ...customerData, salutation: selectedOption.value });
                        }}
                        styles={reactSelectStyles}
                      />
                        {/* <Input
                          className='addAdminSelect'
                          name="salutation"
                          required
                          type="select"
                          disabled = {!isCustomerDataChanged}
                          value={customerData.salutation}
                          onChange={(e) => {
                            handleCustomerDataChange(e);
                          }}
                        >
                          <option value="Herr">Herr</option>
                          <option value="Frau">Frau</option>
                          <option value="Divers">Divers</option>
                          <option value="Keine Angabe">Keine Angabe</option>
                        </Input> */}
                      </div>
                    </Col>
                    <Col
                      lg={{
                        order: 2,
                        size: 7,
                      }}
                      sm={{
                        order: 3,
                        size: 12,
                      }}
                      className="my-2"
                    >
                      {/* Title */}
                      <p className="ss-fieldLabel">Titel</p>
                      <Select
                      name="title"
                      required
                      isDisabled={!isCustomerDataChanged}
                      options={[
                        { value: 'Dr', label: 'Dr' },
                        { value: 'Prof', label: 'Prof' },
                        { value: 'Prof. Dr', label: 'Prof. Dr' },
                        { value: 'Dipl.-Ing', label: 'Dipl.-Ing' },
                        { value: 'keine', label: 'keine' },
                      ]}
                      value={customerData.title ? { value: customerData.title, label: customerData.title } : null}
                      onChange={(selectedOption) => {
                        setCustomerData({ ...customerData, 'title': selectedOption.value });
                      }}
                      styles={reactSelectStyles}
                    />
                      {/* <Input
                        className='addAdminSelect'
                        name="title"
                        required
                        type="select"
                        value={customerData.title}
                        
                        disabled = {!isCustomerDataChanged}
                        onChange={(e) => {
                          handleCustomerDataChange(e);
                        }}
                      >
                        <option value="Dr">Dr</option>
                        <option value="Prof">Prof</option>
                        <option value="Prof. Dr">Prof. Dr</option>
                        <option value="Dipl.-Ing">Dipl.-Ing</option>
                        <option value="keine">keine</option>
                      </Input> */}
                    </Col>
                    <Col
                      lg={{
                        order: 3,
                        size: 3,
                      }}
                      sm={{
                        order: 2,
                        size: 9,
                      }}
                      className="my-2"
                    >
                      {/* Kunden-Nr */}
                      <p className="ss-fieldLabel">Kunden-Nr</p>
                      <div className="auth-input-container">
                        <Input
                          className="auth-input"
                          placeholder="Kunden-Nr"
                          name="customerNo"
                          type="text"
                          disabled
                          value={customerData.customerNo}
                          onChange={(e) => {
                            handleCustomerDataChange(e);
                          }}
                        ></Input>
                      </div>
                    </Col>

                    <Col
                      sm={{
                        order: 5,
                        size: 12,
                      }}
                      lg={{
                        order: 5,
                        size: 6,
                      }}
                      className="my-2"
                    >
                      <p className="ss-fieldLabel">Vorname</p>
                      <div className="auth-input-container">
                        <Input
                          className="auth-input"
                          placeholder="Vorname"
                          required
                          type="text"
                          name="name"
                          
                          disabled = {!isCustomerDataChanged}
                          value={customerData.name}
                          onChange={(e) => {
                            handleCustomerDataChange(e);
                          }}
                        ></Input>
                      </div>
                    </Col>
                    <Col
                      sm={{
                        order: 6,
                        size: 12,
                      }}
                      lg={{
                        order: 6,
                        size: 6,
                      }}
                      className="my-2"
                    >
                      <p className="ss-fieldLabel">Nachname</p>
                      <div className="auth-input-container">
                        <Input
                          className="auth-input"
                          placeholder="Nachname"
                          required
                          type="text"
                          name="lastName"
                          value={customerData.lastName}
                          
                          disabled = {!isCustomerDataChanged}
                          onChange={(e) => {
                            handleCustomerDataChange(e);
                          }}
                        ></Input>
                      </div>
                    </Col>
                    <Col
                      sm={{
                        order: 7,
                        size: 12,
                      }}
                      lg={{
                        order: 7,
                        size: 6,
                      }}
                      className="my-2"
                    >
                      <p className="ss-fieldLabel">Firma</p>
                      <div className="auth-input-container">
                        <Input
                          className="auth-input"
                          placeholder="Firma"
                          type="text"
                          name="firm"
                          value={customerData.firm}
                          
                          disabled = {!isCustomerDataChanged}
                          onChange={(e) => {
                            handleCustomerDataChange(e);
                          }}
                        ></Input>
                      </div>
                    </Col>
                    <Col
                      sm={{
                        order: 8,
                        size: 12,
                      }}
                      className="my-2"
                    >
                      <p className="ss-fieldLabel">Email</p>
                      <div className="auth-input-container">
                        <Input
                          className="auth-input"
                          placeholder="Email"
                          name="email"
                          required
                          type="email"
                          value={customerData.email}
                          
                          disabled = {!isCustomerDataChanged}
                          onChange={(e) => {
                            handleCustomerDataChange(e);
                          }}
                        ></Input>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col lg={1}></Col> */}
                    <Col
                      className="d-flex align-items-center ml-4"
                      md={4}
                      sm={12}
                    >
                      <Input
                        name="greetings"
                        className=""
                        type="radio"
                        value="formal"
                        
                        disabled = {!isCustomerDataChanged}
                        checked={customerData.greetings === "formal"}
                        onChange={(e) => {
                          handleCustomerDataChange(e);
                        }}
                      />
                      <p className="greeting-radio-text">Formelle Anrede</p>
                    </Col>
                    <Col
                      className="d-flex align-items-center ml-4"
                      md={4}
                      sm={12}
                    >
                      <Input
                        name="greetings"
                        className=""
                        type="radio"
                        value="informal"
                        
                        disabled = {!isCustomerDataChanged}
                        checked={customerData.greetings === "informal"}
                        onChange={(e) => {
                          handleCustomerDataChange(e);
                        }}
                      />
                      <p className="greeting-radio-text">Informelle Anrede</p>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col
                      lg={{
                        offset: 8,
                        size: 4,
                      }}
                      sm={12}
                      className="d-flex justify-content-end"
                    >
                      <Button
                        className=" mt-3 mt-md-0 customer-header-button"
                        disabled={loading }
                        type="submit"
                      >
                        {loading ? <Spinner size="sm" /> : isCustomerDataChanged ? "Änderungen speichern":"Bearbeiten"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default EditCustomer;
