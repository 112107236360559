/* eslint-disable import/no-anonymous-default-export */
import Repository from './Repository';
const SURVEYANSWER = '/surveyanswer';
const BOOKMARK="/bookmark"

export default {
	addSurvey(payload) {	
		return Repository.post(`${SURVEYANSWER}`, payload);
	},

	getSurveyAnswers(payload) {
		const queryParams = Object.entries(payload) //create query string 
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
		return Repository.get(
			`${SURVEYANSWER}?${queryParams}`
		);
	},
	verifySurveyAnswer(payload){
		return Repository.get(
			`${SURVEYANSWER}/verify/${payload}`
		);
	},
	submitSurveyAnswer(payload,id) {
		return Repository.put(`${SURVEYANSWER}/submit/${id}`,payload);
	},
	surveyFilter(payload) {
		return Repository.post(`${SURVEYANSWER}`, payload);
	},
	bookmarkSurveyAnswer(id) {
		return Repository.put(`${SURVEYANSWER}${BOOKMARK}/${id}`);
	  },
};
