import OnlyHeader from 'components/Headers/OnlyHeader'
import React, { useState } from 'react'
import {
    Button,
    Card,
    CardHeader,
    Col,
    Form,
    Input,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Spinner,
    Label,
} from "reactstrap";

import CustomersIcon from '../assets/img/icons/costumers-blue.svg';
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { addAdmin } from "store/action/adminAction";
import { useHistory } from "react-router-dom";
import {
    getAdmins,
    searchByNameAdmin,
} from "store/action/adminAction";
import { addReview } from 'store/action/placesAction';
import axios from 'axios';
import { reactSelectStyles } from 'components/selectStyle';
export default function AddAdmin() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { admins, adminLoading, loading } = useSelector((state) => state.admin);
    const [placeValidation, setPlaceValidation] = useState(null);
    // const salutationOptions = [
    //     { value: 'Herr', label: 'Herr' },
    //     { value: 'Frau', label: 'Frau' },
    //     { value: 'Divers', label: 'Divers' },
    //     { value: 'Keine Angabe', label: 'Keine Angabe' },
    // ];


    const [adminData, setAdminData] = useState({
        name: "",
        email: "",
        lastName: "",
        role: "admin",
        greetings: "formal",
        salutation: "Herr",
        title: "keine",
        adminNo: "",
        firm: "",
        telephone: "",
        mobile: "",
        streetNo: "",
        zip: "",
        location: "",
        placeId: "",

        // contactNumber: "",
    });
    const clearFields = () => {
        setAdminData({
            name: "",
            email: "",
            lastName: "",
            role: "admin",
            greetings: "formal",
            salutation: "Herr",
            title: "keine",
            adminNo: "",
            firm: "",
            telephone: "",
            mobile: "",
            streetNo: "",
            zip: "",
            location: "",
            placeId: "",

            // contactNumber: "",
        })
    };
    const handleAdminDataChange = (e) => {
        // e.preventDefault();
        // setAdminData({ ...adminData, [e.target.name]: e.target.value });

        if (e.target.name === "greetings") {
            setAdminData({ ...adminData, [e.target.name]: e.target.value });
        } else {
            e.preventDefault();
            setAdminData({ ...adminData, [e.target.name]: e.target.value });
        }
    };
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            //   borderColor: state.isFocused ? 'red' : '#ccc',
            borderRadius: '20px', // set border radius to 20px on focus
            border: `1px solid ${state.isFocused ? 'yellow' : 'green'}`,

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#2B3347' : 'white',
            color: state.isSelected ? 'white' : 'black',
            ':active': {
                backgroundColor: '#2B3347',
                color: 'white',
            },
        }),
        // other styles here
    };
    // const customStyles = {
    //     option: (provided, state) => ({
    //       ...provided,
    //       backgroundColor: state.isSelected ? 'red' : 'white',
    //       color: state.isSelected ? 'white' : 'black',
    //       ':active': {
    //         backgroundColor: 'green',
    //         color: 'white',
    //       },
    //     }),
    //     control: (provided, state) => ({
    //       ...provided,
    //       borderColor: state.isSelected ? 'red' : provided.borderColor,
    //       ':hover': {
    //         borderColor: state.isSelected ? 'red' : provided.borderColor,
    //       },
    //     }),
    //   };
    return (
        <div>
            <OnlyHeader />
            <Container className='mt--7' fluid>
                <Row className='display-flex justify-content-center'>
                    <Col md={8} className='pt-6 pb-6'>
                        <div className='customersHeading mb-5'>
                            <img src={CustomersIcon}></img>
                            <p className='custHeading'>Kunde anlegen</p>
                        </div>
                        {/* <div className='tabs'>

                        </div> */}
                        <div className='form-fields'>
                            <div>
                                <Form
                                    role="form"
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        console.log(adminData, "AdminData");
                                        await dispatch(
                                            addAdmin(adminData, async () => {
                                                if (adminData.placeId)
                                                    await dispatch(addReview(adminData.email));
                                                history.push('/admin/admins')
                                                clearFields();
                                            })
                                        );
                                    }}
                                >
                                    <Row>
                                        <Col xs={{ size: 4, order: 1 }} lg={2} className='mt-3'>
                                            <p className="ss-fieldLabel">Kunde</p>

                                            <Select
                                                className="ss-selectField"
                                                name="salutation"
                                                required
                                                options={[
                                                { value: 'Herr', label: 'Herr' },
                                                { value: 'Frau', label: 'Frau' },
                                                { value: 'Divers', label: 'Divers' },
                                                { value: 'Keine Angabe', label: 'Keine Angabe' },
                                                ]}
                                                value={adminData.salutation ? { value: adminData.salutation, label: adminData.salutation } : null}
                                                onChange={(selectedOption) => {
                                                    setAdminData({ ...adminData, salutation: selectedOption.value });
                                                }}
                                                styles={reactSelectStyles}
                                            />
                                        </Col>
                                        <Col xs={{ size: 12, order: 3 }} lg={{ size: 6, order: 2 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Titel</p>
                                            <Select
                                                className="addAdminSelect"
                                                name="title"
                                                required
                                                options={[
                                                    { value: 'Dr', label: 'Dr' },
                                                    { value: 'Prof', label: 'Prof' },
                                                    { value: 'Prof. Dr', label: 'Prof. Dr' },
                                                    { value: 'Dipl.-Ing', label: 'Dipl.-Ing' },
                                                    { value: 'keine', label: 'keine' },
                                                ]}
                                                value={adminData.title ? { value: adminData.title, label: adminData.title } : null}
                                                onChange={(selectedOption) => {
                                                    setAdminData({ ...adminData, 'title': selectedOption.value });
                                                }}
                                                styles={reactSelectStyles}
                                            />
                                        </Col>
                                        <Col xs={{ size: 8, order: 2 }} lg={{ size: 4, order: 3 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Kunden-Nr</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Kunden-Nr"
                                                    type="text"
                                                    value={adminData.adminNo}
                                                    name="adminNo"
                                                    disabled
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Vorname *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Vorname"
                                                    required
                                                    type="text"
                                                    name="name"
                                                    value={adminData.name}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Nachname *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Nachname"
                                                    required
                                                    type="text"
                                                    name="lastName"
                                                    value={adminData.lastName}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='mt-3'>
                                            <p className="ss-fieldLabel">Firma</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Firma"
                                                    name="firm"
                                                    type="text"
                                                    value={adminData.firm}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='mt-3'>
                                            <p className="ss-fieldLabel">E-mail *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="E-mail"
                                                    name="email"
                                                    required
                                                    type="email"
                                                    value={adminData.email}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Telefon *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Telefon"
                                                    name="telephone"
                                                    type="number"
                                                    required
                                                    value={adminData.telephone}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Mobil</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Mobil"
                                                    name="mobile"
                                                    type="number"
                                                    value={adminData.mobile}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Straße + Nr. *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Straße Nr."
                                                    name="streetNo"
                                                    type="text"
                                                    required
                                                    value={adminData.streetNo}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">PLZ *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="PLZ"
                                                    name="zip"
                                                    type="number"
                                                    required
                                                    value={adminData.zip}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Ort *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Ort"
                                                    name="location"
                                                    type="text"
                                                    required
                                                    value={adminData.location}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Google Place Id</p>
                                            <div className="auth-input-container">

                                                <Input
                                                    className="auth-input"
                                                    placeholder="Google Place Id"
                                                    name="placeId"
                                                    type="text"
                                                    valid={placeValidation == "loading" ? null : placeValidation}
                                                    value={adminData.placeId}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                                <div className='auth-password-toggle' onClick={() => {
                                                    window.open(`https://search.google.com/local/writereview?placeid=${adminData.placeId}`, '_blank')
                                                }}>
                                                    verifizieren</div>
                                            </div>
                                        </Col>
                                        <Col xs={{ size: 12, order: 3 }} lg={{ size: 6, order: 2 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Role</p>
                                            <Input
                                                className='addAdminSelect'
                                                name="role"
                                                required
                                                type="select"
                                                value={adminData.role}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            >
                                                <option value="admin">Admin</option>
                                                <option value="super-admin">Super Admin</option>
                                                <option value="admin-pro">Admin Pro</option>

                                            </Input>
                                        </Col>
                                    </Row>
                                    <Row className=' mt-3'>
                                        {/* <Col lg={1}></Col> */}
                                        <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                                            <Input
                                                name="greetings"
                                                className=""
                                                type="radio"
                                                value="formal"
                                                checked={adminData.greetings === "formal"}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            />
                                            <p className="greeting-radio-text">Formelle Anrede</p>
                                        </Col>
                                        <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                                            <Input
                                                name="greetings"
                                                className=""
                                                type="radio"
                                                value="informal"
                                                checked={adminData.greetings === "informal"}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            />
                                            <p className="greeting-radio-text">Informelle Anrede</p>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-end mt-5">
                                        <Button color="primary" type="submit" disabled={loading} className='addAdminButton'>
                                            {loading ? <Spinner size="sm" /> : "Kunde anlegen"}
                                        </Button>
                                    </div>

                                </Form>
                            </div>
                        </div>

                    </Col>
                </Row>

            </Container>
        </div >
    )
}
