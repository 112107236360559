import { RepositoryFactory } from 'repository/RepositoryFactory';
var statistics = RepositoryFactory.get('statistics');

export const getGraphLoading = val => async dispatch => {
	dispatch({ type: 'GRAPH_LOADING', payload: val });
};

export const getStatisticsGraphData = payload => async dispatch => {
	try {
		// await dispatch(getGraphLoading(true));
		let { data } = await statistics.getStatisticsGraphData(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_STATISTICS_DATA',
				payload: data.data,
			});
		}
		// dispatch(getGraphLoading(false));
	} catch (e) {
		// dispatch(getGraphLoading(false));
		alert(e.message);
	}
};
export const getSuperAdminStatisticsGraphData = payload => async dispatch => {
	try {
		// await dispatch(getGraphLoading(true));
		let {category}=payload
		let { data } = await statistics.getStatisticsGraphData(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_SUPERADMIN_STATISTICS_DATA',
				payload: {data:data.data,category},
			});
		}
		// dispatch(getGraphLoading(false));
	} catch (e) {
		// dispatch(getGraphLoading(false));
		alert(e.message);
	}
};
export const getStatisticsGraphDataIndividualAdmin = payload => async dispatch => {
	try {
		// await dispatch(getGraphLoading(true));
		let { data } = await statistics.getStatisticsGraphDataIndividualAdmin(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_STATISTICS_DATA_INDIVIDUAL',
				payload: data.data,
			});
		}
		// dispatch(getGraphLoading(false));
	} catch (e) {
		// dispatch(getGraphLoading(false));
		alert(e.message);
	}
};
export const getNpsReviews = (payload) => async (dispatch) => {
	dispatch({ type: "GET_NPS_REVIEWS_LOADING",payload:{category:payload.category,loading:true} });

	if(payload.skip===0) //no filter and page is initial/first
	dispatch({ type: "CLEAR_NPS_REVIEWS",payload:{category:payload.category}})
	try {
	  let { data } = await statistics.getNpsReviews(payload);

		if (data.success) {
			dispatch({ type: "GET_NPS_REVIEWS_SUCCESS", payload: {data:data.data,category:data.category,total:data.total} });
		}
		dispatch({ type: "GET_NPS_REVIEWS_LOADING",payload:{category:payload.category,loading:false} });
  
	  // Dispatch success action with the received data
	  
	} catch (error) {
	  // Dispatch failure action with the error message
	  console.log(error);
	  dispatch({ type: "GET_NPS_REVIEWS_LOADING",payload:{category:payload.category,loading:false} });
	}
  };
  export const getNpsMetrics = (payload) => async (dispatch) => {

	try {
	  let { data } = await statistics.getNpsMetrics(payload);

		if (data.success) {
			dispatch({ type: "GET_NPS_METRICS", payload: data.data });
		}
	  
	} catch (error) {
	  // Dispatch failure action with the error message
	  console.log(error);
	}
  };
  