/* eslint-disable import/no-anonymous-default-export */
import Repository from './Repository';
const ADMIN = '/admin';
const DELINK = '/delink';
const INVITATIONS = '/invitations';
const SENDINVITE='/sendinvite'
const RESET = '/admin/reset';
const UPLOADLOGO='admin/uploadLogo';
const UPLOADFAV='admin/uploadFav'
const SUMMARY='/summary'

export default {
	addAdmin(payload) {
		
		return Repository.post(`${ADMIN}`, payload);
	},

	getAllAdmin(payload) {
		const queryParams = Object.entries(payload) //create query string 
    .filter(([key, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
		return Repository.get(
			`${ADMIN}?${queryParams}`
		);
	},

	setAdminPassword(payload) {
		return Repository.post(
			`${ADMIN}/updatePassword`,payload
		);
	},

	getAdminById(payload) {
		return Repository.get(
			`${ADMIN}/${payload}`
		);
	},

	deleteAdmin(payload) {
		return Repository.delete(`${ADMIN}/${payload}`, {});
	},
	
	updateAdmin(payload, id) {
		return Repository.put(`${ADMIN}/${id}`, payload);
	},

	updateAdminStatus(id) {
		console.log(id);
		return Repository.put(`${ADMIN}/updateAdminStatus/${id}`);
	},

	uploadLogo(payload, id) {
		return Repository.post(`${UPLOADLOGO}/${id}`, payload);
	},
	uploadFav(payload, id) {
		return Repository.post(`${UPLOADFAV}/${id}`, payload);
	},

	searchByName(payload) {
		return Repository.get(`${ADMIN}/search/${payload}`);
	},
	adminFilter(payload) {
		return Repository.post(`${ADMIN}`, payload);
	},
	manualPassResetRep(email) {
		return Repository.post(`${RESET}`, email);
	},
	inviteAdminInTeam(email){
		return Repository.post(`${INVITATIONS}${SENDINVITE}`, email);
	},
	delinkAdminfromTeam(payload){
		return Repository.post(`${ADMIN}${DELINK}`, payload);
	},
	sendSummary(payload){
		return Repository.get(`${ADMIN}${SUMMARY}/${payload}`);
	}
};
