import { useEffect, useState } from "react";
import { SocialIcon } from "react-social-icons";
import logo from "../assets/img/logos/jaworskiLogin.svg";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  Spinner,
  CustomInput,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { verifySurveyAnswerToken } from "store/action/surveyAnswerAction";
import { useLocation } from "react-router-dom";
import { submitSurveyAnswer } from "store/action/surveyAnswerAction";
import closeIcon from "../assets/img/icons/close.svg";
import emoji06 from "../assets/img/icons/emoji6-0.svg";
import emoji78 from "../assets/img/icons/emoji 7-8.png";
import emojii78 from "../assets/img/icons/emoji-7-8.svg";
import emoji910 from "../assets/img/icons/emoji9-10.svg";
import heart from "../assets/img/icons/heart.svg";
import doubleArrow from "../assets/img/icons/double-arrow.svg";
import { Watermark } from "@hirohe/react-watermark";

const rating = [
  {
    score: 0,
    text: "Auf keinen Fall",
    emoji: emoji06,
  },

  {
    score: 1,
    text: "sehr unwahrscheinlich",
    emoji: emoji06,
  },

  {
    score: 2,
    text: "unwahrscheinlich",
    emoji: emoji06,
  },

  {
    score: 3,
    text: "Eher nicht",
    emoji: emoji06,
  },

  {
    score: 4,
    text: "müsste ich abwägen",
    emoji: emoji06,
  },

  {
    score: 5,
    text: "nicht uneingeschränkt",
    emoji: emoji06,
  },

  {
    score: 6,
    text: "Auf jeden Fall, immer!",
    emoji: emoji06,
  },

  {
    score: 7,
    text: "ich denke schon",
    emoji: emoji78,
  },

  {
    score: 8,
    text: "Auf jeden Fall, immer!",
    emoji: emoji78,
  },

  {
    score: 9,
    text: "Auf jeden Fall, immer!",
    emoji: emoji910,
  },

  {
    score: 10,
    text: "Auf jeden Fall, immer!",
    emoji: emoji910,
  },
];

const PreviewSurveyForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const logoURL = queryParams.get('logoURL') || ""
  console.log(logoURL,"logoURL");
  //   const {previewImage}=location.state
  const [previewImage, setPreviewImage] = useState(null);
  const { surveyAnswer, surveyAnswerLoading, isAnswered } = useSelector(
    (state) => state.surveyAnswer
  );
  const [step, setStep] = useState(0);
  const [hoveredScore, setHoveredScore] = useState(null);
  const state = window.history.state;
  //   const { previewImage } = state;

  // Now you can use the retrieved state in your page
  console.log(state);
  useEffect(() => {
    if (localStorage.getItem("imagePreviewUrl"))
      setPreviewImage(localStorage.getItem("imagePreviewUrl"));
    // Remove imagePreviewUrl from localStorage when closing the tab
    const handleBeforeUnload = () => {
      localStorage.removeItem("imagePreviewUrl");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  console.log(previewImage, "previewImage");
  return (
    <Watermark text={` Preview Survey`} lineHeight={0.5} textSize={20}>
      <div className="auth-container d-md-block text-center survey-container overflow-hidden">
        <Row style={{maxHeight:"100vh",overflowY:"auto",width:"100%"}} className="d-flex align-items-center justify-content-center">
          {surveyAnswerLoading ? (
            <>
              <Col
                md={{
                  offset: 2,
                  size: 8,
                }}
                lg={{
                  offset: 3,
                  size: 6,
                }}
                className="surveyform__inner-container mt--15 p-5"
              >
                <Spinner />
              </Col>
            </>
          ) : (
            <>
              {/* <Row> */}
              <Col
                md={{ size: 7 }}
                lg={{ size: 5}}
                className="col-xxl-4 survey-form-header d-flex align-items-center justify-content-between"
              >
                {/* <div className="d-flex justify-content-between align-items-center"> */}
                {/* <FontAwesomeIcon icon={faTimes} /> */}
                <div className="sf-header-text">
                  <img src={closeIcon} width={25} height={25}></img>
                </div>
                <div className="sf-header-text">FEEDBACK</div>
                <div className="sf-header-text">
                  {step === 0 ? "1/2" : "2/2"}
                </div>
                {/* </div> */}
              </Col>
              {/* </Row> */}
              <Col md={12}></Col>
              <Col
                md={{ size: 7 }}
                lg={{ size: 5}}
                className={`col-xxl-4 surveyform__inner-container ${
                  step === 1 || step === 2 ? "pt-5 zero-lr-padding" : "pt-5"
                }`}
              >
                {(previewImage || logoURL) && (
                  <Row className="mb-2 d-flex align-items-center justify-content-center">
                    <img
                      alt=""
                      src={logoURL || previewImage}
                      // height={54}
                      // width={202}
                      style={{
                        display: 'block',
                        height: 'auto',
                        width: '100%',
                        maxWidth: '600px', // Adjust the max-width as needed
                        margin: '0 auto',
                        border: '0',
                      }}
                    ></img>
                  </Row>
                )}{" "}
                {step === 0 && (
                  <Form>
                    {/* <Row> */}
                      <Col
                        md={{
                          offset: 1,
                          size: 10,
                        }}
                        className="text-center mb-3"
                      >
                        <Row className="justify-content-center align-items-center">
                        <Col>
                        <h4 className="survey-text">
                          Lieber Matthias, wie zufrieden warst du mit unserer
                          Beratung / unserem Service zu deinem letzten Anliegen?
                        </h4>
                        </Col>
                        </Row>
                    {/* </Row> */}
                    <FormGroup>
                      {/* <Label for="nps-score">NPS Score:</Label> */}
                      <div className="sf-score-container">
                        {rating.slice().reverse().map((rate) => (
                          <div
                            key={rate.score}
                            className="nps-score-box sf-score"
                            style={{
                              // width: "43px",
                              // height: "43px",
                              fontSize: "21.67px",
                              // borderRadius: "50%",
                              backgroundColor:
                                rate.score < 7
                                  ? "#EF3256"
                                  : rate.score < 9
                                  ? "#FFC600"
                                  : "#8FC73C",
                              // backgroundColor:score>8?hoveredScore===score?"#0C2D6F":"#008480":score>6?hoveredScore===score?"#677CA5":"#66B5B3":hoveredScore===score?"#C0C8D9":"#BFE0DF",
                              transition:
                                "width 0.2s, height 0.2s, background-color 0.2s",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: "bold",
                              color:
                                hoveredScore === rate.score ? "#fff" : "#fff",
                              cursor: "pointer",
                              // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                              marginRight: "5px",
                            }}
                            // title={score}
                            onMouseEnter={() => setHoveredScore(rate.score)}
                            onMouseLeave={() => setHoveredScore(null)}
                            onClick={() => {
                              //   handleScoreClick(rate.score);
                            }}
                          >
                            <Row>
                              <Col className="d-flex align-items-center  justify-content-center">
                                <div className="desktop-score">
                                  {hoveredScore !== rate.score
                                    ? rate.score
                                    : ""}
                                  {hoveredScore === rate.score && (
                                    <img src={rate.emoji}></img>
                                  )}
                                </div>
                                <div className="mobile-score">
                                  <div className="mobile-score-text">
                                    {rate.text}
                                  </div>
                                  <div className="mobile-score-text">
                                    {rate.score}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <br />
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                    <Row className="d-flex justify-content-between mt-1">
                      <Col xs={5} className="text-left sf-text">Auf keinen Fall</Col>
                      <Col xs={2}></Col>
                      <Col xs={5} className="text-right sf-text">
                        Auf jeden Fall, immer!
                      </Col>
                    </Row>
                    </Col>
                  </Form>
                )}
              </Col>
              <Col md={12}></Col>
              <Col
                md={{ size: 7 }}
                lg={{ size: 5}}
                
                className="col-xxl-4 sf-footer d-flex align-items-center justify-content-center"
              >
                Powered by johnkent.de
              </Col>
            </>
          )}
        </Row>
      </div>
    </Watermark>
  );
};
export default PreviewSurveyForm;
