import BarChart from "components/Charts/BarChart";
import PieChart from "components/Charts/PieChart";
import { useHistory } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import Rating from "react-rating-stars-component";
import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  CardHeader,
  Spinner,
  Button,
} from "reactstrap";
import dashboardBlue from "../../assets/img/icons/dashboard-blue.svg";
import ProgressChart from "components/Charts/ProgressChart";
import { useDispatch, useSelector } from "react-redux";
import { getGraphData } from "store/action/dashboardAction";
import { getGoogleGraphData } from "store/action/dashboardAction";
import CustomDatePicker from "components/Statistics/DatePickerComponet";
import { getGoogleMetrics } from "store/action/placesAction";
import { getFacebookGraphData } from "store/action/dashboardAction";
import { getFacebookMetrics } from "store/action/facebookAction";
import CustomOverlay from "components/BasicUI/CustomOverlay";
import { calculateRelativeChange } from "utils/chartUtils";
import { findRoundedMaxValue } from "utils/chartUtils";
import { getNpsMetrics } from "store/action/statisticsAction";

const AdminGraphs = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, role } = useSelector((state) => state.authUser);
  const {
    stackedBarChartData,
    pieChartData,
    googleBarChartData,
    googlePieChartData,
    facebookBarChartData,
    facebookPieChartData,
    graphLoading,
  } = useSelector((state) => state.dashboard);
  const {googleReviewMetrics,googleMetricsLoading  } = useSelector((state) => state.places);
  const {facebookReviewMetrics,facebookMetricsLoading,facebookTokenInvalid,facebookTokenPresent  } = useSelector((state) => state.facebook);
  const {npsMetrics} = useSelector((state) => state.statistics);
  const [comparison, setComparision] = useState("");
  const [comparisonStartDate, setComparisonStartDate] = useState("");
  const [comparisonEndDate, setComparisonEndDate] = useState("");
  const today = new Date();
  const createdAtDate = new Date(user.createdAt);

  const formattedStartDate = `${createdAtDate.getFullYear()}-${(createdAtDate.getMonth() + 1).toString().padStart(2, '0')}-${createdAtDate.getDate().toString().padStart(2, '0')}`;

  const [startDate, setStartDate] = useState(new Date(formattedStartDate));

  const [startDate2, setStartDate2] = useState(new Date(formattedStartDate));

  const [endDate, setEndDate] = useState(new Date(today.toISOString().split('T')[0]));
  const adminId = user._id;
  const [options1, setOptions1] = useState({
    colors: ["#9EB37C", "#DFDF8D", "#C67684"],
    chart: {
      id: "mychart",
      width: 600,
      height: 400,
      type: "pie",
    },
    toolbar: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },

    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
          // type: 'none',
        },
      },
    },
    labels: ["Promotoren", "Neutral", "Detraktoren"], // Add labels here
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "45%",
          labels: {
            show: false,
            name: {
              show: true,
              color: "#cacaca",
              formatter: function (val, test, test1) {
                console.log(val, "VALUE", test, test1);
                return 0;
              },
            },
            total: {
              show: true,
              showAlways: true,
              formatter: function (w) {
                const totals = w.globals.seriesTotals;
                console.log(totals, "formatter", w);
                const promoters = totals[0];
                const detractors = totals[1];
                const totalResponses = promoters + detractors + totals[2];
                if (totalResponses) {
                  const nps = ((promoters - detractors) / totalResponses) * 100;
                  return nps.toFixed();
                }
                return 0;
              },
            },
          },
        },
      },
    },
  });

  const series2Initial = [
    {
      name: "Promotoren",
      data: [],
      type: "column",
      color: "#9EB37C",
      max: 100,
    },
    {
      name: "Neutral",
      data: [],
      type: "column",
      color: "#DFDF8D",
      max: 100,
    },
    {
      name: "Detraktoren",
      data: [],
      type: "column",
      color: "#C67684",
      max: 100,
    },

    {
      name: "NPS 1",
      data: [],
      type: "line",
      color: "#1b4079",
      max: 100,
    },
    {
      name: "NPS 2",
      data: [],
      type: "line",
      color: "#d9d9d9",
      max: 100,
    },
  ];
  const categories = [
  ];
  const colors = series2Initial.map((item) => item.color);
  const [options2Initial, setOptions2Initial] = useState({
    chart: {
      stacked: false,
      stackType: "normal",
      type: "bar",
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
        tools: {
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          selection: true,
          reset: true,
          download: true,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
    },
    stroke: {
      width: [0, 0, 0, 4, 4],
    },

    grid: {
      borderColor: "#111",
      strokeDashArray: 7,
    },
    markers: {
      size: 4,
    },

    xaxis: {
      categories: categories,
    },

    fill: {
      opacity: 1,
      colors: colors,
    },
    colors: colors,
    legend: {
      position: "top",
      offsetX: 0,
      offsetY: 0,
    },

    xAxisScale: {
      niceMin: 0,
    },

    yaxis: [
      {
        seriesName: "Promotoren",
        min: 0,
        max: 10,
        title: {
          text: "Anzahl",
        },
      },
      {
        seriesName: "Detraktoren",
        show: false,
        min: 0,
        max: 10,
      },
      {
        seriesName: "Neutral",
        show: false,
        min: 0,
        max: 10,
      },

      {
        seriesName: "NPS 1",
        opposite: true,
        min: -100,
        max: 100,
        title: {
          text: "NPS",
        },
      },
      {
        seriesName: "NPS 2",
        show: false,
        // title: {
        //   text: "Line series 2"
        // },
        // opposite: true,
        min: -100,
        max: 100,
      },
    ],
  });

  const [series1, setSeries1] = useState([0, 0, 0]);
  const [options3, setOptions3] = useState({
    colors: ['#f0f0f0'],
    chart: {
      id: "mychart",
      width: 600,
      height: 400,
      type: "pie",
    },
    toolbar: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
          // type: 'none',
        },
      },
    },
    labels: [" ", " ", " "], // Add labels here
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "45%",
          labels: {
            show: false,
            name: { show: false },
            total: {
              show: true,
              showAlways: true,
              formatter: function (w) {
                const totals = w.globals.seriesTotals;
                console.log(totals, "formatter", w);
                const promoters = totals[0];
                const detractors = totals[1];
                const totalResponses = promoters + detractors + totals[2];
                if (totalResponses) {
                  const nps = ((promoters - detractors) / totalResponses) * 100;
                  return nps.toFixed();
                }
                return 0;
              },
            },
          },
        },
      },
    },
  });
  const [options4, setOptions4] = useState({
    colors: ['#f0f0f0'],
    chart: {
      id: "mychart",
      width: 600,
      height: 400,
      type: "pie",
    },
    toolbar: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
          // type: 'none',
        },
      },
    },
    labels: [" "], // Add labels here
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "45%",
          labels: {
            show: false,
            name: { show: false },
            total: {
              show: true,
              showAlways: true,
              formatter: function (w) {
                const totals = w.globals.seriesTotals;
                console.log(totals, "formatter", w);
                const promoters = totals[0];
                const detractors = totals[1];
                const totalResponses = promoters + detractors + totals[2];
                if (totalResponses) {
                  const nps = ((promoters - detractors) / totalResponses) * 100;
                  return nps.toFixed();
                }
                return 0;
              },
            },
          },
        },
      },
    },
  });
  const [series3, setSeries3] = useState([73, 21, 5]); //GOOGLE 
  const [series4, setSeries4] = useState([73, 21, 5]); //FACEBOOK

  const [series2, setSeries2] = useState([
    {
      // name: "Promotors (9-10)",
      data: [1, 2, 3],
    },
    {
      // name: "Detractors (7-8)",
      data: [2, 4, 6],
    },
    {
      // name: "Passives(0-6)",
      data: [1, 3, 9],
    },
  ]); //Barchart


  const [totalNpsScore, setTotalNpsScore] = useState("");
  const handleStartDateChange = (date) => {
    console.log(date, "CHECK DATE");
    if (!isNaN(date) && date) {
      setStartDate(date);
    }

  };

  const handleEndDateChange = (date) => {
    if (!isNaN(date) && date)
      setEndDate(date);
  };
  function checkIfDummyData(arr) {
    if (!Array.isArray(arr)) {
      return false;
    }

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        return false;
      }
    }

    return true;
  }
  const aggregate = role === "admin-pro" ? true : false;
  const isInitialRenderPage = useRef(true);
  useEffect(() => {

    dispatch(
      getGraphData({
        startDate,
        endDate,
        aggregate,
        comparison,
        comparisonStartDate,
        comparisonEndDate,
      })
    );
    dispatch(getNpsMetrics({startDate,endDate,aggregate}))
    if (isInitialRenderPage.current) {
      isInitialRenderPage.current = false;
      dispatch(
        getGoogleGraphData({
          aggregate,
          comparison,
          comparisonStartDate,
          comparisonEndDate,
        })
      );
      dispatch(getGoogleMetrics({}))
      dispatch(
        getFacebookGraphData({
          aggregate,
          comparison,
          comparisonStartDate,
          comparisonEndDate,
        })
      );
      dispatch(getFacebookMetrics({}))
    }
    else {
      dispatch(
        getGoogleGraphData({
          startDate,
          endDate,
          aggregate,
          comparison,
          comparisonStartDate,
          comparisonEndDate,
        })
      );
      dispatch(getGoogleMetrics({ startDate, endDate }))
      dispatch(
        getFacebookGraphData({
          startDate,
          endDate,
          aggregate,
          comparison,
          comparisonStartDate,
          comparisonEndDate,
        })
      );
      dispatch(getFacebookMetrics({ startDate, endDate }))
    }
  }, [startDate, endDate, aggregate, comparison]);
  useEffect(() => {

    if (comparison === "yearly") {
      if (startDate) {
        const startYearAgo = new Date(startDate);
        startYearAgo.setFullYear(startYearAgo.getFullYear() - 1);
        setComparisonStartDate(startYearAgo.toISOString().slice(0, 10));
      }
      if (endDate) {
        const endYearAgo = new Date(endDate);
        endYearAgo.setFullYear(endYearAgo.getFullYear() - 1);
        setComparisonEndDate(endYearAgo.toISOString().slice(0, 10));
      }

    } else if (comparison === "period") {
      if (startDate && endDate) {

        const startDatePeriod = new Date(startDate);
        const endDatePeriod = new Date(endDate);
        const daysDifference = ((endDatePeriod - startDatePeriod) / (1000 * 3600 * 24)).toFixed(0);
        console.log(daysDifference, "daysDifference");
        startDatePeriod.setDate(startDatePeriod.getDate() - daysDifference);
        endDatePeriod.setDate(endDatePeriod.getDate() - daysDifference);
        setComparisonStartDate(startDatePeriod.toISOString().slice(0, 10));
        setComparisonEndDate(endDatePeriod.toISOString().slice(0, 10));
      }
    } else {
      setComparisonStartDate("");
      setComparisonEndDate("");

    }
  }, [comparison, startDate, endDate]);
  useEffect(() => {
    if (stackedBarChartData.data) {
      // setOptions2((prevOptions) => ({
      //   ...prevOptions,
      //   xaxis: { ...prevOptions.xaxis, categories: stackedBarChartData.labels },
      // }));
      console.log(stackedBarChartData.data, "stackedBarChartData.data");
      if (stackedBarChartData.labels.length){
        const newMaxValue=findRoundedMaxValue(stackedBarChartData.data)
        setOptions2Initial((prevOptions) => ({
          ...prevOptions,
          xaxis: { categories: stackedBarChartData.labels },
          yaxis: prevOptions.yaxis.map(yaxisItem => {
            switch (yaxisItem.seriesName) {
              case "Promotoren":
                return { ...yaxisItem, max: newMaxValue };
              case "Detraktoren":
                return { ...yaxisItem, max: newMaxValue };
              case "Neutral":
                return { ...yaxisItem, max: newMaxValue };
              default:
                return yaxisItem;
            }
          }),
    
        }));
      }

      setSeries2([
        ...stackedBarChartData.data.map((val, ind) => {
          switch (ind) {
            case 0:
              return {
                data: val,
                name: "Promotoren",
                type: "column",
                color: "#8FC73C",
              };
            case 1:
              return {
                data: val,
                name: "Neutral",
                type: "column",
                color: "#FFC600",
              };
            case 2:
              return {
                data: val,
                name: "Detraktoren",
                type: "column",
                color: "#EF3256",
              };

            default:
              break;
          }
          // return {data:val,name:ind+1}
        }),
        {
          name: "NPS 1",
          data: stackedBarChartData.npsScores,
          type: "line",
          color: "#1b4079",
          max: 100,
        },
        {
          name: "NPS 2",
          data: stackedBarChartData.comparisonScores,
          type: "line",
          color: "#d9d9d9",
          max: 100,
        },
      ]);

    }
  }, [stackedBarChartData]);

  useEffect(() => {
    if (pieChartData.data) {
      setSeries1(pieChartData.data);
      setTotalNpsScore(pieChartData.totalNpsScore);
      if (!checkIfDummyData(pieChartData.data))
        setOptions1((prevOptions) => ({
          ...prevOptions,
          colors: ["#8FC73C", "#FFC600", "#EF3256"],
          labels: ["Promotoren", "Neutral", "Detraktoren",],
          dataLabels: {
            enabled: true,
          },
        }))
      else
        setOptions1((prevOptions) => ({
          ...prevOptions,
          colors: ['#f0f0f0'],
          labels: [" "],
          dataLabels: {
            enabled: false,
          },
        }))
    }
  }, [pieChartData]);
  useEffect(() => {
    if (googlePieChartData.data) {
      setSeries3(googlePieChartData.data);
      if (!checkIfDummyData(googlePieChartData.data))
        setOptions3((prevOptions) => ({
          ...prevOptions,
          colors: ["#8FC73C", "#FFC600", "#EF3256"],
          labels: [" ", " ", " "],
          dataLabels: {
            enabled: true,
          },
        }))
      else
        setOptions3((prevOptions) => ({
          ...prevOptions,
          colors: ['#f0f0f0'],
          labels: [" "],
          dataLabels: {
            enabled: false,
          },
        }))
    }
  }, [googlePieChartData]);
  useEffect(() => {
    if (facebookPieChartData.data) {
      setSeries4(facebookPieChartData.data);
      if (!checkIfDummyData(facebookPieChartData.data))
        setOptions4((prevOptions) => ({
          ...prevOptions,
          colors: ["#8FC73C", "#FFC600", "#EF3256"],
          labels: [" ", " ", " "],
          dataLabels: {
            enabled: true,
          },
        }))
      else
        setOptions4((prevOptions) => ({
          ...prevOptions,
          colors: ['#f0f0f0'],
          labels: [" "],
          dataLabels: {
            enabled: false,
          },
        }))
    }
  }, [facebookPieChartData]);


  const handleConnectFacebook=()=>{
    history.push("/admin/settings")
       // Scroll to the element with ID "login" after a brief delay to ensure the element is available in the DOM
       setTimeout(() => {
        const loginElement = document.getElementById('#login');
        console.log(loginElement,"loginElement");
        if (loginElement) {
          loginElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
  }
  return (
    <>
      <div className="header  pb-8 pt-5 pt-md-8">
        <Container fluid>
          <Row className="header-body">
            {graphLoading ? (
              <Container
                fluid
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
              >
                <Spinner color="primary" />
              </Container>
            ) : (
              <Col
                xl={{
                  offset: 2,
                  size: 8,
                }}
                lg={{
                  offset: 1,
                  size: 10,
                }}
              >
                <Row className="d-flex justify-content-between align-items-center">
                  <Col>
                    <div className="dashboard-row">
                      <img src={dashboardBlue} alt="dashboard-blue"></img>
                      <span className="dashboard-row__text">Dashboard</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="dashboard-legend d-flex align-items-center ">
                      <div className="dashboard-legend-color__promoter" />
                      <span className="dashboard-legend__text">Promotoren</span>
                      <div className="dashboard-legend-color__neutral" />
                      <span className="dashboard-legend__text">Neutral</span>
                      <div className="dashboard-legend-color__detractor" />
                      <span className="dashboard-legend__text">
                        Detraktoren
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="startDate">
                      Zeitraum von
                    </Label>
                    <CustomDatePicker selectedDate={startDate} onChange={handleStartDateChange}></CustomDatePicker>
                    {/* <Input
                      className="dashboard-date-input"
                      type="date"
                      id="startDate"
                      value={startDate}
                      onChange={handleStartDateChange}
                    /> */}
                  </Col>
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="endDate">
                      bis
                    </Label>
                    <CustomDatePicker selectedDate={endDate} onChange={handleEndDateChange}></CustomDatePicker>
                    {/* <Input
                      className="dashboard-date-input"
                      type="date"
                      id="endDate"
                      value={endDate}
                      onChange={handleEndDateChange}
                    /> */}
                  </Col>
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="comparison">
                      Vergleichen
                    </Label>
                    <Input
                      className="addAdminSelect"
                      name="comparison"
                      type="select"
                      value={comparison}
                      onChange={(e) => {
                        setComparision(e.target.value);
                      }}
                    >
                      <option value="">Vergleich auswählen</option>
                      <option value="yearly">Vorjahresvergleich</option>
                      <option value="period">
                        Mit vorherigem Zeitraum vergleichen
                      </option>
                    </Input>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="startDate">
                      Vergleich&nbsp;Zeitraum&nbsp;von
                    </Label>
                    <Input
                      className="dashboard-date-input"
                      type="date"
                      id="startDate"
                      value={comparisonStartDate}
                      disabled
                      onChange={(e) => {
                        setComparisonStartDate(e.target.value);
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="endDate">
                      bis
                    </Label>
                    <Input
                      className="dashboard-date-input"
                      type="date"
                      id="endDate"
                      value={comparisonEndDate}
                      disabled
                      onChange={(e) => {
                        setComparisonEndDate(e.target.value);
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    {(role !== "admin-pro" && <Button
                      className="add-customer-button-dashboard"
                      onClick={() => {
                        history.push("/admin/addcustomer");
                      }}
                    >
                      Kunde anlegen
                    </Button>)}
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col lg={4} md={12}>
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardHeader className="card-stats-header d-flex justify-content-between align-items-center">
                        <p className="card-stats-header__text-ubersicht">
                          Übersicht
                        </p>
                        <p className="card-stats-header__text-nps">NPS</p>
                      </CardHeader>
                      <CardBody>
                        {checkIfDummyData(pieChartData.data) && (
                          <Row>
                            <p className="dummydata-text justify-content-center">
                              *Noch keine Daten vorhanden
                            </p>
                          </Row>
                        )}
                        {pieChartData.comparisonNpsScore && (
                          <p className="compare-percentage-text">
                            {calculateRelativeChange(
                              pieChartData?.totalNpsScore,
                              pieChartData.comparisonNpsScore
                            ).toFixed(2)}
                            %
                          </p>
                        )}
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ position: "relative" }}
                        >
                          {/* The div container to hold the pie chart */}
                          {!checkIfDummyData(pieChartData.data) ? (
                            <PieChart
                              options1={options1}
                              series1={series1}
                              pieEnabled={10}
                              formatterValue={pieChartData?.totalNpsScore}
                            />
                          ) : (
                            <>
                              <PieChart
                                options1={options1}
                                series1={[1]}
                                pieEnabled={10}
                                formatterValue={10}
                              />
                            </>
                          )}

                          {/* The absolute positioned div to display NPS scores */}
                          <div className="piechart-text">
                            <p className="piechartnps-text">
                              {!checkIfDummyData(pieChartData.data) && pieChartData?.totalNpsScore}{" "}
                              {/* Display the total NPS score */}
                            </p>
                            {( !checkIfDummyData(pieChartData.data) && pieChartData?.comparisonNpsScore !== "") && (
                              <p className="piechartnpscomparison-text">
                                {pieChartData?.comparisonNpsScore}
                                {/* Display the comparison NPS score */}
                              </p>
                            )}
                          </div>
                        </div>
                        <Row className="align-items-center justify-content-center mt-3">
                        <p className="percentmailtext">Teilnehmerquote {npsMetrics.total?.totalSent !== 0 ? ((npsMetrics.total?.totalResponded / npsMetrics.total?.totalSent) * 100).toFixed(2) : 0.00}%</p>
                      </Row>
                      <Row className="align-items-center justify-content-center ">
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center mr-1"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.total?.totalSent ? npsMetrics.total?.totalSent : 0 }</p>{" "}
                          <p className="percentmailsubtext">gesendet</p>
                        </div>
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.total?.totalOpened ? npsMetrics.total?.totalOpened : 0 }</p>{" "}
                          <p className="percentmailsubtext">geöffnet</p>
                        </div>
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center ml-1"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.total?.totalResponded ? npsMetrics.total?.totalResponded : 0 }</p>{" "}
                          <p className="percentmailsubtext">reagiert</p>
                        </div>
                      </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={8} md={12}>
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardHeader className="card-stats-header d-flex justify-content-end align-items-center">
                        <p className="card-stats-header__text-nps">NPS</p>
                      </CardHeader>
                      <CardBody>
                        <Row className="justify-content-center">
                          {stackedBarChartData?.labels?.length === 0 ? (
                            <p className="dummydata-text">
                              *Noch keine Daten vorhanden
                            </p>
                          ) : (
                            ""
                          )}
                          <BarChart
                            options2={
                              stackedBarChartData?.labels?.length > 0
                                ? options2Initial
                                : options2Initial
                            }
                            series2={
                              stackedBarChartData?.labels?.length > 0
                                ? series2
                                : series2Initial
                            }
                            height={350}
                            tab="2"
                          />
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg={4} md={12}>
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardHeader className="card-stats-header d-flex justify-content-start align-items-center">
                        <p style={{cursor:"pointer"}} onClick={()=>{history.push("/admin"+"/googlestats")}} className="card-stats-header__text-ubersicht">
                          Google Reviews
                        </p>
                        {/* <p className="card-stats-header__text-nps">NPS</p> */}
                      </CardHeader>
                      <CardBody>
                        {checkIfDummyData(googlePieChartData.data) && (
                          <Row>
                            <p className="dummydata-text justify-content-center">
                              *Noch keine Daten vorhanden
                            </p>
                          </Row>
                        )}
                        {comparison !== "" && (
                          <p className="compare-percentage-text"> {calculateRelativeChange(
                            googlePieChartData?.averageRating,
                            googlePieChartData?.averageRatingComparison
                          ).toFixed(2)}%</p>
                        )}
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ position: "relative" }}
                        >
                          {/* The div container to hold the pie chart */}
                          {!checkIfDummyData(googlePieChartData.data) ? (
                            <PieChart
                              options1={options3}
                              series1={series3}
                              pieEnabled={10}
                            />
                          ) : (
                            <>
                              <PieChart
                                options1={options3}
                                series1={[1]}
                                pieEnabled={10}
                                formatterValue={10}
                              />
                            </>
                          )}

                          {/* The absolute positioned div to display NPS scores */}
                          <div className="piechart-text">
                            <p className="piechartnps-text">
                              {!checkIfDummyData(googlePieChartData.data) && googlePieChartData?.reviewCount}
                               {/* googlePieChartData?.averageRating?.toFixed(2)}{" "} */}
                              {/* Display the total NPS score */}
                            </p>
                            { (!checkIfDummyData(googlePieChartData.data) && googlePieChartData?.comparisonCount !==
                              "") && (
                                <p className="piechartnpscomparison-text">
                                  {googlePieChartData?.comparisonCount}
                                  {/* Display the comparison NPS score */}
                                </p>
                              )}
                          </div>
                        </div>
                        {/* <Row className="justify-content-center">
                          {!checkIfDummyData(googlePieChartData?.data) ? (
                            <PieChart
                              options1={options3}
                              series1={series3}
                              pieEnabled={10}
                              formatterValue={googlePieChartData?.averageRating}
                            />
                          ) : (
                            <>
                              <p className="dummydata-text">
                                *Noch keine Daten vorhanden 
                              </p>
                              <PieChart
                                options1={options3}
                                series1={[10, 15, 20]}
                                pieEnabled={10}
                                formatterValue={10}
                              />
                            </>
                          )}
                        </Row> */}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={8} md={12}>
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardHeader className="card-stats-header d-flex justify-content-between  align-items-center">
                      {(!googleMetricsLoading) && (
                  <Rating
                    key={`stars`}
                    value={googleReviewMetrics.averageReviewScore}
                    count={5}
                    size={30}
                    edit={false}
                    activeColor="#ffd700"
                    isHalf
                  />
                )}
                <p className="card-stats-header__text-nps">{googleReviewMetrics.averageReviewScore}</p>
              {/* </Row> */}
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            {googleBarChartData?.max > 0 ? (
                              <ProgressChart
                                data={googleBarChartData.data}
                                maxValue={googleBarChartData.max + 10}
                                dataComparision={
                                  googleBarChartData.comparisonRatings &&
                                    googleBarChartData.comparisonMax
                                    ? googleBarChartData.comparisonRatings
                                    : {}
                                }
                              />
                            ) : (
                              <>
                                <p className="dummydata-text">
                                  *Noch keine Daten vorhanden
                                </p>
                                <ProgressChart
                                  data={{
                                    1: 0,
                                    2: 0,
                                    3: 0,
                                    4: 0,
                                    5: 0,
                                  }}
                                  // dummy={true}
                                  maxValue={20}
                                />
                              </>
                            )}
                            {/* {googleBarChartData.data && (
                            <ProgressChart
                              data={googleBarChartData.data}
                              maxValue={googleBarChartData.max + 10}
                            />
                          )} */}
                          </Col>
                          {/* <BarChart
                          options2={options2}
                          series2={series2}
                          tab="2"
                        /> */}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-4" style={{position: 'relative'}}>
                  <Col lg={4} md={12}>
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardHeader className="card-stats-header d-flex justify-content-start align-items-center">
                        <p style={{cursor:"pointer"}} onClick={()=>{history.push("/admin"+"/facebookstats")}} className="card-stats-header__text-ubersicht">
                          Facebook Reviews
                        </p>
                        {/* <p className="card-stats-header__text-nps">NPS</p> */}
                      </CardHeader>
                      <CardBody>
                        {checkIfDummyData(facebookPieChartData.data) && (
                          <Row>
                            <p className="dummydata-text justify-content-center">
                              *Noch keine Daten vorhanden
                            </p>
                          </Row>
                        )}
                        {comparison !== "" && (
                          <p className="compare-percentage-text"> {calculateRelativeChange(
                            facebookPieChartData?.averageRating,
                            facebookPieChartData?.averageRatingComparison
                          ).toFixed(2)}%</p>
                        )}
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ position: "relative" }}
                        >
                          {/* The div container to hold the pie chart */}
                          {!checkIfDummyData(facebookPieChartData.data) ? (
                            <PieChart
                              options1={options4}
                              series1={series4}
                              pieEnabled={10}
                            />
                          ) : (
                            <>
                              <PieChart
                                options1={options4}
                                series1={[1]}
                                pieEnabled={10}
                                formatterValue={10}
                              />
                            </>
                          )}

                          {/* The absolute positioned div to display NPS scores */}
                          <div className="piechart-text">
                            <p className="piechartnps-text">
                              {!checkIfDummyData(facebookPieChartData.data) && facebookPieChartData?.reviewCount}
                               {/* facebookPieChartData?.averageRating?.toFixed(2)}{" "} */}
                              {/* Display the total NPS score */}
                            </p>
                            { (!checkIfDummyData(facebookPieChartData.data) && facebookPieChartData?.comparisonCount !==
                              "") && (
                                <p className="piechartnpscomparison-text">
                                  {facebookPieChartData?.comparisonCount}
                                  {/* Display the comparison NPS score */}
                                </p>
                              )}
                          </div>
                        </div>
                        {/* <Row className="justify-content-center">
                          {!checkIfDummyData(googlePieChartData?.data) ? (
                            <PieChart
                              options1={options3}
                              series1={series3}
                              pieEnabled={10}
                              formatterValue={googlePieChartData?.averageRating}
                            />
                          ) : (
                            <>
                              <p className="dummydata-text">
                                *Noch keine Daten vorhanden 
                              </p>
                              <PieChart
                                options1={options3}
                                series1={[10, 15, 20]}
                                pieEnabled={10}
                                formatterValue={10}
                              />
                            </>
                          )}
                        </Row> */}
                      </CardBody>
                    </Card>
                  </Col>
                  {/* FACEBOOK FUNCTIONALITY 1*/}
                  {/* { facebookTokenInvalid  && 
                  <CustomOverlay >
                    <Row style={{width: '100%'}} className="d-flex justify-content-center align-items-center">
                      <Col lg={{size:6}} xs={{size:10}}>
                    <Button onClick={handleConnectFacebook} className='facebook-button' style={{width: '100%'}}>
                    <span><i class="fab fa-facebook-f fa-lg mr-4"></i></span>Connect to Facebook
                    </Button>
                    {
                      facebookTokenPresent ?<><span className="text-white">Ihr Zugangstoken ist abgelaufen/ungültig, bitte melden Sie sich erneut an</span></> :""
                    }
                    </Col>
                    </Row>
                  </CustomOverlay>} */}
                  <Col lg={8} md={12}>
                    <Card className="card-stats mb-4 mb-xl-0">
                    <CardHeader className="card-stats-header d-flex justify-content-between  align-items-center">
                      {(!facebookMetricsLoading) && (
                  <Rating
                    key={`stars`}
                    value={facebookReviewMetrics.averageReviewScore}
                    count={5}
                    size={30}
                    edit={false}
                    activeColor="#ffd700"
                    isHalf
                  />
                )}
                <p className="card-stats-header__text-nps">{facebookReviewMetrics.averageReviewScore}</p>
              {/* </Row> */}
                      </CardHeader>
                      <CardBody>
                        <Row>
                        <Col>
                            {facebookBarChartData?.max > 0 ? (
                              <ProgressChart
                                data={facebookBarChartData.data}
                                maxValue={facebookBarChartData.max + 10}
                                dataComparision={
                                  facebookBarChartData.comparisonRatings &&
                                    facebookBarChartData.comparisonMax
                                    ? facebookBarChartData.comparisonRatings
                                    : {}
                                }
                              />
                            ) : (
                              <>
                                <p className="dummydata-text">
                                  *Noch keine Daten vorhanden
                                </p>
                                <ProgressChart
                                  data={{
                                    1: 0,
                                    2: 0,
                                    3: 0,
                                    4: 0,
                                    5: 0,
                                  }}
                                  // dummy={true}
                                  maxValue={20}
                                />
                              </>
                            )}
                            {/* {googleBarChartData.data && (
                            <ProgressChart
                              data={googleBarChartData.data}
                              maxValue={googleBarChartData.max + 10}
                            />
                          )} */}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AdminGraphs;
