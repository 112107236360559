export const checkIfDummyData=(arr)=> {
    if (!Array.isArray(arr)) {
      return false;
    }

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        return false;
      }
    }

    return true;
  }
 export const calculateRelativeChange = (initialValue, finalValue) => {
  const initialValueNum = parseFloat(initialValue);
  const finalValueNum = parseFloat(finalValue);

  if (finalValueNum === 0) {
    return initialValueNum >= 0 ? 100 : -100;
  }

  return ((initialValueNum - finalValueNum) / finalValueNum) * 100;
  };
  
  export const findRoundedMaxValue=(arrayStructure)=>{
    // Flatten the nested arrays
    const flatArray = arrayStructure.flatMap(subArray => subArray);
  
    // Find the maximum value
    const max = Math.max(...flatArray);
  
    // Round the maximum value to the nearest 10
    const roundedMax = Math.ceil(max / 10) * 10;
  
    return roundedMax;
  }