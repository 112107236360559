const initialState = {
    notifications: [],
    notificationsLoading:false,
    notificationMetrics:{
      unreadCount:0,
      readCount:0
    }
  };
  
  
   const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_NOTIFICATION":
        return {
          ...state,
          notifications: [action.payload, ...state.notifications],
        };
      case "MARK_NOTIFICATION_AS_READ":
        return {
          ...state,
          notifications: state.notifications.map((notification) =>
            notification._id === action.payload._id
              ? { ...notification, read: true }
              : notification
          ),
        };
        case "FETCH_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload,
      };
        case "NOTIFICATIONS_METRICS":
          return {
            ...state,
            notificationMetrics: action.payload,
          };
      case "NOTIFICATION_LOADING":
        return {
          ...state,
          notificationsLoading: action.payload,
        };
      default:
        return state;
    }
  };
  export default notificationsReducer;