const initialData = {
	invitations: [],
	inviteLoading: false,
};

const invitationReducer = (state = initialData, action) => {
	switch (action.type) {
		case 'GET_INVITATION_LOADING':
			return {
				...state,
				inviteLoading: action.payload,
			};
		case 'GET_ALL_INVITATIONS':
			return {
				...state,
				invitations: action.payload,
			};
		default:
			return state;
	}
};

export default invitationReducer;
