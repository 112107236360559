import React, { useEffect, useRef, useState } from "react";
import OnlyHeader from "components/Headers/OnlyHeader";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  Input,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Spinner,
  Label,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";

import { OverlayTrigger, Popover } from "react-bootstrap";
import customersLogo from "../assets/img/icons/costumers-blue.svg";
import questionBlue from "../assets/img/icons/questions-blue.svg";
import LockLoginLogo from "../assets/img/icons/LockLogin.svg";
import emojiRed from "../assets/img/icons/emoji6-0.svg";
import emojiYellow from "../assets/img/icons/emoji-7-8-yellow.svg";
import emojiGreen from "../assets/img/icons/emoji9-10.svg";
import { getSurveyAnswers } from "store/action/surveyAnswerAction";
import {  useParams } from "react-router-dom";
import { getCustomerById } from "store/action/customerAction";
function SurveyAnswers() {
  const history = useHistory();
  const { user,role } = useSelector((state) => state.authUser);
  const aggregate= role ==="admin-pro" ? true:false
  const { surveyAnswers, surveyAnswerLoading } = useSelector(
    (state) => state.surveyAnswer
  );

  const dispatch = useDispatch();
  let { id } = useParams();
  const [nameSearch, setNameSearch] = useState(false);

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [showPopovers, setShowPopovers] = useState([]);

  const [activeTab, setActiveTab] = useState(2); //nav tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handlePopoverClose = (index) => {
    // for overlay triggers
    const newShowPopovers = [...showPopovers];
    newShowPopovers[index] = false;
    setShowPopovers(newShowPopovers);
  };

  const pageClickHandler = (e) => {
    setPage(e.selected + 1);
  };

  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getUTCFullYear().toString();
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }

  const { customer, customerLoading } = useSelector(
      (state) => state.customer
  );
  const [customerData, setCustomerData] = useState({
      name: "",
      email: "",
      greetings: "formal",
  });
  useEffect(() => {
      dispatch(getCustomerById(id, () => {
          history.push("/admin/customers")
      }));
  }, []);
  useEffect(() => {
      console.log(customer.data);
      if (customer.data?._id) {
          setCustomerData(customer.data);
      }
  }, [customer.data]);
  useEffect(() => {
    dispatch(getSurveyAnswers({ limit: limit, pageNo: page ,aggregate }));
  }, [page]);
  
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
    else{
      if(page===0)
        dispatch(getSurveyAnswers({ limit: limit, pageNo: page ,aggregate }));
      else
        setPage(0)
    }
    
  }, [aggregate]);
  useEffect(() => {
    // for overlay popovers
    if (surveyAnswers?.data?.length) {
      setShowPopovers(Array(surveyAnswers.data.length).fill(false));
    }
  }, [surveyAnswers.data]);

  return (
    <div>
      <OnlyHeader />
      {/* Modal */}
      <div></div>
      <Container className="mt--7 ">
        <Row>
          <div className="col">
            <>
              <Row className="mt-5">
                <Col
                  sm={12}
                  md={8}
                  lg={6}
                  className="d-flex  align-items-center"
                >
                  <img
                    className="mb-3 mr-2"
                    src={customersLogo}
                    alt="surveyAnswers"
                  ></img>{" "}
                  <p className="text-kunde-header mx-2">{`Kunde`}</p>{" "}
                  <p className="text-user-header"> {customer.data?.name} {customer.data?.lastName} </p>
                </Col>
                <Col md={0} lg={2}></Col>
                <Col sm={12} md={4} lg={3}></Col>
              </Row>

              <Nav
                className="border-bottom-nav my-3"
                style={{ display: "flex", justifyContent: "" }}
              >
                <NavItem className="nav-item-tab">
                  <NavLink
                    className={`${activeTab === 1 ? "active-tab-nav" : "inactive-tab-nav"
                      }`}
                    onClick={() => toggleTab(1)}
                  >
                    Allgemein
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item-tab">
                  <NavLink
                    className={`${activeTab === 2 ? "active-tab-nav" : "inactive-tab-nav"
                      }`}
                    onClick={() => toggleTab(2)}
                  >
                    Umfragen
                  </NavLink>
                </NavItem>
              </Nav>

              <LoadingOverlay
                active={surveyAnswerLoading}
                spinner
                text="Loading...."
              >
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {/* medium-screen-cell is used as d-md-block changes the display from table cell to block which changes vertical alignment */}
                      <th className="medium-screen-cell" scope="col">Datum</th>  
                      <th scope="col">Kategorie</th>
                      <th className="medium-screen-cell" scope="col">Mitarbeiter</th>
                      <th scope="col">Bewertung</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {surveyAnswers &&
                      surveyAnswers.data &&
                      surveyAnswers.data.map((el, index) => {
                        return (
                          <tr>
                            <td className="medium-screen-cell">{formatDate(el.updatedAt)}</td>
                            <td>{`${el.surveyId.category}`}</td>
                            <td className=" medium-screen-cell">{`${el?.customerId?.email}`}</td>
                            <td className="text-center">
                              {el.npsScore >= 9 ? (
                                <img src={emojiGreen} alt="green emoji"></img>
                              ) : el.npsScore >= 7 ? (
                                <img src={emojiYellow} alt="yellow emoji"></img>
                              ) : (
                                <img src={emojiRed} alt="red emoji"></img>
                              )}
                            </td>
                            <td className="">
                              <OverlayTrigger
                                key={index}
                                trigger="click"
                                placement="bottom"
                                rootClose={true}
                                onHide={() => handlePopoverClose(index)}
                                show={showPopovers[index]}
                                overlay={
                                  <Popover
                                    id={`popover-positioned-bottom-${index}`}
                                  >
                                    <Popover.Body>
                                      <Row>Anzeigen</Row>
                                      <Row
                                        onClick={() => {
                                          history.push(
                                            `/admin/editcustomer/${el._id}`
                                          );
                                        }}
                                      >
                                        Bearbeiten
                                      </Row>
                                      <Row>Duplizieren</Row>
                                      <Row
                                        onClick={() => {
                                          handlePopoverClose(index);
                                        }}
                                      >
                                        Löschen
                                      </Row>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <i
                                  style={{
                                    cursor: "pointer",
                                    transform: `rotate(90deg)`,
                                  }}
                                  className="fas fa-ellipsis-v"
                                  onClick={() => {
                                    const newShowPopovers = [...showPopovers];
                                    newShowPopovers[index] =
                                      !showPopovers[index];
                                    setShowPopovers(newShowPopovers);
                                  }}
                                ></i>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </LoadingOverlay>
              <CardFooter className="py-4">
                {nameSearch == false && (
                  <ReactPaginate
                    previousLabel={<i className="fas fa-angle-left" />}
                    nextLabel={<i className="fas fa-angle-right" />}
                    breakLabel={"..."}
                    pageCount={surveyAnswers && surveyAnswers.total / limit}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={pageClickHandler}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link custom-pageLink"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link custom-pageArrow"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link custom-pageArrow"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active custom-active"}
                  />
                )}
              </CardFooter>
            </>
          </div>
        </Row>
        <Row className="">
          <Col sm={12} md={8} lg={6} className="d-flex  align-items-center">
            <img
              className="mb-3 mr-2"
              src={questionBlue}
              alt="surveyAnswers"
            ></img>{" "}
            <p className="text-kunde-header mx-2">{`Neue Umfrage anlegen`}</p>{" "}
          </Col>
          <Col md={0} lg={2}></Col>
          <Col sm={12} md={4} lg={3}></Col>
        </Row>
        <Row>
          <Col md={6} sm={12} className="my-2">
            <p className="ss-fieldLabel">Kunde</p>
            <div className="auth-input-container">
              <Input
                className="customer-input"
                placeholder="Anrede"
                name="salutation"
                required
                type="text"
              ></Input>
            </div>
          </Col>
          <Col md={6} sm={12} className="my-2">
            <p className="ss-fieldLabel">Kundenanliegen</p>
            <div className="auth-input-container">
              <Input
                className="customer-input"
                placeholder="Anrede"
                name="salutation"
                required
                type="text"
              ></Input>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 mb-5">
          <Col
            lg={{
              offset: 8,
              size: 4,
            }}
            sm={12}
            className="d-flex justify-content-end"
          >
            <Button
              className=" mt-3 mt-md-0 customer-header-button"
              type="submit"
            >
              {false ? <Spinner size="sm" /> : "Kunde anlegen"}
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SurveyAnswers;
