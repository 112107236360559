import React, { useState, useEffect, useRef } from "react";
import { getCustomerById } from "store/action/customerAction";
import customersLogo from "../assets/img/icons/costumers-blue.svg";
import { useDispatch, useSelector } from "react-redux";
import OnlyHeader from "components/Headers/OnlyHeader";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  Input,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Spinner,
  Label,
  CustomInput,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import { getSurveyAnswers } from "store/action/surveyAnswerAction";
import emojiRed from "../assets/img/icons/emoji6-0.svg";
import emojiYellow from "../assets/img/icons/emoji-7-8-yellow.svg";
import emojiGreen from "../assets/img/icons/emoji9-10.svg";
import emojiGrey from "../assets/img/icons/emoji-grey.svg";
import ReviewCard from "components/Statistics/ReviewCard";
import { getNpsReviews } from "store/action/statisticsAction";
import { reactSelectStyles } from "components/selectStyle";
import Select from "react-select";
export default function ViewCustomer() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [nameSearch, setNameSearch] = useState(false);
  const { user, role } = useSelector((state) => state.authUser);
  const { customer, customerLoading } = useSelector((state) => state.customer);
  const { surveyAnswers, surveyAnswerLoading } = useSelector(
    (state) => state.surveyAnswer
  );
  const { npsReviews, npsLoading } = useSelector((state) => state.statistics);
  const aggregate = role === "admin-pro" ? true : false
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    greetings: "formal",
  });
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(1); //nav tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const activetabFromUrl = searchParams.get('activetab');

    if (activetabFromUrl) {
      setActiveTab(parseInt(activetabFromUrl, 10));
    }
  }, [location.search]);
  useEffect(() => {
    dispatch(
      getCustomerById(id, () => {
        history.push("/admin/customers");
      })
    );
  }, [id]);
  useEffect(() => {
    console.log(customer.data);
    if (customer.data?._id) {
      setCustomerData(customer.data);
    }
  }, [customer.data]);
  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getUTCFullYear().toString();
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }
  const pageClickHandler = (e) => {
    setPage(e.selected + 1);
  };
  useEffect(() => {
    dispatch(getSurveyAnswers({ limit: limit, page: page, aggregate, customerId: id }));
  }, [page,id]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
    else {
      if (page === 1)
        dispatch(getSurveyAnswers({ limit: limit, page: page, aggregate, customerId: id }));
      else
        setPage(1)
    }

  }, [aggregate,id]);
  useEffect(() => {

    dispatch(getNpsReviews({ category: "performance", skip: 0, aggregate, customerId: id }));
    dispatch(getNpsReviews({ category: "service", skip: 0, aggregate, customerId: id }));
    dispatch(getNpsReviews({ category: "consulting", skip: 0, aggregate, customerId: id }));

  }, [aggregate,id]);
  return (
    <>
      <OnlyHeader />
      {/* Modal */}
      {customerLoading ? (
        <Container
          fluid
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner color="primary" />
        </Container>
      ) : (
        <Container className="mt--7 main-container">
          <Row>
            <Col
              md={{
                offset: 2,
                size: 8,
              }}
            >
              <Nav
                className="border-bottom-nav"
                style={{ display: "flex", justifyContent: "" }}
              >
                <NavItem className="nav-item-tab">
                  <NavLink
                    className={`${activeTab === 1 ? "active-tab-nav" : "inactive-tab-nav"
                      }`}
                    onClick={() => toggleTab(1)}
                  >
                    Allgemein
                  </NavLink>
                </NavItem>
                <NavItem className="nav-item-tab">
                  <NavLink
                    className={`${activeTab === 2 ? "active-tab-nav" : "inactive-tab-nav"
                      }`}
                    onClick={() => toggleTab(2)}
                  >
                    Umfragen
                  </NavLink>
                </NavItem>
              </Nav>
              {activeTab === 1 && (
                <>
                  <Row className="mt-5">
                    <Col
                      sm={12}
                      md={8}
                      lg={6}
                      className="d-flex  align-items-center"
                    >
                      <img
                        className="mb-3 mr-2"
                        src={customersLogo}
                        alt="customers"
                      ></img>{" "}
                      <p className="text-kunde-header mx-2">{`Kunde`}</p>{" "}
                      <p className="text-user-header">
                        {" "}
                        {`${customerData.name} ${customerData.lastName}`}
                      </p>
                    </Col>
                    <Col md={0} lg={2}></Col>
                    <Col sm={12} md={4} lg={3}></Col>
                  </Row>

                  <Form role="form">
                    {/* <h2>Add Customer</h2> */}

                    <Row>
                      <Col
                        lg={{
                          order: 1,
                          size: 2,
                        }}
                        sm={{
                          order: 1,
                          size: 3,
                        }}
                        className="my-2"
                      >
                        <p className="ss-fieldLabel">Anrede</p>
                        <div className="auth-input-container">
                          <Select
                            name="salutation"
                            required
                            isDisabled
                            options={[
                              { value: 'Herr', label: 'Herr' },
                              { value: 'Frau', label: 'Frau' },
                              { value: 'Divers', label: 'Divers' },
                              { value: 'Keine Angabe', label: 'Keine Angabe' },
                            ]}
                            value={customerData.salutation ? { value: customerData.salutation, label: customerData.salutation } : null}
                            onChange={(selectedOption) => {
                              setCustomerData({ ...customerData, salutation: selectedOption.value });
                            }}
                            styles={reactSelectStyles}
                          />
                          {/* <Input
                            className="addAdminSelect"
                            name="salutation"
                            required
                            type="select"
                            disabled
                            value={customerData.salutation}
                          >
                            <option value="Herr">Herr</option>
                            <option value="Frau">Frau</option>
                            <option value="Divers">Divers</option>
                            <option value="Keine Angabe">Keine Angabe</option>
                          </Input> */}
                        </div>
                      </Col>
                      <Col
                        lg={{
                          order: 2,
                          size: 7,
                        }}
                        sm={{
                          order: 3,
                          size: 12,
                        }}
                        className="my-2"
                      >
                        {/* Title */}
                        <p className="ss-fieldLabel">Titel</p>
                        <Select
                          name="title"
                          required
                          isDisabled
                          options={[
                            { value: 'Dr', label: 'Dr' },
                            { value: 'Prof', label: 'Prof' },
                            { value: 'Prof. Dr', label: 'Prof. Dr' },
                            { value: 'Dipl.-Ing', label: 'Dipl.-Ing' },
                            { value: 'keine', label: 'keine' },
                          ]}
                          value={customerData.title ? { value: customerData.title, label: customerData.title } : null}
                          onChange={(selectedOption) => {
                            setCustomerData({ ...customerData, 'title': selectedOption.value });
                          }}
                          styles={reactSelectStyles}
                        />
                        {/* <Input
                          className="addAdminSelect"
                          name="title"
                          required
                          type="select"
                          value={customerData.title}
                          disabled
                        >
                          <option value="Dr">Dr</option>
                          <option value="Prof">Prof</option>
                          <option value="Prof. Dr">Prof. Dr</option>
                          <option value="Dipl.-Ing">Dipl.-Ing</option>
                          <option value="keine">keine</option>
                        </Input> */}
                      </Col>
                      <Col
                        lg={{
                          order: 3,
                          size: 3,
                        }}
                        sm={{
                          order: 2,
                          size: 9,
                        }}
                        className="my-2"
                      >
                        {/* Kunden-Nr */}
                        <p className="ss-fieldLabel">Kunden-Nr</p>
                        <div className="auth-input-container">
                          <Input
                            className="auth-input"
                            placeholder="Kunden-Nr"
                            name="customerNo"
                            type="text"
                            disabled
                            value={customerData.customerNo}
                          ></Input>
                        </div>
                      </Col>

                      <Col
                        sm={{
                          order: 5,
                          size: 12,
                        }}
                        lg={{
                          order: 5,
                          size: 6,
                        }}
                        className="my-2"
                      >
                        <p className="ss-fieldLabel">Vorname</p>
                        <div className="auth-input-container">
                          <Input
                            className="auth-input"
                            placeholder="Vorname"
                            required
                            type="text"
                            name="name"
                            disabled
                            value={customerData.name}
                          ></Input>
                        </div>
                      </Col>
                      <Col
                        sm={{
                          order: 6,
                          size: 12,
                        }}
                        lg={{
                          order: 6,
                          size: 6,
                        }}
                        className="my-2"
                      >
                        <p className="ss-fieldLabel">Nachname</p>
                        <div className="auth-input-container">
                          <Input
                            className="auth-input"
                            placeholder="Nachname"
                            required
                            type="text"
                            name="lastName"
                            value={customerData.lastName}
                            disabled
                          ></Input>
                        </div>
                      </Col>
                      <Col
                        sm={{
                          order: 7,
                          size: 12,
                        }}
                        lg={{
                          order: 7,
                          size: 6,
                        }}
                        className="my-2"
                      >
                        <p className="ss-fieldLabel">Firma</p>
                        <div className="auth-input-container">
                          <Input
                            className="auth-input"
                            placeholder="Firma"
                            type="text"
                            name="firm"
                            value={customerData.firm}
                            disabled
                          ></Input>
                        </div>
                      </Col>
                      <Col
                        sm={{
                          order: 8,
                          size: 12,
                        }}
                        className="my-2"
                      >
                        <p className="ss-fieldLabel">Email</p>
                        <div className="auth-input-container">
                          <Input
                            className="auth-input"
                            placeholder="Email"
                            name="email"
                            required
                            type="email"
                            value={customerData.email}
                            disabled
                          ></Input>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {/* <Col lg={1}></Col> */}
                      <Col
                        className="d-flex align-items-center ml-4"
                        md={4}
                        sm={12}
                      >
                        <Input
                          name="greetings"
                          className=""
                          type="radio"
                          value="formal"
                          disabled
                          checked={customerData.greetings === "formal"}
                        />
                        <p className="greeting-radio-text">Formelle Anrede</p>
                      </Col>
                      <Col
                        className="d-flex align-items-center ml-4"
                        md={4}
                        sm={12}
                      >
                        <Input
                          name="greetings"
                          className=""
                          type="radio"
                          value="informal"
                          disabled
                          checked={customerData.greetings === "informal"}
                        />
                        <p className="greeting-radio-text">Informelle Anrede</p>
                      </Col>
                    </Row>
                    {/* <Row className="mt-3">
                <Col
                  lg={{
                    offset: 8,
                    size: 4,
                  }}
                  sm={12}
                  className="d-flex justify-content-end"
                >
                  <Button
                    className=" mt-3 mt-md-0 customer-header-button"
                    disabled={loading }
                    type="submit"
                  >
                    {loading ? <Spinner size="sm" /> : isCustomerDataChanged ? "Änderungen speichern":"Bearbeiten"}
                  </Button>
                </Col>
              </Row> */}
                  </Form>
                </>
              )}
              {activeTab === 2 && (<>
                <Row>
                  <div className="col">
                    <>
                      <Row className="mt-5">
                        <Col
                          sm={12}
                          md={8}
                          lg={6}
                          className="d-flex  align-items-center"
                        >
                          <img
                            className="mb-3 mr-2"
                            src={customersLogo}
                            alt="surveyAnswers"
                          ></img>{" "}
                          <p className="text-kunde-header mx-2">{`Kunde`}</p>{" "}
                          <p className="text-user-header"> {customer.data?.name} {customer.data?.lastName} </p>
                        </Col>
                        <Col md={0} lg={2}></Col>
                        <Col sm={12} md={4} lg={3}></Col>
                      </Row>


                      <LoadingOverlay
                        active={surveyAnswerLoading}
                        spinner
                        text="Loading...."
                      >
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              {/* medium-screen-cell is used as d-md-block changes the display from table cell to block which changes vertical alignment */}
                              <th className="medium-screen-cell" scope="col">Datum</th>
                              <th scope="col">Kategorie</th>
                              <th className="medium-screen-cell" scope="col">Mitarbeiter</th>
                              <th scope="col">Bewertung</th>
                            </tr>
                          </thead>
                          <tbody>
                            {surveyAnswers &&
                              surveyAnswers.data &&
                              surveyAnswers.data.map((el, index) => {
                                return (
                                  <tr>
                                    <td className="medium-screen-cell">{formatDate(el.updatedAt)}</td>
                                    <td>{`${el.surveyId.category}`}</td>
                                    <td className=" medium-screen-cell">{`${el?.adminId?.email}`}</td>
                                    <td className="text-center">
                                      {el.npsScore >= 9 ? (
                                        <img src={emojiGreen} alt="green emoji"></img>
                                      ) : el.npsScore >= 7 ? (
                                        <img src={emojiYellow} alt="yellow emoji"></img>
                                      ) : (el.npsScore >= 0 && el.isAnswered) ? (
                                        <img src={emojiRed} alt="red emoji"></img>
                                      ) : (<img src={emojiGrey} alt="grey emoji"></img>)}
                                    </td>

                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </LoadingOverlay>
                      <CardFooter className="py-4">
                        {nameSearch == false && (
                          <ReactPaginate
                            previousLabel={<i className="fas fa-angle-left" />}
                            nextLabel={<i className="fas fa-angle-right" />}
                            breakLabel={"..."}
                            pageCount={surveyAnswers && surveyAnswers.total / limit}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={pageClickHandler}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link custom-pageLink"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link custom-pageArrow"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link custom-pageArrow"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active custom-active"}
                          />
                        )}
                      </CardFooter>
                    </>
                    <Row className="mt-5">
                      <Col md={4}>
                        <Row className="d-flex flex-column m-1">
                          {" "}
                          <p className="reviewcardtitle">Beratung/Verkauf</p>{" "}
                          {npsLoading.consulting ? (<Col className="d-flex align-items-center justify-content-center" style={{ height: "10vh" }}><Spinner></Spinner></Col>) : (npsReviews.consulting.map(el => (
                            <ReviewCard customerName={`${el.customerId.name} ${el.customerId.lastName}`} reviewText={el.answers[0].answer} npsScore={el.npsScore} date={el.updatedAt} id={el._id} category="consulting" npsNumber={el.npsScore} />
                          )))}
                        </Row>
                        {/* <Row className="mt-2 d-flex flex-column align-items-center justify-content-center">
                    <Button onClick={() => { setConsultingReviewsPage(prev => prev + 1) }} className="more-button">NACHLADEN</Button>
                    <Button className="details-button mt-2">DETAILS</Button>
                  </Row> */}
                      </Col>
                      <Col md={4}>
                        <Row className="d-flex flex-column m-1">
                          <p className="reviewcardtitle">Service</p>
                          {npsLoading.service ? (<Col className="d-flex align-items-center justify-content-center" style={{ height: "10vh" }}><Spinner></Spinner></Col>) : (npsReviews.service.map(el => (
                            <ReviewCard customerName={`${el.customerId?.name} ${el.customerId?.lastName}`} reviewText={el.answers[0].answer} npsScore={el.npsScore} date={el.updatedAt} id={el._id} category="service" npsNumber={el.npsScore} />
                          )))}
                        </Row>
                        {/* <Row className="mt-2 d-flex flex-column align-items-center justify-content-center">
                    <Button onClick={() => { setServiceReviewsPage(prev => prev + 1) }} className="more-button">NACHLADEN</Button>
                    <Button className="details-button mt-2">DETAILS</Button>
                  </Row> */}
                      </Col>
                      <Col md={4}>
                        <Row className="d-flex flex-column m-1">
                          <p className="reviewcardtitle">Schaden</p>
                          {npsLoading.performance ? (<Col className="d-flex align-items-center justify-content-center" style={{ height: "10vh" }}><Spinner></Spinner></Col>) : (npsReviews.performance.map(el => (
                            <ReviewCard customerName={`${el.customerId.name} ${el.customerId.lastName}`} reviewText={el.answers[0].answer} npsScore={el.npsScore} id={el._id} date={el.updatedAt} category="performance" npsNumber={el.npsScore} />
                          )))}
                        </Row>
                        {/* <Row className="mt-2 d-flex flex-column align-items-center justify-content-center">
                    <Button onClick={() => { setPerformanceReviewPage(prev => prev + 1) }} className="more-button">NACHLADEN</Button>
                    <Button className="details-button mt-2">DETAILS</Button>
                  </Row> */}
                      </Col>
                    </Row>
                  </div>
                </Row>
              </>)}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
