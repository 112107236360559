/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const NOTIFICATION = "/notification";
const MARKASREAD='/markAsRead'
const METRICS='/metrics'
export default {
    
    markNotificationAsRead(id) {
      return Repository.put(`${NOTIFICATION}/${id}`);
    },
    
    fetchNotifications(payload) {
      if (!payload) {
        // Handle the case where payload is undefined or null
        return Repository.get(`${NOTIFICATION}`);
      }
      const queryParams = Object.entries(payload) //create query string 
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
      return Repository.get(`${NOTIFICATION}?${queryParams}`);
    },
    createNotification(payload) {
        return Repository.post(`${NOTIFICATION}`, payload);
    },
   getNotificationMetrics() {
      return Repository.get(`${NOTIFICATION}${METRICS}`);
  },
    markNotificationsAsRead(){
      return Repository.get(`${NOTIFICATION}/${MARKASREAD}`);
    }
  };
  