import React, { useEffect, useState } from "react";
import OnlyHeader from "components/Headers/OnlyHeader";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  Input,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Spinner,
  Label,
} from "reactstrap";
import {
  getSurveys,
  searchByNameSurvey,
} from "store/action/surveyAction";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { addSurvey } from "store/action/surveyAction";
import { updateSurvey } from "store/action/surveyAction";
import { deleteSurvey } from "store/action/surveyAction";

function Surveys() {
  const history = useHistory();
  const { user } = useSelector((state) => state.authUser);
  const { surveys, surveyLoading, loading } = useSelector((state) => state.survey);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpendel, setIsOpendel] = useState(false);
  const [isOpenedit, setIsOpenedit] = useState(false);
  const [categorySearch, setNameSearch] = useState(false);
  const [searchByName, setSearchByName] = useState("");
  const [supportLists,setSupportLists]=useState([""])
  const [surveyData, setSurveyData] = useState({
    category: "",
    email:"",
    npsQuestion:"How likely are you to recommend our Beratung service to a friend or colleague?"
  });

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [id, setId] = useState("");
  const handleSurveyDataChange = (e) => {
      e.preventDefault();
      setSurveyData({ ...surveyData, [e.target.category]: e.target.value });
  };

  const handleAddSupportLists = () => {
    setSupportLists([...supportLists, ""]);
  };
  const handleRemoveSupportLists = (ind) => {
    let temp = supportLists;
    temp.splice(ind, 1);
    setSupportLists([...temp]);
  };

  const setValueSupportLink = (ind, e) => {
    let temp = [...supportLists];
    temp[ind]= e.target.value;
    setSupportLists(temp);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggledel = () => {
    setIsOpendel(!isOpendel);
  };
  const toggleedit = () => {
    setIsOpenedit(!isOpenedit);
  };
  const pageClickHandler = (e) => {
    setPage(e.selected + 1);
  };

  const clearFields = () => {
    setSurveyData({
      category: "",
      email:"",
    })
  };

  console.log("surveys", surveys);
  useEffect(() => {
    dispatch(getSurveys({ limit: limit, pageNo: page }));
  }, [page]);
  return (
    <div>
      <OnlyHeader />
      {/* Modal */}
      <div></div>
      <Container className="mt--7" fluid>
        <h1 className="">Surveys</h1>

        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <h3 className="mb-0">Surveys</h3>
                  </Col>
                    <Col className="text-right">
                      <Button
                        className="justify-content-right"
                        color="primary"
                        size="xl"
                        onClick={() => {
                          toggle();
                        }}
                      >
                        ADD SURVEY
                      </Button>
                    </Col>
                </Row>

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setNameSearch(true);
                    dispatch(searchByNameSurvey(searchByName));
                  }}
                >
                  <Row className="mt-3">
                    <Col md="4" sm="8">
                      <Label>Search</Label>

                      <Input
                        type="text"
                        readOnly={categorySearch}
                        placeholder="Search by category"
                        value={searchByName}
                        onChange={(e) => setSearchByName(e.target.value)}
                        required
                      ></Input>
                    </Col>
                    <Col md="2" sm="4" className="d-flex align-items-end mt-3">
                      {categorySearch ? (
                        <Button
                          color="danger"
                          className="mt-3 mt-md-0"
                          size="md"
                          type="button"
                          disabled={surveyLoading}
                          onClick={() => {
                            setSearchByName("");
                            setNameSearch(false);
                            dispatch(
                              getSurveys({
                                limit: limit,
                                pageNo: page,
                              })
                            );
                          }}
                        >
                          Clear
                        </Button>
                      ) : (
                        <Button
                          color="success"
                          className="mt-3 mt-md-0"
                          size="md"
                          type="submit"
                          disabled={surveyLoading}
                        >
                          Search
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <LoadingOverlay active={surveyLoading} spinner text="Loading....">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>


                        <th scope="col">Actions</th>

                    </tr>
                  </thead>
                  {surveys &&
                    surveys.data &&
                    surveys.data.map((el) => {
                      return (
                        <tbody>
                          <tr>
                            <td>{`${el.category}`}</td>

                              <td>
                                <UncontrolledTooltip target="edit">
                                  Edit
                                </UncontrolledTooltip>
                                <Button
                                  id="edit"
                                  size="sm"
                                  color="info"
                                  onClick={() => {
                                    toggleedit();
									                  setId(el._id)
                                    setSurveyData({
                                      ...el
                                    })
                                  }}
                                >
                                  <i className="fas fa-pen"></i>
                                </Button>
                                <UncontrolledTooltip target="delete">
                                  Delete
                                </UncontrolledTooltip>
                                <Button
                                  id="delete"
                                  size="sm"
                                  className="ml-2"
                                  color="danger"
                                  onClick={() =>
                                    dispatch(
                                      deleteSurvey(el._id, () => {
                                        dispatch(
                                          getSurveys({ limit: 10, pageNo: page })
                                        );
                                      })
                                    )
                                  }
                                >
                                  <i className="fas fa-trash"></i>
                                </Button>
                              </td>


                          </tr>
                        </tbody>
                      );
                    })}
                </Table>
              </LoadingOverlay>
              <CardFooter className="py-4">
                {categorySearch == false && (
                  <ReactPaginate
                    previousLabel={<i className="fas fa-angle-left" />}
                    nextLabel={<i className="fas fa-angle-right" />}
                    breakLabel={"..."}
                    pageCount={surveys && surveys.total / limit}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={pageClickHandler}
                    containerClassName={"pagination justify-content-end"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>

      <div className="ml-5">
        {/* Add survey */}
        <Modal isOpen={isOpen} toggle={toggle}>
          <Form
            role="form"
            onSubmit={async (e) => {
              e.preventDefault();
              console.log(surveyData,"SurveyData");
              await dispatch(
                addSurvey(surveyData, () => {
                  dispatch(
                    getSurveys({
                      limit: 10,
                      pageNo: page,
                    })
                  );
                  clearFields();
                  toggle();
                })
              );
            }}
          >
            <ModalHeader
              toggle={() => {
                toggle();
                clearFields();
              }}
            >
              <h2>Add Survey</h2>
            </ModalHeader>
            <ModalBody>
              <Row>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Name:</label>
                  </Row>

                  <Input
                    placeholder="Category"
                    required
                    type="text"
                    name="name"
                    value={surveyData.name}
                    onChange={(e) => {
                      handleSurveyDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Category:</label>
                  </Row>

                  <Input
                    name="role"
                    required
                    type="select"
                    value={surveyData.category}
                    onChange={(e) => {
                      handleSurveyDataChange(e);
                    }}
                  >
                    <option value="Beratung">Beratung</option>
                    <option value="Service">Service</option>
                    <option value="Schaden">Schaden</option>
                    <option value="other">Other</option>
                  </Input>
                </Col>
                <Col md="12 mt-3">
                      <h3>NPS Question</h3>
                    </Col>
                    <Col md="12">
                  <Input
                    placeholder="NPS question"
                    required
                    type="textarea"
                    name="npsQuestion"
                    value={surveyData.npsQuestion}
                    onChange={(e) => {
                      handleSurveyDataChange(e);
                    }}
                  ></Input>
                    </Col>
                <Col md="12 mt-3">
                      <h3>Questions</h3>
                    </Col>
                    <Col md="12">
                      <div className="my-2 ml-3">
                        {/* <Label>Support Lists</Label> */}

                        {supportLists.map((obj, ind) => {
                          return (
                            <Col className=" d-flex my-1 pl-0" md="12">
                              <Input
                                className=" w-100 mr-2"
                                required
                                name="supportLists"
                                placeholder="Enter Question"
                                value={obj.supportLists}
                                onChange={(e) => {
                                  setValueSupportLink(ind, e);
                                }}
                              />

                              {supportLists.length > 1 &&
                                ind != supportLists.length - 1 && (
                                  <Button
                                    className="addword-icon-minus"
                                    onClick={() => {
                                      handleRemoveSupportLists(ind);
                                    }}
                                  >
                                    <i
                                      className="fa fa-minus-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </Button>
                                )}
                              {supportLists.length - 1 == ind && (
                                <>
                                  <Button
                                    className="addword-icon"
                                    onClick={handleAddSupportLists}
                                  >
                                    {" "}
                                    <span>
                                      <i class="fas fa-plus-circle"></i>
                                    </span>{" "}
                                  </Button>
                                </>
                              )}
                            </Col>
                          );
                        })}
                      </div>
                    </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                disabled={loading}
                onClick={() => {
                  toggle();
                  clearFields();
                }}
              >
                Close
              </Button>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? <Spinner size="sm" /> : "Add"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        {/* Edit modal */}
        <Modal isOpen={isOpenedit} toggle={toggleedit}>
          <Form
            role="form"
            onSubmit={async (e) => {
              e.preventDefault();
              const data={
                email:surveyData.email,
                category:surveyData.category,
              }
              await dispatch(
                updateSurvey(surveyData, id, () => {
                  dispatch(
                    getSurveys({
                      limit: 10,
                      pageNo: page,
                    })
                  );
                  toggleedit();
                  clearFields();
                })
              );
            }}
          >
            <ModalHeader
              toggle={() => {
                toggleedit();
                clearFields();
              }}
            >
              <h2>Questions</h2>
            </ModalHeader>
            <ModalBody>
            <Row>
              <Col md="6" className="my-2">

                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                disabled={loading}
                onClick={() => {
                  toggleedit();
                  clearFields();
                }}
              >
                Close
              </Button>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? <Spinner size="sm" /> : "Update"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={isOpendel} toggle={toggledel}>
          <ModalHeader>
            <ModalBody>
              <h2>Are you sure you want to delete?</h2>
            </ModalBody>
          </ModalHeader>
          <ModalFooter>
            <Row className="text-right">
              <Button color="secondary" onClick={toggledel}>
                No
              </Button>

              <Button
                color="primary"
                onClick={async () => {
                  toggledel();
                }}
              >
                Yes
              </Button>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Surveys;
