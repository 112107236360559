import { RepositoryFactory } from "repository/RepositoryFactory";
const surveyanswer=RepositoryFactory.get("surveyAnswer")

export const setSurveyLoading = val => async dispatch => {
	dispatch({ type: 'SURVEYANSWER_LOADING', payload: val });
};
export const setIsAnswered = val => async dispatch => {
	dispatch({ type: 'IS_SURVEY_ANSWERED', payload: val });
};
export const getSurveyAnswers = payload => async dispatch => {
	try {
		await dispatch(setSurveyLoading(true));
		let { data } = await surveyanswer.getSurveyAnswers(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_SURVEY_ANSWERS',
				payload: data,
			});
		}
		dispatch(setSurveyLoading(false));
	} catch (e) {
		dispatch(setSurveyLoading(false));
		alert(e.message);
	}
};
export const verifySurveyAnswerToken =
(id, onSuccess = () => {}) =>
async dispatch => {
    await dispatch(setSurveyLoading(true));
    try {
        let { data } = await surveyanswer.verifySurveyAnswer(id);
        if (data.success) {
            await dispatch(setSurveyLoading(false));
            await dispatch(setIsAnswered(data.data.isAnswered));
                await dispatch({
                    type: 'GET_SURVEY_ANSWER',
                    payload: data.data,
                });

            // alert('Survey sent successfully!');
            onSuccess();
        }
    } catch (e) {
        dispatch(setSurveyLoading(false));
        alert(e.response.data.message);
        window.close()
    }
};

export const submitSurveyAnswer =
(payload,id, onSuccess = () => {}) =>
async dispatch => {
    await dispatch(setSurveyLoading(true));
    try {
        let { data } = await surveyanswer.submitSurveyAnswer(payload,id);
        if (data.success) {
            await dispatch(setSurveyLoading(false));
            onSuccess();
        }
    } catch (e) {
        dispatch(setSurveyLoading(false));
        alert(e.response.data.message);
    }
};

export const bookmarkSurveyAnswer =
(id, category) =>
async dispatch => {
    await dispatch(setSurveyLoading(true));
    try {
        let { data } = await surveyanswer.bookmarkSurveyAnswer(id);
        if (data.success) {
            await dispatch({
                type: 'BOOKMARK_SURVEY_ANSWER',
                payload: {id,data:data.data,category:category},
            });
        }
    } catch (e) {
        dispatch(setSurveyLoading(false));
        console.log(e);
        alert(e.response.message);
    }
};