import React, { useState,useEffect } from 'react'
import OnlyHeader from 'components/Headers/OnlyHeader'
import {
    Button,
    Card,
    CardHeader,
    Col,
    Form,
    Input,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Spinner,
    Label,
} from "reactstrap";
import CustomersIcon from '../assets/img/icons/costumers-blue.svg';
import { useDispatch, useSelector } from "react-redux";
import { updateAdmin } from 'store/action/adminAction';
import { useHistory } from "react-router-dom";
import { uploadLogo } from 'store/action/adminAction';
import axios from 'axios';
import { getAdminById } from 'store/action/adminAction';



export default function AdminProfile() {
    const { user } = useSelector((state) => state.authUser);
    const adminId = user._id;
    const dispatch = useDispatch();
    const [adminData, setAdminData] = useState({
        name: "",
        email: "",
        lastName: "",
        role: "admin",
        greetings: "formal",
        salutation: "",
        title: "",
        adminNo: "",
        firm: "",
        telephone: "",
        mobile: "",
        streetNo: "",
        zip: "",
        location: ""
        // contactNumber: "",
    });
    const { admin, adminLoading, loading,adminLogoLoading } = useSelector((state) => state.admin);
    const history = useHistory();
    const handleAdminDataChange = (e) => {
        // e.preventDefault();
        // setAdminData({ ...adminData, [e.target.name]: e.target.value });
        if (e.target.name === "greetings") {
            setAdminData({ ...adminData, [e.target.name]: e.target.value });
        } else {
            e.preventDefault();
            setAdminData({ ...adminData, [e.target.name]: e.target.value });
        }
    };

    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(user.logoURL && user.logoURL !== '' ? user.logoURL : 'https://images.crutchfieldonline.com/ImageBank/v20150915135000/dummyphoto405x300.jpg');
    const handleImageInputChange = (event) => {
        setSelectedImage(event.target.files[0]);
        // Preview image
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    useEffect(() => {
        dispatch(getAdminById(adminId));
    }, [adminId]);
    useEffect(() => {
        if (admin && admin.data) {
            setAdminData({
                name: admin.data.name,
                email: admin.data.email,
                lastName: admin.data.lastName,
                role: admin.data.role,
                greetings: admin.data.greetings,
                salutation: admin.data.salutation,
                title: admin.data.title,
                adminNo: admin.data.adminNo,
                firm: admin.data.firm,
                telephone: admin.data.telephone,
                mobile: admin.data.mobile,
                streetNo: admin.data.streetNo,
                zip: admin.data.zip,
                location: admin.data.location
                // contactNumber: "",
            });
            setImagePreviewUrl(admin.data.logoURL);
        }
        
    }, [admin])
    return (
        <div>
            <OnlyHeader />
            <Container className='mt--7' fluid>
                <Row className='display-flex justify-content-center'>
                    <Col md={7} className='pt-6 pb-6'>
                        <div className='customersHeading mb-5'>
                            <img src={CustomersIcon}></img>
                            <p className='custHeading'>Kunde {user.name} {user.lastName}</p>
                        </div>
                        {/* <div className='tabs'>

                        </div> */}
                        <div className='form-fields'>
                            <div>
                                <Form
                                    role="form"
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        console.log(adminData);
                                        await dispatch(
                                            updateAdmin(adminData, adminId)
                                        );
                                        // e.preventDefault();
                                        // console.log(adminData, "AdminData");
                                        // await dispatch(
                                        //     addAdmin(adminData, () => {
                                        //         history.push('/admin/admins')
                                        //         clearFields();
                                        //     })
                                        // );
                                    }}
                                >
                                    <Row>
                                        <Col xs={{ size: 4, order: 1 }} lg={2} className='mt-3'>
                                            <p className="ss-fieldLabel">Kunde</p>
                                            <Input
                                                className='addAdminSelect'
                                                name="salutation"
                                                required
                                                type="select"
                                                value={adminData.salutation}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            >
                                                {/* <option value="">Select role</option> */}

                                                <option value="Mr">Mr</option>
                                                <option value="Mrs">Mrs</option>
                                                <option value="Miss">Miss</option>
                                            </Input>
                                        </Col>
                                        <Col xs={{ size: 12, order: 3 }} lg={{ size: 6, order: 2 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Titel</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Title"
                                                    type="text"

                                                    value={adminData.title}
                                                    name="title"

                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={{ size: 8, order: 2 }} lg={{ size: 4, order: 3 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Kunden-Nr</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Customer Number"
                                                    type="text"
                                                    value={adminData.adminNo}
                                                    name="adminNo"
                                                    disabled
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Vorname *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="First Name"
                                                    required
                                                    type="text"
                                                    name="name"
                                                    value={adminData.name}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Nachname *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Last Name"
                                                    required
                                                    type="text"
                                                    name="lastName"
                                                    value={adminData.lastName}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='mt-3'>
                                            <p className="ss-fieldLabel">Firma</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Firm"
                                                    name="firm"
                                                    type="text"
                                                    value={adminData.firm}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='mt-3'>
                                            <p className="ss-fieldLabel">E-mail *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Email"
                                                    name="email"
                                                    required
                                                    type="text"
                                                    value={adminData.email}
                                                    disabled
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Telefon *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Telephone"
                                                    name="telephone"
                                                    type="text"
                                                    required
                                                    value={adminData.telephone}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Mobil</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Mobile"
                                                    name="mobile"
                                                    type="text"
                                                    value={adminData.mobile}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Straße + Nr. *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Street No"
                                                    name="streetNo"
                                                    type="text"
                                                    required
                                                    value={adminData.streetNo}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">PLZ *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Zip Code"
                                                    name="zip"
                                                    type="text"
                                                    required
                                                    value={adminData.zip}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Ort *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Location"
                                                    name="location"
                                                    type="text"
                                                    required
                                                    value={adminData.location}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className=' mt-3'>
                                        {/* <Col lg={1}></Col> */}
                                        <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                                            <Input
                                                name="greetings"
                                                className=""
                                                type="radio"
                                                value="formal"
                                                checked={adminData.greetings === "formal"}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            />
                                            <p className="greeting-radio-text">Formelle Anrede</p>
                                        </Col>
                                        <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                                            <Input
                                                name="greetings"
                                                className=""
                                                type="radio"
                                                value="informal"
                                                checked={adminData.greetings === "informal"}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            />
                                            <p className="greeting-radio-text">Informelle Anrede</p>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-end mt-5">
                                        <Button color="primary" type="submit" disabled={loading} className='addAdminButton'>
                                            {loading ? <Spinner size="sm" /> : "Bearbeiten"}
                                        </Button>
                                    </div>

                                </Form>
                            </div>
                        </div>

                    </Col>
                    <Col md={4} className='pt-6 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imagePreviewUrl} width={250} height={250} className='adminImage'></img>

                        <label htmlFor="imageInput" className="addAdminButton mt-3 d-flex align-items-center justify-content-center button-width select-logo-button">
                            Preview Logo
                        </label>
                        <input
                            type="file"
                            id="imageInput"
                            name="image"
                            accept="image/*"
                            onChange={handleImageInputChange}
                            style={{ display: 'none' }}
                        />
                        <Button color="primary" className='addAdminButton mt-1 ' disabled={adminLogoLoading} onClick={async (e) => {
                            e.preventDefault()
                            const formData = new FormData();
                            formData.append('file', selectedImage);
                            // await axios.post(`http://localhost:5000/admin/uploadLogo/${user._id}`,formData,{
                            //     headers: {
                            //       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0MTk0MDFkZmVmZWM1M2IwZWQ4YmQ2OCIsImlhdCI6MTY4Mjk1MjMwMSwiZXhwIjoxNjgzMDM4NzAxfQ.WBUdT3UI5DSOYJCh3NIS929kxR9J-vNeJPbcNcPguIU`
                            //     }
                            //   }).then(response => {
                            //     // alert(response.message);
                            //     alert(response.data.message);
                            //   }).catch(error => {
                            //     // handle the error
                            //     console.log(error.response.data); // Check if there's an error message in the response
                            //   });
                            await dispatch(uploadLogo(formData, user._id))
                        }

                        }>
                            {adminLogoLoading ? <Spinner size="sm" /> : "Upload Logo"}
                        </Button>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}
