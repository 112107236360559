import React, { useCallback, useEffect, useState } from "react";
import OnlyHeader from "components/Headers/OnlyHeader";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  Input,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Spinner,
  Label,
} from "reactstrap";
import {
  getAdmins,
  searchByNameAdmin,
} from "store/action/adminAction";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { addAdmin } from "store/action/adminAction";
import { updateAdmin } from "store/action/adminAction";
import { deleteAdmin } from "store/action/adminAction";
import customersLogo from "../assets/img/icons/costumers-blue.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { updateAdminStatus } from "store/action/adminAction";
import debounce from "lodash/debounce";
import { sendSummary } from "store/action/adminAction";

function Admins() {
  const history = useHistory();
  const { user } = useSelector((state) => state.authUser);
  const { admins, adminLoading, loading } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpendel, setIsOpendel] = useState(false);
  const [isOpenedit, setIsOpenedit] = useState(false);
  const [adminData, setAdminData] = useState({
    name: "",
    email: "",
    lastName: "",
    role: "admin",
    greetings: "formal",
    salutation: "",
    title: "",
    adminNo: "",
    firm: "",
    telephone: "",
    mobile: "",
    streetNo: "",
    zip: "",
    location: "",
    isEnabled: false,
    // contactNumber: "",
  });

  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchToggle = () => {
    setIsChecked(!isChecked);
  };

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  const [showPopovers, setShowPopovers] = useState([]);
  const debounceSearch = useCallback(
    debounce(async (value) => {
      if (page !== 1) {
        setPage(1);
      }
      dispatch(getAdmins({ limit: limit, page: page ,search:value }));
    }, 1000),
    []
  );
  
  const handleAdminDataChange = (e) => {
    e.preventDefault();
    setAdminData({ ...adminData, [e.target.name]: e.target.value });
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggledel = () => {
    setIsOpendel(!isOpendel);
  };
  const toggleedit = () => {
    setIsOpenedit(!isOpenedit);
  };
  const pageClickHandler = (e) => {
    setPage(e.selected + 1);
  };

  const clearFields = () => {
    setAdminData({
      name: "",
      email: "",
      lastName: "",
      role: "admin",
      greetings: "formal",
      salutation: "",
      title: "",
      adminNo: "",
      firm: "",
      telephone: "",
      mobile: "",
      streetNo: "",
      zip: "",
      location: "",
      isEnabled: false,
      // contactNumber: "",
    })
  };
  const handlePopoverClose = (index) => {
    // for overlay triggers
    const newShowPopovers = [...showPopovers];
    newShowPopovers[index] = false;
    setShowPopovers(newShowPopovers);
  };
  const handleSendSummary=(id,email)=>{
    const confirmed = window.confirm(`Zusammenfassung an ${email} senden?`);
    if(confirmed){
      dispatch(sendSummary(id))
    }
  }
  useEffect(() => {
    dispatch(getAdmins({ limit: limit, page: page,search }));
    console.log(admins);
  }, [page]);
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
    dispatch(getAdmins({ limit: limit, page: page ,search }))
    } else {
      debounceSearch(e.target.value);
    }
  }
  const clearSearch=()=>{
    setSearch("")
    if (page !== 1) {
      setPage(1);
    }
    dispatch(getAdmins({ limit: limit, page: page }));
  }
  const handleClickOutside = (event) => {
    // Check if the click is outside any popover
    if (!event.target.closest('.popover')) {
      setShowPopovers(Array(admins.data?.length).fill(false));
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div>
      <OnlyHeader />
      {/* Modal */}
      <div></div>
      <Container fluid className="mt--7 main-container">
        {/* <h1 className="">Admins</h1> */}

        <Row>
          <div className="col">
            {/* <Card className="shadow"> */}
            <CardHeader className="border-0">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Row className="mt-3 align-items-center justify-content-center">
                  <Col
                    md={{
                      // offset: 1,
                      size: 3,
                    }}
                  >
                    <Row className="mt-2 align-items-center justify-content-center">
                      {" "}
                      <img
                        className="mb-2"
                        src={customersLogo}
                        alt="customers"
                      ></img>{" "}
                      <p className="text-search-header ml-2">{`Admin(${admins.total ? admins?.total : " "
                        })`}</p>
                    </Row>
                  </Col>
                  <Col
                    md="4"
                  // sm="8"
                  >
                    <div className="search-input-container">
                      <Input
                        className="search-input"
                        placeholder="Admin durchsuchen …"
                        type="text"
                        value={search}
                        name="name"
                        required
                        onInput={(e) => {
                          handleSearch(e);
                        }}
                      />
                        {search && (
                          <span
                            className="clear-icon"
                            onClick={clearSearch}
                          >
                            <i class="fas fa-times fa-md"></i>
                          </span>
                        )}
                      <span className="search-icon">
                        <i class="fas fa-search fa-lg"></i>
                      </span>
                    </div>
                  </Col>
                  <Col
                    md="4"
                    sm="4"
                    className="d-flex align-items-end justify-content-center"
                  >
                    <Button
                      className="mt-3 mt-md-0 add-customer-button"
                      disabled={adminLoading}
                      onClick={() => { history.push("/admin/addadmin") }}
                    >
                      Admin anlegen
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardHeader>


            <LoadingOverlay active={adminLoading} spinner text="Loading....">

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Kd.-Nr</th>
                    <th scope="col">Name</th>
                    <th scope="col">Firma</th>
                    <th scope="col">Email</th>
                    <th scope="col" className="text-center">Rolle</th>
                    <th scope="col" className="text-center">Status</th>
                    <th scope="col" className="text-center">Details</th>

                  </tr>
                </thead>
                <tbody>
                  {admins &&
                    admins.data &&
                    admins.data.map((el, index) => {
                      return (
                        <tr>
                          <td>{!adminLoading ? (index + 1) + (10 * (page - 1)) : ""}</td>
                          <td>{`${el.lastName}, ${el.name}`}</td>
                          <td>{`${el?.firm}`}</td>
                          <td>{el.email}</td>
                          <td className="text-center">{el.role=="admin"?'A':'AP'}</td>
                          <td className="d-flex align-item-center justify-content-center">  
                            <div
                              className={`switch-toggle ${el.isEnabled ? 'on' : 'off'}`}
                              onClick={async () => {
                                await dispatch(updateAdminStatus(el._id,()=>{
                                  dispatch(getAdmins({ limit: limit, page: page }));
                                }));
                                
                              }}
                            >
                              <div className="switch-knob" />
                            </div>
                          </td>
                          <td className="text-center">
                            <OverlayTrigger
                              key={index}
                              trigger="focus"
                              placement="bottom"
                              rootClose={true}
                              rootCloseEvent="click"
                              onHide={() => handlePopoverClose(index)}
                              show={showPopovers[index]}
                              overlay={
                                <Popover
                                  id={`popover-positioned-bottom-${index}`}
                                >
                                  <Popover.Body>
                                    <Row className="popover-row" onClick={() => {
                                      history.push(`/admin/view-admin?id=${el._id}`)
                                    }}>Anzeigen</Row>
                                    <Row className="popover-row" onClick={() => {
                                      history.push(`/admin/editadmin?id=${el._id}`)

                                    }}>Bearbeiten</Row>
                                    <Row className="popover-row" onClick={() => {
                                      history.push(`/admin/adminstatistics?id=${el._id}`)

                                    }}>Statistiken</Row>
                                    <Row className="popover-row" onClick={() => {handleSendSummary(el._id,el.email)}}>Zusammenfassung senden</Row>
                                    {/* <Row>Duplizieren</Row> */}
                                    {/*<======== Edit Admin ========> */}

                                    {/* onClick={() => {
                                      toggleedit();
                                      setId(el._id)
                                      setAdminData({
                                        ...el
                                      })
                                    }} */}
                                    <Row
                                    className="popover-row"
                                      onClick={() => {
                                        const confirmed = window.confirm('Mit dieser Aktion wird dieser Kunde aus der Datenbank gelöscht. Sind Sie sicher?');
                                        if (confirmed) {
                                          dispatch(
                                            deleteAdmin(el._id, () => {
                                              dispatch(
                                                getAdmins({ limit: 10, page: page })
                                              );
                                            })
                                          )
                                        }
                                        handlePopoverClose(index);
                                      }
                                      }
                                    >
                                      Löschen
                                    </Row>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <i
                                style={{
                                  cursor: "pointer",
                                  transform: `rotate(90deg)`,
                                }}
                                className="fas fa-ellipsis-v"
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  e.preventDefault(); 
                                  e.nativeEvent.stopImmediatePropagation();
                                  const newShowPopovers = [...showPopovers];
                                  newShowPopovers[index] =
                                    !showPopovers[index];
                                  setShowPopovers(newShowPopovers);
                                }}
                              ></i>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </LoadingOverlay>
            <CardFooter className="py-4">
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left" />}
                  nextLabel={<i className="fas fa-angle-right" />}
                  breakLabel={"..."}
                  pageCount={admins && admins.total / limit}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={pageClickHandler}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link custom-pageLink"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link custom-pageArrow"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link custom-pageArrow"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active custom-active"}
                />
            </CardFooter>
            {/* </Card> */}
          </div>
        </Row>
      </Container>

      <div className="ml-5">
        {/* Add admin */}
        <Modal isOpen={isOpen} toggle={toggle}>
          <Form
            role="form"
            onSubmit={async (e) => {
              e.preventDefault();
              console.log(adminData, "AdminData");
              await dispatch(
                addAdmin(adminData, () => {
                  dispatch(
                    getAdmins({
                      limit: 10,
                      page: page,
                    })
                  );
                  clearFields();
                  toggle();
                })
              );
            }}
          >
            <ModalHeader
              toggle={() => {
                toggle();
                clearFields();
              }}
            >
              <h2>Add Admin</h2>
            </ModalHeader>
            <ModalBody>
              <Row>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Andred:</label>
                  </Row>

                  <Input
                    placeholder="salutation"
                    name="salutation"
                    type="text"
                    value={adminData.salutation}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Titel</label>
                  </Row>

                  <Input
                    placeholder="Title"
                    name="title"
                    type="text"
                    value={adminData.title}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Kunden-Nr</label>
                  </Row>

                  <Input
                    placeholder="Customer Number"
                    name="adminNo"
                    type="text"
                    value={adminData.adminNo}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Firma</label>
                  </Row>

                  <Input
                    placeholder="Firm"
                    name="firm"
                    type="text"
                    value={adminData.firm}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Email:</label>
                  </Row>

                  <Input
                    placeholder="Email"
                    name="email"
                    required
                    type="text"
                    value={adminData.email}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>First Name:</label>
                  </Row>

                  <Input
                    placeholder="First Name"
                    required
                    type="text"
                    name="name"
                    value={adminData.name}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Last Name:</label>
                  </Row>

                  <Input
                    placeholder="Last Name"
                    required
                    type="text"
                    name="lastName"
                    value={adminData.lastName}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Telefon</label>
                  </Row>

                  <Input
                    placeholder="Telephone"
                    name="telephone"
                    type="text"
                    required
                    value={adminData.telephone}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Mobil</label>
                  </Row>

                  <Input
                    placeholder="Mobile"
                    name="mobile"
                    type="text"
                    value={adminData.mobile}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Straße + Nr. *</label>
                  </Row>

                  <Input
                    placeholder="Street No"
                    name="streetNo"
                    type="text"
                    required
                    value={adminData.streetNo}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>PLZ</label>
                  </Row>

                  <Input
                    placeholder="Zip Code"
                    name="zip"
                    type="text"
                    required
                    value={adminData.zip}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>ORT</label>
                  </Row>

                  <Input
                    placeholder="Location"
                    name="location"
                    type="text"
                    required
                    value={adminData.location}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Greetings:</label>
                  </Row>

                  <Input
                    name="greetings"
                    required
                    type="select"
                    value={adminData.greetings}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  >
                    {/* <option value="">Select role</option> */}

                    <option value="formal">Formal</option>
                    <option value="informal">Informal</option>
                  </Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Role:</label>
                  </Row>

                  <Input
                    name="role"
                    required
                    type="select"
                    value={adminData.role}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  >
                    {/* <option value="">Select role</option> */}

                    <option value="admin">Admin</option>
                    <option value="super-admin">Super-Admin</option>
                  </Input>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                disabled={loading}
                onClick={() => {
                  toggle();
                  clearFields();
                }}
              >
                Close
              </Button>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? <Spinner size="sm" /> : "Add"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        {/* Edit modal */}
        <Modal isOpen={isOpenedit} toggle={toggleedit}>
          <Form
            role="form"
            onSubmit={async (e) => {
              e.preventDefault();
              const data = {
                // contactNumber:adminData.contactNumber,
                greetings: adminData.greetings,
                email: adminData.email,
                name: adminData.name,
                lastName: adminData.lastName,
                title: adminData.title,
                salutation: adminData.salutation,
                adminNo: adminData.adminNo,
                firm: adminData.firm,
                telephone: adminData.telephone,
                mobile: adminData.mobile,
                streetNo: adminData.streetNo,
                zip: adminData.zip,
                location: adminData.location,
                role: adminData.role,
              }
              console.log(data, "data");
              await dispatch(
                updateAdmin(adminData, id, () => {
                  dispatch(
                    getAdmins({
                      limit: 10,
                      page: page,
                    })
                  );
                  toggleedit();
                  clearFields();
                })
              );
            }}
          >
            <ModalHeader
              toggle={() => {
                toggleedit();
                clearFields();
              }}
            >
              <h2>Update Admin</h2>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Andred:</label>
                  </Row>

                  <Input
                    placeholder="salutation"
                    name="salutation"
                    type="text"
                    value={adminData.salutation}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Titel</label>
                  </Row>

                  <Input
                    placeholder="Title"
                    name="title"
                    type="text"
                    value={adminData.title}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Kunden-Nr</label>
                  </Row>

                  <Input
                    placeholder="Customer Number"
                    name="adminNo"
                    type="text"
                    value={adminData.adminNo}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Firma</label>
                  </Row>

                  <Input
                    placeholder="Firm"
                    name="firm"
                    type="text"
                    value={adminData.firm}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Email:</label>
                  </Row>

                  <Input
                    placeholder="Email"
                    name="email"
                    required
                    type="text"
                    value={adminData.email}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>First Name:</label>
                  </Row>

                  <Input
                    placeholder="First Name"
                    required
                    type="text"
                    name="name"
                    value={adminData.name}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Last Name:</label>
                  </Row>

                  <Input
                    placeholder="Last Name"
                    required
                    type="text"
                    name="lastName"
                    value={adminData.lastName}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Telefon</label>
                  </Row>

                  <Input
                    placeholder="Telephone"
                    name="telephone"
                    type="text"
                    required
                    value={adminData.telephone}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Mobil</label>
                  </Row>

                  <Input
                    placeholder="Mobile"
                    name="mobile"
                    type="text"
                    value={adminData.mobile}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Straße + Nr. *</label>
                  </Row>

                  <Input
                    placeholder="Street No"
                    name="streetNo"
                    type="text"
                    required
                    value={adminData.streetNo}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>PLZ</label>
                  </Row>

                  <Input
                    placeholder="Zip Code"
                    name="zip"
                    type="text"
                    required
                    value={adminData.zip}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>ORT</label>
                  </Row>

                  <Input
                    placeholder="Location"
                    name="location"
                    type="text"
                    required
                    value={adminData.location}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Greetings:</label>
                  </Row>

                  <Input
                    name="greetings"
                    required
                    type="select"
                    value={adminData.greetings}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  >

                    <option value="formal">Formal</option>
                    <option value="informal">Informal</option>
                  </Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Role:</label>
                  </Row>

                  <Input
                    name="role"
                    required
                    type="select"
                    value={adminData.role}
                    onChange={(e) => {
                      handleAdminDataChange(e);
                    }}
                  >
                    {/* <option value="">Select role</option> */}

                    <option value="admin">Admin</option>
                    <option value="super-admin">Super-Admin</option>
                  </Input>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                disabled={loading}
                onClick={() => {
                  toggleedit();
                  clearFields();
                }}
              >
                Close
              </Button>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? <Spinner size="sm" /> : "Update"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={isOpendel} toggle={toggledel}>
          <ModalHeader>
            <ModalBody>
              <h2>Are you sure you want to delete?</h2>
            </ModalBody>
          </ModalHeader>
          <ModalFooter>
            <Row className="text-right">
              <Button color="secondary" onClick={toggledel}>
                No
              </Button>

              <Button
                color="primary"
                onClick={async () => {
                  toggledel();
                }}
              >
                Yes
              </Button>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Admins;
