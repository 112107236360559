const initialData = {
	surveyAnswers:[],
	surveyAnswer: {},
	surveyAnswerLoading: false,
    isAnswered:false
};

const surveyAnswerReducer = (state = initialData, action) => {
	switch (action.type) {
		case 'GET_SURVEY_ANSWERS':
			return {
				...state,
				surveyAnswers: action.payload,
			};
        case 'GET_SURVEY_ANSWER':
			return {
				...state,
				surveyAnswer: action.payload,
			};
            case 'IS_SURVEY_ANSWERED':
                return {
                    ...state,
                    isAnswered: action.payload,
                };
		case 'SURVEYANSWER_LOADING':
			return {
				...state,
				surveyAnswerLoading: action.payload,
			};
		default:
			return state;
	}
};

export default surveyAnswerReducer;
