import axios from 'axios';
import { RepositoryFactory } from 'repository/RepositoryFactory';
var notes = RepositoryFactory.get('notes');

export const setNotesLoading = val => async dispatch => {
	dispatch({ type: 'GET_NOTES_LOADING', payload: val });
};
export const getNotesLoading = val => async dispatch => {
	dispatch({ type: 'GET_NOTES_LOADING', payload: val });
};

export const addNote =
	(payload, onSuccess = () => { }) =>
		async dispatch => {
			dispatch(setNotesLoading(true));
			try {
				let { data } = await notes.addNote(payload);

				if (data.success) {
					dispatch(setNotesLoading(false));
					onSuccess();
					alert(data.message);
				}
			} catch (e) {
				dispatch(setNotesLoading(false));
				alert(e.response.data.message);
			}
		};

export const getNotes = payload => async dispatch => {
	try {
		await dispatch(getNotesLoading(true));
		let { data } = await notes.getAllNotes(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_ALL_NOTES',
				payload: data,
			});
		}
		dispatch(getNotesLoading(false));
	} catch (e) {
		dispatch(getNotesLoading(false));
		alert(e.message);
	}
};

export const deleteNote =
	(id, onSuccess = () => { }) =>
		async dispatch => {
			await dispatch(setNotesLoading(true));

			try {
				let { data } = await notes.deleteNotes(id);

				if (data.success) {
					await dispatch(setNotesLoading(false));
					onSuccess();
					alert(data.message);
				}
			} catch (e) {
				dispatch(getNotesLoading(false));
				alert(e.response.data.message);
			}
		};

export const downloadNote =
	(id, onSuccess = () => { }) =>
		async dispatch => {
			await dispatch(setNotesLoading(true));

			try {
				let response = await notes.downloadNote(id);

				// let response = await axios.get(`http://localhost:5000/notes/downloadnote/${id}`);

				
				console.log(response);
				if (response.status == 200) {

					let binaryString = window.atob(response.data.message);
					let binaryLen = binaryString.length;
					let bytes = new Uint8Array(binaryLen);
					for (let i = 0; i < binaryLen; i++) {
						let ascii = binaryString.charCodeAt(i);
						bytes[i] = ascii;
					}
					const blob = new Blob([bytes],  {type: "application/pdf"});
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = 'Note.pdf';
					a.click();
					await dispatch(setNotesLoading(false));
					onSuccess();
					alert('Notiz erfolgreich heruntergeladen');
				}
				else {
					dispatch(getNotesLoading(false));
					alert(response.data.message);
				}

			} catch (e) {
				dispatch(getNotesLoading(false));
				alert(e.response.data.message || 'An error occurred while downloading the PDF');
			}
		};

export const updateNote =
	(obj, id, onSuccess = () => { }) =>
		async dispatch => {
			await dispatch(setNotesLoading(true));
			try {
				let { data } = await notes.updateNotes(obj, id);
				if (data.success) {
					await dispatch(setNotesLoading(false));
					alert(data.message);
					onSuccess();
				}
			} catch (e) {
				dispatch(getNotesLoading(false));
				alert(e.response.data.message);
			}
		};

export const verifyCustomerbyEmail =
	(email, onSuccess = () => { }) =>
		async dispatch => {
			try {
				let { data } = await notes.verifyCustomerbyEmail({ email });
				if (data.success) {
					if (data.data)
						onSuccess(data.notes);
				}
			} catch (e) {
				alert(e.response.data.message);
			}
		};

export const pushCustomerAsCollaborator =
	(email, onSuccess = () => { }) =>
		async dispatch => {
			try {
				let { data } = await notes.pushCustomerAsCollaborator({ email });
				if (data.success) {
					alert(data.data)
					onSuccess()
				}
			} catch (e) {
				alert(e.response.data.message);
			}
		};