import { Card, CardBody, CardFooter, CardHeader, Row } from "reactstrap";
import emoji910 from "assets/img/icons/emoji9-10.svg";
import emoji06 from "assets/img/icons/emoji6-0.svg";
import emojii78 from "assets/img/icons/emoji 7-8.png";
import { useDispatch } from "react-redux";
import { bookmarkSurveyAnswer } from "store/action/surveyAnswerAction";

const ReviewCard = ({npsScore,bookmarked,reviewText,date,id,category,customerName,npsNumber}) => {
    const dispatch=useDispatch()
    const footerColor= (npsScore >= 0 && npsScore<7) ? "red" : (npsScore>=7 && npsScore <9) ? "yellow" : "green"
    const footerEmoji= (npsScore >= 0 && npsScore<7) ? emoji06 : (npsScore>=7 && npsScore <9) ? emojii78 : emoji910
    function formatDate(dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString("en", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return formattedDate;
    }
  return (
    <Card className="card-stats mb-4 mb-xl-0 mt-2">
      <CardHeader className="card-stats-header d-flex justify-content-between align-items-center">
        <p className="card-stats-header__text-customer mr-1 mb-0">{customerName}</p>
        <p className="card-stats-header__text-customer ml-1 mb-0">{formatDate(date)}</p>
      </CardHeader>
      <CardBody className="card-stats-body">
          <p className="card-review-text">
            {reviewText}
          </p>
      </CardBody>
      <CardFooter className={`card-stats-footer ${footerColor} d-flex justify-content-between align-items-center`}>
 
    <div className="d-flex align-items-center">
    <img height={25} width={25} src={footerEmoji} alt="emoji nps"></img>
    {npsNumber != null && (<span className="ml-2 card-stats-footer__npsscore">{npsNumber}</span>)}
    </div>

       {bookmarked !=null && <i onClick={()=>dispatch(bookmarkSurveyAnswer(id,category))} className={`fas fa-bookmark text-small bookmark-icon-footer ${bookmarked ? "" :"text-white"}`}></i>} 
      </CardFooter>
    </Card>
  );
};
export default ReviewCard;
