import { RepositoryFactory } from 'repository/RepositoryFactory';
var survey = RepositoryFactory.get('survey');

export const setSurveyLoading = val => async dispatch => {
	dispatch({ type: 'SURVEY_LOADING', payload: val });
};
export const getSurveyLoading = val => async dispatch => {
	dispatch({ type: 'GET_SURVEY_LOADING', payload: val });
};

export const addSurvey =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(setSurveyLoading(true));
		try {
			let { data } = await survey.addSurvey(payload);

			if (data.success) {
				dispatch(setSurveyLoading(false));
				onSuccess();
				alert('Umfrage erfolgreich hinzugefügt!');
			}
		} catch (e) {
			dispatch(setSurveyLoading(false));
			alert(e.response.data.message);
		}
	};

export const getSurveys = payload => async dispatch => {
	try {
		await dispatch(getSurveyLoading(true));
		let { data } = await survey.getAllSurvey(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_ALL_SURVEY',
				payload: data,
			});
		}
		dispatch(getSurveyLoading(false));
	} catch (e) {
		dispatch(getSurveyLoading(false));
		alert(e.message);
	}
};

export const searchByNameSurvey = payload => async dispatch => {
	try {
		await dispatch(getSurveyLoading(true));
		let { data } = await survey.getAllSurvey(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_ALL_SURVEY',
				payload: data,
			});
		}
		dispatch(getSurveyLoading(false));
	} catch (e) {
		dispatch(getSurveyLoading(false));
		alert(e.response.data.message);
	}
};
export const deleteSurvey =
	(id, onSuccess = () => {}) =>
	async dispatch => {
		await dispatch(setSurveyLoading(true));

		try {
			let { data } = await survey.deleteSurvey(id);

			if (data.success) {
				await dispatch(setSurveyLoading(false));
				onSuccess();
				alert('Umfrage erfolgreich gelöscht!');
			}
		} catch (e) {
			dispatch(getSurveyLoading(false));
			alert(e.response.data.message);
		}
	};

export const updateSurvey =
	(obj, id, onSuccess = () => {}) =>
	async dispatch => {
		await dispatch(setSurveyLoading(true));
		try {
			let { data } = await survey.updateSurvey(obj, id);
			if (data.success) {
				await dispatch(setSurveyLoading(false));
				alert('Umfrage erfolgreich aktualisiert!');
				onSuccess();
			}
		} catch (e) {
			dispatch(getSurveyLoading(false));
			alert(e.response.data.message);
		}
	};

export const sendSurvey =
	(obj, onSuccess = () => {}) =>
	async dispatch => {
		await dispatch(getSurveyLoading(true));
		try {
			let { data } = await survey.sendSurvey(obj);
			if (data.success) {
				await dispatch(getSurveyLoading(false));
				alert('Umfrage erfolgreich gesendet!');
				onSuccess();
			}
		} catch (e) {
			dispatch(getSurveyLoading(false));
			alert(e.response?.data?.message);
		}
	};

	export const sendTestSurvey =
	(obj,id, onSuccess = () => {}) =>
	async dispatch => {
		await dispatch(getSurveyLoading(true));
		try {
			let { data } = await survey.sendTestSurvey(obj,id);
			if (data.success) {
				await dispatch(getSurveyLoading(false));
				alert('Testumfrage verschickt!');
				onSuccess();
			}
		} catch (e) {
			dispatch(getSurveyLoading(false));
			alert(e.response?.data?.message);
		}
	};