const initialState = {
    googleReviewMetrics:{totalReviewCount:0,averageReviewScore:0},
    googleReviews: [],
    googleReviewsLoading: false,
    googleMetricsLoading:false
  };
  
  const placesReducer = (state = initialState, action) => {
    switch (action.type) {

      case "GET_GOOGLE_REVIEWS_LOADING":
        return {
          ...state,
          googleReviewsLoading:action.payload
        };
        case "GET_GOOGLE_METRICS_LOADING":
        return {
          ...state,
          googleMetricsLoading:action.payload
        };
      case "CLEAR_GOOGLE_REVIEWS":
        return {
          ...state,
          googleReviews:[]
        };
      case "GET_GOOGLE_REVIEWS_SUCCESS":
        return {
          ...state,
          googleReviews:[...state.googleReviews,...action.payload],
        };
        case "BOOKMARK_GOOGLE_REVIEW":
          const id  = action.payload;
          const updatedReviews = state.googleReviews.map((review) => {
            if (review._id === id) {
              return { ...review, isBookmarked: !review.isBookmarked };
            }
            return review;
          });
          console.log(id,"REVIEW",updatedReviews);
          return {
            ...state,
            googleReviews: updatedReviews,
          };
          case "GET_GOOGLE_REVIEWS_METRICS":
            return {
              ...state,
              googleReviewMetrics:{...action.payload}
            };
      default:
        return state;
    }
  };
  
  export default placesReducer;
  