/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const GRAPHDATA = "/graph-data";
const GOOGLEDATA="googledata"
const FACEBOOKDATA="facebookdata"
export default {
    
    getAllGraphData(payload) {
      const queryParams = Object.entries(payload) //create query string 
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      return Repository.get(`${GRAPHDATA}?${queryParams}`);
    },
    getGoogleGraphData(payload) {
      const queryParams = Object.entries(payload) //create query string 
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      return Repository.get(`${GRAPHDATA}/${GOOGLEDATA}?${queryParams}`);

    },
    getFacebookGraphData(payload) {
      const queryParams = Object.entries(payload) //create query string 
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      return Repository.get(`${GRAPHDATA}/${FACEBOOKDATA}?${queryParams}`);

    },
    getFilteredGraphData(id,startDate,endDate) {
        return Repository.get(`${GRAPHDATA}/${id}`);
      },
  };
  