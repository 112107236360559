import Rating from "react-rating-stars-component";
import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  CardHeader,
  Button,
  Spinner,
} from "reactstrap";
import dashboardBlue from "assets/img/icons/dashboard-blue.svg";
import ProgressChart from "components/Charts/ProgressChart";
import { useDispatch, useSelector } from "react-redux";
import { getGraphData } from "store/action/dashboardAction";
import { getGoogleGraphData } from "store/action/dashboardAction";
import NumbersFilter from "components/Statistics/NumbersFilter";
import ReviewCard from "components/Statistics/ReviewCard";
import { getGoogleReviews } from "store/action/placesAction";
import GoogleReviewCard from "components/Statistics/GoogleReviewCard";
import { getGoogleMetrics } from "store/action/placesAction";
import CustomDatePicker from "components/Statistics/DatePickerComponet";

const GoogleStats = () => {
  const dispatch = useDispatch();
  const { user, role } = useSelector((state) => state.authUser);
  const aggregate = role === "admin-pro" ? true : false;
  const { googleBarChartData } = useSelector((state) => state.dashboard);
  const {googleReviewMetrics,googleReviews,googleReviewsLoading,googleMetricsLoading  } = useSelector((state) => state.places);
  const [reviewPage,setReviewPage]=useState(0)
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [isBookMarkedSelected, setIsBookMarkedSelected] = useState(false);
  const [comparison, setComparison] = useState("");
  const [comparisonStartDate,setComparisonStartDate]=useState("")
  const [comparisonEndDate,setComparisonEndDate]=useState("")
  const handleBoxClick = (number) => {
    let updatedSelection;
    if (selectedNumbers.includes(number)) {
      updatedSelection = selectedNumbers.filter((n) => n !== number);
    } else {
      updatedSelection = [...selectedNumbers, number];
    }
    setSelectedNumbers(updatedSelection);
  };
  const handleStartDateChange = (date) => {
    if(!isNaN(date))  
      setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    if(!isNaN(date))  
      setEndDate(date);
  };
  const isInitialRenderDate = useRef(true);
  const isInitialRenderPage = useRef(true);
  useEffect(() => {
    if (!reviewPage)
    dispatch(getGoogleReviews({ limit: 3, skip: reviewPage * 3, reviewFilters: selectedNumbers.join(","), isBookmarked: isBookMarkedSelected,startDate, endDate,aggregate }));
    setReviewPage(0)
  }, [selectedNumbers,isBookMarkedSelected]);
  useEffect(() => {
    if (isInitialRenderPage.current){
      isInitialRenderPage.current=false
      return
    }
      dispatch(getGoogleReviews({ limit: 3, skip: reviewPage * 3, reviewFilters: selectedNumbers.join(","), isBookmarked: isBookMarkedSelected,startDate, endDate,aggregate }));
  }, [reviewPage]);
  
  useEffect(() => {

    // dispatch(getGoogleGraphData({ startDate, endDate,comparison,comparisonStartDate,comparisonEndDate,aggregate }));

    dispatch(getGoogleMetrics({ startDate, endDate,aggregate }))
    dispatch(getGoogleGraphData({aggregate,startDate, endDate,comparison,comparisonStartDate,comparisonEndDate}));
    if (isInitialRenderDate.current) {
      isInitialRenderDate.current = false;
      return;
    }
    if (!reviewPage)
    dispatch(getGoogleReviews({ limit: 3, skip: reviewPage * 3, reviewFilters: selectedNumbers.join(","), isBookmarked: isBookMarkedSelected,startDate, endDate,aggregate }));
    setReviewPage(0)
  }, [startDate, endDate,aggregate,comparisonStartDate,comparisonEndDate]);
  useEffect(() => {
 
    if(comparison==="yearly"){
      if (startDate) {
        const startYearAgo = new Date(startDate);
        startYearAgo.setFullYear(startYearAgo.getFullYear() - 1);
        setComparisonStartDate(startYearAgo.toISOString().slice(0, 10)); 
      }
      if (endDate) {
        const endYearAgo = new Date(endDate);
        endYearAgo.setFullYear(endYearAgo.getFullYear() - 1);
        setComparisonEndDate(endYearAgo.toISOString().slice(0, 10));
      }

    }else if (comparison === "period") {
      if (startDate && endDate) {

        const startDatePeriod = new Date(startDate);
        const endDatePeriod = new Date(endDate);
        const daysDifference = ((endDatePeriod - startDatePeriod) / (1000 * 3600 * 24)).toFixed(0);
        console.log(daysDifference, "daysDifference");
        startDatePeriod.setDate(startDatePeriod.getDate() - daysDifference);
        endDatePeriod.setDate(endDatePeriod.getDate() - daysDifference);
        setComparisonStartDate(startDatePeriod.toISOString().slice(0, 10));
        setComparisonEndDate(endDatePeriod.toISOString().slice(0, 10));
      }
    }else {
      setComparisonStartDate("")
      setComparisonEndDate("")
    }
  }, [comparison,startDate, endDate]);


  return (
    <>
      <div className="header  pb-8 pt-5 pt-md-8">
        <Container fluid>
          <Row className="header-body">
            <Col
              xl={{
                offset: 2,
                size: 8,
              }}
              lg={{
                offset: 1,
                size: 10,
              }}
            >
              <Row className="d-flex justify-content-between align-items-center">
                <Col>
                  <div className="dashboard-row">
                    <img src={dashboardBlue} alt="dashboard-blue"></img>
                    <span className="dashboard-row__text">
                      Statistik Google
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={4}>
                  <Label className="dashboard-date-label" for="startDate">
                    Zeitraum von
                  </Label>
                  <CustomDatePicker selectedDate = {startDate} onChange={handleStartDateChange}></CustomDatePicker>
                  {/* <Input
                    className="dashboard-date-input"
                    type="date"
                    id="startDate"
                    value={startDate}
                    onChange={handleStartDateChange}
                  /> */}
                </Col>
                <Col lg={4}>
                  <Label className="dashboard-date-label" for="endDate">
                    bis
                  </Label>
                  <CustomDatePicker selectedDate = {endDate} onChange={handleEndDateChange}></CustomDatePicker>
                  {/* <Input
                    className="dashboard-date-input"
                    type="date"
                    id="endDate"
                    value={endDate}
                    onChange={handleEndDateChange}
                  /> */}
                </Col>
                <Col lg={4}>
                    <Label className="dashboard-date-label" for="comparison">
                      Vergleichen
                    </Label>
                    <Input
                      className="addAdminSelect"
                      name="comparison"
                      type="select"
                      value={comparison}
                      onChange={(e) => {setComparison(e.target.value)}}
                    >
                      <option value="">Vergleich auswählen</option>
                      <option value="yearly">Vorjahresvergleich</option>
                      <option value="period">
                        Mit vorherigem Zeitraum vergleichen
                      </option>
                    </Input>
                  </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={4}>
                    <Label className="dashboard-date-label" for="startDate">
                    Vergleich&nbsp;Zeitraum&nbsp;von
                    </Label>
                    <Input
                      className="dashboard-date-input"
                      type="date"
                      id="startDate"
                      disabled
                      value={comparisonStartDate}
                      onChange={(e)=>{setComparisonStartDate(e.target.value)}}
                    />
                  </Col>
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="endDate">
                      bis
                    </Label>
                    <Input
                      className="dashboard-date-input"
                      type="date"
                      id="endDate"
                      disabled
                      value={comparisonEndDate}
                      onChange={(e)=>{setComparisonEndDate(e.target.value)}}
                    />
                  </Col>
                </Row>
              <Col>
              <Row className="main-rating-container mt-5">
                <Col className="p-0 average-ratings-container d-flex flex-column align-items-center justify-content-center" xs={6} >
                    {/* <Row className="d-flex flex-column m-1"> */}
                  <p className="average-review-rating">{googleReviewMetrics.averageReviewScore}</p>
                  <div>
                {(!googleMetricsLoading) && (
                  <Rating
                    key={`stars`}
                    value={googleReviewMetrics.averageReviewScore}
                    count={5}
                    size={30}
                    edit={false}
                    activeColor="#ffd700"
                    isHalf
                  />
                )}
                 </div>
                  {/* </Row> */}
                </Col>
                <Col className=" p-0 total-review-container d-flex flex-column align-items-center justify-content-center" xs={6}  >
                    <p className="total-reviews-number">{googleReviewMetrics.totalReviewCount}</p>
                     <p className="total-reviews-text">Reviews</p></Col>
              </Row>
              </Col>
              <Row className="mt-4">
                {/* GRAPH FRONTEND */}
                <Col md={12}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <Col>
                          {googleBarChartData?.max > 0 ? (
                            <ProgressChart
                              data={googleBarChartData.data}
                              maxValue={googleBarChartData.max + 10}
                              dataComparision={(googleBarChartData.comparisonRatings && googleBarChartData.comparisonMax) ? googleBarChartData.comparisonRatings :{} }
                            />
                          ) : (
                            <>
                              <p className="text-small text-red">
                              *Noch keine Daten vorhanden 
                              </p>
                              <ProgressChart
                                data={{
                                  1: 0,
                                  2: 0,
                                  3:0,
                                  4:0,
                                  5: 0,
                                }}
                                maxValue={20}
                              />
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* Number/Bookmart fitlers */}
              <NumbersFilter
                isBookMarkedSelected={isBookMarkedSelected}
                setIsBookMarkedSelected={setIsBookMarkedSelected}
                selectedNumbers={selectedNumbers}
                handleBoxClick={handleBoxClick}
                IsGoogle={true}
              />
              {/* <ReviewCard/> */}
              <Row className="mt-5">
                <Col md={12}>
                  <Row className="d-flex flex-column m-1">
                    {" "}
                    {/* <p className="reviewcardtitle">Beratung/Verkauf</p>{" "} */}
                    {googleReviewsLoading ? (<Col className="d-flex align-items-center justify-content-center" style={{height:"10vh"}}><Spinner></Spinner></Col>):(googleReviews.map(el=>(
                    <GoogleReviewCard reviewText={el.text} rating={el.rating} date={el.time} bookmarked={el?.isBookmarked} id={el._id}  authorName={el.authorName} />
                   )))} 
                  </Row>
                  <Row className="mt-2 d-flex flex-column align-items-center justify-content-center">
                    <Button onClick={()=>setReviewPage(prev => prev + 1)} className="more-button">NACHLADEN</Button>
                    {/* <Button className="details-button mt-2">DETAILS</Button> */}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default GoogleStats;
