import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Row,
  Col,
} from "reactstrap";
import { logout } from "store/action/authAction";
import userLogo from "assets/img/icons/user.svg";
import notificationsLogo from "assets/img/icons/news.svg";
import jaworskiLogo from "assets/img/icons/jaworskiLogo.svg";
import flag from '../../assets/img/icons/somalia.png';
import NotificationDropdown from "components/NotificationDropdown";
import questionsBlue from "../../assets/img/icons/questions-blue.svg"
import switchLogo from "../../assets/img/icons/swtich-logo.svg";
import { switchAdminRole } from "store/action/adminAction";
import linkConfig from "config/config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { searchCustomerNavbar } from "store/action/customerAction";
import { useState } from "react";
const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  const { user,role } = useSelector((state) => state.authUser);
  const {searchNavbar}=useSelector((state) => state.customer);
  const [searchValue,setSearchValue]=useState("")
  const history=useHistory()

  // const {role}=useSelector((state)=>state.authUser);
  const toggleAdminRole=()=>{
    if(role==="admin-pro"){
      dispatch(switchAdminRole("admin"))
    }
    else if(role==="admin"){
      dispatch(switchAdminRole("admin-pro"))
    }
  }
  const handleSearch=(e)=>{
    e.preventDefault()
    dispatch(searchCustomerNavbar(e.target.value))
    setSearchValue(e.target.value)
    if(e.target.value)
    history.push("/admin/customers")
  }
  return (
    <>
      <Navbar className={`navbar-dark d-none d-md-block ${user.role=="super-admin"?(`navbar-top-dark`):(`navbar-top-light`)}`} expand="md" id="navbar-main">
        <Container fluid>
          <div className="d-flex flex-row justify-content-start align-items-center">
          <div
            className="h4 mb-0 text-white text-uppercase d-none d-lg-block "
            // to="/"
          >
            <Row>
              <Col xs={3}>
                {/* <img src={jaworskiLogo}></img> */}
                <img width={40} height={40} src={user.favURL ?user.favURL : "https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK.png?alt=media&token=7544e708-a68a-491e-864b-7e8e50c1a4dc"} alt="Logo"></img>
              </Col>
              <Col xs={9}>
                {" "}
                <p className="navbar-text-angemeldet">Angemeldet als</p>
                <p className="navbar-text-admin">
                  {user.name} {user.lastName}
                </p>
              </Col>
            </Row>

            {/* {props.brandText} */}
          </div>
          {user.role === "admin-pro" ? (<>
          <img src={switchLogo} width="30px" height="33px" className="ml-1 d-none d-md-block" onClick={toggleAdminRole}></img>
          <p onClick={toggleAdminRole} className={`switchrole_logo_text_${role ==="admin"? "A" : "AP"} d-none d-md-block`}>{role ==="admin"? "A" : "AP"}</p>
          </>) :""}
          </div>
          <p style={{fontWeight:"bold",color:'red',position:"relative",top:"10px"}}>{linkConfig.version}</p>
          <Row className=" form-inline mr-3 d-none d-md-flex ">
            <div className="search-input-container-navbar">
              <Input
                className="search-input-navbar"
                placeholder="Kunden durchsuchen …"
                type="text"
                value={searchValue}
                name="name"
                required
                onChange={(e) => {
                  handleSearch(e);
                }}
                autoComplete="off"
              />
           
              <span className="search-icon-navbar">
                <i class="fas fa-search fa-lg"></i>
              </span>
            </div>

          </Row>
          <Nav className={`align-items-center d-none d-md-flex` }navbar>
            <NotificationDropdown/>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <img src={userLogo} alt="user"></img>
                
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>

                <DropdownItem to="/admin/settings" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Einstellungen</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="/#" onClick={() => dispatch(logout())}>
                  <i className="ni ni-user-run" />
                  <span>Abmelden</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
