import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  CardHeader,
  Button,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  ModalFooter,
  FormGroup,
  ModalHeader,
  Form,
  Modal,
  ModalBody,
  CardFooter,
  Table,
} from "reactstrap";
import LockLoginLogo from "../../assets/img/icons/LockLogin.svg";
import { useEffect, useState } from "react";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import { getNotes } from "store/action/notesAction";
import { addNote } from "store/action/notesAction";

import { OverlayTrigger, MenuItem, Popover } from "react-bootstrap";
import { downloadNote } from "store/action/notesAction";
import { updateNote } from "store/action/notesAction";
import { deleteNote } from "store/action/notesAction";
import downloadLogo from "assets/img/icons/t&c-download-logo.svg";



const Notes = ({ isOpenNotes, setIsOpenNotes, startDate, endDate, category }) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const { user, role } = useSelector((state) => state.authUser);
  const { notesLoading, notes } = useSelector((state) => state.notes);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const reactSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "20px",
      fontSize: "11pt",
      paddingLeft: "25px",
    }),
    indicatorSeparator: (provided) => ({
      display: "none", // Hide the indicator separator
    }),
    menu: (provided) => ({
      ...provided,
      paddingLeft: "25px",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected && "#CACACA",
      ":active": {
        background: "transparent",
      },
      ":hover": {
        color: "white",
        background: "#2b3347",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "grey",
    }),
  };

  //view mode
  const [viewMode, setViewMode] = useState(false);

  //notes edit modal

  const [editNote, setEditNote] = useState(
    {
      startDate: "",
      endDate: "",
      notesText: "",
      freeMaßnahmen: "",
      maßnahmen: "",
      category: category
    }
  );

  const [editNoteMaßnahmen, setEditNoteMaßnahmen] = useState({ value: "Mehr davon", label: "Mehr davon" });

  const [noteEditModal, setNoteEditModal] = useState(false);


  const toggleNotesEditModal = () => {
    setNoteEditModal(!noteEditModal);
  };

  const handleEditNotesDataChange = (e) => {
    e.preventDefault();
    setEditNote({ ...editNote, [e.target.name]: e.target.value });
  };


  //popover code
  const [showPopovers, setShowPopovers] = useState([]);
  const handlePopoverClose = (index) => {
    // for overlay triggers
    const newShowPopovers = [...showPopovers];
    newShowPopovers[index] = false;
    setShowPopovers(newShowPopovers);
  };


  const pageClickHandler = (e) => {
    setPage(e.selected + 1);
  };
  const toggleNotesModal = () => {
    setIsOpenNotes(!isOpenNotes);
  };
  const [notesData, setNotesData] = useState({
    notesText: "",
    freeMaßnahmen: "",
  });
  const handleNotesDataChange = (e) => {
    e.preventDefault();
    setNotesData({ ...notesData, [e.target.name]: e.target.value });
  };
  const [selectedMaßnahmen, setSelectedMaßnahmen] = useState(null);
  const maßnahmenOptions = [{ value: "Mehr davon", label: "Mehr davon" }, { value: "Weniger davon", label: "Weniger davon" }, { value: "Starte", label: "Starte" }, { value: "Stop", label: "Stop" }]
  useEffect(() => {
    dispatch(getNotes({ limit: limit, pageNo: page, role, category }))
  }, [])
  console.log(notes, "NOTES", notesLoading);
  return (
    <>

      <Row>
        <div className="col">
          <>
            <CardHeader className="border-0">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Row className="mt-3 align-items-center">
                  <Col
                    md={{
                      offset: 1,
                      size: 3,
                    }}
                  >
                    <Row className="mt-2 align-items-center">
                      {" "}
                      {/* <img
                          className="mb-2"
                          src={customersLogo}
                          alt="customers"
                        ></img>{" "} */}
                      <p className="text-search-header ml-2">{`Notiz(${notes.total ? notes?.total : " "
                        })`}</p>
                    </Row>
                  </Col>


                  {startDate && endDate ? (
                    <>
                      <Col
                        md="4"
                      >
                      </Col>
                      <Col
                        md="4"
                        sm="4"
                        className="d-flex align-items-end justify-content-center"
                      >
                        {/* <Button
                          className="mt-3 mt-md-0 add-customer-button"
                          type="submit"
                          disabled={notesLoading}
                          onClick={() => {
                            //   history.push("/admin/addcustomer");
                            setIsOpenNotes(!isOpenNotes)
                          }}
                        >
                          Notiz anlegen
                        </Button>  */}
                        </Col></>) :
                    <>
                      <Col md="2"></Col>
                      <Col
                        md="6"
                        sm="6"
                        className="d-flex align-items-end justify-content-center"
                      >
                        <p className="text-small text-red justify-content-center">
                          *Wählen Sie Start- und Enddatum, um eine Notiz hinzuzufügen
                        </p></Col> </>}

                </Row>
              </Form>
            </CardHeader>
            <LoadingOverlay
              active={notesLoading}
              spinner
              text="Loading...."
            >
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Zeitraum</th>
                    <th scope="col">Bereich</th>
                    <th scope="col">Notiz</th>
                    <th scope="col">Retroperspektive</th>
                    <th scope="col">Maßnahmen</th>
                    <th scope="col">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {notes &&
                    notes.data &&
                    notes.data
                      .map((el, index) => {
                        return (
                          <tr style={{ height: "50px" }}>
                            <td>{`${el.startDate.split("T")[0]} - ${el.endDate.split("T")[0]}`}</td>
                            <td>{`${el.category}`}</td>
                            <td>{`${el.notesText}`}</td>
                            <td>{`${el?.maßnahmen}`}</td>
                            <td>{el?.freeMaßnahmen}</td>
                            <td className="text-center">
                              <OverlayTrigger
                                key={index}
                                trigger="click"
                                placement="bottom"
                                rootClose={true}
                                onHide={() => handlePopoverClose(index)}
                                show={showPopovers[index]}
                                overlay={
                                  <Popover
                                    id={`popover-positioned-bottom-${index}`}
                                  >
                                    <Popover.Body>
                                      <Row
                                        onClick={async () => {

                                          await dispatch(downloadNote(el._id));
                                          handlePopoverClose(index);
                                        }}
                                      >
                                        Herunterladen
                                      </Row>
                                      <Row onClick={() => {
                                        setEditNoteMaßnahmen({ label: el.maßnahmen, value: el.maßnahmen });
                                        setEditNote(el);
                                        setViewMode(true);
                                        toggleNotesEditModal();
                                      }}>Anzeigen</Row>
                                      {user._id === el.adminId ? <><Row
                                        onClick={() => {
                                          setEditNoteMaßnahmen({ label: el.maßnahmen, value: el.maßnahmen });
                                          setEditNote(el);
                                          setViewMode(false);
                                          toggleNotesEditModal();

                                        }}
                                      >
                                        Bearbeiten
                                      </Row></> : <></>}
                                      {/* <Row>Duplizieren</Row> */}
                                      {user._id === el.adminId ? <><Row
                                        onClick={async () => {
                                          {
                                            const confirmed = window.confirm(
                                              "Sind Sie sicher?"
                                            );
                                            if (confirmed) {
                                              await dispatch(deleteNote(el._id, () => {
                                                dispatch(getNotes({ limit: limit, pageNo: page, role, category }))
                                              }));
                                            }
                                            handlePopoverClose(index);
                                          }

                                        }}
                                      >
                                        Löschen
                                      </Row></> : <></>}

                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <i
                                  style={{
                                    cursor: "pointer",
                                    transform: `rotate(90deg)`,
                                  }}
                                  className="fas fa-ellipsis-v"
                                  onClick={() => {
                                    const newShowPopovers = [...showPopovers];
                                    newShowPopovers[index] =
                                      !showPopovers[index];
                                    setShowPopovers(newShowPopovers);
                                  }}
                                ></i>
                              </OverlayTrigger>
                            </td>

                            {/* <td className="text-center">
                              <Button>View</Button>
                            </td> */}

                          </tr>
                        );
                      })}
                </tbody>
              </Table>
            </LoadingOverlay>
            <CardFooter className="py-4">
              <ReactPaginate
                previousLabel={<i className="fas fa-angle-left" />}
                nextLabel={<i className="fas fa-angle-right" />}
                breakLabel={"..."}
                pageCount={notes && notes.total / limit}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={pageClickHandler}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link custom-pageLink"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link custom-pageArrow"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link custom-pageArrow"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active custom-active"}
              />
            </CardFooter>
          </>
        </div>
      </Row>
      <Modal size="lg" centered isOpen={isOpenNotes} toggle={toggleNotesModal}>
        <Form
          role="form"
          onSubmit={async (e) => {
            e.preventDefault();
            const tempData = {
              startDate,
              endDate,
              category,
              notesText: notesData.notesText,
              maßnahmen: selectedMaßnahmen.value,
              freeMaßnahmen: notesData.freeMaßnahmen
            }
            dispatch(addNote(tempData, () => {
              dispatch(getNotes({ limit: limit, pageNo: page, role, category }))
              toggleNotesModal()
            }))
          }}
        >
          <ModalHeader>
            <div className="sendSurveyHeader">
              <p className="sendSurveyHeading">Notiz</p>
            </div>
            {/* <h2>Send Survey</h2> */}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" className="my-2">
                <Row className="mb-2">
                  <Col md={12}>
                    <p className="ss-fieldLabel">Zeitraum</p>
                    <FormGroup className="rounded">
                      <div className="auth-input-container">
                        <span className="auth-icon">
                          <img
                            src={LockLoginLogo}
                            alt="user"
                            className="img-fluid"
                          />
                        </span>
                        <Input
                          className="auth-input"
                          placeholder=""
                          type="text"
                          value={`${moment(startDate).format('DD/MM/YYYY')}- ${moment(endDate).format('DD/MM/YYYY')}`}
                          name="customeName"
                          disabled
                          onChange={(e) => {
                            // handleLoginChange(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <p className="ss-fieldLabel">Bereich</p>
                    <FormGroup className="rounded">
                      <div className="auth-input-container">
                        <span className="auth-icon">
                          {/* <i className="ni ni-category-83" /> */}
                          <img
                            src={LockLoginLogo}
                            alt="user"
                            className="img-fluid"
                          />
                        </span>
                        <Input
                          className="auth-input"
                          placeholder=""
                          type="text"
                          autoComplete=""
                          value={category}
                          name="customerEmail"
                          required
                          onChange={(e) => {
                            // handleLoginChange(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <p className="ss-fieldLabel">Notizen</p>
                    <FormGroup className="rounded">
                      <div className="auth-input-container">
                        <Input
                          className="auth-input"
                          placeholder="Hier bitte Notizen eintragen"
                          type="textarea"
                          autoComplete=""
                          value={notesData.notesText}
                          name="notesText"
                          required
                          onChange={(e) => {
                            handleNotesDataChange(e);
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Row className="ml-1">
                      <label className="ss-fieldLabel">Retroperspektive</label>
                    </Row>

                    <Select
                      name="survey-select"
                      required
                      value={selectedMaßnahmen}
                      options={maßnahmenOptions}
                      onChange={setSelectedMaßnahmen}
                      className="ss-selectField"
                      styles={reactSelectStyles}
                      placeholder="Auswählen"
                    />
                  </Col>
                  <Col md={12} className="mt-4">
                    <p className="ss-fieldLabel">Maßnahmen</p>
                    <FormGroup className="rounded">
                      <div className="auth-input-container">
                        <Input
                          className="auth-input"
                          placeholder=""
                          type="text"
                          required
                          value={notesData.freeMaßnahmen}
                          name="freeMaßnahmen"
                          onChange={(e) => {
                            handleNotesDataChange(e);
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button
              className="ss-closeButton"
              color="secondary"
              disabled={notesLoading}
              onClick={() => {
                toggleNotesModal();
              }}
            >
              Abbrechen
            </Button>
            <Button
              className="ss-sendButton"
              color="primary"
              type="submit"
              disabled={notesLoading}
            >
              {notesLoading ? <Spinner size="sm" /> : "Speichern"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>


      {/* View Modal */}

      <Modal size="lg" centered isOpen={noteEditModal} toggle={toggleNotesEditModal}>
        <Form
          role="form"
          onSubmit={async (e) => {
            e.preventDefault();
            const tempData = {
              startDate: editNote.startDate,
              endDate: editNote.endDate,
              category,
              notesText: editNote.notesText,
              maßnahmen: editNoteMaßnahmen.value,
              freeMaßnahmen: editNote.freeMaßnahmen
            };
            await dispatch(updateNote(tempData, editNote._id, () => {
              dispatch(getNotes({ limit: limit, pageNo: page, role, category }))
              toggleNotesEditModal();

            }))

          }}
        >
          <ModalHeader>
            <div className="sendSurveyHeader">
              <p className="sendSurveyHeading">Notiz</p>
            </div>
            {/* <h2>Send Survey</h2> */}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12" className="my-2">
                <Row className="mb-2">
                  <Col md={12}>
                    <p className="ss-fieldLabel">Zeitraum</p>
                    <FormGroup className="rounded">
                      <div className="auth-input-container">
                        <span className="auth-icon">
                          <img
                            src={LockLoginLogo}
                            alt="user"
                            className="img-fluid"
                          />
                        </span>
                        <Input
                          className="auth-input"
                          placeholder=""
                          type="text"
                          value={`${moment(editNote.startDate).format('MM/DD/YYYY')} - ${moment(editNote.endDate).format('MM/DD/YYYY')}`}
                          name="customeName"
                          disabled
                          onChange={(e) => {
                            // handleLoginChange(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <p className="ss-fieldLabel">Bereich</p>
                    <FormGroup className="rounded">
                      <div className="auth-input-container">
                        <span className="auth-icon">
                          {/* <i className="ni ni-category-83" /> */}
                          <img
                            src={LockLoginLogo}
                            alt="user"
                            className="img-fluid"
                          />
                        </span>
                        <Input
                          className="auth-input"
                          placeholder=""
                          type="text"
                          autoComplete=""
                          value={category}
                          name="customerEmail"
                          required
                          onChange={(e) => {
                            // handleLoginChange(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <p className="ss-fieldLabel">Notizen</p>
                    <FormGroup className="rounded">
                      <div className="auth-input-container">
                        <Input
                          className="auth-input"
                          placeholder="Hier bitte Notizen eintragen"
                          type="textarea"
                          autoComplete=""
                          disabled={viewMode}
                          value={editNote.notesText}
                          name="notesText"
                          required
                          onChange={(e) => {
                            handleEditNotesDataChange(e);
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Row className="ml-1">
                      <label className="ss-fieldLabel">Retroperspektive</label>
                    </Row>
                    <Select
                      name="survey-select"
                      required
                      isDisabled={viewMode}
                      value={editNoteMaßnahmen}
                      options={maßnahmenOptions}
                      onChange={setEditNoteMaßnahmen}
                      className="ss-selectField"
                      styles={reactSelectStyles}
                    />
                  </Col>
                  <Col md={12}className="mt-4">
                    <p className="ss-fieldLabel">Maßnahmen</p>
                    <FormGroup className="rounded">
                      <div className="auth-input-container">
                        <Input
                          className="auth-input"
                          placeholder=""
                          type="text"
                          disabled={viewMode}
                          value={editNote.freeMaßnahmen}
                          name="freeMaßnahmen"
                          required
                          onChange={(e) => {
                            handleEditNotesDataChange(e);
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button
              className="ss-closeButton"
              color="secondary"
              disabled={notesLoading}
              onClick={() => {
                toggleNotesEditModal();
              }}
            >
              Abbrechen
            </Button>
            {viewMode && <img
              width={25}
              height={30}
              className="hover-zoom"
              src={downloadLogo}
              onClick={async () => {
                await dispatch(downloadNote(editNote._id));
              }}
              alt="Download Svg"
            ></img>}
            {!viewMode && <Button
              className="ss-sendButton"
              color="primary"
              type="submit"
              disabled={notesLoading}
            >
              {notesLoading ? <Spinner size="sm" /> : "Bearbeiten"}
            </Button>}

          </ModalFooter>
        </Form>
      </Modal>


    </>
  );
};
export default Notes;
