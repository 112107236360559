import { Col, Progress, Row } from "reactstrap";
import Rating from "react-rating-stars-component";

const ProgressChart = ({ data, dataComparision, maxValue,dummy }) => {
  const RatingBar = ({ ratingValue, progressValue, maxProgressValue, color, reviewCount, dataComparision }) => {
    // Check if dataComparision exists and contains the necessary data for the current ratingValue
    const comparisonValue = dataComparision && dataComparision[ratingValue];

    // Calculate the width of the comparison bar if comparisonValue is available
    const comparisonWidth = comparisonValue ? ((comparisonValue / (maxProgressValue+3.5)) * 100) : 0;

    return (
      <Row className="d-flex align-items-center">
        <Col className="rating-container" xs={4}>
          <Rating
            key={`stars_${ratingValue}`}
            value={ratingValue}
            count={5}
            size={16}
            edit={false}
            activeColor="#ffd700"
            isHalf
          />
        </Col>
        <Col xs={6} style={{ position: "relative" }}>
          {/* Grey comparison bar */}
          {comparisonValue ? (
            <div className="grey-bar" style={{ width: `${comparisonWidth}%` }} />
          ) :""}
          {/* Actual progress bar */}
          <Progress
            color={color}
            className="custom-progress"
            value={progressValue}
            max={maxProgressValue}
            // style={{ width: `${progressWidth}%` }} // Set the width of the progress bar
          />
        </Col>
        <p className="mt-2 google-review-rating">{reviewCount}</p>
      </Row>
    );
  };

  return (
    <>
      <RatingBar
        ratingValue={5}
        progressValue={data["5"]}
        dataComparision={dataComparision}
        maxProgressValue={maxValue}
        color={`progressgreen${dummy?"-dummy":""}`}
        reviewCount={data["5"]}
      />
      <RatingBar
        ratingValue={4}
        progressValue={data["4"]}
        dataComparision={dataComparision}
        maxProgressValue={maxValue}
        color={`progressgreen${dummy?"-dummy":""}`}
        reviewCount={data["4"]}
      />
      <RatingBar
        ratingValue={3}
        progressValue={data["3"]}
        dataComparision={dataComparision}
        maxProgressValue={maxValue}
        color={`progressyellow${dummy?"-dummy":""}`}
        reviewCount={data["3"]}
      />
      <RatingBar
        ratingValue={2}
        progressValue={data["2"]}
        dataComparision={dataComparision}
        maxProgressValue={maxValue}
         color={`progressred${dummy?"-dummy":""}`}
        reviewCount={data["2"]}
      />
      <RatingBar
        ratingValue={1}
        progressValue={data["1"]}
        dataComparision={dataComparision}
        maxProgressValue={maxValue}
         color={`progressred${dummy?"-dummy":""}`}
        reviewCount={data["1"]}
      />
    </>
  );
};

export default ProgressChart;
