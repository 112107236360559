/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const NOTES = "/notes";

export default {
  addNote(payload) {

    return Repository.post(`${NOTES}`, payload);
  },

  getAllNotes(payload) {
    const queryParams = Object.entries(payload) //create query string 
    .filter(([key, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
    return Repository.get(
      `${NOTES}?${queryParams}`
    );
  },
  getNotesById(payload) {
    return Repository.get(`${NOTES}/${payload}`);
  },
  deleteNotes(payload) {
    return Repository.delete(`${NOTES}/${payload}`, {});
  },
  updateNotes(payload, id) {
    return Repository.put(`${NOTES}/${id}`, payload);
  },
  downloadNote(id) {
    return Repository.get(`${NOTES}/downloadnote/${id}`);
  },
};
