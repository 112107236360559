const initState = { loading: false, authLoading: false };

const authReducer = (state = initState, { payload, type }, action) => {
	switch (type) {
		case 'SET_AUTH_LOADING':
			return { ...state, loading: payload };
		case 'GET_AUTH_LOADING':
			return { ...state, authLoading: payload };
		default:
			return state;
	}
};
export default authReducer;
