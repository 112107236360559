
import ReactApexChart from "react-apexcharts";
const PieChart = (props) => {
    return (
        <ReactApexChart
        options={{
          ...props.options1,
          plotOptions: {
            pie: {
              donut: {
                size: '58%',
              },
            },
          },
        }}
        series={props.series1}
        type="donut" // Set the chart type to donut
        height={300}
        width={390}
      />
    )
}
export default PieChart