
import Index from 'views/Index.js';
import Profile from 'views/Profile.js';
import Maps from 'views/Maps.js';
import Register from 'views/auth/Register.js';
import Login from 'views/auth/Login.js';
import Tables from 'views/Tables.js';
import Icons from 'views/Icons.js';
import Admins from 'views/Admins';
import ForgetPassword from 'views/auth/ForgetPassword';
import SetNewPassword from 'views/auth/SetNewPassword';
import Customers from 'views/Customers';
import Surveys from 'views/Surveys';
import SurveyAnswers from 'views/SurveyAnswers';
import AddCustomers from 'views/AddCustomers';
import AddAdmin from 'views/AddAdmin';
import EditCustomer from 'views/EditCustomer';
import EditAdmin from 'views/EditAdmin';
import Settings from 'views/Settings';
import NotificationList from 'views/NotificationsList';
import AdminProfile from 'views/AdminProfile';
import Statistics from 'views/Statistics';
import GoogleStats from 'views/GoogleStats';
import ViewAdmin from 'views/ViewAdmin';
import ViewCustomer from 'views/ViewCustomer';
import ConsultingStatistics from 'views/ConsultingStatistics';
import PerformaceStatistics from 'views/PerformanceStatistics';
import ServicesStatistics from 'views/ServicesStatistics';
import FacebookStats from 'views/FacebookStats';
import AdminStatsSuperAdmin from 'views/AdminStatsSuperAdmin';

var routes = [
	{
		path: '/index',
		name: 'Dashboard',
		icon: 'ni ni-tv-2 text-primary',
		component: Index,
		layout: '/admin',
		role: "admin",
		commonroute: true
	},
	// {
	// 	path: '/icons',
	// 	name: 'Icons',
	// 	icon: 'ni ni-planet text-blue',
	// 	component: Icons,
	// 	layout: '/admin',
	// 	role:"super-admin"
	// },
	// {
	// 	path: '/maps',
	// 	name: 'Maps',
	// 	icon: 'ni ni-pin-3 text-orange',
	// 	component: Maps,
	// 	layout: '/admin',
	// 	role:"super-admin"
	// },
	{
		path: '/user-profile',
		name: 'User Profile',
		icon: 'ni ni-single-02 text-yellow',
		component: Profile,
		layout: '/admin',
		role: "admin"
	},
	// {
	// 	path: '/tables',
	// 	name: 'Tables',
	// 	icon: 'ni ni-bullet-list-67 text-red',
	// 	component: Tables,
	// 	layout: '/admin',
	// 	role:"admin"
	// },
	{
		path: '/notifications',
		name: 'Surveys',
		icon: 'fas fa-poll-h fa-lg',
		component: NotificationList,
		layout: '/admin',
		role: "super-admin",
		isMenu: false
	},
	{
		path: '/admins',
		name: 'Admins',
		icon: 'ni ni-badge text-red',
		component: Admins,
		layout: '/admin',
		role: "super-admin"
	},
	{
		path: '/statistics',
		name: 'Statistics',
		icon: 'fas fa-users',
		component: Statistics,
		layout: '/admin',
		role: "super-admin",
		// onlyadmin:true
	},
	{
		path: '/googlestats',
		name: 'Statistics',
		icon: 'fas fa-users',
		component: GoogleStats,
		layout: '/admin',
		role: "super-admin",
		// onlyadmin:true
	},
	{
		path: '/adminstatistics',
		name: 'Admin Stats Super Admin',
		icon: 'fas fa-users',
		component: AdminStatsSuperAdmin,
		layout: '/admin',
		role: "super-admin",
		// onlyadmin:true
	},
	// FACEBOOK FUNCTIONALITY 5
	// {
	// 	path: '/facebookstats',
	// 	name: 'Facebook Stats',
	// 	icon: 'fas fa-users',
	// 	component: FacebookStats,
	// 	layout: '/admin',
	// 	role: "super-admin",
	// 	// onlyadmin:true
	// },
	{
		path: '/consulting',
		name: 'Statistics',
		icon: 'fas fa-users',
		component: ConsultingStatistics,
		layout: '/admin',
		role: "super-admin",
		// onlyadmin:true
	},
	{
		path: '/service',
		name: 'Statistics',
		icon: 'fas fa-users',
		component: ServicesStatistics,
		layout: '/admin',
		role: "super-admin",
		// onlyadmin:true
	},
	{
		path: '/performance',
		name: 'Statistics',
		icon: 'fas fa-users',
		component: PerformaceStatistics,
		layout: '/admin',
		role: "super-admin",
		// onlyadmin:true
	},
	{
		path: '/addadmin',
		name: 'AddAdmin',
		icon: 'fas fa-users',
		component: AddAdmin,
		layout: '/admin',
		role: "super-admin",
		// onlyadmin:true

	},
	{
		path: '/editadmin',
		name: 'EditAdmin',
		icon: 'fas fa-users',
		component: EditAdmin,
		layout: '/admin',
		role: "super-admin",
		// onlyadmin:true

	},
	{
		path: '/view-admin',
		name: 'ViewAdmin',
		icon: 'fas fa-users',
		component: ViewAdmin,
		layout: '/admin',
		role: "super-admin",
	},
	{
		path: '/customers',
		name: 'Customers',
		icon: 'fas fa-users',
		component: Customers,
		layout: '/admin',
		role: "admin",
		onlyadmin: true

	},
	{
		path: '/profile',
		name: 'Profile',
		icon: 'fas fa-users',
		component: AdminProfile,
		layout: '/admin',
		role: "admin",
		onlyadmin: true

	},
	{
		path: '/settings',
		name: 'Settnigs',
		icon: 'fas fa-users',
		component: Settings,
		layout: '/admin',
		role: "admin",
		onlyadmin: true

	},
	{
		path: '/addcustomer',
		name: 'Add Customers',
		icon: 'fas fa-users',
		component: AddCustomers,
		layout: '/admin',
		role: "admin",
		onlyadmin: true
	},
	{
		path: "/editcustomer/:id",
		name: "Update Fundraisers",
		icon: "ni ni-bullet-list-67 text-Warning",
		component: EditCustomer,
		layout: "/admin",
		isMenu: false,
	},
	{
		path: "/view-customer/:id",
		name: "View Fundraisers",
		icon: "ni ni-bullet-list-67 text-Warning",
		component: ViewCustomer,
		layout: "/admin",
		isMenu: false,
	},
	{
		path: '/surveys',
		name: 'Surveys',
		icon: 'fas fa-poll-h fa-lg',
		component: Surveys,
		layout: '/admin',
		role: "super-admin"
	},
	{
		path: '/customersurveys',
		name: 'Customer Surveys',
		icon: 'fas fa-poll-h fa-lg',
		component: SurveyAnswers,
		layout: '/admin',
		role: "admin"
	},
	{
		path: '/login',
		name: 'Login',
		icon: 'ni ni-key-25 text-info',
		component: Login,
		layout: '/auth',
		isMenu: false,
	},
	{
		path: '/register',
		name: 'Register',
		icon: 'ni ni-circle-08 text-pink',
		component: Register,
		layout: '/auth',
		isMenu: false
	},
	{
		path: '/forgetpassword',
		name: 'Forget Password',
		icon: 'ni ni-circle-08 text-pink',
		component: ForgetPassword,
		layout: '/auth',
		isMenu: false
	},
	{
		path: '/setnewpassword',
		name: 'SetNewPassword',
		icon: 'ni ni-key-25 text-info',
		component: SetNewPassword,
		layout: '/auth',
		isMenu: false,
	},
];
export default routes;
