/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from 'jwt-decode';
import { logout } from "store/action/authAction";
import { getFacebookTokenValidity } from "store/action/facebookAction";
const Admin = (props) => {
  // const [isTokenExpired, setIsTokenExpired] = useState(false);
  let { token } = useSelector(state => state.authUser);
  const [isOpen,setIsOpen]=useState(true) //sideBar
  
	let isTokenExpired=false
	const dispatch=useDispatch()
		if(token){
			const decodedToken = jwt_decode(token);
			const isExpired = decodedToken.exp < Date.now() / 1000;
			isTokenExpired=isExpired
		}
		else {
			isTokenExpired=false
		  }
		
      useEffect(() => {
        let timeoutId;
        if (isTokenExpired) {
          timeoutId = setTimeout(() => {
            alert("Sitzung abgelaufen, bitte erneut anmelden")
            dispatch(logout())
          }, 5);
        }
        // FACEBOOK FUNCTIONALITY 3
        // dispatch(getFacebookTokenValidity())
        return () => clearTimeout(timeoutId);
      }, [isTokenExpired, dispatch]);
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      <div className={`${isOpen?"main-content":"main-content sidebar-closed"}`} ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
      </div>
    </>
  );
};

export default Admin;
