import React, { useCallback, useEffect, useState } from "react";
import OnlyHeader from "components/Headers/OnlyHeader";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  Input,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Spinner,
  Label,
  CustomInput,
} from "reactstrap";
import {
  getCustomers,
  searchByNameCustomer,
} from "store/action/customerAction";
import { useDispatch, useSelector } from "react-redux";
import { addCustomer } from "store/action/customerAction";
import customersLogo from "../assets/img/icons/costumers-blue.svg";
import Select from "react-select";
import { verifyCustomerbyEmail } from "store/action/customerAction";
import { pushCustomerAsCollaborator } from "store/action/customerAction";
import { reactSelectStyles } from "components/selectStyle";
function AddCustomers() {
  const history = useHistory();
  const { user } = useSelector((state) => state.authUser);
  const { customers, customerLoading, loading } = useSelector(
    (state) => state.customer
  );

  const dispatch = useDispatch();
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    greetings: "formal",
    salutation: "Herr",
    title: "keine",
  });

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [isCollaborator, setIsCollaborator] = useState(false);
  const debounceEmailCheck = useCallback(
    debounce(async (email) => {
      if (email && email.includes("@")) {

        console.log("DEBOUNCING", email);
        dispatch(verifyCustomerbyEmail(email, async (customer) => {
          const isConfirmed = window.confirm("Dieser Kunde existiert bereits in Ihrem Team. Möchten Sie ihn als Mitarbeiter hinzufügen?");

          if (isConfirmed) {
            try {
              console.log(customer, "CUSTOMER");
              setCustomerData({
                email: customer.email,
                name: customer.name,
                lastName: customer.lastName,
                salutation: customer.salutation,
                title: customer.title,
                customerNo: customer.customerNo,
                firm: customer.firm,
                greetings: customer.greetings
              })
              setIsCollaborator(true)

            } catch (error) {
              console.error("An error occurred:", error);
            }
          } else {
            alert("Duplikate dürfen nicht erstellt werden");
            history.push("/admin/customers"); // Redirect to the home page
          }
        }))

      }
    }, 1000),
    []
  );
  const handleCustomerDataChange = (e) => {

    if (e.target.name === "greetings") {
      setCustomerData({ ...customerData, [e.target.name]: e.target.value });
    } else {
      e.preventDefault();
      setCustomerData({ ...customerData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "email")
      debounceEmailCheck(e.target.value);

  };

  const clearFields = () => {
    setCustomerData({
      name: "",
      email: "",
      greetings: "formal",
    });
  };

  return (
    <>
      <OnlyHeader />
      {/* Modal */}
      <Container className="mt--7 main-container">
        <Row>
          <Col
            md={{
              offset: 2,
              size: 8,
            }}
          >
            <>
              <Row className="mt-5">
                <Col
                  sm={12}
                  md={8}
                  lg={6}
                  className="d-flex  align-items-center"
                >
                  <img
                    className="mb-3 mr-2"
                    src={customersLogo}
                    alt="customers"
                  ></img>{" "}
                  <p className="text-kunde-header mx-2">{`Kunde`}</p>{" "}
                  <p className="text-user-header"> {user.name}</p>
                </Col>
                <Col md={0} lg={2}></Col>
                <Col sm={12} md={4} lg={3}>
                </Col>
              </Row>

              <Form
                role="form"
                onSubmit={async (e) => {
                  e.preventDefault();
                  console.log(customerData, "CustomerData");
                  if (isCollaborator) {
                    await dispatch(pushCustomerAsCollaborator(customerData.email, () => {
                      history.push("/admin/customers");
                    }));
                  } else {
                    await dispatch(
                      addCustomer(customerData, (newCustomerId,newCustomerName,newCustomerLastName,newCustomerEmail) => {
                        history.push({
                          pathname: '/admin/customers',
                          state: {
                            newCustomerId,newCustomerName,newCustomerLastName,newCustomerEmail
                          }
                        });
                        clearFields();
                      })
                    );
                  }


                }}
              >
                <h2>Kunde anlegen</h2>

                <Row>
                  <Col
                    sm={{
                      order: 1,
                      size: 12,
                    }}
                    className="my-2"
                  >
                    <p className="ss-fieldLabel">Email</p>
                    <div className="auth-input-container">
                      <Input
                        className="auth-input"
                        placeholder="Email"
                        name="email"
                        required
                        type="email"
                        value={customerData.email}
                        disabled={isCollaborator}
                        onChange={(e) => {
                          handleCustomerDataChange(e);
                        }}
                      ></Input>
                    </div>
                  </Col>
                  <Col
                    lg={{
                      order: 2,
                      size: 2,
                    }}
                    sm={{
                      order: 2,
                      size: 3,
                    }}
                    className="my-2"
                  >
                    {/* Anrede */}

                    {/* <Row className="justify-content-center">
                      <label>Anrede</label>
                    </Row> */}
                    <p className="ss-fieldLabel">Anrede</p>
                    <div className="auth-input-container">
                      <Select
                        name="salutation"
                        required
                        options={[
                          { value: 'Herr', label: 'Herr' },
                          { value: 'Frau', label: 'Frau' },
                          { value: 'Divers', label: 'Divers' },
                          { value: 'Keine Angabe', label: 'Keine Angabe' },
                        ]}
                        isDisabled={isCollaborator}
                        value={customerData.salutation ? { value: customerData.salutation, label: customerData.salutation } : null}
                        onChange={(selectedOption) => {
                          setCustomerData({ ...customerData, salutation: selectedOption.value });
                        }}
                        styles={reactSelectStyles}
                      />
                      {/* <Input
                        className='addAdminSelect'
                        name="salutation"
                        required
                        type="select"
                        value={customerData.salutation}
                        disabled={isCollaborator}
                        onChange={(e) => {
                          handleCustomerDataChange(e);
                        }}
                      >
                        <option value="Herr">Herr</option>
                        <option value="Frau">Frau</option>
                        <option value="Divers">Divers</option>
                        <option value="Keine Angabe">Keine Angabe</option>
                      </Input> */}
                    </div>
                  </Col>
                  <Col
                    lg={{
                      order: 3,
                      size: 7,
                    }}
                    sm={{
                      order: 4,
                      size: 12,
                    }}
                    className="my-2"
                  >
                    {/* Title */}
                    <p className="ss-fieldLabel">Titel</p>
                    <Select
                      name="title"
                      required
                      isDisabled={isCollaborator}
                      options={[
                        { value: 'Dr', label: 'Dr' },
                        { value: 'Prof', label: 'Prof' },
                        { value: 'Prof. Dr', label: 'Prof. Dr' },
                        { value: 'Dipl.-Ing', label: 'Dipl.-Ing' },
                        { value: 'keine', label: 'keine' },
                      ]}
                      value={customerData.title ? { value: customerData.title, label: customerData.title } : null}
                      onChange={(selectedOption) => {
                        setCustomerData({ ...customerData, 'title': selectedOption.value });
                      }}
                      styles={reactSelectStyles}
                    />
                    {/* <Input
                      className='addAdminSelect'
                      name="title"
                      required
                      type="select"
                      value={customerData.title}
                      disabled={isCollaborator}
                      onChange={(e) => {
                        handleCustomerDataChange(e);
                      }}
                    >
                      <option value="keine">Keine</option>
                      <option value="Dr">Dr</option>
                      <option value="Prof">Prof</option>
                      <option value="Prof. Dr">Prof. Dr</option>
                      <option value="Dipl.-Ing">Dipl.-Ing</option>

                    </Input> */}
                  </Col>
                  <Col
                    lg={{
                      order: 4,
                      size: 3,
                    }}
                    sm={{
                      order: 3,
                      size: 9,
                    }}
                    className="my-2"
                  >
                    {/* Kunden-Nr */}
                    <p className="ss-fieldLabel">Kunden-Nr</p>
                    <div className="auth-input-container">
                      <Input
                        className="auth-input"
                        placeholder="Kunden-Nr"
                        name="customerNo"
                        type="text"
                        disabled={isCollaborator || true}
                        value={customerData.customerNo}
                        onChange={(e) => {
                          handleCustomerDataChange(e);
                        }}
                      ></Input>
                    </div>
                  </Col>

                  <Col
                    sm={{
                      order: 6,
                      size: 12,
                    }}
                    lg={{
                      order: 6,
                      size: 6,
                    }}
                    className="my-2"
                  >
                    <p className="ss-fieldLabel">Vorname</p>
                    <div className="auth-input-container">
                      <Input
                        className="auth-input"
                        placeholder="Vorname"
                        required
                        type="text"
                        name="name"
                        value={customerData.name}
                        disabled={isCollaborator}
                        onChange={(e) => {
                          handleCustomerDataChange(e);
                        }}
                      ></Input>
                    </div>
                  </Col>
                  <Col
                    sm={{
                      order: 7,
                      size: 12,
                    }}
                    lg={{
                      order: 7,
                      size: 6,
                    }}
                    className="my-2"
                  >
                    <p className="ss-fieldLabel">Nachname</p>
                    <div className="auth-input-container">
                      <Input
                        className="auth-input"
                        placeholder="Nachname"
                        required
                        type="text"
                        name="lastName"
                        value={customerData.lastName}
                        disabled={isCollaborator}
                        onChange={(e) => {
                          handleCustomerDataChange(e);
                        }}
                      ></Input>
                    </div>
                  </Col>
                  <Col
                    sm={{
                      order: 8,
                      size: 12,
                    }}
                    lg={{
                      order: 8,
                      size: 6,
                    }}
                    className="my-2"
                  >
                    <p className="ss-fieldLabel">Firma</p>
                    <div className="auth-input-container">
                      <Input
                        className="auth-input"
                        placeholder="Firma"
                        type="text"
                        name="firm"
                        value={customerData.firm}
                        disabled={isCollaborator}
                        onChange={(e) => {
                          handleCustomerDataChange(e);
                        }}
                      ></Input>
                    </div>
                  </Col>

                </Row>

                <Row>
                  {/* <Col lg={1}></Col> */}
                  <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                    <Input
                      name="greetings"
                      className=""
                      type="radio"
                      value="formal"
                      disabled={isCollaborator}
                      checked={customerData.greetings === "formal"}
                      onChange={(e) => {
                        handleCustomerDataChange(e);
                      }}
                    />
                    <p className="greeting-radio-text">Formelle Anrede</p>
                  </Col>
                  <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                    <Input
                      name="greetings"
                      className=""
                      type="radio"
                      value="informal"
                      disabled={isCollaborator}
                      checked={customerData.greetings === "informal"}
                      onChange={(e) => {
                        handleCustomerDataChange(e);
                      }}
                    />
                    <p className="greeting-radio-text">Informelle Anrede</p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col
                    lg={{
                      offset: 8,
                      size: 5,
                    }}
                    sm={12}
                    className="d-flex justify-content-end"
                  >
                    <Button
                      className=" mt-3 mt-md-0 customer-header-button"
                      disabled={loading}
                      type="submit"
                    >
                      {loading ? <Spinner size="sm" /> : isCollaborator ? "Kunde Umfrage senden" : "Kunde anlegen"}
                      {/* als Kollaborateur hinzufügen */}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddCustomers;
