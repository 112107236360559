
import AdminRepository from './AdminRepository';
import AuthRepository from './AuthRepository';
import CustomerRepository from './CustomerRepository';
import DashboardRepository from './DashboardRepository';
import FacebookRepository from './FacebookRepository';
import InvitationsRepository from './InvitationsRepository';
import NotesRepository from './NotesRepository';
import NotificationsRepository from './NotificationsRepository';
import PlacesRepository from './PlacesRepository';
import StatisticsRepository from './StatisticsRepository';
import SurveyAnswerRepository from './SurveyAnswerRepository';
import SurveyRepository from './SurveyRepository';


const repositories = {
	admin: AdminRepository,
	auth: AuthRepository,
	customer:CustomerRepository,
	survey:SurveyRepository,
	surveyAnswer:SurveyAnswerRepository,
	notification:NotificationsRepository,
	dashboard:DashboardRepository,
	places:PlacesRepository,
	statistics:StatisticsRepository,
	invitations:InvitationsRepository,
	notes:NotesRepository,
	facebook:FacebookRepository
};
// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
	get: name => repositories[name],
};
