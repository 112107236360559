export const reactSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "20px",
      fontSize: "11pt",
      height: "42px",
      "&:disabled": {
        backgroundColor: "lightgray", 
        cursor: "not-allowed", 
      },
    }),
    indicatorSeparator: (provided) => ({
      display: "none", // Hide the indicator separator
    }),
    menu: (provided) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected && "#CACACA",
      ":active": {
        background: "transparent",
      },
      ":hover": {
        color: "white",
        background: "#2b3347",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "grey",
    }),
  };
  