/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const INVITATIONS = "/invitations";
const ACCEPT="/accept"


export default {
    

    acceptInvite(id) {
        return Repository.get(`${INVITATIONS}${ACCEPT}/${id}`);
        //http://127.0.0.1:5000/invitations/accept/${data.inviteId}
      },
      getInvitations(){
        return Repository.get(`${INVITATIONS}`);
      }
  };
  