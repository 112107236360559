const initialData = {
	admins: [],
	searchAdmin: [],
	loading: false,
	adminLoading: false,
	admin: {},
	resetpassword: '',
	adminLogoLoading: false,
	adminFavLoading: false,
	inviteLoading:false
};

const adminReducer = (state = initialData, action) => {
	switch (action.type) {
		case 'ADMIN_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'GET_ADMIN_LOADING':
			return {
				...state,
				adminLoading: action.payload,
			};
			case 'INVITE_LOADING':
				return {
					...state,
					inviteLoading: action.payload,
				};
		case 'GET_ALL_ADMIN':
			return {
				...state,
				admins: action.payload,
			};
		case 'GET_ADMIN':
			return {
				...state,
				admin: action.payload,
			};
		case 'SEARCH_ADMIN':
			return {
				...state,
				searchAdmin: action.payload,
			};
		case 'SEARCH_ADMIN_CLEAR':
			return {
				...state,
				searchAdmin: action.payload,
			};

		case 'GET_PASS':
			return {
				...state,
				resetpassword: action.payload,
			};
		case 'ADMIN_LOGO_LOADING':
			return {
				...state,
				adminLogoLoading: action.payload,
			};

		case 'ADMIN_FAV_LOADING':
			return {
				...state,
				adminFavLoading: action.payload,
			};
		default:
			return state;
	}
};

export default adminReducer;
