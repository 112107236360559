import { useHistory } from "react-router-dom/cjs/react-router-dom";

const { default: PieChart } = require("components/Charts/PieChart");
const { Col, Card, CardHeader, CardBody, Row, CardFooter } = require("reactstrap");
const { calculateRelativeChange } = require("utils/chartUtils");
const { checkIfDummyData } = require("utils/chartUtils");

const NpsCard = ({ cardData, series, seriesOptions,cardHeaderText, cardFooterText,adminNPS,adminSurveys,startDate,endDate,comparison }) => {
  const history=useHistory()
    return (
      <Col lg={4} md={12}>
        <Card className="card-stats mb-4 mb-xl-0">
          <CardHeader className="card-stats-header d-flex justify-content-between align-items-center">
            <p style={{cursor:adminNPS?"pointer":""}} onClick={()=>{if(adminNPS) history.push(`/admin/adminstatistics?id=${adminNPS}&startdate=${startDate}&enddate=${endDate}&comparison=${comparison}`)}} className="card-stats-header__text-ubersicht">{cardHeaderText}</p>
            <p className="card-stats-header__text-nps">NPS</p>
          </CardHeader>
          <CardBody>
            {checkIfDummyData(cardData.data) && (
              <Row>
                <p className="dummydata-text justify-content-center">
                  *Noch keine Daten vorhanden
                </p>
              </Row>
            )}
            {cardData.comparisonNpsScore && (
              <p className="compare-percentage-text">
                {calculateRelativeChange(
                  cardData?.totalNpsScore,
                  cardData.comparisonNpsScore
                ).toFixed(2)}
                %
              </p>
            )}
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ position: "relative" }}
            >
              {!checkIfDummyData(cardData.data) ? (
                <PieChart
                  options1={seriesOptions}
                  series1={series}
                  pieEnabled={10}
                  formatterValue={cardData?.totalNpsScore}
                />
              ) : (
                <PieChart
                  options1={seriesOptions}
                  series1={[1]}
                  pieEnabled={10}
                  formatterValue={10}
                />
              )}
              <div className="piechart-text">
                <p className="piechartnps-text">
                  {!checkIfDummyData(cardData.data) && cardData?.totalNpsScore}
                </p>
                {(!checkIfDummyData(cardData.data) &&
                  cardData?.comparisonNpsScore !== "") && (
                  <p className="piechartnpscomparison-text">
                    {cardData?.comparisonNpsScore}
                  </p>
                )}
              </div>
            </div>
          </CardBody>
          <CardFooter className="d-flex justify-content-between">
            <p style={{cursor:adminSurveys?"pointer":""}} onClick={()=>{if(adminSurveys) history.push(`/admin/adminstatistics?id=${adminSurveys}&startdate=${startDate}&enddate=${endDate}&comparison=${comparison}`)}} className="card-feedback-footer__text-feedback">
              {cardFooterText}
            </p>
            <span className="d-flex">
              <p className="card-feedback-footer__text-totalNps">
                {cardData?.totalNpsCount}
              </p>{" "}
              {cardData?.totalNpsCountComparison !== undefined && (
                <p className="card-feedback-footer__text-totalNpsComparison">
                  /{cardData?.totalNpsCountComparison}
                </p>
              )}
            </span>
          </CardFooter>
        </Card>
      </Col>
    );
  };

export default NpsCard;