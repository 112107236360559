import { RepositoryFactory } from "repository/RepositoryFactory";

var invitations = RepositoryFactory.get('invitations');
export const acceptInvite = (payload,onSuccess = () => { }) => async dispatch => {
	try {

		// dispatch(inviteLoading(true));
		let { data } = await invitations.acceptInvite(payload);
		if (data.success) {
			alert(data.message); //Admin added to team successfully
			onSuccess()
		}
	} catch (e) {
		// dispatch(inviteLoading(false));
		alert(e.response.data.message);
		onSuccess()
	}
};

export const getInvitations = (payload,onSuccess = () => { }) => async dispatch => {
	try {

		// dispatch(inviteLoading(true));
		let { data } = await invitations.getInvitations(payload);
		if (data.success) {
			await dispatch({
				type: 'GET_ALL_INVITATIONS',
				payload: data.data,
			});
		}
	} catch (e) {
		// dispatch(inviteLoading(false));
		alert(e.response.data.message);
		onSuccess()
	}
};