import { useEffect, useState } from "react";
import { SocialIcon } from "react-social-icons";
import logo from "../assets/img/logos/jaworskiLogin.svg";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  Spinner,
  CustomInput,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { verifySurveyAnswerToken } from "store/action/surveyAnswerAction";
import { useLocation } from "react-router-dom";
import { submitSurveyAnswer } from "store/action/surveyAnswerAction";
import closeIcon from "../assets/img/icons/close.svg";
import emoji06 from "../assets/img/icons/emoji6-0.svg";
import emoji78 from "../assets/img/icons/emoji 7-8.png";
import emojii78 from "../assets/img/icons/emoji-7-8.svg";
import emoji910 from "../assets/img/icons/emoji9-10.svg";
import heart from "../assets/img/icons/heart.svg";
import doubleArrow from "../assets/img/icons/double-arrow.svg";
import quesionMessage from '../assets/img/icons/question-message.svg'
import starSmile from '../assets/img/icons/star_smile.svg'
import googleLogo from '../assets/img/icons/Google_logo.svg'
import googleStars from '../assets/img/icons/google_stars.svg'
import progress1 from '../assets/img/icons/progress1-2.svg'
import progress2 from '../assets/img/icons/progress2-2.svg'
import progress2green from '../assets/img/icons/progree2-2-green.svg'
import emojiYellow from "../assets/img/icons/emoji-7-8-yellow.svg";
import { surveyCancelFormal, surveyCancelInformal, surveyBetterFeedbackFormal, surveyBetterFeedbackInformal, surveyCopyTextFormal, surveyCopyTextInformal, surveyFeedbackThankyouFormal0_6, surveyFeedbackThankyouFormal7_8, surveyFeedbackThankyouFormal9_10, surveyFeedbackThankyouInFormal0_6, surveyFeedbackThankyouInFormal7_8, surveyFeedbackThankyouInFormal9_10, surveyGoogleExtraStepFormal, surveyGoogleExtraStepInformal, surveyInfoTextFormal, surveyInfoTextInformal, surveyQuestionFormal0_6, surveyQuestionFormal7_8, surveyQuestionFormal9_10, surveyQuestionInFormal0_6, surveyQuestionInFormal7_8, surveyQuestionInFormal9_10, surveyText, surveyTextFormal, surveyTextInformal, surveyThankyouFormal, surveyThankyouInformal } from "./DisplayText";
import { surveyBeratungText, surveyServiceText, surveySchadenText, surveyTextFormalNew, surveyTextInformalNew } from "./DisplayText";
import { toast } from "react-toastify";


const feedbackQuestions = [
  "Was schätzen Sie am meisten an unserem Service?",
  "Was hat Ihnen gefehlt, um uns eine 9 oder 10 zu geben?",
  "Wie können wir die Erfahrung verbessern?",
];
const scoreToEmoji = {
  0: "😞",
  1: "😞",
  2: "😞",
  3: "😞",
  4: "😐",
  5: "😐",
  6: "😐",
  7: "🙂",
  8: "🙂",
  9: "🙂",
  10: "🙂",
};

const scoreToText = {
  0: "Hier fehlt noch die",
  1: "auf keinen Fall!",
  2: "sehr unwahrscheinlich",
  3: "unwahrscheinlich",
  4: "eher nicht",
  5: "müsste ich abwägen",
  6: "nicht uneingeschränkt",
  7: "ich denke schon",
  8: "ja, würde ich",
  9: "sehr wahrscheinlich",
  10: "auf jeden Fall, immer!",
};

const rating = [
  {
    score: 0,
    text: "Auf gar keinen Fall",
    emoji: emoji06,
  },

  {
    score: 1,
    text: "Auf keinen Fall!",
    emoji: emoji06,
  },

  {
    score: 2,
    text: "Sehr unwahrscheinlich",
    emoji: emoji06,
  },

  {
    score: 3,
    text: "Unwahrscheinlich",
    emoji: emoji06,
  },

  {
    score: 4,
    text: "Eher nicht",
    emoji: emoji06,
  },

  {
    score: 5,
    text: "Müsste ich abwägen",
    emoji: emoji06,
  },

  {
    score: 6,
    text: "Nicht uneingeschränkt",
    emoji: emoji06,
  },

  {
    score: 7,
    text: "Ich denke schon",
    emoji: emoji78,
  },

  {
    score: 8,
    text: "Ja, würde ich",
    emoji: emoji78,
  },

  {
    score: 9,
    text: "Sehr wahrscheinlich",
    emoji: emoji910,
  },

  {
    score: 10,
    text: "Auf jeden Fall, immer!",
    emoji: emoji910,
  },
];

const SurveyForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { surveyAnswer, surveyAnswerLoading, isAnswered } = useSelector(
    (state) => state.surveyAnswer
  );
  const [hoveredScore, setHoveredScore] = useState(null);
  const [selectedScore, setSelectedScore] = useState(null);
  const [step, setStep] = useState(0);
  const [tempStep, setTempStep] = useState(0);
  const [feedbackThankyou, setFeedbackThankyou] = useState("");
  const [feedbackEmoji, setFeedbackEmoji] = useState();
  const [feedbackQuestion, setFeedbackQuestion] = useState("");
  const [sendWithoutMessage, setSendWithoutMessage] = useState(false);

  const handleScoreClick = (score) => {
    //static survey
    if (score >= 9) {
      setFeedbackQuestion(surveyAnswer.customerId?.greetings==="formal" ? surveyQuestionFormal9_10 :surveyQuestionInFormal9_10 );
      setFeedbackThankyou(surveyAnswer.customerId?.greetings==="formal" ? surveyFeedbackThankyouFormal9_10: surveyFeedbackThankyouInFormal9_10);
      setFeedbackEmoji(emoji910);
    } else if (score >= 7) {
      setFeedbackQuestion(surveyAnswer.customerId?.greetings==="formal" ? surveyQuestionFormal7_8 :surveyQuestionInFormal7_8 );
      setFeedbackThankyou(surveyAnswer.customerId?.greetings==="formal" ? surveyFeedbackThankyouFormal7_8: surveyFeedbackThankyouInFormal7_8);
      setFeedbackEmoji(emojiYellow);
    } else {
      setFeedbackQuestion(surveyAnswer.customerId?.greetings==="formal" ? surveyQuestionFormal0_6 :surveyQuestionInFormal0_6 );
      setFeedbackThankyou(surveyAnswer.customerId?.greetings==="formal" ? surveyFeedbackThankyouFormal0_6: surveyFeedbackThankyouInFormal0_6);
      setFeedbackEmoji(emoji06);
    }

    setSelectedScore(score);
    setStep((prev) => prev + 1);
    setTempStep((prev) => prev + 1);
    window.scrollTo(0, 0)
  };
  const handleStep = () => {
    setStep((prev) => prev + 1);
    setTempStep((prev) => prev + 1);
  }
  const handlePrevStep = () => {
    setStep((prev) => prev - 1);
    setTempStep((prev) => prev - 1);
  }
  const handleCancel = () => {
    if (step == 3) {
      console.log("close button on step 3");
      window.close();
    }
    else {
      setStep(4);
    }
  }
  console.log(surveyAnswer, surveyAnswerLoading, isAnswered, "surveyAnswer");
  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const token = params.get("token");
    dispatch(verifySurveyAnswerToken(token), () => { });
  }, []);
  const handleSurveyAnswerSubmit = () => {

    const answersObj = [
      { question: feedbackQuestion, answer: sendWithoutMessage ?"":message },
    ];
    const tempObj = {
      npsScore: selectedScore,
      answers: answersObj,
    };

    dispatch(
      submitSurveyAnswer(tempObj, surveyAnswer._id, () => {
        // setStep((prev) => prev + 1);
        setStep(3);
        setTempStep(3);
      })
    );
  };

  if (surveyAnswer.surveyId?.category === "Beratung") {
    var surveyAnswerTextCategory = surveyBeratungText;
  } else if (surveyAnswer.surveyId?.category === "Service") {
    var surveyAnswerTextCategory = surveyServiceText;
  } else {
    var surveyAnswerTextCategory = surveySchadenText;
  }

  const [message, setMessage] = useState("");

  const messageChangeHandler = (e) => {
    setMessage(e.target.value);
  }
  const [infoBox,setInfoBox]=useState(false)
  const toggleInfoBox=()=>{
    console.log("Toggled");
    setInfoBox((prev)=>!prev)
  }
  console.log("show info box",infoBox);
  const handleGoogleButtonClick = () => {
    if(sendWithoutMessage){
      window.open(`https://search.google.com/local/writereview?placeid=${surveyAnswer.adminId?.placeId}`, '_blank');
    } else{
      const buttonStyle = {
        fontSize: '10px',
        // backgroundColor:surveyAnswer.adminId?.hexcode,
        boxShadow:"none",
        color:"#ffffff",
      };
      const toastId = toast.info(
        <>
        <div className="d-flex">
        <div onClick={toggleInfoBox} 
          style={{ transition: "transform 0.3s" }}
          onMouseEnter={(e) => { e.currentTarget.style.transform = "scale(1.2)"; }}
          onMouseLeave={(e) => { e.currentTarget.style.transform = "scale(1)"; }}
          className="Toastify__toast-icon Toastify--animate-icon Toastify__zoom-enter">
        <svg viewBox="0 0 24 24" width="100%" height="100%" fill="var(--toastify-icon-color-info)">
          <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.25 5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm2.25 13.5h-4a1 1 0 010-2h.75a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-.75a1 1 0 010-2h1a2 2 0 012 2v4.75a.25.25 0 00.25.25h.75a1 1 0 110 2z"></path>
        </svg>
        </div>
        <div>
          <div className="thankyou-text">{surveyAnswer.customerId?.greetings === "formal" ? surveyCopyTextFormal : surveyCopyTextInformal }</div>
          <Button 
          onMouseOver={(e) => {e.target.style.backgroundColor = surveyAnswer.adminId?.hexcode;e.target.style.color = "#ffffff"}}
          onMouseOut={(e) => {e.target.style.backgroundColor = "#F6F7FB";e.target.style.color = "#000000"}}        
           style={{...buttonStyle,color:"#000000"}}  onClick={() => handleCopyButtonClick(toastId)}>Kopieren</Button>
          <Button style={{...buttonStyle,backgroundColor:surveyAnswer.adminId?.hexcode}}  onClick={() => handleDontCopyButtonClick(toastId)}>Nicht kopieren</Button>
        </div>
        </div>
        </>,
        {
          autoClose: 10000, // Auto-close the toast after 10 seconds
          onClose: () => handleToastClose(toastId),
          position: "top-center",
          icon:false,
          closeOnClick: false
        }
      );
    }

  };
  const handleCopyButtonClick = (toastId) => {
    navigator.clipboard.writeText(message).then(() => {
    // window.open(`https://search.google.com/local/writereview?placeid=${surveyAnswer.adminId?.placeId}`, '_blank');
    toast.dismiss(toastId);
    })
    .catch((error) => {
      console.error('Error copying to clipboard:', error);
    })

  };

  const handleDontCopyButtonClick = (toastId) => {
    // window.open(`https://search.google.com/local/writereview?placeid=${surveyAnswer.adminId?.placeId}`, '_blank');
    toast.dismiss(toastId);
  };

  const handleToastClose = (toastId) => {
    setInfoBox(false)
    // Open the link after the toast is closed
    window.open(`https://search.google.com/local/writereview?placeid=${surveyAnswer.adminId?.placeId}`, '_blank');
  };
  if (!surveyAnswerLoading && isAnswered) {
    return (
      <Container fluid className="auth-container d-md-block text-center">
        <Row className="d-flex align-items-center justify-content-center after-submit-container">
          <Col
            md={{
              offset: 2,
              size: 8,
            }}
            className="surveyform__inner-container p-5"
          >
            {surveyAnswer.adminId?.logoURL && (
              <Row className="mb-5 d-flex align-items-center justify-content-center">
                <img
                  alt=""
                  src={surveyAnswer.adminId.logoURL}
                  className="banner-logo mb-sm-3"
                ></img>
              </Row>
            )}
            <>
              <Row>
                <Col
                  md={{
                    offset: 1,
                    size: 10,
                  }}
                  className="text-center mb-3"
                >
                  <h2>Danke!</h2>
                  <br />
                  <h4>Diese Umfrage wurde bereits beantwortet.</h4>
                </Col>
              </Row>
            </>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <div className="auth-container d-md-block text-center survey-container overflow-hidden">
              {infoBox && (<>
          <div class="feedback-box">
          <button onClick={()=>setInfoBox(false)} class="Toastify__close-button Toastify__close-button--light close-icon" type="button" aria-label="close"><svg aria-hidden="true" viewBox="0 0 14 16"><path fill-rule="evenodd" d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"></path></svg></button>
          <p className="thankyou-text">{surveyAnswer.customerId?.greetings === "formal" ? surveyInfoTextFormal : surveyInfoTextInformal}</p>
        </div>
        </>)}
      <Row
        style={{
          maxHeight: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          width: "100%",
        }}
        className="d-flex align-items-center justify-content-center"
      >
        {surveyAnswerLoading ? (
          <>
            <Container
              style={{ height: "10vh", width: "50%", backgroundColor: "white" }}
              className="mt--15 p-5 d-flex align-items-center justify-content-center"
            >
              <Spinner />
            </Container>
          </>
        ) : (
          <>
            {/* <Row> */}
            <Col
              md={{ size: 7 }}
              lg={{ size: 5 }}
              style={{ backgroundColor: surveyAnswer.adminId?.hexcode }}
              className="col-xxl-4 survey-form-header d-flex align-items-center justify-content-between"
            >
              {/* <div className="d-flex justify-content-between align-items-center"> */}
              {/* <FontAwesomeIcon icon={faTimes} /> */}
              <div className="sf-header-text">
                <img
                  src={closeIcon}
                  width={25}
                  height={25}
                  onClick={handleCancel}
                  className="cancelButton"
                  alt="cancel-img"
                ></img>
              </div>
              <div className="sf-header-text">FEEDBACK</div>
              {/* <div className="sf-header-text">{step === 0 ?  : }</div> */}

              {/* change here if its last step the make progress bar as yellow and full */}
              <div className="sf-header-text m-3">
                {step === 0 ? (
                  <Row className="align-items-center">
                    {" "}
                    1/2 <img src={progress1} className="ml-2 mb-1" />{" "}
                  </Row>
                ) : (
                  <>
                    {step === 3 && selectedScore <= 6 ? (
                      <Row className="align-items-center">
                        {" "}
                        2/2 <img className="ml-2" src={progress2green} />
                      </Row>
                    ) : step === 3 &&
                      selectedScore >= 7 &&
                      !surveyAnswer.adminId?.placeId ? (
                      <Row className="align-items-center">
                        {" "}
                        2/2 <img className="ml-2" src={progress2green} />{" "}
                      </Row>
                    ) : (
                      <Row className="align-items-center">
                        {" "}
                        2/2 <img className="ml-2" src={progress2} />
                      </Row>
                    )}
                  </>
                )}
              </div>
              {/* </div> */}
            </Col>
            {/* </Row> */}
            <Col md={12}></Col>
            <Col
              md={{ size: 7 }}
              lg={{ size: 5 }}
              className={`col-xxl-4 surveyform__inner-container ${
                false ? "pt-5 zero-lr-padding" : ""
              }`}
            >
              {surveyAnswer.adminId?.logoURL ? (
                <Row className="mb-2 d-flex align-items-center justify-content-center">
                  <img
                    alt=""
                    src={surveyAnswer.adminId.logoURL}
                    className="banner-logo"
                  ></img>
                </Row>
              ) : (
                <Row className="mb-2 d-flex align-items-center justify-content-center">
                  <p className="firm-heading">{surveyAnswer.adminId?.firm}</p>
                </Row>
              )}{" "}
              {step === 0 && (
                <Form>
                  {/* <Row> */}
                  <Col
                    md={{
                      offset: 1,
                      size: 10,
                    }}
                    className="text-center mb-3"
                  >
                    <Row className="justify-content-center align-items-center">
                      <Col>
                        <h4 className="survey-text">
                          {/* {`Lieber ${surveyAnswer.customerId?.name}, wie zufrieden warst du mit unserer Beratung / unserem Service zu deinem letzten Anliegen?`} */}
                          {/* This is intentional to adjust the frontend display text from display file */}
                          {surveyAnswer.customerId?.greetings == "formal"
                            ? eval("`" + surveyTextFormalNew + "`")
                            : eval("`" + surveyTextInformalNew + "`")}
                        </h4>
                      </Col>
                    </Row>

                    {/* </Row> */}
                    <FormGroup>
                      {/* <Label for="nps-score">NPS Score:</Label> */}
                      {hoveredScore === null && (
                        <div
                          style={{ visibility: "hidden" }}
                          className="survey-temp-text mb-3 text-danger"
                        >
                          Hidden Text
                        </div>
                      )}
                      {rating.map(
                        (rate) =>
                          rate.score === hoveredScore && (
                            <div
                              className={`survey-temp-text mb-3 ${
                                hoveredScore <= 6
                                  ? "text-danger"
                                  : hoveredScore <= 8
                                  ? "text-yellow"
                                  : "text-success"
                              }`}
                            >
                              {rate.text}
                            </div>
                          )
                      )}

                      <div className="sf-score-container">
                        {rating
                          .slice()
                          .reverse()
                          .map((rate, index) => (
                            <div
                              key={rate.score}
                              className="nps-score-box sf-score"
                              style={{
                                // width: "43px",
                                // height: "43px",
                                fontSize: "21.67px",
                                // borderRadius: "50%",
                                backgroundColor:
                                  rate.score < 7
                                    ? "#EF3256"
                                    : rate.score < 9
                                    ? "#FFC600"
                                    : "#8FC73C",
                                // backgroundColor:score>8?hoveredScore===score?"#0C2D6F":"#008480":score>6?hoveredScore===score?"#677CA5":"#66B5B3":hoveredScore===score?"#C0C8D9":"#BFE0DF",
                                transition:
                                  "width 0.2s, height 0.2s, background-color 0.2s",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold",
                                color:
                                  hoveredScore === rate.score ? "#fff" : "#fff",
                                cursor: "pointer",
                                // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                marginRight: rate.score !== 10 ? "5px" : "0px",
                              }}
                              // title={score}
                              onMouseEnter={() => setHoveredScore(rate.score)}
                              onMouseLeave={() => setHoveredScore(null)}
                              onClick={() => {
                                handleScoreClick(rate.score);
                              }}
                            >
                              <Row>
                                <Col className="d-flex align-items-center  justify-content-center">
                                  <div className="desktop-score">
                                    {hoveredScore !== rate.score
                                      ? rate.score
                                      : ""}
                                    {hoveredScore === rate.score && (
                                      <img src={rate.emoji}></img>
                                    )}
                                  </div>
                                  <div className="mobile-score">
                                    <div className="mobile-score-text">
                                      {rate.text}
                                    </div>
                                    <div className="mobile-score-text">
                                      {rate.score}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <br />
                            </div>
                          ))}
                      </div>
                    </FormGroup>
                    <Row className="d-flex justify-content-between mt-1">
                      <Col xs={5} className="text-left sf-text">
                        Auf keinen Fall
                      </Col>
                      <Col xs={2}></Col>
                      <Col xs={5} className="text-right sf-text">
                        Auf jeden Fall, immer!
                      </Col>
                    </Row>
                  </Col>
                </Form>
              )}
              {step === 1 && (
                <>
                  <Form
                    onSubmit={
                      sendWithoutMessage ? handleStep : handleSurveyAnswerSubmit
                    }
                  >
                    <Row className="m-0">
                      <Col sm={12} className="mb-3">
                        <img src={feedbackEmoji}></img>
                      </Col>
                    </Row>
                    <Row className=" fb-question-row">
                      <Col
                        md={{
                          offset: 1,
                          size: 10,
                        }}
                        className="text-center mb-3"
                      >
                        <h4 className="feedback-thankyou">
                          {feedbackThankyou}
                        </h4>
                      </Col>
                    </Row>
                    <Row className="pl-5 pr-5 fb-question-row">
                      <Col
                        md={{
                          offset: 1,
                          size: 10,
                        }}
                        className="text-center mb-3"
                      >
                        <h4 className="feedback-question">
                          {feedbackQuestion}
                        </h4>
                      </Col>
                    </Row>
                    <div className="form-row">
                      {/* <Row>
                      <Col
                        className="input-container"
                      > */}
                      <div className="input-container">
                        <FormGroup>
                          <Input
                            placeholder="Bitte keine personenbezogenen Daten eingeben"
                            required={!sendWithoutMessage}
                            type="textarea"
                            name="answer"
                            disabled={sendWithoutMessage}
                            className="sf-input"
                            value={message}
                            onChange={messageChangeHandler}
                          ></Input>
                        </FormGroup>
                      </div>

                      {/* </Col> */}
                      <div className="button-container">
                        <Col>
                          <div className="sf-radio-container mb-3">
                            <Input
                              name="survey-radio"
                              className="sf-radio"
                              type="radio"
                              value={sendWithoutMessage}
                              checked={sendWithoutMessage}
                              onClick={(e) => {
                                setSendWithoutMessage(!sendWithoutMessage);
                                setMessage("");
                              }}
                            />
                            <p className="sf-radio-label">
                              ohne Nachricht fortfahren
                            </p>
                          </div>
                          <Row className="align-items-center justify-content-between">
                            <Button
                              className="back-button mt-2"
                              onClick={() => {
                                setStep(0);
                              }}
                            >
                              Zurück
                            </Button>
                            <Button
                              type="submit"
                              style={{
                                backgroundColor: surveyAnswer.adminId?.hexcode,
                              }}
                              className="sf-button ml-md-4 mt-2"
                            >
                              {surveyAnswer._id && surveyAnswerLoading ? (
                                <Spinner />
                              ) : (
                                "Senden"
                              )}
                            </Button>
                          </Row>
                        </Col>
                      </div>

                      {/* </Row> */}
                      {/* <Row className="align-items-center justify-content-center">
                      
                    </Row> */}
                    </div>
                  </Form>
                </>
              )}
              {step === 2 && (
                // <Form>
                <Row>
                  <Col
                    md={{
                      offset: 1,
                      size: 10,
                    }}
                    className="text-center mb-3"
                  >
                    <img src={quesionMessage} width={40} className="mt-3"></img>
                    <h4 className="survey-text mt-3">
                      {`${
                        surveyAnswer.customerId?.salutation === "Herr"
                          ? `Lieber`
                          : surveyAnswer.customerId?.salutation === "Frau"
                          ? `Liebe`
                          : `Hallo`
                      }  ${
                        surveyAnswer.customerId?.name
                      }, wie wahrscheinlich ist es, dass Du unseren Service einem Freund oder Kollegen weiterempfiehlst?`}

                      {/* {`Lieber ${surveyAnswer.customerId?.name}, wie zufrieden warst du mit unserer Beratung / unserem Service zu deinem letzten Anliegen?`} */}
                      {/* Möchtest du uns wirklich keine
                      persönliche Nachricht hinterlassen? */}
                    </h4>
                    <button className="blueButton" onClick={handlePrevStep}>
                      Nachricht verfassen
                    </button>
                    <button
                      type="submit"
                      className="silverButton mb-6"
                      onClick={handleSurveyAnswerSubmit}
                    >
                      ohne Nachricht fortfahren
                    </button>
                  </Col>
                </Row>
                // </Form>
              )}
              {step === 3 && (
                <>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="thankyou-mainContainer p-5 mt-2">
                      <Row>
                        <Col
                          md={{
                            offset: 1,
                            size: 10,
                          }}
                          className="text-center mb-1"
                        >
                          <img src={heart} className="mb-1"></img>
                          <p className="thankyou-text">
                            {surveyAnswer.customerId?.greetings === "formal"
                              ? surveyThankyouFormal
                              : surveyThankyouInformal}
                          </p>
                          {/* <br /> */}
                          {selectedScore >= 7 &&
                            surveyAnswer.adminId?.placeId && (
                              <Row className="d-flex justify-content-center text-align-center mt-0">
                                <Button
                                  className="survey-google-button"
                                  onClick={handleGoogleButtonClick}
                                >
                                  <div className="image-container">
                                    <img
                                      width={100}
                                      height={50}
                                      src={googleLogo}
                                      className="hover-pointer"
                                      alt="Google Logo"
                                    />
                                  </div>
                                  Bewertung abgeben
                                </Button>
                                <p className="thankyou-text mt-3">
                                  {surveyAnswer.customerId?.greetings ===
                                  "formal"
                                    ? surveyGoogleExtraStepFormal
                                    : surveyGoogleExtraStepInformal}
                                </p>
                                <Button
                                  className="survey-google-button"
                                  onClick={handleGoogleButtonClick}
                                >
                                  <div className="image-container">
                                    <img
                                      width={100}
                                      height={50}
                                      src={googleLogo}
                                      className="hover-pointer"
                                      alt="Google Logo"
                                    />
                                  </div>
                                  Bewertung abgeben
                                </Button>
                              </Row>
                            )}
                        </Col>
                        {selectedScore >= 7 &&
                          surveyAnswer.adminId?.placeId && (
                            <>
                              {" "}
                              <Col
                                md={{
                                  offset: 1,
                                  size: 10,
                                }}
                                className="text-center mb-3 mt-1"
                              >
                                <img src={starSmile} className="mb-4"></img>
                                {/* <p className="thankyou-text">
                                Ihre Meinung zählt!{" "}
                                Würden Sie für uns noch einen weiteren Schritt gehen?
                                Schnell gemacht, wichtig für uns!
                              </p>
                              <br />
                              <p className="thankyou-text">
                                Wir freuen uns über eine Rezension auf Google.
                              </p> */}
                              </Col>
                              <Col></Col>
                            </>
                          )}
                        {(selectedScore < 7 || !surveyAnswer.adminId?.placeId) && (
                          <p className="thankyou-text mt-3">
                            {surveyAnswer.customerId?.greetings === "formal"
                              ? surveyBetterFeedbackFormal
                              : surveyBetterFeedbackInformal}
                          </p>
                        )}
                      </Row>
                    </div>
                  </Form>
                </>
              )}
              {step === 4 && (
                // <Form>
                <Row>
                  <Col
                    md={{
                      offset: 1,
                      size: 10,
                    }}
                    className="text-center mb-3"
                  >
                    <img src={quesionMessage} width={40} className="mt-3"></img>
                    <h4 className="survey-text mt-3">
                      {/* {`Lieber ${surveyAnswer.customerId?.name}, wie zufrieden warst du mit unserer Beratung / unserem Service zu deinem letzten Anliegen?`} */}
                      {surveyAnswer.customerId?.greetings === "formal"
                              ? surveyCancelFormal
                              : surveyCancelInformal}
                    </h4>
                    <button
                      className="blueButton"
                      onClick={() => {
                        setStep(tempStep);
                      }}
                    >
                      Mit der Befragung fortfahren
                    </button>
                    <button
                      onClick={() => {
                        window.close();
                      }}
                      className="silverButton mb-6"
                    >
                      Befragung abbrechen
                    </button>
                  </Col>
                </Row>
                // </Form>
              )}
            </Col>
            <Col md={12}></Col>
            <Col
              md={{ size: 7 }}
              lg={{ size: 5 }}
              className="col-xxl-4 sf-footer d-flex align-items-center justify-content-center"
              style={{ backgroundColor: surveyAnswer.adminId?.hexcode }}
            >
              Powered by johnkent.de
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
export default SurveyForm;
