import { RepositoryFactory } from 'repository/RepositoryFactory';
var customer = RepositoryFactory.get('customer');

export const setCustomerLoading = val => async dispatch => {
	dispatch({ type: 'CUSTOMER_LOADING', payload: val });
};
export const getCustomerLoading = val => async dispatch => {
	dispatch({ type: 'GET_CUSTOMER_LOADING', payload: val });
};

export const addCustomer =
	(payload, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(setCustomerLoading(true));
		try {
			let { data } = await customer.addCustomer(payload);

			if (data.success) {
				dispatch(setCustomerLoading(false));
				const { _id, name, lastName, email } = data.data;
				onSuccess(_id, name, lastName, email);
				alert('Kunde erfolgreich hinzugefügt!');
			}
		} catch (e) {
			dispatch(setCustomerLoading(false));
			alert(e.response.data.message);
		}
	};

export const getCustomers = payload => async dispatch => {
	try {
		await dispatch(getCustomerLoading(true));
		let { data } = await customer.getAllCustomer(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_ALL_CUSTOMER',
				payload: data,
			});
		}
		dispatch(getCustomerLoading(false));
	} catch (e) {
		dispatch(getCustomerLoading(false));
		alert(e.message);
	}
};

export const getCustomerById = (payload, onFail = () => {}) => async dispatch => {
	try {
		await dispatch(getCustomerLoading(true));
		let { data } = await customer.getCustomerById(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_CUSTOMER',
				payload: data,
			});
		}
		dispatch(getCustomerLoading(false));
	} catch (e) {
		dispatch(getCustomerLoading(false));
		console.log(e,"error");
		alert(e.response.data.message);
		onFail()
	}
};
export const customerExcelFileUpload = (payload,adminId, onSuccess = () => {}) => async dispatch => {
	try {
		await dispatch(getCustomerLoading(true));
		let { data } = await customer.uploadCustomerFile(payload,adminId);

		if (data.success) {
			alert("Hochgeladene Datei");
			console.log(data.data);
			await dispatch({
				type: 'GET_UPLOAD_CUSTOMERS',
				payload: data.data,
			});
			onSuccess();
		}
		dispatch(getCustomerLoading(false));
	} catch (e) {
		dispatch(getCustomerLoading(false));
		alert(e.message);
		
	}
};

export const deleteTempCustomers = (adminId, onFail = () => {}) => async dispatch => {
	try {
		await dispatch(getCustomerLoading(true));
		let { data } = await customer.deleteTemporaryCustomers(adminId);
		dispatch(getCustomerLoading(false));
		return ;
	} catch (e) {
		dispatch(getCustomerLoading(false));
		alert(e.message);
		onFail();
	}
};
export const transferTempCustomers = (adminId, onSuccess = () =>{},onFail = () => {}) => async dispatch => {
	try {
		await dispatch(getCustomerLoading(true));
		let { data } = await customer.transferTemporaryCustomers(adminId);
		if(data.success){
			alert(data.message);
			onSuccess();
		}
		dispatch(getCustomerLoading(false));
		return ;
	} catch (e) {
		dispatch(getCustomerLoading(false));
		alert(e.message);
		onFail();
	}
};

export const customerCSVFileDownload = (payload, onFail = () => {}) => async dispatch => {
	try {
		await dispatch(getCustomerLoading(true));
		let { data } = await customer.downloadCustomerFile();
		
		const blob = new Blob([data.message], {type: 'text/csv;charset=utf-8;'});

		if (data.success) {
			alert("Erfolgreiches Herunterladen von Dateien");
		}
		dispatch(getCustomerLoading(false));
		return blob;
	} catch (e) {
		dispatch(getCustomerLoading(false));
		alert(e.message);
		onFail()
	}
};

export const searchByNameCustomer = payload => async dispatch => {
	try {
		await dispatch(getCustomerLoading(true));
		let { data } = await customer.getAllCustomer(payload);

		if (data.success) {
			await dispatch({
				type: 'GET_ALL_CUSTOMER',
				payload: data,
			});
		}
		dispatch(getCustomerLoading(false));
	} catch (e) {
		dispatch(getCustomerLoading(false));
		alert(e.response.data.message);
	}
};
export const deleteCustomer =
	(id, onSuccess = () => {}) =>
	async dispatch => {
		await dispatch(setCustomerLoading(true));

		try {
			let { data } = await customer.deleteCustomer(id);

			if (data.success) {
				await dispatch(setCustomerLoading(false));
				onSuccess();
				alert('Kunde erfolgreich gelöscht!');
			}
		} catch (e) {
			dispatch(getCustomerLoading(false));
			alert(e.response.data.message);
		}
	};

export const updateCustomer =
	(obj, id, onSuccess = () => {}) =>
	async dispatch => {
		await dispatch(setCustomerLoading(true));
		try {
			let { data } = await customer.updateCustomer(obj, id);
			if (data.success) {
				await dispatch(setCustomerLoading(false));
				alert('Kunde erfolgreich aktualisiert!');
				onSuccess();
			}
		} catch (e) {
			dispatch(getCustomerLoading(false));
			alert(e.response.data.message);
		}
	};

	export const verifyCustomerbyEmail =
	(email, onSuccess = () => {}) =>
	async dispatch => {
		try {
			let { data } = await customer.verifyCustomerbyEmail({email});
			if (data.success) {
				if(data.data)
					onSuccess(data.customer);
			}
		} catch (e) {
			alert(e.response.data.message);
		}
	};

	export const pushCustomerAsCollaborator =
	(email, onSuccess = () => {}) =>
	async dispatch => {
		try {
			let { data } = await customer.pushCustomerAsCollaborator({email});
			if (data.success) {
				alert(data.data)
				onSuccess()
			}
		} catch (e) {
			alert(e.response.data.message);
		}
	};

	export const searchCustomerNavbar = payload => async dispatch => {

				 dispatch({
					type: 'SEARCH_CUSTOMER_NAVBAR',
					payload: payload,
				});
	};