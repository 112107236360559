
import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import AdminGraphs from "components/Charts/AdminGraphs.js";
import { useDispatch, useSelector } from "react-redux";

import { getFacebookTokenValidity } from "store/action/facebookAction";
import SuperAdminGraphs from "components/SuperAdminGraphs/SuperAdminGraphs";

const Index = (props) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.authUser);
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  
  return (
    <>
    {user.role === "super-admin" ? (<SuperAdminGraphs/>) :(<AdminGraphs /> )}


    </>
  );
};

export default Index;
