import react, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Spinner,
  Col,
  CardFooter,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { login as userLogin } from "../../store/action/authAction";
import { useSelector, useDispatch } from "react-redux";
import jaworskiLogo from "../../assets/img/logos/jaworskiLogin.svg";
import UserLoginLogo from "../../assets/img/icons/UserLogin.svg";
import LockLoginLogo from "../../assets/img/icons/LockLogin.svg"
import linkConfig from "config/config";
const Login = () => {
  const [login, setLogin] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const dispatch = useDispatch();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginChange = (name, value) => {
    setLogin((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const { loading } = useSelector((state) => state.auth);
  useEffect(() => {
    const img = new Image();
    img.src = "https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK-Logo.jpg?alt=media&token=adcb3d18-9d24-40d9-a94b-72949ea29136";
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);
  return (
    <>
      <Col lg={6} md={7} sm={8} className="p-0">
        <Card className="bg-secondary auth-card border-0">
          {/* <Card className='bg-transparent border-0'>  for mobile */}
          <CardBody className="px-lg-5 py-lg-5">
            <Row className="d-flex align-items-center justify-content-center mb-5">
              {imageLoaded ? (
              <img
                width={300}
                height={200}
                src="https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK-Logo.jpg?alt=media&token=adcb3d18-9d24-40d9-a94b-72949ea29136"
                alt="jaworski"
                className="img-fluid"
              />
            ) : (
              ""
            )}
            </Row>
            <div className="text-center mb-4  fs--40 hallo-text">HALLO!</div>
            <Row>
              <Col sm={12} md={{ offset: 2, size: 8 }}>
                <Form
                  role="form"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    let data = {
                      email: login.email,
                      password: login.password,
                    };
                    await dispatch(userLogin(data));
                  }}
                >
                  <FormGroup className="mb-3 rounded">
                    <div className="auth-input-container">
                      <span className="auth-icon">
                        {/* <i className="ni ni-email-83" /> */}
						<img src={UserLoginLogo} alt="user" className="img-fluid" />
                      </span>
                      <Input
                        className="auth-input"
                        placeholder="Email"
                        type="text"
                        autoComplete="new-email"
                        value={login.email}
                        name="email"
                        required
                        onChange={(e) => {
                          handleLoginChange(e.target.name, e.target.value);
                        }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="auth-input-container">
                      <span className="auth-icon">
                        {/* <i className="ni ni-lock-circle-open" /> */}
						<img src={LockLoginLogo} alt="lock" className="img-fluid" />
                      </span>
                      <Input
                        className="auth-input"
                        placeholder="Passwort"
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        value={login.password}
                        name="password"
                        required
                        onChange={(e) => {
                          handleLoginChange(e.target.name, e.target.value);
                        }}
                      />
                      <div
                        className="auth-password-toggle"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? (
                          <i className="fas fa-eye" />
                        ) : (
                          <i className="fas fa-eye-slash" />
                        )}
                      </div>
                    </div>
                  </FormGroup>
                  {/* <Row>
                    <Col className="d-flex justify-content-start pl-5">
                      <Input type="radio" />
                      <small className="auth-radio-text">Passwort merken</small>
                    </Col>
                    <Col className="d-flex justify-content-end pr-4">
                      <Link to="/auth/forgetpassword">
                        <small className="forgot-password">
                          Forgot password?
                        </small>
                      </Link>
                    </Col>
                  </Row> */}
                  <Row className="d-flex align-items-center">
                    <Col  xs={5} className="d-flex justify-content-left pl-5">
                      {" "}
                      <Input name="remember-password" className="radio-input-login mr-2" type="radio" /> <p className="auth-radio-text">Passwort merken</p>
                    </Col>
                    <Col xs={2}></Col>
                    <Col className="d-flex justify-content-right pl-5" xs={5}>
                      {" "}
                      <Link
                        to="/auth/forgetpassword"
                        className="ml-auto forgot-password"
                      >
                        Passwort vergessen
                      </Link>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      className="my-4 login-button"
                      disabled={loading}
                      color="primary"
                      type="submit"
                    >
                      {loading ? <Spinner size="sm" /> : "EINLOGGEN"}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="auth-card-footer text-center">
            {`Powered by johnkent.de ${linkConfig.footerVersion}`}
          </CardFooter>
        </Card>
      </Col>
    </>
  );
};

export default Login;
