
const linkConfig={
    serverURL:"https://server.npuls.cls.pm",
    version:"",
    footerVersion:"2.91.2"
};


// const linkConfig={
//     serverURL:"https://rating-system-backend-3bp37nx75q-ey.a.run.app",
//     version:"Test Version 2.91",
//     footerVersion:""
// };


export default linkConfig;