import React, { useState, useEffect } from 'react'
import OnlyHeader from 'components/Headers/OnlyHeader'
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { getAdminById } from 'store/action/adminAction';
import Select from "react-select";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Form,
    Input,
    Container,
    Row,
    UncontrolledTooltip,
    Spinner,
    Label,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import CustomersIcon from '../assets/img/icons/costumers-blue.svg';
import { reactSelectStyles } from 'components/selectStyle';

export default function ViewAdmin() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(1); //nav tabs
    const toggleTab = (tab) => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(location.search);
    const adminId = searchParams.get('id');
    const { admin } = useSelector((state) => state.admin);
    const [adminData, setAdminData] = useState({
        name: "",
        email: "",
        lastName: "",
        role: "admin",
        greetings: "formal",
        salutation: "",
        title: "",
        adminNo: "",
        firm: "",
        telephone: "",
        mobile: "",
        streetNo: "",
        zip: "",
        location: "",
        placeId: ""
    });
    useEffect(() => {
        console.log(`Admin ID: ${adminId}`);
        dispatch(getAdminById(adminId));
    }, [adminId]);


    //fetching the urls to preview
    const [imagePreviewUrl, setImagePreviewUrl] = useState('https://images.crutchfieldonline.com/ImageBank/v20150915135000/dummyphoto405x300.jpg');
    const [favPreviewUrl, setFavPreviewUrl] = useState("https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK.png?alt=media&token=7544e708-a68a-491e-864b-7e8e50c1a4dc");


    useEffect(() => {
        if (admin && admin.data) {
            setImagePreviewUrl(admin.data.logoURL || 'https://images.crutchfieldonline.com/ImageBank/v20150915135000/dummyphoto405x300.jpg');
            setFavPreviewUrl(admin.data.favURL || "https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK.png?alt=media&token=7544e708-a68a-491e-864b-7e8e50c1a4dc");
            setAdminData({
                name: admin.data.name,
                email: admin.data.email,
                lastName: admin.data.lastName,
                role: admin.data.role,
                greetings: admin.data.greetings,
                salutation: admin.data.salutation,
                title: admin.data.title,
                adminNo: admin.data.adminNo,
                firm: admin.data.firm,
                telephone: admin.data.telephone,
                mobile: admin.data.mobile,
                streetNo: admin.data.streetNo,
                zip: admin.data.zip,
                location: admin.data.location,
                placeId: admin.data.placeId
            });
        }
    }, [admin])


    return (
        <div>
            <OnlyHeader />
            <Container className='mt--7' fluid>
                <Row className='display-flex justify-content-center'>
                    <Col md={8} className='pt-6 pb-6'>
                        <div className='customersHeading mb-5'>
                            <img src={CustomersIcon}></img>
                            {admin && admin.data && (
                                <p className='custHeading'>Kunde {admin.data.name} {admin.data.lastName}</p>
                            )}
                        </div>

                        <Nav
                            className="border-bottom-nav"
                            style={{ display: "flex", justifyContent: "" }}
                        >
                            <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 1 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(1)}
                                >
                                    Stammdaten
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 2 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(2)}
                                >
                                    Layout
                                </NavLink>
                            </NavItem>

                        </Nav>

                        {activeTab === 1 && (<div className='form-fields'>
                            <div>
                                <Form
                                    role="form"
                                >
                                    <Row>
                                        <Col xs={{ size: 4, order: 1 }} lg={2} className='mt-3'>
                                            <p className="ss-fieldLabel">Kunde</p>
                                            <Select
                                                name="salutation"
                                                required
                                                options={[
                                                { value: 'Herr', label: 'Herr' },
                                                { value: 'Frau', label: 'Frau' },
                                                { value: 'Divers', label: 'Divers' },
                                                { value: 'Keine Angabe', label: 'Keine Angabe' },
                                                ]}
                                                value={adminData.salutation ? { value: adminData.salutation, label: adminData.salutation } : null}
                                                onChange={(selectedOption) => {
                                                    setAdminData({ ...adminData, salutation: selectedOption.value });
                                                }}
                                                styles={reactSelectStyles}
                                            />
                                            {/* <Input
                                                className='addAdminSelect'
                                                name="salutation"
                                                required
                                                type="select"
                                                value={adminData.salutation}
                                                disabled
                                            >
                                                <option value="Herr">Herr</option>
                                                <option value="Frau">Frau</option>
                                                <option value="Divers">Divers</option>
                                                <option value="Keine Angabe">Keine Angabe</option>
                                            </Input> */}
                                        </Col>
                                        <Col xs={{ size: 12, order: 3 }} lg={{ size: 6, order: 2 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Titel</p>
                                            <Select
                                                name="title"
                                                required
                                                options={[
                                                    { value: 'Dr', label: 'Dr' },
                                                    { value: 'Prof', label: 'Prof' },
                                                    { value: 'Prof. Dr', label: 'Prof. Dr' },
                                                    { value: 'Dipl.-Ing', label: 'Dipl.-Ing' },
                                                    { value: 'keine', label: 'keine' },
                                                ]}
                                                value={adminData.title ? { value: adminData.title, label: adminData.title } : null}
                                                onChange={(selectedOption) => {
                                                    setAdminData({ ...adminData, 'title': selectedOption.value });
                                                }}
                                                styles={reactSelectStyles}
                                            />
                                            {/* <Input
                                                className='addAdminSelect'
                                                name="title"
                                                required
                                                type="select"
                                                disabled
                                                value={adminData.title}
                                            >
                                                <option value="Dr">Dr</option>
                                                <option value="Prof">Prof</option>
                                                <option value="Prof. Dr">Prof. Dr</option>
                                                <option value="Dipl.-Ing">Dipl.-Ing</option>
                                                <option value="keine">keine</option>
                                            </Input> */}
                                        </Col>
                                        <Col xs={{ size: 8, order: 2 }} lg={{ size: 4, order: 3 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Kunden-Nr</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Kunden-Nr"
                                                    type="text"
                                                    value={adminData.adminNo}
                                                    name="adminNo"
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Vorname *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Vorname"
                                                    required
                                                    type="text"
                                                    name="name"
                                                    disabled
                                                    value={adminData.name}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Nachname *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Nachname"
                                                    required
                                                    type="text"
                                                    name="lastName"
                                                    disabled
                                                    value={adminData.lastName}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='mt-3'>
                                            <p className="ss-fieldLabel">Firma</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Firma"
                                                    name="firm"
                                                    type="text"
                                                    disabled
                                                    value={adminData.firm}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='mt-3'>
                                            <p className="ss-fieldLabel">E-mail *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="E-mail"
                                                    name="email"
                                                    required
                                                    type="text"
                                                    disabled
                                                    value={adminData.email}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Telefon *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Telefon"
                                                    name="telephone"
                                                    type="text"
                                                    required
                                                    disabled
                                                    value={adminData.telephone}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Mobil</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Mobil"
                                                    name="mobile"
                                                    type="text"
                                                    disabled
                                                    value={adminData.mobile}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Straße Nr. *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Straße Nr."
                                                    name="streetNo"
                                                    type="text"
                                                    required
                                                    disabled
                                                    value={adminData.streetNo}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">PLZ *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="PLZ"
                                                    name="zip"
                                                    type="text"
                                                    required
                                                    disabled
                                                    value={adminData.zip}
                                                ></Input>
                                            </div>
                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Ort *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Ort"
                                                    name="location"
                                                    type="text"
                                                    required
                                                    disabled
                                                    value={adminData.location}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Google Place Id</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Google Place Id"
                                                    name="placeId"
                                                    type="text"
                                                    disabled
                                                    value={adminData.placeId}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className=' mt-3'>
                                        {/* <Col lg={1}></Col> */}
                                        <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                                            <Input
                                                name="greetings"
                                                className=""
                                                type="radio"
                                                disabled
                                                value="formal"
                                                checked={adminData.greetings === "formal"}
                                            />
                                            <p className="greeting-radio-text">Formelle Anrede</p>
                                        </Col>
                                        <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                                            <Input
                                                name="greetings"
                                                className=""
                                                type="radio"
                                                disabled
                                                value="informal"
                                                checked={adminData.greetings === "informal"}
                                            />
                                            <p className="greeting-radio-text">Informelle Anrede</p>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>)}

                        {/* code for view layout */}

                        {activeTab === 2 && (<><Row className='mt-3'>
                            <Col lg={4} className='d-flex flex-column-reverse'>

                                <Button className='logo-button mt-2 mb-2' disabled >
                                    Logo upload
                                </Button>
                                <p className='settingLable'>Umfrage-Logo</p>

                            </Col>
                            {/* <Col lg={1}></Col> */}
                            <Col lg={8}>
                                <img src={imagePreviewUrl}
                                    className='logo-image'></img>
                            </Col>
                        </Row>
                            <div className='horizontal-line mt-4'></div>
                            <Row className='mt-3'>
                                <Col lg={4} className='d-flex flex-column-reverse'>

                                <Button disabled className='logo-button mt-2 mb-2' >
                                         Profil-Upload
                                    </Button>
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                    />
                                    <p className='settingLable '>Datenupload (png, svg, jpg)</p>
                                    <p className='settingLable mt-4'>Profil-Logo</p>
                                    <img src={favPreviewUrl}
                                        className='fav-image'></img>
                                </Col>
                                {/* <Col lg={8} className='d-flex'>
                                    <div className='white-color'>
                                        <div className='blue-color' />
                                    </div>
                                    <div className='white-color'>
                                        <div className='green-color' />
                                    </div>

                                </Col> */}
                            </Row>
                            <div className='horizontal-line mt-4'></div>
                            <Row className='d-flex flex-row-reverse mt-4'>

                            </Row>
                        </>

                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
