const initialData = {
	surveys: [],
	loading: false,
	surveyLoading: false,
};

const surveyReducer = (state = initialData, action) => {
	switch (action.type) {
		case 'SURVEY_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'GET_SURVEY_LOADING':
			return {
				...state,
				surveyLoading: action.payload,
			};
		case 'GET_ALL_SURVEY':
			return {
				...state,
				surveys: action.payload,
			};
		case 'SEARCH_SURVEY':
			return {
				...state,
				searchCustomers: action.payload,
			};

		default:
			return state;
	}
};

export default surveyReducer;
