import OnlyHeader from 'components/Headers/OnlyHeader'
import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import settingLogo from "../assets/img/icons/settings-blue.svg";
import Select from "react-select";
import { updateAdmin } from 'store/action/adminAction';
import { useDispatch, useSelector } from 'react-redux';
import PasswordStrengthBar from 'react-password-strength-bar';
import { logoutAllDevices, login as userLogin } from "../store/action/authAction";
import {

    Col,
    Button,
    Spinner,
    Container,
    Row,
    Form, FormGroup, Label, Input,
    Nav,
    NavItem,
    NavLink,
    Table,
    Badge,
} from "reactstrap";
// import { customerCSVFileDownload } from 'store/action/customerAction';
// import { customerExcelFileUpload } from 'store/action/customerAction';
import { getAdminById } from 'store/action/adminAction';
import { uploadLogo } from 'store/action/adminAction';
import { setAdminPassword } from 'store/action/adminAction';
import { uploadFav } from 'store/action/adminAction';
import { addReview } from 'store/action/placesAction';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { inviteAdminInTeam } from 'store/action/adminAction';
import { delinkAdminFromTeam } from 'store/action/adminAction';
import { emailReceptionText, layoutFormalText, layoutInformalText, placeIdRedTextFormal, placeIdRedTextInformal, teamErweiternFormalText, teamErweiternInformalText, umfragenFormalText, umfragenInformalText } from './DisplayText';
import { reactSelectStyles } from 'components/selectStyle';
import Invitations from 'components/Settings/Invitations';
import axios from 'axios';
import { getFacebookReviews } from 'store/action/facebookAction';
import { addInitialFacebookReview } from 'store/action/facebookAction';
import { getFacebookTokenValidity } from 'store/action/facebookAction';
import { fbLogout } from 'store/action/facebookAction';


export default function Settings() {
    const { user, role } = useSelector((state) => state.authUser);
    const { facebookTokenInvalid } = useSelector((state) => state.facebook)
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(1); //nav tabs
    const [benutzerverwaltungTab, setBenutzerverwaltungTab] = useState(1); //nav tabs
    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const toggleBenutzerverwaltungTab = (tab) => {
        if (benutzerverwaltungTab !== tab) setBenutzerverwaltungTab(tab);
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const imageRef = useRef(null);
    const favRef = useRef(null);

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFavImage, setSelectedFavImage] = useState(null);
    const [password, setPassword] = useState("");
    const [confirmNewPassword, setconfirmNewPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [varified, setVarified] = useState(false);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(user.logoURL && user.logoURL !== '' ? user.logoURL : 'https://images.crutchfieldonline.com/ImageBank/v20150915135000/dummyphoto405x300.jpg');
    const [favPreviewUrl, setFavPreviewUrl] = useState(user.favURL && user.favURL !== '' ? user.favURL : "https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK.png?alt=media&token=7544e708-a68a-491e-864b-7e8e50c1a4dc");
    const [showPopovers, setShowPopovers] = useState([]);
    const [inviteEmail, setInviteEmail] = useState("")
    const [isFacebookLogged, setIsFacebookLogged] = useState(false)
    const handlePopoverClose = (index) => {
        // for overlay triggers
        const newShowPopovers = [...showPopovers];
        newShowPopovers[index] = false;
        setShowPopovers(newShowPopovers);
    };

    // const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/.test(password);
    const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$!%*?&#<>]{8,}$/.test(newPassword);



    const isConfirmPasswordValid = confirmNewPassword === newPassword;
    const confirmPasswordErrorMessage = 'Das Passwort stimmt nicht überein.';

    const isLowercaseValid = /^(?=.*[a-z])/.test(newPassword);
    const isUppercaseValid = /^(?=.*[A-Z])/.test(newPassword);
    const isDigitValid = /^(?=.*\d)/.test(newPassword);
    const isSpecialCharValid = /^(?=.*[@$!%*?&#<>])/.test(newPassword);
    const isLengthValid = /^.{8,}$/.test(newPassword);
    console.log(isLowercaseValid, isUppercaseValid, isLengthValid, "TEST");
    const handleImageInputChange = (event) => {
        setSelectedImage(event.target.files[0]);
        // Preview image
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
            console.log(reader.result, "READER RESULT");
            localStorage.setItem('imagePreviewUrl', reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
        const win = window.open("/previewsurvey", "_blank");
        win.focus();

    };

    const handleFavInputChange = (event) => {
        setSelectedFavImage(event.target.files[0]);
        // Preview image
        const reader = new FileReader();
        reader.onloadend = () => {
            setFavPreviewUrl(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    //Admin fetch

    const { admin, adminLoading, loading, adminLogoLoading, adminFavLoading, inviteLoading } = useSelector((state) => state.admin);
    const adminId = user._id;

    const [adminData, setAdminData] = useState({
        name: "",
        email: "",
        lastName: "",
        role: "admin",
        greetings: "formal",
        salutation: "",
        title: "",
        adminNo: "",
        firm: "",
        telephone: "",
        mobile: "",
        streetNo: "",
        zip: "",
        location: "",
        placeId: "",
        emailReception:""
        // contactNumber: "",
    });
    const [initialAdminData, setInitialAdminData] = useState({
        name: "",
        email: "",
        lastName: "",
        role: "admin",
        greetings: "formal",
        salutation: "",
        title: "",
        adminNo: "",
        firm: "",
        telephone: "",
        mobile: "",
        streetNo: "",
        zip: "",
        location: "",
        placeId: "",
        emailReception:""
        // contactNumber: "",
    });
    const [isAdminDataChanged, setIsAdminDataChanged] = useState(false);
    // useEffect(() => { 

    //     if(isEqual(adminData, initialAdminData))
    //     setIsAdminDataChanged(false)
    //     else
    //     setIsAdminDataChanged(true)
    // },[adminData,initialAdminData])
    useEffect(() => {
        dispatch(getAdminById(adminId));
    }, [adminId]);
    useEffect(() => {
        if (admin && admin.data) {
            console.log(admin.data.emailReception,"admin.data.emailReception");
            setAdminData({
                name: admin.data.name,
                email: admin.data.email,
                lastName: admin.data.lastName,
                role: admin.data.role,
                greetings: admin.data.greetings,
                salutation: admin.data.salutation,
                title: admin.data.title,
                adminNo: admin.data.adminNo,
                firm: admin.data.firm,
                telephone: admin.data.telephone,
                mobile: admin.data.mobile,
                streetNo: admin.data.streetNo,
                zip: admin.data.zip,
                location: admin.data.location,
                placeId: admin.data.placeId,
                emailReception:admin.data.emailReception ===undefined ? true :admin.data.emailReception ,
                // contactNumber: "",
            });
            setInitialAdminData({
                name: admin.data.name,
                email: admin.data.email,
                lastName: admin.data.lastName,
                role: admin.data.role,
                greetings: admin.data.greetings,
                salutation: admin.data.salutation,
                title: admin.data.title,
                adminNo: admin.data.adminNo,
                firm: admin.data.firm,
                telephone: admin.data.telephone,
                mobile: admin.data.mobile,
                streetNo: admin.data.streetNo,
                zip: admin.data.zip,
                location: admin.data.location,
                placeId: admin.data.placeId,
                emailReception:admin.data.emailReception !==undefined ? admin.data.emailReception : true,
                // contactNumber: "",
            });
            // setImagePreviewUrl(admin.data.logoURL);
        }

    }, [admin?.data]);

    const handleImageUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (selectedImage != null) {
            formData.append('file', selectedImage);
            await dispatch(uploadLogo(formData, user._id));
        }
    };

    const handleFavUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (selectedFavImage != null) {
            formData.append('file', selectedFavImage);
            await dispatch(uploadFav(formData, user._id));
        }
    };
    const handleAdminDataChange = (e) => {
        // e.preventDefault();
        // setAdminData({ ...adminData, [e.target.name]: e.target.value });
        if (e.target.name === "greetings") {
            setAdminData({ ...adminData, [e.target.name]: e.target.value });
        } else {
            e.preventDefault();
            setAdminData({ ...adminData, [e.target.name]: e.target.value });
        }
    };


    async function submitForm(e) {

        e.preventDefault();
        if (isAdminDataChanged) {
            console.log(adminData);
            await dispatch(
                updateAdmin(adminData, adminId, async () => {
                    if (adminData.placeId !== initialAdminData.placeId) {
                        setInitialAdminData({...initialAdminData,placeId:adminData.placeId})
                        await dispatch(addReview());
                    }
                })
            );
            setIsAdminDataChanged(false);
        } else {
            setIsAdminDataChanged(true);
        }


    }
    async function handleEmailReception() {

            
            console.log(adminData.emailReception,"adminData.emailReception");
            setAdminData({...adminData,emailReception:!adminData.emailReception})
            await dispatch(
                updateAdmin({emailReception:!adminData.emailReception}, adminId, async () => {
                    // dispatch(getAdminById(adminId)); 
                })
            );

    }
    const handleLogoutAllDevices=()=>{
        const confirmed = window.confirm(
            "Sind Sie sicher, dass Sie sich bei allen Geräten abmelden möchten?"
          );
          if(confirmed)
          dispatch(logoutAllDevices())
          
    }
    const loginFacebook= async()=>{
      try{
        const res1=window.FB.getLoginStatus((response)=>{
          console.log(response,"LOGGED IN STATUS");
        })
        console.log("RES 10 ");
        const res=await window.FB.login(async(response)=>{
          console.log(response,"TEST LOGIN");
          if(response.status==="connected"){
            // const resME=await axios.get(`https://graph.facebook.com/me/accounts?access_token=${response.authResponse.accessToken}`)
            // console.log(resME,"resME");
            // const pageAccessToken=resME.data.data[0].access_token
            dispatch(addInitialFacebookReview(response.authResponse.accessToken,()=>{
                setIsFacebookLogged(true)
            }))
            
            // const resPage=await axios.get(`https://graph.facebook.com/me/ratings?fields=has_rating,created_time,has_review,open_graph_story,rating,recommendation_type,review_text,reviewer&access_token=${pageAccessToken}`)
            // console.log(resPage,"resPage");

          }
         
         },{scope: 'email,pages_read_user_content,pages_read_engagement'});
         
      }catch(err){
        console.log(err,"ERROR");
      }
    }

    const logoutFacebook = async() =>{
      console.log("logout called");
    //   window.FB.logout((res)=>{
    //     console.log("logout", res)
    //     setIsFacebookLogged(false)
        
    //   })
      dispatch(fbLogout())

    }
     
    useEffect(()=>{
      const facebookLoggedStatus=async()=>{
        // window.FB.getLoginStatus((res)=>{
        //   console.log(res,"LOGIN STATUS");
        //   if(res.status === "connected" && Date.now()/1000 < res.authResponse?.data_access_expiration_time ){

        //     setIsFacebookLogged(true)
        //   }else{
        //     setIsFacebookLogged(false)
        //   }
        // })
        
      }
       facebookLoggedStatus()
    },[])

    return (
        <div>
            <OnlyHeader />
            <Container className='mt--7' fluid>
                <Row className='display-flex justify-content-center'>
                    <Col md={9} className='pt-6 pb-6'>
                        <div className='mb-5'>
                            <Row>
                                <img className="ml--12" src={settingLogo} alt="setting"></img>
                                <p className='settingHeading'>Einstellungen</p>
                            </Row>
                        </div>
                        <Nav
                            className="border-bottom-nav"
                            style={{ display: "flex", justifyContent: "" }}
                        >
                            <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 1 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(1)}
                                >
                                    Stammdaten
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 2 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(2)}
                                >
                                    Login
                                </NavLink>

                            </NavItem>
                            {(user.role === "admin" || user.role === "admin-pro") && (<NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 3 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(3)}
                                >
                                    Umfragen
                                </NavLink>
                            </NavItem>)}

                            {(user.role !=="super-admin") && (
                                <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 4 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(4)}
                                >
                                    Layout
                                </NavLink>
                            </NavItem>
                            )}
                            {(user.role === "admin") && (<NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 5 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(5)}
                                >
                                    Team erweitern
                                </NavLink>
                            </NavItem>)}
                            {(role === "admin-pro" && user.role === "admin-pro") && (<NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 6 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(6)}
                                >
                                    Benutzerverwaltung
                                </NavLink>
                            </NavItem>)}
                            <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 7 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(7)}
                                >
                                    E-Mail-Empfang
                                </NavLink>
                            </NavItem>
                        </Nav>

                        {activeTab === 1 && (<div className='form-fields'>
                            <div>
                                <Form
                                    role="form"
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        console.log(adminData);
                                        await dispatch(
                                            updateAdmin(adminData, adminId, async () => {
                                                if (adminData.placeId !== initialAdminData.placeId){
                                                    setInitialAdminData({...initialAdminData,placeId:adminData.placeId})
                                                    await dispatch(addReview());
                                                }
                                                
                                            })
                                        );
                                    }}
                                >
                                    <Row>
                                        <Col xs={{ size: 4, order: 1 }} lg={2} className='mt-3'>
                                            <p className="ss-fieldLabel">Kunde</p>
                                            <Select
                                                name="salutation"
                                                required
                                                isDisabled={!isAdminDataChanged}
                                                options={[
                                                { value: 'Herr', label: 'Herr' },
                                                { value: 'Frau', label: 'Frau' },
                                                { value: 'Divers', label: 'Divers' },
                                                { value: 'Keine Angabe', label: 'Keine Angabe' },
                                                ]}
                                                value={adminData.salutation ? { value: adminData.salutation, label: adminData.salutation } : null}
                                                onChange={(selectedOption) => {
                                                    setAdminData({ ...adminData, salutation: selectedOption.value });
                                                }}
                                                styles={reactSelectStyles}
                                            />
                                            {/* <Input
                                                className='addAdminSelect'
                                                name="salutation"
                                                required
                                                type="select"
                                                disabled={!isAdminDataChanged}
                                                value={adminData.salutation}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            >
                                                

                                                <option value="Herr">Herr</option>
                                                <option value="Frau">Frau</option>
                                                <option value="Divers">Divers</option>
                                                <option value="Keine Angabe">Keine Angabe</option>

                                            </Input> */}
                                        </Col>
                                        <Col xs={{ size: 12, order: 3 }} lg={{ size: 6, order: 2 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Titel</p>
                                            <Select
                                                name="title"
                                                required
                                                isDisabled={!isAdminDataChanged}
                                                options={[
                                                    { value: 'Dr', label: 'Dr' },
                                                    { value: 'Prof', label: 'Prof' },
                                                    { value: 'Prof. Dr', label: 'Prof. Dr' },
                                                    { value: 'Dipl.-Ing', label: 'Dipl.-Ing' },
                                                    { value: 'keine', label: 'keine' },
                                                ]}
                                                value={adminData.title ? { value: adminData.title, label: adminData.title } : null}
                                                onChange={(selectedOption) => {
                                                    setAdminData({ ...adminData, 'title': selectedOption.value });
                                                }}
                                                styles={reactSelectStyles}
                                            />
                                            {/* <Input
                                                className='addAdminSelect'
                                                name="title"
                                                required
                                                type="select"
                                                value={adminData.title}
                                                disabled={!isAdminDataChanged}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            >
                                                <option value="Dr">Dr</option>
                                                <option value="Prof">Prof</option>
                                                <option value="Prof. Dr">Prof. Dr</option>
                                                <option value="Dipl.-Ing">Dipl.-Ing</option>
                                                <option value="keine">keine</option>
                                            </Input> */}
                                        </Col>
                                        <Col xs={{ size: 8, order: 2 }} lg={{ size: 4, order: 3 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Kunden-Nr</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Kunden-Nr"
                                                    type="text"
                                                    value={adminData.adminNo}
                                                    name="adminNo"
                                                    disabled
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Vorname *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Vorname"
                                                    required
                                                    type="text"
                                                    name="name"
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.name}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Nachname *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Nachname"
                                                    required
                                                    type="text"
                                                    name="lastName"
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.lastName}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='mt-3'>
                                            <p className="ss-fieldLabel">Firma</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Firma"
                                                    name="firm"
                                                    type="text"
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.firm}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='mt-3'>
                                            <p className="ss-fieldLabel">E-mail *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="E-mail"
                                                    name="email"
                                                    required
                                                    type="email"
                                                    value={adminData.email}
                                                    disabled
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Telefon *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Telefon"
                                                    name="telephone"
                                                    type="number"
                                                    required
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.telephone}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Mobil</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Mobil"
                                                    name="mobile"
                                                    type="number"
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.mobile}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Straße + Nr. *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Straße Nr."
                                                    name="streetNo"
                                                    type="text"
                                                    required
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.streetNo}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">PLZ *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="PLZ"
                                                    name="zip"
                                                    type="number"
                                                    required
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.zip}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Ort *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Ort"
                                                    name="location"
                                                    type="text"
                                                    required
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.location}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Google Place Id</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    onClick={() => {
                                                        alert("*Bitte ändern Sie die Placeid sorgfältig, da die Eingabe einer falschen Placeid dazu führen kann, dass falsche Bewertungen abgerufen werden.");
                                                    }}
                                                    className="auth-input"
                                                    placeholder="Google Place Id"
                                                    name="placeId"
                                                    type="text"
                                                    disabled={!isAdminDataChanged}
                                                    required
                                                    value={adminData.placeId}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                                <div className='auth-password-toggle' onClick={() => {
                                                    window.open(`https://search.google.com/local/writereview?placeid=${adminData.placeId}`, '_blank')
                                                }}>
                                                    verifizieren</div>
                                            </div>
                                        </Col>
                                        <p className='px-4 text-align-center text-small text-red'>
                                            {user.greetings === "formal" ? placeIdRedTextFormal : placeIdRedTextInformal}
                                        </p>
                                    </Row>
                                    {/* FACEBOOK FUNCTIONALITY 2 */}
                                    {/* <Row className='mt-3'>
                                      <Col lg={10}   className='d-flex' id='#login'>
                                        <Col md={6} >
                                        <Button onClick={loginFacebook} disabled={loading || !facebookTokenInvalid} className='facebook-button'>
                                        <span><i class="fab fa-facebook-f fa-lg mr-4"></i></span>
                                            {facebookTokenInvalid ? "Login" : "Connected"}
                                        </Button>
                                        </Col>
                                        <Col md={6} >
                                        {facebookTokenInvalid || <Button onClick={logoutFacebook} disabled={loading} className='facebook-button'>
                                            Logout
                                        </Button>}
                                        </Col>
                                      </Col>
                                    </Row> */}
                                    <Row className=' mt-3'>
                                        {/* <Col lg={1}></Col> */}
                                        <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                                            <Input
                                                name="greetings"
                                                className=""
                                                type="radio"
                                                value="formal"
                                                disabled={!isAdminDataChanged}
                                                checked={adminData.greetings === "formal"}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            />
                                            <p className="greeting-radio-text">Formelle Anrede</p>
                                        </Col>
                                        <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                                            <Input
                                                name="greetings"
                                                className=""
                                                type="radio"
                                                value="informal"
                                                disabled={!isAdminDataChanged}
                                                checked={adminData.greetings === "informal"}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            />
                                            <p className="greeting-radio-text">Informelle Anrede</p>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-end mt-5">
                                        <Button onClick={submitForm} disabled={loading} className='simple-button'>
                                            {loading ? <Spinner size="sm" /> : isAdminDataChanged ? "Änderungen speichern" : "Bearbeiten"}
                                        </Button>
                                    </div>

                                </Form>
                            </div>
                        </div>
                        )}
                        {activeTab === 2 && (
                            <Row>
                                <Col className='mt-3' md={6}>
                                    <p className='settingLable'>Benutzername</p>
                                    <div className="auth-input-container">
                                        <Input
                                            className="auth-input"
                                            placeholder="Benutzername"
                                            type="text"
                                            value={adminData.email}
                                            name="email"
                                            disabled
                                            onChange={(e) => {
                                                // handleAdminDataChange(e);
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}></Col>

                                {!varified ? (<>
                                    <Col md={6}>
                                        <p className='settingLable mt-3'>Passwort</p>
                                        <div className="auth-input-container">
                                            <Input
                                                className="auth-input"
                                                placeholder=""
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                autoComplete="new-password"
                                                value={password}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setPassword(e.target.value);
                                                }}
                                            />
                                            <div
                                                className="auth-password-toggle"
                                                onClick={toggleShowPassword}
                                            >
                                                {showPassword ? (
                                                    <i className="fas fa-eye" />
                                                ) : (
                                                    <i className="fas fa-eye-slash" />
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}></Col>
                                    <Col md={6}>
                                        <p className='settingLable mt-3'>Neues Passwort *</p>
                                        <div className="auth-input-container">
                                            <Input
                                                className="auth-input"
                                                placeholder=""
                                                type={showPassword ? "text" : "password"}
                                                name="newpassword"
                                                value={newPassword}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setNewPassword(e.target.value);
                                                }}
                                            />
                                            <div
                                                className="auth-password-toggle"
                                                onClick={toggleShowPassword}
                                            >
                                                {showPassword ? (
                                                    <i className="fas fa-eye" />
                                                ) : (
                                                    <i className="fas fa-eye-slash" />
                                                )}
                                            </div>
                                        </div>

                                        <Row className='ml-2 mt-2'>
                                            <h4>
                                                <Badge color={isLengthValid ? 'success' : 'danger'}>8-20 zeichen</Badge>
                                            </h4>
                                            <h4>
                                                <Badge color={isUppercaseValid ? 'success' : 'danger'}>1 Großbuchstabe</Badge>
                                            </h4>
                                            <h4>
                                                <Badge color={isLowercaseValid ? 'success' : 'danger'}>1 Kleinbuchstabe</Badge>
                                            </h4>
                                            <h4>
                                                <Badge color={isDigitValid ? 'success' : 'danger'}>1 Ziffer</Badge>
                                            </h4>
                                            <h4>
                                                <Badge color={isSpecialCharValid ? 'success' : 'danger'}>Sonderzeichen</Badge>
                                            </h4>
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <p className='settingLable mt-3'>Neues Passwort wiederholen *</p>
                                        <div className="auth-input-container">
                                            <Input
                                                className="auth-input"
                                                placeholder=""
                                                type={showPassword ? "text" : "password"}
                                                name="confirmpassword"
                                                value={confirmNewPassword}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setconfirmNewPassword(e.target.value);
                                                }}
                                            />
                                            <div
                                                className="auth-password-toggle"
                                                onClick={toggleShowPassword}
                                            >
                                                {showPassword ? (
                                                    <i className="fas fa-eye" />
                                                ) : (
                                                    <i className="fas fa-eye-slash" />
                                                )}
                                            </div>
                                        </div>
                                        {!isConfirmPasswordValid && <div style={{ color: 'red', fontSize: '11px' }}>{confirmPasswordErrorMessage}</div>}

                                    </Col>

                                    <Col md={6} className='mt-4'><Button onClick={handleLogoutAllDevices} className='simple-full-button-grey'>
                                    Überall anders abmelden
                                    </Button></Col>
                                    <Col md={6} className=' mt-4 d-flex justify-content-end'>
                                        <Button className='simple-button-green' onClick={async (e) => {

                                            try {
                                                e.preventDefault();
                                                let data = {
                                                    email: adminData.email,
                                                    password: password,
                                                };

                                                const returnValue = await dispatch(userLogin(data));
                                                if (returnValue) {
                                                    toggleShowPassword();

                                                    if (isPasswordValid) {
                                                        if (newPassword.length >= 8 && isConfirmPasswordValid && password !== newPassword) {
                                                            await dispatch(setAdminPassword({ password: newPassword }));
                                                            toggleShowPassword();
                                                            setPassword("");
                                                            setNewPassword("");
                                                            setconfirmNewPassword("")
                                                        }
                                                        else if (password === newPassword) {
                                                            alert("Das neue Passwort kann nicht das alte Passwort sein")
                                                        }
                                                        else {
                                                            alert("Bitte geben Sie ein gültiges Passwort ein");
                                                        }
                                                    }
                                                    else {
                                                        alert("Bitte geben Sie ein gültiges Passwort ein");
                                                    }
                                                }

                                            } catch (e) {
                                                console.log(e);
                                                toggleShowPassword();
                                                setPassword("");
                                                setNewPassword("");
                                                setconfirmNewPassword("")
                                            }

                                        }}>
                                            passwort speichern
                                        </Button>
                                    </Col>
                                </>) : (<>
                                </>)}
                            </Row>
                        )}
                        {activeTab === 3 && (<Col className='d-flex align-item-center justify-content-center mt-5'  >
                            {user.greetings === "formal" ? umfragenFormalText : umfragenInformalText}
                        </Col>
                        )}
                        {activeTab === 4 && (
                            <>
                                {user.role !== "super-admin" ? <Col className='d-flex align-item-center justify-content-center mt-5' >
                                    {user.greetings === "formal" ? layoutFormalText : layoutInformalText}
                                </Col> :
                                    <>
                                        <Row className='mt-3'>
                                            <Col lg={4} className='d-flex flex-column-reverse'>
                                                <Button className='logo-button mt-2 mb-2' onClick={handleImageUpload}>
                                                    Logo upload
                                                </Button>
                                                <Button className='upload-file-button ml-0 mt-2 mb-2' onClick={() => {
                                                    imageRef.current.click();
                                                }}>
                                                    Logo-Upload
                                                    <div
                                                        className="auth-password-toggle"
                                                    >
                                                        <i className="fas fa-upload" />
                                                    </div>
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={imageRef}
                                                    name="image"
                                                    accept="image/*"
                                                    onChange={handleImageInputChange}
                                                    style={{ display: 'none' }}
                                                />
                                                <p className='settingLable'>Datenupload (png, svg, jpg)</p>

                                                <p className='settingLable'>Umfrage-Logo</p>

                                            </Col>
                                            {/* <Col lg={1}></Col> */}
                                            <Col lg={8}>
                                                <img src={imagePreviewUrl}
                                                    className='logo-image'></img>
                                            </Col>
                                        </Row>
                                        <div className='horizontal-line mt-4'></div>
                                        <Row className='mt-3'>
                                            <Col lg={4} className='d-flex flex-column-reverse'>
                                                <Button disabled={adminFavLoading} className='logo-button mt-2 mb-2' onClick={handleFavUpload}>
                                                    {adminFavLoading ? <Spinner></Spinner> : "Profil-Upload"}
                                                </Button>
                                                <Button className='upload-file-button ml-0 mt-2 mb-2' onClick={() => {
                                                    favRef.current.click();
                                                }}>
                                                    Profil-Upload
                                                    <div
                                                        className="auth-password-toggle"
                                                    // onClick={toggleShowPassword}
                                                    >
                                                        <i className="fas fa-upload" />
                                                    </div>
                                                </Button>
                                                <input
                                                    type="file"
                                                    ref={favRef}
                                                    name="image"
                                                    accept="image/*"
                                                    onChange={handleFavInputChange}
                                                    style={{ display: 'none' }}
                                                />
                                                <p className='settingLable '>Datenupload (png, svg, jpg)</p>
                                                <p className='settingLable mt-4'>Profil-Logo</p>
                                                <img src={favPreviewUrl}
                                                    className='fav-image'></img>
                                            </Col>
                                            {/* <Col lg={8} className='d-flex'>
                                                <div className='white-color'>
                                                    <div className='blue-color' />
                                                </div>
                                                <div className='white-color'>
                                                    <div className='green-color' />
                                                </div>

                                            </Col> */}
                                        </Row>
                                        <div className='horizontal-line mt-4'></div>
                                        <Row className='d-flex flex-row-reverse mt-4'>
                                            <Button className='simple-button mt-2 mb-2'>
                                                Speichern
                                            </Button>
                                        </Row>
                                    </>
                                }
                            </>

                        )}
                        {activeTab === 5 && (<Col className='d-flex align-item-center justify-content-center mt-5' >
                            {user.greetings === "formal" ? teamErweiternFormalText : teamErweiternInformalText}</Col>
                        )}
                        {(activeTab === 6 && role === "admin-pro") && (<>
                            <Nav
                            className="border-bottom-nav mt-5"
                            style={{ display: "flex", justifyContent: "" }}
                        >
                            <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${benutzerverwaltungTab === 1 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleBenutzerverwaltungTab(1)}
                                >
                                    Übersicht
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${benutzerverwaltungTab === 2 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleBenutzerverwaltungTab(2)}
                                >
                                    Einladungen
                                </NavLink>

                            </NavItem>
                        </Nav>
                        {benutzerverwaltungTab === 1 && <Col>
                                <Table className="align-items-center table-flush mt-5" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Benutzer</th>
                                            <th scope="col">Rollen</th>
                                            <th scope="col">E-Mail</th>
                                            <th scope="col">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {admin?.data?.subAdmins &&
                                            admin.data.subAdmins.map((el, index) => {
                                                return (
                                                    <tr>
                                                        <td>{`${el.lastName}, ${el.name}`}</td>
                                                        <td>{`${el?.role}`}</td>
                                                        <td>{el.email}</td>
                                                        <td className="text-center">
                                                            {el?.role !== "admin-pro" ? <OverlayTrigger
                                                                key={index}
                                                                trigger="click"
                                                                placement="bottom"
                                                                rootClose={true}
                                                                onHide={() => handlePopoverClose(index)}
                                                                show={showPopovers[index]}
                                                                overlay={
                                                                    <Popover
                                                                        id={`popover-positioned-bottom-${index}`}
                                                                    >
                                                                        <Popover.Body>
                                                                            <Row
                                                                                onClick={() => {

                                                                                    const confirmed = window.confirm(
                                                                                        "Sind Sie sicher?"
                                                                                    );
                                                                                    if (confirmed) {
                                                                                        dispatch(delinkAdminFromTeam({ id: el._id }, () => {
                                                                                            dispatch(getAdminById(adminId));
                                                                                        }));
                                                                                        
                                                                                    }
                                                                                    handlePopoverClose(index)
                                                                                }}
                                                                            >
                                                                                Aus dem Team entfernen
                                                                            </Row>
                                                                            {/* <Row onClick={() => {
                                        }}>Anzeigen</Row>
                                      <Row
                                        onClick={() => {
                                        }}
                                      >
                                        Bearbeiten
                                      </Row>
                                      <Row
                                        onClick={() => {
                                          handlePopoverClose(index);
                                        }}
                                      >
                                        Löschen
                                      </Row> */}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <i
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        transform: `rotate(90deg)`,
                                                                    }}
                                                                    className="fas fa-ellipsis-v"
                                                                    onClick={() => {
                                                                        const newShowPopovers = [...showPopovers];
                                                                        newShowPopovers[index] =
                                                                            !showPopovers[index];
                                                                        setShowPopovers(newShowPopovers);
                                                                    }}
                                                                ></i>
                                                            </OverlayTrigger> : ""}

                                                        </td>

                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </Col>}
                            {benutzerverwaltungTab === 2 && <>
                                <Invitations/>
                            </>}  
                            <Form
                                className='mt-5'
                                role="form"
                                onSubmit={async (e) => {
                                    e.preventDefault();
                                    dispatch(inviteAdminInTeam({ email: inviteEmail }))
                                }}
                            >
                                <h2>Team erweitern</h2>

                                <Row>
                                    <Col
                                        sm={6}
                                        className=""
                                    >
                                        <p className="ss-fieldLabel">Email</p>
                                        <div className="auth-input-container">
                                            <Input
                                                className="auth-input"
                                                placeholder="Email"
                                                name="email"
                                                required
                                                type="email"
                                                value={inviteEmail}
                                                onChange={(e) => {
                                                    setInviteEmail(e.target.value);
                                                }}
                                            ></Input>
                                        </div>
                                    </Col>
                                    <Col
                                        lg={{ size: 4, offset: 2 }}
                                        md={6}
                                        className=""
                                    >
                                        <p style={{ visibility: "hidden" }} className="ss-fieldLabel">Email</p>
                                        <Button
                                            className=" customer-header-button"
                                            disabled={inviteLoading}
                                            type="submit"
                                        >
                                            {inviteLoading ? <Spinner size="sm" /> : "Team erweitern"}
                                        </Button>
                                    </Col>
                                </Row>

                                <Row className="mt-3">

                                </Row>
                            </Form>
                        </>
                        )}
                        {activeTab === 7 && (
                            <>
                            <Col className='d-flex align-item-center justify-content-center mt-3'>
                            {emailReceptionText}
                            </Col>
                            <Col className='d-flex align-item-center justify-content-left mt-3'>
                            E-Mail-Empfang 
                            <div
                              className={`ml-1 switch-toggle ${adminData.emailReception ? 'on' : 'off'}`}
                              onClick={handleEmailReception}
                            >
                              <div className="switch-knob" />
                            </div>
                            </Col>
                            <Row className='align-items-center justify-content-left mt-3'>
                                     
                            </Row>
                            </>

                        )}
                    </Col>
                </Row>



                {/* <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="file">Upload a file</Label>
                        <Input type="file" name="file" id="file" onChange={handleFileChange} />
                    </FormGroup>
                    <Row className="mt-3">
                        <Col
                            lg={{
                                offset: 8,
                                size: 4,
                            }}
                            sm={12}
                            className="d-flex justify-content-end"
                        >
                            <Button
                                className=" mt-3 mt-md-0 customer-header-button"
                                type="submit"
                            >Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row className="mt-3">
                    <Col
                        lg={{
                            offset: 8,
                            size: 4,
                        }}
                        sm={12}
                        className="d-flex justify-content-end"
                    >
                        <Button onClick={async () => {
                            const csvFile = await dispatch(
                                customerCSVFileDownload()
                            );
                            console.log(csvFile);
                            const url = URL.createObjectURL(csvFile);
                            // Create an anchor tag with the URL and trigger the download
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = 'customers.csv';
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }}
                            className=" mt-3 mt-md-0 customer-header-button"
                        >Download Customers
                        </Button>
                    </Col>
                </Row> */}
            </Container>
        </div >
    )
}
