import React, { useState, useEffect, useRef } from 'react'
import Select from "react-select";
import OnlyHeader from 'components/Headers/OnlyHeader'
import {
    Button,
    Card,
    CardHeader,
    Col,
    Form,
    Input,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Spinner,
    Label,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";
import CustomersIcon from '../assets/img/icons/costumers-blue.svg';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { getAdminById } from 'store/action/adminAction';
import { updateAdmin } from 'store/action/adminAction';
import { useHistory } from "react-router-dom";
import { addReview } from 'store/action/placesAction';
import { uploadLogo } from 'store/action/adminAction';
import { uploadFav } from 'store/action/adminAction';
import { customerExcelFileUpload } from 'store/action/customerAction';
import { deleteTempCustomers } from 'store/action/customerAction';
import { transferTempCustomers } from 'store/action/customerAction';
import { SketchPicker } from 'react-color';
import { reactSelectStyles } from 'components/selectStyle';
import { sendTestSurvey } from 'store/action/surveyAction';

export default function EditAdmin() {

    const history = useHistory();
    const [activeTab, setActiveTab] = useState(1); //nav tabs

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const adminId = searchParams.get('id');
    const [customerPreviewModal, setcustomerPreviewModal] = useState(false);
    const customerPreviewModalToggle = () => setcustomerPreviewModal(!customerPreviewModal);

    const dispatch = useDispatch();

    //Color picker side functions

    const [colorChangeModal, setColorChangeModal] = useState(false);
    const colorChangeModalToggle = () => setColorChangeModal(!colorChangeModal);

    const [buttonColor, setButtonColor] = useState('#008480');
    const [pickerColor, setPickerColor] = useState('#008480');

    const handlePickerChange = (color) => {
        setPickerColor(color.hex);
    }

    const handleColorChange = () => {
        console.log('Selected color:', pickerColor);
        setButtonColor(pickerColor);
        colorChangeModalToggle();
    };

    //Customer file handling variables

    const [fileName, setFileName] = useState("Datenupload (.csv)");
    const [excelFile, setExcelFile] = useState(null);

    const fileRef = useRef(null);

    //Customer file handling code
    function handleFileChange(event) {
        let selectedFile = event.target.files[0];
        const fileType = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/csv",
        ];
        if (selectedFile) {
            console.log(selectedFile.name);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                setExcelFile(selectedFile);
                setFileName(selectedFile.name);
            }
        } else {
            setExcelFile(null);
            setFileName("Datenupload (.csv)");
            console.log("File not selected");
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        if (excelFile !== null) {
            const formData = new FormData();
            formData.append("file", excelFile);
            await dispatch(
                customerExcelFileUpload(formData, adminId, () => {
                    // dispatch(getCustomers({ limit: limit, pageNo: page }));
                    // setFileName("Datenupload (.csv)");
                    // setExcelFile(null);
                    customerPreviewModalToggle();
                })
            );

        } else {
            console.log(excelFile);
            setExcelFile(null);
        }
    }

    // Image uploading backend code here

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFavImage, setSelectedFavImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('https://images.crutchfieldonline.com/ImageBank/v20150915135000/dummyphoto405x300.jpg');
    const [favPreviewUrl, setFavPreviewUrl] = useState("https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK.png?alt=media&token=7544e708-a68a-491e-864b-7e8e50c1a4dc");

    const imageRef = useRef(null);
    const favRef = useRef(null);

    const handleImageUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (selectedImage != null) {
            formData.append('file', selectedImage);
            await dispatch(uploadLogo(formData, adminId));
        }
    };
    const handleFavUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (selectedFavImage != null) {
            console.log(adminId);
            formData.append('file', selectedFavImage);
            await dispatch(uploadFav(formData, adminId));
        }
    };
    const handleTestBanner = async (e) => {
        localStorage.setItem('imagePreviewUrl', imagePreviewUrl);
        console.log(selectedImage,"SELECTED IMAGE",admin.data.logoURL);
        let formData = new FormData();
        if(selectedImage && selectedImage.type)
            formData.append("file", selectedImage);
        else
            formData.append("imageurl",admin.data.logoURL)
        console.log(formData,"FORMDATA");
        dispatch(sendTestSurvey(formData,adminId,()=>{
            const win = window.open("/previewsurvey", "_blank");
            if (win) {
                win.focus();
            } else {
                console.error("Failed to open the new window");
            }
        }))
        
    };


    const handleImageInputChange = (event) => {
        setSelectedImage(event.target.files[0]);
        // Preview image
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
            console.log(reader.result, "READER RESULT");
            localStorage.setItem('imagePreviewUrl', reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
        // const win = window.open("/previewsurvey", "_blank");
        // win.focus();

    };
    const handleFavInputChange = (event) => {
        setSelectedFavImage(event.target.files[0]);
        // Preview image
        const reader = new FileReader();
        reader.onloadend = () => {
            setFavPreviewUrl(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    };
    //Image uploading backend code ended

    const [adminData, setAdminData] = useState({
        name: "",
        email: "",
        lastName: "",
        role: "admin",
        greetings: "formal",
        salutation: "",
        title: "",
        adminNo: "",
        firm: "",
        telephone: "",
        mobile: "",
        streetNo: "",
        zip: "",
        location: "",
        placeId: ""
        // contactNumber: "",
    });

    const [initialAdminData, setInitialAdminData] = useState({
        name: "",
        email: "",
        lastName: "",
        role: "admin",
        greetings: "formal",
        salutation: "",
        title: "",
        adminNo: "",
        firm: "",
        telephone: "",
        mobile: "",
        streetNo: "",
        zip: "",
        location: "",
        placeId: ""
        // contactNumber: "",
    });
    const [isAdminDataChanged, setIsAdminDataChanged] = useState(false);
    // useEffect(() => { 

    //     if(isEqual(adminData, initialAdminData))
    //     setIsAdminDataChanged(false)
    //     else
    //     setIsAdminDataChanged(true)
    // },[adminData,initialAdminData])
    const { admin, adminLoading, loading, adminFavLoading, adminLogoLoading } = useSelector((state) => state.admin);
    const {  surveyLoading } = useSelector((state) => state.survey);

    const { tempCustomers } = useSelector(
        (state) => state.customer
    );

    const toggleTab = (tab) => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }
    const handleAdminDataChange = (e) => {
        // e.preventDefault();
        // setAdminData({ ...adminData, [e.target.name]: e.target.value });
        if (e.target.name === "greetings") {
            setAdminData({ ...adminData, [e.target.name]: e.target.value });
        } else {
            e.preventDefault();
            setAdminData({ ...adminData, [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
        console.log(`Admin ID: ${adminId}`);
        dispatch(getAdminById(adminId));
    }, [adminId]);
    useEffect(() => {
        if (admin && admin.data) {
            setButtonColor(admin.data?.hexcode || '#008480');
            setPickerColor(admin.data?.hexcode || '#008480');
            setImagePreviewUrl(admin.data.logoURL || 'https://images.crutchfieldonline.com/ImageBank/v20150915135000/dummyphoto405x300.jpg');
            setFavPreviewUrl(admin.data.favURL || "https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK.png?alt=media&token=7544e708-a68a-491e-864b-7e8e50c1a4dc");
            setAdminData({
                name: admin.data.name,
                email: admin.data.email,
                lastName: admin.data.lastName,
                role: admin.data.role,
                greetings: admin.data.greetings,
                salutation: admin.data.salutation,
                title: admin.data.title,
                adminNo: admin.data.adminNo,
                firm: admin.data.firm,
                telephone: admin.data.telephone,
                mobile: admin.data.mobile,
                streetNo: admin.data.streetNo,
                zip: admin.data.zip,
                location: admin.data.location,
                placeId: admin.data.placeId,
                // contactNumber: "",
            });
           
            setInitialAdminData({
                name: admin.data.name,
                email: admin.data.email,
                lastName: admin.data.lastName,
                role: admin.data.role,
                greetings: admin.data.greetings,
                salutation: admin.data.salutation,
                title: admin.data.title,
                adminNo: admin.data.adminNo,
                firm: admin.data.firm,
                telephone: admin.data.telephone,
                mobile: admin.data.mobile,
                streetNo: admin.data.streetNo,
                zip: admin.data.zip,
                location: admin.data.location,
                placeId: admin.data.placeId
                // contactNumber: "",
            });
        }
    }, [admin])
    return (
        <div>
            <OnlyHeader />
            <Container className='mt--7' fluid>
                <Row className='display-flex justify-content-center'>
                    <Col md={8} className='pt-6 pb-6'>
                        <div className='customersHeading mb-5'>
                            <img src={CustomersIcon}></img>
                            {admin && admin.data && (
                                <p className='custHeading'>Kunde {admin.data.name} {admin.data.lastName}</p>
                            )}
                        </div>

                        <Nav
                            className="border-bottom-nav"
                            style={{ display: "flex", justifyContent: "" }}
                        >
                            <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 1 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(1)}
                                >
                                    Stammdaten
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 2 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(2)}
                                >
                                    Layout
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-item-tab">
                                <NavLink
                                    className={`${activeTab === 3 ? "active-tab-nav" : "inactive-tab-nav"
                                        }`}
                                    onClick={() => toggleTab(3)}
                                >
                                    Kunde Hochladen
                                </NavLink>
                            </NavItem>

                        </Nav>

                        {activeTab === 1 && (<div className='form-fields'>
                            <div>
                                <Form
                                    role="form"
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        if (isAdminDataChanged) {

                                            console.log(adminData);
                                            await dispatch(
                                                updateAdmin(adminData, adminId, async () => {
                                                    if (adminData.placeId !== initialAdminData.placeId) {
                                                        await dispatch(addReview(adminData.email));
                                                    }
                                                })
                                            );
                                            history.push('/admin/admins');
                                            setIsAdminDataChanged(false);
                                        }
                                        else {
                                            setIsAdminDataChanged(true);
                                        }

                                    }}
                                >
                                    <Row>
                                        <Col xs={{ size: 4, order: 1 }} lg={2} className='mt-3'>
                                            <p className="ss-fieldLabel">Kunde</p>
                                            <Select
                                                className="ss-selectField"
                                                name="salutation"
                                                required
                                                isDisabled={!isAdminDataChanged}
                                                options={[
                                                { value: 'Herr', label: 'Herr' },
                                                { value: 'Frau', label: 'Frau' },
                                                { value: 'Divers', label: 'Divers' },
                                                { value: 'Keine Angabe', label: 'Keine Angabe' },
                                                ]}
                                                value={adminData.salutation ? { value: adminData.salutation, label: adminData.salutation } : null}
                                                onChange={(selectedOption) => {
                                                    setAdminData({ ...adminData, salutation: selectedOption.value });
                                                }}
                                                styles={reactSelectStyles}
                                            />
                                            {/* <Input
                                                className='addAdminSelect'
                                                name="salutation"
                                                required
                                                type="select"
                                                value={adminData.salutation}
                                                disabled={!isAdminDataChanged}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            >
                                                

                                                <option value="Herr">Herr</option>
                                                <option value="Frau">Frau</option>
                                                <option value="Divers">Divers</option>
                                                <option value="Keine Angabe">Keine Angabe</option>
                                            </Input> */}
                                        </Col>
                                        <Col xs={{ size: 12, order: 3 }} lg={{ size: 6, order: 2 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Titel</p>
                                            <Select
                                                className="addAdminSelect"
                                                name="title"
                                                required
                                                isDisabled={!isAdminDataChanged}
                                                options={[
                                                    { value: 'Dr', label: 'Dr' },
                                                    { value: 'Prof', label: 'Prof' },
                                                    { value: 'Prof. Dr', label: 'Prof. Dr' },
                                                    { value: 'Dipl.-Ing', label: 'Dipl.-Ing' },
                                                    { value: 'keine', label: 'keine' },
                                                ]}
                                                value={adminData.title ? { value: adminData.title, label: adminData.title } : null}
                                                onChange={(selectedOption) => {
                                                    setAdminData({ ...adminData, 'title': selectedOption.value });
                                                }}
                                                styles={reactSelectStyles}
                                            />
                                            {/* <Input
                                                className='addAdminSelect'
                                                name="title"
                                                required
                                                type="select"
                                                disabled={!isAdminDataChanged}
                                                value={adminData.title}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            >
                                                <option value="Dr">Dr</option>
                                                <option value="Prof">Prof</option>
                                                <option value="Prof. Dr">Prof. Dr</option>
                                                <option value="Dipl.-Ing">Dipl.-Ing</option>
                                                <option value="keine">keine</option>
                                            </Input> */}
                                        </Col>
                                        <Col xs={{ size: 8, order: 2 }} lg={{ size: 4, order: 3 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Kunden-Nr</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Kunden-Nr"
                                                    type="text"
                                                    value={adminData.adminNo}
                                                    name="adminNo"
                                                    disabled
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Vorname *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Vorname"
                                                    required
                                                    type="text"
                                                    name="name"
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.name}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Nachname *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Nachname"
                                                    required
                                                    type="text"
                                                    name="lastName"
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.lastName}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='mt-3'>
                                            <p className="ss-fieldLabel">Firma</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Firma"
                                                    name="firm"
                                                    type="text"
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.firm}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className='mt-3'>
                                            <p className="ss-fieldLabel">E-mail *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="E-mail"
                                                    name="email"
                                                    required
                                                    type="email"
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.email}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Telefon *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Telefon"
                                                    name="telephone"
                                                    type="number"
                                                    required
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.telephone}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Mobil</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Mobil"
                                                    name="mobile"
                                                    type="number"
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.mobile}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Straße + Nr. *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Straße Nr."
                                                    name="streetNo"
                                                    type="text"
                                                    required
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.streetNo}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">PLZ *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="PLZ"
                                                    name="zip"
                                                    type="number"
                                                    required
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.zip}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Ort *</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Ort"
                                                    name="location"
                                                    type="text"
                                                    required
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.location}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='mt-3'>
                                            <p className="ss-fieldLabel">Google Place Id</p>
                                            <div className="auth-input-container">
                                                <Input
                                                    className="auth-input"
                                                    placeholder="Google Place Id"
                                                    name="placeId"
                                                    type="text"
                                                    disabled={!isAdminDataChanged}
                                                    value={adminData.placeId}
                                                    onChange={(e) => {
                                                        handleAdminDataChange(e);
                                                    }}
                                                ></Input>
                                            </div>

                                        </Col>

                                        {/* <Col xs={{ size: 12, order: 3 }} lg={{ size: 6, order: 2 }} className='mt-3'>
                                            <p className="ss-fieldLabel">Role</p>
                                            <Input
                                                className='addAdminSelect'
                                                name="role"
                                                required
                                                type="select"
                                                value={adminData.role}
                                                disabled={!isAdminDataChanged}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            >
                                                <option value="admin">Admin</option>
                                                <option value="super-admin">Super Admin</option>
                                                <option value="admin-pro">Admin Pro</option>
                                                
                                            </Input>
                                        </Col> */}
                                    </Row>
                                    <Row className=' mt-3'>
                                        {/* <Col lg={1}></Col> */}
                                        <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                                            <Input
                                                name="greetings"
                                                className=""
                                                type="radio"
                                                disabled={!isAdminDataChanged}
                                                value="formal"
                                                checked={adminData.greetings === "formal"}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            />
                                            <p className="greeting-radio-text">Formelle Anrede</p>
                                        </Col>
                                        <Col className="d-flex align-items-center ml-4" md={4} sm={12}>
                                            <Input
                                                name="greetings"
                                                className=""
                                                type="radio"
                                                disabled={!isAdminDataChanged}
                                                value="informal"
                                                checked={adminData.greetings === "informal"}
                                                onChange={(e) => {
                                                    handleAdminDataChange(e);
                                                }}
                                            />
                                            <p className="greeting-radio-text">Informelle Anrede</p>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-end mt-5">
                                        <Button color="primary" type="submit" disabled={loading} className='addAdminButton'>
                                            {loading ? <Spinner size="sm" /> : isAdminDataChanged ? "Änderungen speichern" : "Bearbeiten"}
                                        </Button>
                                    </div>

                                </Form>
                            </div>
                        </div>)}

                        {/* Logo editing panel */}
                        {activeTab === 2 && (<>

                            <Row className='mt-3'>
                                <Col lg={4} className='d-flex flex-column-reverse'>
                                    <Button disabled={adminLogoLoading} className='logo-button mt-2 mb-2' onClick={handleImageUpload}>
                                        {adminLogoLoading ? <Spinner></Spinner> : 'Go Live'}
                                    </Button>
                                    <Button disabled={surveyLoading} className='logo-button mt-2 mb-2 ml-0 ' onClick={handleTestBanner}>
                                    {surveyLoading ? <Spinner></Spinner> : 'Preview/Test email'}
                                    </Button>
                                    <Button className='upload-file-button ml-0 mt-2 mb-2' onClick={() => {
                                        imageRef.current.click();
                                    }}>
                                        Logo-Upload
                                        <div
                                            className="auth-password-toggle"
                                        >
                                            <i className="fas fa-upload" />
                                        </div>
                                    </Button>
                                    <input
                                        type="file"
                                        ref={imageRef}
                                        name="image"
                                        accept="image/*"
                                        onChange={handleImageInputChange}
                                        style={{ display: 'none' }}
                                    />
                                    <p className='settingLable'>Datenupload (png, svg, jpg)</p>

                                    <p className='settingLable'>Umfrage-Logo</p>
                                    <Button
                                        // variant="primary"
                                        className='m-4'
                                        onClick={colorChangeModalToggle}
                                        style={{
                                            width: '60px',
                                            height: '60px',
                                            backgroundColor: buttonColor,
                                            // border: '1px solid #000', // Add a 1px solid black border
                                            // margin: '4px',
                                        }} // Apply custom styles to the button
                                    >
                                    </Button>


                                </Col>
                                {/* <Col lg={1}></Col> */}
                                <Col lg={8}>
                                    <img src={imagePreviewUrl}
                                        className='logo-image'></img>

                                </Col>
                            </Row>
                            <Row className='align-items-center justify-content-center mt-1'>
                                <Col>
                                <p className="text-small text-red justify-content-center">
                          *Für eine optimale Darstellung sowohl in Umfragen als auch in E-Mail-Umfragen sollten Sie Bilder mit einer Größe von 512 x 145 verwenden, um die beste visuelle Qualität zu gewährleisten.
                        </p>
                                </Col>
                            
                        </Row>
                            <div className='horizontal-line mt-4'></div>
                            <Row className='mt-3'>
                                <Col lg={4} className='d-flex flex-column-reverse'>
                                    <Button disabled={adminFavLoading} className='logo-button mt-2 mb-2' onClick={handleFavUpload}>
                                        {adminFavLoading ? <Spinner></Spinner> : 'Profil-Upload'}
                                    </Button>
                                    <Button className='upload-file-button ml-0 mt-2 mb-2' onClick={() => {
                                        favRef.current.click();
                                    }}>
                                        Profil-Upload
                                        <div
                                            className="auth-password-toggle"
                                        // onClick={toggleShowPassword}
                                        >
                                            <i className="fas fa-upload" />
                                        </div>
                                    </Button>
                                    <input
                                        type="file"
                                        ref={favRef}
                                        name="image"
                                        accept="image/*"
                                        onChange={handleFavInputChange}
                                        style={{ display: 'none' }}
                                    />
                                    <p className='settingLable '>Datenupload (png, svg, jpg)</p>
                                    <p className='settingLable mt-4'>Profil-Logo</p>
                                    <img src={favPreviewUrl}
                                        className='fav-image'></img>
                                </Col>
                                {/* <Col lg={8} className='d-flex'>
                                    <div className='white-color'>
                                        <div className='blue-color' />
                                    </div>
                                    <div className='white-color'>
                                        <div className='green-color' />
                                    </div>

                                </Col> */}
                            </Row>
                            <div className='horizontal-line mt-4'></div>
                            <Row className='d-flex flex-row-reverse mt-4'>
                                <Button className='simple-button mt-2 mb-2'>
                                    Speichern
                                </Button>
                            </Row>
                        </>

                        )}
                        {activeTab === 3 && (
                            <Col className='d-flex align-item-center justify-content-center mt-5'>
                                <Row>
                                    {/* <Col md={2}></Col> */}
                                    <Col>
                                        <p className="text-search-header">
                                            Kundendaten Import
                                        </p>
                                    </Col>
                                    {/* </Row>
                                <Row> */}
                                    {/* <Col md={2}></Col> */}
                                    <Col>
                                        <p className="uploadtype-label">{fileName}</p>
                                        <Button
                                            className="csv-file-button m-2"
                                            onClick={() => {
                                                fileRef.current.click();
                                            }}
                                        >
                                            Datei auswählen (csv.)
                                            <div
                                                className="auth-password-toggle"
                                           
                                            >
                                                <i className="fas fa-upload" />
                                            </div>
                                        </Button>
                                        <input
                                            type="file"
                                            ref={fileRef}
                                            name="file"
                                            accept=".xlsx, .xls, .csv"
                                            onChange={handleFileChange}
                                            style={{ display: "none" }}
                                        />
                                        <Button className="upload-button m-2" onClick={handleSubmit}>
                                            Daten hochladen
                                        </Button>

                                        {/* <Button className="format-button m-2" onClick={csvToggle}>
                                            <div
                                                className="button-left-icon"
                                            >
                                                <i className="fas fa-info-circle" />
                                            </div>
                                            <p className="format-button-text">Infos / Vorschau</p>
                                        </Button> */}

                                    </Col>
                                </Row>

                            </Col>
                        )}


                    </Col>
                </Row>

            </Container>

            {/* Modals */}

            <Modal size="lg" isOpen={customerPreviewModal} toggle={customerPreviewModalToggle}>
                <ModalHeader toggle={customerPreviewModalToggle}>Kunden Upload</ModalHeader>
                <ModalBody>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Kd.-Nr</th>
                                <th scope="col">Anrede</th>
                                <th scope="col">Titel</th>
                                <th scope="col">Name</th>
                                <th scope="col">Firma</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tempCustomers.map((el, index) => (
                                <tr >
                                    <td>{el.customerNo}</td>
                                    <td>{el.salutation}</td>
                                    <td>{el.title}</td>
                                    <td>{`${el.lastName}, ${el.name}`}</td>
                                    <td>{el.firm}</td>
                                    <td>{el.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button style={{backgroundColor:'#2b3347',color:"white"}} onClick={async () => {
                        await dispatch(
                            transferTempCustomers(adminId));
                        customerPreviewModalToggle();
                    }}>Bestätigen</Button>
                    <Button color="secondary" onClick={async () => {
                        await dispatch(
                            deleteTempCustomers(adminId), () => {
                                setFileName("Datenupload (.csv)");
                                setExcelFile(null);
                            });
                        customerPreviewModalToggle()
                    }}>Abbrechen</Button>
                </ModalFooter>
            </Modal>

            <Modal size="sm" isOpen={colorChangeModal} toggle={colorChangeModalToggle}>
                <ModalHeader toggle={colorChangeModalToggle}>Umfrage-Layout Ändern</ModalHeader>
                <ModalBody>
                    {/* <ColorPicker></ColorPicker> */}
                    <Row className='d-flex aling-item-center justify-content-center'><SketchPicker color={pickerColor} onChange={handlePickerChange}></SketchPicker>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button style={{backgroundColor:'#2b3347',color:"white"}} onClick={async () => {
                        handleColorChange();
                        await dispatch(
                            updateAdmin({ hexcode: pickerColor, color: true }, adminId)
                        );
                    }}>Bestätigen</Button>
                    <Button color="secondary" onClick={colorChangeModalToggle}>Schließen</Button>
                </ModalFooter>
            </Modal>



        </div>
    )
}

{/* <Button variant="primary" onClick={handleColorChange}>
                        Confirm
                    </Button>
                    <Button variant="secondary" onClick={() => { colorChangeModalToggle() }}>
                        Cancel
                    </Button> */}
