import BarChart from "components/Charts/BarChart";
import PieChart from "components/Charts/PieChart";
import ReactApexChart from "react-apexcharts";
import Rating from "react-rating-stars-component";
import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  CardHeader,
  Button,
  Spinner,
} from "reactstrap";
import dashboardBlue from "assets/img/icons/dashboard-blue.svg";
import switchLogo from "assets/img/icons/swtich-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { getStatisticsGraphData } from "store/action/statisticsAction";
import { getNpsMetrics } from "store/action/statisticsAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomDatePicker from "components/Statistics/DatePickerComponet";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { getStatisticsGraphDataIndividualAdmin } from "store/action/statisticsAction";
import { getGoogleMetrics } from "store/action/placesAction";
import { getGoogleGraphData } from "store/action/dashboardAction";
import ProgressChart from "components/Charts/ProgressChart";
import { getAdminById } from "store/action/adminAction";
import { calculateRelativeChange } from "utils/chartUtils";

const AdminStatsSuperAdmin = () => {
  const dispatch = useDispatch();
  const history= useHistory()
  const { user } = useSelector((state) => state.authUser);
  const {googleReviewMetrics,googleReviews,googleReviewsLoading,googleMetricsLoading  } = useSelector((state) => state.places);
  const { googleBarChartData } = useSelector((state) => state.dashboard);
  const [adminRole,setAdminRole]=useState("admin")
  const aggregate = adminRole === "admin-pro" ? true : false;
  if(user.role!=="super-admin")
  history.push("/admin")
  // Extracting values from the URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const adminStatsId = queryParams.get('id') || ""
  const { admin } = useSelector((state) => state.admin);
  const [adminData, setAdminData] = useState({
      name: "",
      email: "",
      lastName: "",
      role: "admin",
      greetings: "formal",
      salutation: "",
      title: "",
      adminNo: "",
      firm: "",
      telephone: "",
      mobile: "",
      streetNo: "",
      zip: "",
      location: "",
      placeId: ""
  });

  const today = new Date();
  const createdAtDate = new Date(user.createdAt);
  const formattedStartDate = `${createdAtDate.getFullYear()}-${(createdAtDate.getMonth() + 1).toString().padStart(2, '0')}-${createdAtDate.getDate().toString().padStart(2, '0')}`;
  const defaultStartDate=queryParams.get('startdate') || new Date(formattedStartDate)
  const defaultEndDate=queryParams.get('enddate') || new Date(today.toISOString().split('T')[0])
  const [startDate, setStartDate] = useState(new Date(defaultStartDate));
  const [endDate, setEndDate] = useState(new Date(defaultEndDate));
  const defaultComparison=queryParams.get('comparison') || ""
  const [comparison, setComparison] = useState(defaultComparison);
  const [comparisonStartDate,setComparisonStartDate]=useState("")
  const [comparisonEndDate,setComparisonEndDate]=useState("")

  const { beratungPieChart, servicePieChart, schadenPieChart,npsMetrics,adminNPSData } = useSelector((state) => state.statistics);


  const [optionsBeratungPieChart, setOptionsBeratungPieChart] = useState({
    colors: ["#8FC73C", "#FFC600", "#EF3256"],
    chart: {
      id: "mychart",
      width: 600,
      height: 400,
      type: "pie",
    },
    toolbar: {
      show: true,
    },
    dataLabels:{
      enabled:false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
          // type: 'none',
        },
      },
    },
      labels: ["Promotoren", "Neutral", "Detraktoren"], // Add labels here
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "45%",
          labels: {
            show: false,
            name: { show: false },
            total: {
              show: true,
              showAlways: true,
              formatter: function (w) {
                const totals = w.globals.seriesTotals;
                console.log(totals, "formatter", w);
                const promoters = totals[0];
                const detractors = totals[1];
                const totalResponses = promoters + detractors + totals[2];
                if (totalResponses) {
                  const nps = ((promoters - detractors) / totalResponses) * 100;
                  return nps.toFixed();
                }
                return 0;
              },
            },
          },
        },
      },
    },
  });
  const [optionsServicePieChart, setOptionsServicePieChart] = useState({
    colors: ["#8FC73C", "#FFC600", "#EF3256"],
    chart: {
      id: "mychart",
      width: 600,
      height: 400,
      type: "pie",
    },
    toolbar: {
      show: true,
    },
    dataLabels:{
      enabled:false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
          // type: 'none',
        },
      },
    },
    labels: ["Promotoren", "Neutral", "Detraktoren"], // Add labels here
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "45%",
          labels: {
            show: false,
            name: { show: false },
            total: {
              show: true,
              showAlways: true,
              formatter: function (w) {
                const totals = w.globals.seriesTotals;
                console.log(totals, "formatter", w);
                const promoters = totals[0];
                const detractors = totals[1];
                const totalResponses = promoters + detractors + totals[2];
                if (totalResponses) {
                  const nps = ((promoters - detractors) / totalResponses) * 100;
                  return nps.toFixed();
                }
                return 0;
              },
            },
          },
        },
      },
    },
  });
  const [optionsSchadenPieChart, setOptionsSchadenPieChart] = useState({
    colors: ["#8FC73C", "#FFC600", "#EF3256"],
    chart: {
      id: "mychart",
      width: 600,
      height: 400,
      type: "pie",
    },
    toolbar: {
      show: true,
    },
    dataLabels:{
      enabled:false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
          // type: 'none',
        },
      },
    },
      labels: ["Promotoren", "Neutral", "Detraktoren",], // Add labels here
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "45%",
          labels: {
            show: false,
            name: { show: false },
            total: {
              show: true,
              showAlways: true,
              formatter: function (w) {
                const totals = w.globals.seriesTotals;
                console.log(totals, "formatter", w);
                const promoters = totals[0];
                const detractors = totals[1];
                const totalResponses = promoters + detractors + totals[2];
                if (totalResponses) {
                  const nps = ((promoters - detractors) / totalResponses) * 100;
                  return nps.toFixed();
                }
                return 0;
              },
            },
          },
        },
      },
    },
  });


  const [beratungSeries, setBeratungSeries] = useState([])
  const [serviceSeries, setServiceSeries] = useState([])
  const [schadenSeries, setSchadenSeries] = useState([])



  const handleStartDateChange = (date) => {
    if(!isNaN(date) &&date)
      setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    if(!isNaN(date)&&date)
      setEndDate(date);
  };

  const toggleAdminRole=()=>{
    if(adminRole==="admin-pro"){
      setAdminRole("admin")
    }
    else if(adminRole==="admin"){
      setAdminRole("admin-pro")
    }
  }

  useEffect(() => {

      dispatch(getStatisticsGraphDataIndividualAdmin({ startDate, endDate,comparison,comparisonStartDate,comparisonEndDate,adminId:adminStatsId,aggregate }));
      dispatch(getNpsMetrics({startDate,endDate,adminId:adminStatsId,aggregate}))
      dispatch(getGoogleMetrics({ startDate, endDate,adminId:adminStatsId,aggregate}))
      dispatch(getGoogleGraphData({startDate, endDate,comparison,comparisonStartDate,comparisonEndDate,adminId:adminStatsId,aggregate}));

  }, [startDate, endDate,comparison,aggregate]);
  useEffect(() => {
 
    if(comparison==="yearly"){
      if (startDate) {
        const startYearAgo = new Date(startDate);
        startYearAgo.setFullYear(startYearAgo.getFullYear() - 1);
        setComparisonStartDate(startYearAgo.toISOString().slice(0, 10)); 
      }
      if (endDate) {
        const endYearAgo = new Date(endDate);
        endYearAgo.setFullYear(endYearAgo.getFullYear() - 1);
        setComparisonEndDate(endYearAgo.toISOString().slice(0, 10));
      }

    }else if(comparison ==="period") {
      if (startDate && endDate) {

        const startDatePeriod = new Date(startDate);
        const endDatePeriod = new Date(endDate);
        const daysDifference = ((endDatePeriod -startDatePeriod) / (1000 * 3600 * 24)).toFixed(0);
        startDatePeriod.setDate(startDatePeriod.getDate() - daysDifference);
        endDatePeriod.setDate(endDatePeriod.getDate() - daysDifference);
        setComparisonStartDate(startDatePeriod.toISOString().slice(0, 10));
        setComparisonEndDate(endDatePeriod.toISOString().slice(0, 10)); 
      }
    }else{
      setComparisonStartDate("");
      setComparisonEndDate("");
    }
  }, [comparison,startDate, endDate]);


  useEffect(() => {
    console.log(`Admin ID: ${adminStatsId}`);
    dispatch(getAdminById(adminStatsId));
}, [adminStatsId]);
  useEffect(() => {
    if (admin && admin.data) {
        setAdminData({
            name: admin.data.name,
            email: admin.data.email,
            lastName: admin.data.lastName,
            role: admin.data.role,
            greetings: admin.data.greetings,
            salutation: admin.data.salutation,
            title: admin.data.title,
            adminNo: admin.data.adminNo,
            firm: admin.data.firm,
            telephone: admin.data.telephone,
            mobile: admin.data.mobile,
            streetNo: admin.data.streetNo,
            zip: admin.data.zip,
            location: admin.data.location,
            placeId: admin.data.placeId
        });
    }
}, [admin])
  function checkIfDummyData(arr) {
    //Just a helper function to check if the data is dummy data or not.
    if (!Array.isArray(arr)) {
      return false;
    }

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        return false;
      }
    }

    return true;
  }
  useEffect(() => {
    if (beratungPieChart.data) {
      setBeratungSeries(beratungPieChart.data);
      if(!checkIfDummyData(beratungPieChart.data))
      setOptionsBeratungPieChart((prevOptions)=>({
        ...prevOptions,
        colors: ["#8FC73C", "#FFC600", "#EF3256"],
        labels: ["Promotoren","Neutral", "Detraktoren", ],
        dataLabels:{
          enabled:true,
        },
      }))
      else
      setOptionsBeratungPieChart((prevOptions) => ({
        ...prevOptions,
        colors: ['#f0f0f0'],
        labels: [" "],
        dataLabels:{
          enabled:false,
        },
      }))
    }
    window.dispatchEvent(new Event('resize')); 
  }, [beratungPieChart]);
  useEffect(() => {
    if (servicePieChart.data) {
      setServiceSeries(servicePieChart.data);

      if(!checkIfDummyData(servicePieChart.data))
      setOptionsServicePieChart((prevOptions)=>({
        ...prevOptions,
        colors: ["#8FC73C", "#FFC600", "#EF3256"],
        labels: ["Promotoren","Neutral", "Detraktoren", ],
        dataLabels:{
          enabled:true,
        },
      }))
      else
      setOptionsServicePieChart((prevOptions) => ({
        ...prevOptions,
        colors: ['#f0f0f0'],
        labels: [" "],
        dataLabels:{
          enabled:false,
        },
      }))
      
    }
  }, [servicePieChart]);
  useEffect(() => {
    if (schadenPieChart.data) {
      setSchadenSeries(schadenPieChart.data);

      if(!checkIfDummyData(schadenPieChart.data))
      setOptionsSchadenPieChart((prevOptions)=>({
        ...prevOptions,
        colors: ["#8FC73C", "#FFC600", "#EF3256"],
        labels: ["Promotoren","Neutral", "Detraktoren", ],
        dataLabels:{
          enabled:true,
        },
      }))
      else
      setOptionsSchadenPieChart((prevOptions) => ({
        ...prevOptions,
        colors: ['#f0f0f0'],
        labels: [" "],
        dataLabels:{
          enabled:false,
        },
      }))
    }
  }, [schadenPieChart]);


  return (
    <>
      <div className="header  pb-8 pt-5 pt-md-8">
        <Container fluid>
          <Row className="header-body">
          {(false) ? (<Container
          fluid
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Spinner color="primary" />
        </Container>) :( <Col
              xl={{
                offset: 2,
                size: 8,
              }}
              lg={{
                offset: 1,
                size: 10,
              }}
            >
              <Row className="d-flex justify-content-between align-items-center">
                  <Col></Col>
                <Col>
                <div className="dashboard-legend d-flex align-items-center ">
                      <div className="dashboard-legend-color__promoter" />
                      <span className="dashboard-legend__text">Promotoren</span>
                      <div className="dashboard-legend-color__neutral" />
                      <span className="dashboard-legend__text">Neutral</span>
                      <div className="dashboard-legend-color__detractor" />
                      <span className="dashboard-legend__text">
                        Detraktoren
                      </span>
                    </div>
                </Col>
                </Row>
              <Row className="d-flex justify-content-between align-items-center mt-2">
                <Col>
                  <div className="dashboard-row">
                    <img src={dashboardBlue} alt="dashboard-blue"></img>
                    <span className="dashboard-row__text">Statistik NPS </span>
                    <span className="dashboard-row__admin">{adminData.name + " " + adminData.lastName} </span>
                    {adminData.role === "admin-pro" ? (
                     <span className="switch-logo-container" onClick={toggleAdminRole}>
                  <img src={switchLogo} alt="switch-logo" width="30px" height="33px" className="ml-1" onClick={toggleAdminRole}></img>
                  <p onClick={toggleAdminRole} className={`switchrole_logo_text_SA_${adminRole ==="admin"? "A" : "AP"}`}>{adminRole ==="admin"? "A" : "AP"}</p>
                  </span>) :""}
                  </div>
                </Col>
                </Row>

              <Row className="mt-4">
                <Col lg={4}>
                  <Label className="dashboard-date-label" for="startDate">
                    Zeitraum von
                  </Label>
                  <CustomDatePicker selectedDate = {startDate} onChange={handleStartDateChange}></CustomDatePicker>
                </Col>
                <Col lg={4}>
                  <Label className="dashboard-date-label" for="endDate">
                    bis
                  </Label>
                  <CustomDatePicker selectedDate = {endDate} onChange={handleEndDateChange}></CustomDatePicker>
                </Col>
                                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="comparison">
                      Vergleichen
                    </Label>
                    <Input
                      className="addAdminSelect"
                      name="comparison"
                      type="select"
                      value={comparison}
                      onChange={(e) => {setComparison(e.target.value)}}
                    >
                      <option value="">Vergleich auswählen</option>
                      <option value="yearly">Vorjahresvergleich</option>
                      <option value="period">
                        Mit vorherigem Zeitraum vergleichen
                      </option>
                    </Input>
                  </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={4}>
                    <Label className="dashboard-date-label" for="startDate">
                    Vergleich&nbsp;Zeitraum&nbsp;von
                    </Label>
                    <Input
                      className="dashboard-date-input"
                      type="date"
                      id="startDate"
                      disabled
                      value={comparisonStartDate}
                      onChange={(e)=>{setComparisonStartDate(e.target.value)}}
                    />
                  </Col>
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="endDate">
                      bis
                    </Label>
                    <Input
                      className="dashboard-date-input"
                      type="date"
                      id="endDate"
                      disabled
                      value={comparisonEndDate}
                      onChange={(e)=>{setComparisonEndDate(e.target.value)}}
                    />
                  </Col>
                </Row>
                <Col className="d-flex align-items-center mt-3">
                    <p className="card-feedback-footer__text-totalNps ml-1">
                    Total NPS : {adminNPSData?.totalNpsScore}
                    </p>{" "}
                  {adminNPSData?.comparisonNpsScore !== undefined && (
                    <p className="card-feedback-footer__text-totalNpsComparison">
                      /{adminNPSData?.comparisonNpsScore}
                    </p>
                  )}

                </Col>
              <Row className="mt-3">
                <Col lg={4} sm={12}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardHeader className="card-stats-header d-flex justify-content-between align-items-center hover-pointer">
                      <p className="card-stats-header__text-ubersicht">
                        Beratung/Verkauf
                      </p>
                    </CardHeader>
                    <CardBody>
                      {beratungPieChart.comparisonNpsScore && (<p className="compare-percentage-text">{calculateRelativeChange(beratungPieChart?.totalNpsScore,beratungPieChart.comparisonNpsScore).toFixed(2)}%</p>)}
                      <div className="d-flex align-items-center justify-content-center" style={{ position: 'relative' }}>
            {/* The div container to hold the pie chart */}
            {!checkIfDummyData(beratungPieChart.data) ? (
              <PieChart
                options1={optionsBeratungPieChart}
                series1={beratungSeries}
                pieEnabled={10}
                formatterValue={beratungPieChart?.totalNpsScore}
              />
            ) : (
              <>
                
                <PieChart
                  options1={optionsBeratungPieChart}
                  series1={[1]}
                  pieEnabled={10}
                  formatterValue={10}
                />
              </>
            )}

            {/* The absolute positioned div to display NPS scores */}
            <div
            className="piechart-text__stats"
            >
              <p className="piechartnps-text">
                {!checkIfDummyData(beratungPieChart.data) && beratungPieChart?.totalNpsScore} {/* Display the total NPS score */}
              </p>
              {beratungPieChart?.comparisonNpsScore !== "" && (
                <p className="piechartnpscomparison-text" >
                  {beratungPieChart?.comparisonNpsScore}{/* Display the comparison NPS score */}
                </p>
              )}
            </div>
          </div>
                      <Row className="justify-content-center"> 
                        {checkIfDummyData(beratungPieChart.data) ||checkIfDummyData(servicePieChart.data) || checkIfDummyData(schadenPieChart.data) ?(
                          <p style={{visibility:!checkIfDummyData(beratungPieChart.data)?"hidden":"",marginBottom:"40px"}} className="dummydata-text mt-1">
                          *Noch keine Daten vorhanden
                         </p>
                        ):""}    
                        </Row>
                      <Row className="align-items-center justify-content-center mt-3">
                        <p className="percentmailtext">Teilnehmerquote {npsMetrics.consulting?.totalSent !== 0 ? ((npsMetrics.consulting?.totalResponded / npsMetrics.consulting?.totalSent) * 100).toFixed(2) : 0.00}%</p>
                      </Row>
                      <Row className="align-items-center justify-content-center ">
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center mr-1"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.consulting?.totalSent ? npsMetrics.consulting?.totalSent : 0 }</p>{" "}
                          <p className="percentmailsubtext">gesendet</p>
                        </div>
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.consulting?.totalOpened ? npsMetrics.consulting?.totalOpened : 0 }</p>{" "}
                          <p className="percentmailsubtext">geöffnet</p>
                        </div>
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center ml-1"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.consulting?.totalResponded ? npsMetrics.consulting?.totalResponded : 0 }</p>{" "}
                          <p className="percentmailsubtext">reagiert</p>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4} sm={12}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardHeader className="card-stats-header d-flex justify-content-between align-items-center hover-pointer">
                      <p className="card-stats-header__text-ubersicht">
                        Service
                      </p>
                    </CardHeader>
                    <CardBody>
                      {servicePieChart.comparisonNpsScore && (<p className="compare-percentage-text">{calculateRelativeChange(servicePieChart?.totalNpsScore,servicePieChart.comparisonNpsScore).toFixed(2)}%</p>)}
                      <div className="d-flex align-items-center justify-content-center" style={{ position: 'relative' }}>
            {/* The div container to hold the pie chart */}
            {!checkIfDummyData(servicePieChart.data) ? (
              <PieChart
                options1={optionsServicePieChart}
                series1={serviceSeries}
                pieEnabled={10}
                formatterValue={servicePieChart?.totalNpsScore}
              />
            ) : (
              <>
                
                <PieChart
                  options1={optionsServicePieChart}
                  series1={[1]}
                  pieEnabled={10}
                  formatterValue={10}
                />
              </>
            )}

            {/* The absolute positioned div to display NPS scores */}
            <div
            className="piechart-text__stats"
            >
              <p className="piechartnps-text">
                {!checkIfDummyData(servicePieChart.data) &&servicePieChart?.totalNpsScore} {/* Display the total NPS score */}
              </p>
              {servicePieChart?.comparisonNpsScore !== "" && (
                <p className="piechartnpscomparison-text" >
                  {servicePieChart?.comparisonNpsScore}{/* Display the comparison NPS score */}
                </p>
              )}
            </div>
          </div>
                      <Row className="justify-content-center"> 
                        {checkIfDummyData(beratungPieChart.data) ||checkIfDummyData(servicePieChart.data) || checkIfDummyData(schadenPieChart.data) ?(
                          <p style={{visibility:!checkIfDummyData(servicePieChart.data)?"hidden":"",marginBottom:"40px"}} className="dummydata-text mt-1">
                          *Noch keine Daten vorhanden
                         </p>
                        ):""}    
                        </Row>
                      <Row className="align-items-center justify-content-center mt-3">
                        <p className="percentmailtext">Teilnehmerquote {npsMetrics.service?.totalSent !== 0 ? ((npsMetrics.service?.totalResponded / npsMetrics.service?.totalSent) * 100).toFixed(2) : 0.00}%</p>
                      </Row>
                      <Row className="align-items-center justify-content-center ">
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center mr-1"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.service?.totalSent ? npsMetrics.service?.totalSent : 0 }</p>{" "}
                          <p className="percentmailsubtext">gesendet</p>
                        </div>
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.service?.totalOpened ? npsMetrics.service?.totalOpened : 0 }</p>{" "}
                          <p className="percentmailsubtext">geöffnet</p>
                        </div>
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center ml-1"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.service?.totalResponded ? npsMetrics.service?.totalResponded : 0 }</p>{" "}
                          <p className="percentmailsubtext">reagiert</p>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4} sm={12}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardHeader className="card-stats-header d-flex justify-content-between align-items-center hover-pointer">
                      <p className="card-stats-header__text-ubersicht">
                        Schaden
                      </p>
                    </CardHeader>
                    <CardBody>
                      {schadenPieChart.comparisonNpsScore && (<p className="compare-percentage-text">{calculateRelativeChange(schadenPieChart?.totalNpsScore,schadenPieChart.comparisonNpsScore).toFixed(2)}%</p>)}
                      <div className="d-flex align-items-center justify-content-center" style={{ position: 'relative' }}>
            {/* The div container to hold the pie chart */}
            {!checkIfDummyData(schadenPieChart.data) ? (
              <PieChart
                options1={optionsSchadenPieChart}
                series1={schadenSeries}
                pieEnabled={10}
                formatterValue={schadenPieChart?.totalNpsScore}
              />
            ) : (
              <>
                <PieChart
                  options1={optionsSchadenPieChart}
                  series1={[1]}
                  pieEnabled={10}
                  formatterValue={10}
                />
              </>
            )}

            {/* The absolute positioned div to display NPS scores */}
            <div
            className="piechart-text__stats"
            >
              <p className="piechartnps-text">
                {!checkIfDummyData(schadenPieChart.data) &&schadenPieChart?.totalNpsScore} {/* Display the total NPS score */}
              </p>
              {schadenPieChart?.comparisonNpsScore !== "" && (
                <p className="piechartnpscomparison-text" >
                  {schadenPieChart?.comparisonNpsScore}{/* Display the comparison NPS score */}
                </p>
              )}
            </div>
          </div>
                      <Row className="justify-content-center"> 
                        {checkIfDummyData(beratungPieChart.data) ||checkIfDummyData(servicePieChart.data) || checkIfDummyData(schadenPieChart.data) ?(
                          <p style={{visibility:!checkIfDummyData(schadenPieChart.data)?"hidden":""}} className="dummydata-text stats mt-1 ">
                          *Noch keine Daten vorhanden
                         </p>
                        ):""}    
                        </Row>
                      <Row className="align-items-center justify-content-center mt-3">
                        <p className="percentmailtext">Teilnehmerquote {npsMetrics.performance?.totalSent !== 0 ? ((npsMetrics.performance?.totalResponded / npsMetrics.performance?.totalSent) * 100).toFixed(2) : 0.00}%</p>
                      </Row>
                      <Row className="align-items-center justify-content-center ">
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center mr-1"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.performance?.totalSent ? npsMetrics.performance?.totalSent : 0 }</p>{" "}
                          <p className="percentmailsubtext">gesendet</p>
                        </div>
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.performance?.totalOpened ? npsMetrics.performance?.totalOpened : 0 }</p>{" "}
                          <p className="percentmailsubtext">geöffnet</p>
                        </div>
                        <div
                          xs={3}
                          className="d-flex flex-column align-items-center justify-content-center ml-1"
                        >
                          {" "}
                          <p className="percentmailnumber">{npsMetrics.performance?.totalResponded ? npsMetrics.performance?.totalResponded : 0 }</p>{" "}
                          <p className="percentmailsubtext">reagiert</p>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="main-rating-container mt-5">
                <Col className="p-0 average-ratings-container d-flex flex-column align-items-center justify-content-center" xs={6} >
                    {/* <Row className="d-flex flex-column m-1"> */}
                  <p className="average-review-rating">{googleReviewMetrics.averageReviewScore}</p>
                  <div>
                {(!googleMetricsLoading) && (
                  <Rating
                    key={`stars`}
                    value={googleReviewMetrics.averageReviewScore}
                    count={5}
                    size={30}
                    edit={false}
                    activeColor="#ffd700"
                    isHalf
                  />
                )}
                 </div>
                  {/* </Row> */}
                </Col>
                <Col className=" p-0 total-review-container d-flex flex-column align-items-center justify-content-center" xs={6}  >
                    <p className="total-reviews-number">{googleReviewMetrics.totalReviewCount}</p>
                     <p className="total-reviews-text">Reviews</p></Col>
              </Row>
              <Row className="mt-4">
                {/* GRAPH FRONTEND */}
                <Col md={12}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <Col>
                          {googleBarChartData?.max > 0 ? (
                            <ProgressChart
                              data={googleBarChartData.data}
                              maxValue={googleBarChartData.max + 10}
                              dataComparision={(googleBarChartData.comparisonRatings && googleBarChartData.comparisonMax) ? googleBarChartData.comparisonRatings :{} }
                            />
                          ) : (
                            <>
                              <p className="text-small text-red">
                              *Noch keine Daten vorhanden 
                              </p>
                              <ProgressChart
                                data={{
                                  1: 0,
                                  2: 0,
                                  3:0,
                                  4:0,
                                  5: 0,
                                }}
                                maxValue={20}
                              />
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>)} 
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AdminStatsSuperAdmin;
