import { Card, CardBody, CardFooter, CardHeader, Row } from "reactstrap";
import emoji910 from "assets/img/icons/emoji9-10.svg";
import emoji06 from "assets/img/icons/emoji6-0.svg";
import emojii78 from "assets/img/icons/emoji 7-8.png";
import { useDispatch } from "react-redux";
import { bookmarkGoogleReview } from "store/action/placesAction";


const GoogleReviewCard = ({rating,authorName,bookmarked,reviewText,date,id}) => {
    const dispatch=useDispatch()
    const footerColor= (rating >= 0 && rating<3) ? "red" : (rating===3 ) ? "yellow" : "green"
    const footerEmoji= (rating >= 0 && rating<3) ? emoji06 : (rating===3) ? emojii78 : emoji910
    function formatDate(dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString("en", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return formattedDate;
    }
    console.log(bookmarked,"BOOKMAKRED");
  return (
    <Card className="card-stats mb-4 mb-xl-0 mt-2">
      <CardHeader className="card-stats-header d-flex justify-content-between align-items-center">
        <p className="card-stats-header__text-customer mr-1 mb-0">{authorName}</p>
        <p className="card-stats-header__text-customer ml-1 mb-0">{formatDate(date)}</p>
      </CardHeader>
      <CardBody>
          <p className="card-review-text">
            {reviewText}
          </p>
      </CardBody>
      <CardFooter className={`card-stats-footer ${footerColor} d-flex justify-content-between align-items-center`}>
        <img height={25} width={25} src={footerEmoji} alt="emoji nps"></img>
        <i onClick={()=>dispatch(bookmarkGoogleReview(id))} className={`fas fa-bookmark text-small bookmark-icon-footer ${bookmarked ? "" :"text-white"}`}></i>
      </CardFooter>
    </Card>
  );
};
export default GoogleReviewCard;
