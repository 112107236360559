export const layoutFormalText = `Wir können die Befragungen auch mit Ihrem Logo versehen. 
So gestalten Sie das Kundenerlebnis noch persönlicher und stärken Ihre Marke.
Sollten Sie Interesse haben, wenden Sie sich gerne an team@johnkent.de.`;

export const layoutInformalText = `Wir können die Befragungen auch mit Deinem Logo versehen.
 So gestaltest Du das Kundenerlebnis noch persönlicher und stärkst Deine Marke.
  Solltest Du Interesse haben, wende Dich  gerne an team@johnkent.de.`;

export const teamErweiternFormalText = `Ihr Team können Sie mit erweiteren Rechten hier im Happinet einbeziehen.
 Bitte wenden Sie sich an uns und wir schalten Sie dann nach vorheriger Beratung gerne frei. 
 Schreibe Sie uns einfach an team@johnkent.de`;

export const teamErweiternInformalText = `Dein Team kannst Du mit erweiterten Rechten hier im Happinet einbeziehen.
Bitte wende Dich an uns und wir schalten Dich dann nach vorheriger Beratung gerne frei.
Schreibe uns einfach an team@johnkent.de`;

export const umfragenFormalText = `Wir können auch andere Befragungen umsetzen.Sollten Sie Interesse an einer individuellen
Befragung haben, wenden Sie sich gerne an team@johnkent.de.  Wir helfen Ihnen gerne weiter.`;

export const umfragenInformalText = `Wir können auch andere Befragungen umsetzen.Solltest Du Interesse an einer individuellen Befragung haben, wende Dich gerne an team@johnkent.de. Wir helfen Dir gerne weiter.`;

export const kundenImportFromalText = `Wir können auch Ihre bestehenden Kundendaten in das System hochladen.
So können Sie  direkt auf  einer größeren Basis Kundenfeedback und Rezensionen einsammeln.
Sollten Sie Interesse  haben, wenden Sie sich gerne an team@johnkent.de an uns.  Wir helfen Ihnen gerne weiter.`;

export const kundenImportInformalText = `Dein Team kannst Du mit erweiterten Rechten hier im Happinet einbeziehen.
Bitte wende Dich an uns und wir schalten Dich dann nach vorheriger Beratung gerne frei.
Schreibe uns einfach an team@johnkent.de`;

export const placeIdRedTextFormal = `*Bitte ändern Sie die Placeid sorgfältig,
 da die Eingabe einer falschen Placeid dazu führen kann, dass falsche Bewertungen abgerufen werden.`;

export const placeIdRedTextInformal = `*Bitte ändere die Place-Id sorgfältig,
da die Eingabe einer falschen Place-Id dazu führen kann, dass falsche Bewertungen abgerufen werden.`;

export const surveyBeratungText = `Beratungserfahrung`;
export const surveyServiceText = `Serviceerfahrung`;
export const surveySchadenText = `Schadenserfahrung`;

export const surveyTextFormalNew = `Wie wahrscheinlich ist es, dass Sie uns aufgrund Ihrer \${surveyAnswerTextCategory || 'Erfahrung'} einem Freund oder Kollegen weiterempfehlen?`;
export const surveyTextInformalNew = `Wie wahrscheinlich ist es, dass Du uns aufgrund Deiner \${surveyAnswerTextCategory || 'Erfahrung'} einem Freund oder Kollegen weiterempfiehlst?`;

export const surveyTextFormal = `\${
  surveyAnswer.customerId?.salutation === "Herr"
    ? "Lieber"
    : surveyAnswer.customerId?.salutation === "Frau"
    ? "Liebe"
    : "Hallo"
} \${surveyAnswer.customerId?.salutation === "Divers"? surveyAnswer.customerId?.name :surveyAnswer.customerId?.salutation} \${surveyAnswer.customerId?.lastName || ''}, wie wahrscheinlich ist es, dass Sie unsere Leistung im Bereich \"\${surveyAnswer.surveyId?.category || ''}\" einem Freund oder Kollegen weiterempfehlen?`;

// export const surveyTextFormal = "Liebe(r) ${surveyAnswer.customerId?.salutation} ${surveyAnswer.customerId?.lastName}, wie wahrscheinlich ist es, dass Sie unsere Leistung im Bereich \"${surveyAnswer.surveyId?.category}\" einem Freund oder Kollegen weiterempfehlen?";
// export const surveyTextInformal = "Liebe(r) ${surveyAnswer.customerId?.name}, wie wahrscheinlich ist es, dass Du unsere Leistung im Bereich \"${surveyAnswer.surveyId?.category}\" einem Freund oder Kollegen weiterempfiehlst?";

export const surveyTextInformal = `\${
  surveyAnswer.customerId?.salutation === "Herr"
    ? "Lieber"
    : surveyAnswer.customerId?.salutation === "Frau"
    ? "Liebe"
    : "Hallo"
} \${surveyAnswer.customerId?.name || ''}, wie wahrscheinlich ist es, dass Du unsere Leistung im Bereich \${surveyAnswer.surveyId?.category} einem Freund oder Kollegen weiterempfiehlst?`;


export const emailReceptionText ="Die E-Mail-Funktion in Happinet ist standardmäßig aktiviert, um sicherzustellen, dass Du regelmäßig wichtige Informationen zu Deinen Statistiken erhältst."

export const surveyCancelFormal="Möchten Sie uns wirklich kein Feedback geben?"
export const surveyCancelInformal="Möchtest Du uns wirklich kein Feedback geben?"

export const surveyBetterFeedbackFormal="Ihr Feedback hilft uns, noch besser zu werden und uns im Sinne unserer Kunden weiterzuentwicklen."
export const surveyBetterFeedbackInformal="Dein Feedback hilft uns, noch besser zu werden und uns im Sinne unserer Kunden weiterzuentwicklen."

export const surveyThankyouFormal="Vielen Dank für Ihr Feedback!"
export const surveyThankyouInformal="Vielen Dank für dein Feedback!"

export const surveyGoogleExtraStepFormal="Würden Sie für uns noch einen weiteren Schritt gehen? Schnell gemacht, wichtig für uns!"
export const surveyGoogleExtraStepInformal="Würdest du für uns noch einen weiteren Schritt gehen? Schnell gemacht, wichtig für uns!"

export const surveyCopyTextFormal="Möchten Sie Ihr Feedback in die Zwischenablage kopieren?"
export const surveyCopyTextInformal="Möchtest Du dein Feedback in die Zwischenablage kopieren"

export const surveyInfoTextFormal="Sie können hier Ihr vorhin gegebenes Feedback kopieren, um dieses im nächsten Schritt direkt als Google-Rezension einzufügen."
export const surveyInfoTextInformal="Du kannst hier dein eben gegebenes Feedback kopieren, um dieses im nächsten Schritt direkt als Google-Rezension einzufügen."

export const surveyQuestionInFormal9_10="Was schätzt Du am meisten an unserem Service?"
export const surveyQuestionFormal9_10="Was schätzen Sie am meisten an unserem Service?"

export const surveyQuestionFormal7_8="Was hat Ihnen gefehlt, um uns eine 9 oder 10 zu geben?"
export const surveyQuestionInFormal7_8="Was hat Dir gefehlt, um uns eine 9 oder 10 zu geben?"

export const surveyQuestionFormal0_6="Wie können wir die Erfahrung verbessern?"
export const surveyQuestionInFormal0_6="Wie können wir die Erfahrung verbessern?"

export const surveyFeedbackThankyouFormal9_10="Vielen Dank für das tolle Feedback."
export const surveyFeedbackThankyouInFormal9_10="Vielen Dank für das tolle Feedback."

export const surveyFeedbackThankyouFormal7_8="Vielen Dank für Ihr Feedback."
export const surveyFeedbackThankyouInFormal7_8="VIELEN DANK FÜR DEIN FEEDBACK"

export const surveyFeedbackThankyouFormal0_6="Vielen Dank für Ihr Feedback, das uns nachdenklich macht."
export const surveyFeedbackThankyouInFormal0_6="VIELEN DANK FÜR DEIN FEEDBACK, DAS UNS NACHDENKLICH MACHT"