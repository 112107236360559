const initialData = {
	customers: [],
	customer: {},
	searchNavbar: "",
	loading: false,
	customerLoading: false,
	tempCustomers:[]
};

const customerReducer = (state = initialData, action) => {
	switch (action.type) {
		case 'CUSTOMER_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'GET_CUSTOMER_LOADING':
			return {
				...state,
				customerLoading: action.payload,
			};
		case 'GET_ALL_CUSTOMER':
			return {
				...state,
				customers: action.payload,
			};
		case 'GET_UPLOAD_CUSTOMERS':
			return {
				...state,
				tempCustomers: action.payload,
			};
		case "GET_CUSTOMER":
			return {
				...state,
				customer: action.payload,
			};
		case 'SEARCH_CUSTOMER':
			return {
				...state,
				searchCustomers: action.payload,
			};
			case 'SEARCH_CUSTOMER_NAVBAR':
				return {
					...state,
					searchNavbar: action.payload,
				};
		default:
			return state;
	}
};

export default customerReducer;
