import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { logout } from "store/action/authAction";
import SurveyForm from "views/SurveyForm";
import PreviewSurveyForm from "views/PreviewSurveyForm";
import TermsConditions from "views/TermsConditions";

function App() {
  let { token } = useSelector((state) => state.authUser);

  return (
    <BrowserRouter>
	 <Switch>
      {token ? (
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      ) : (
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      )}
        <Route path="/survey" render={(props) => <SurveyForm {...props} />}></Route>
        <Route path="/previewsurvey" render={(props) => <PreviewSurveyForm {...props} />}></Route>
        <Route path="/t&c" render={(props) => <TermsConditions {...props} />}></Route>
	  <Redirect to={token ? "/admin/index" : "/auth/login"} />
	  </Switch>
    </BrowserRouter>
  );
}

export default App;
