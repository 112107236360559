import React, { useState } from 'react';

const PreviewCSV = () => {
    var imagePreview = "https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/xlsximageexample.png?alt=media&token=d8cd535d-eddb-4c01-9dc7-cbe47b609a05";
    const [selectedImage, setSelectedImage] = useState(imagePreview);

    return (
        <div>
            {selectedImage && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '50vh',
                    }}
                >
                    <img src={selectedImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
            )}
        </div>
    );
};

export default PreviewCSV;
