import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
// import LoadingOverlay from 'react-loading-overlay';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input, Row,
  Spinner,
  Col,
  CardFooter,
  FormFeedback,
  Badge
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  resetPassword,
  verifyResetPassToken
} from "../../store/action/authAction";
import { useSelector, useDispatch } from "react-redux";
import jaworskiLogo from "../../assets/img/logos/jaworskiLogin.svg";
import LockLoginLogo from "../../assets/img/icons/LockLogin.svg";

const SetNewPassword = () => {
  const [pass, setPass] = useState({ password: "", confirmPassword: "" });
  const [error, setError] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();


  const handleSetNewPassChange = (name, value) => {
    setPass((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  // const isPasswordValid = /^.{8,}$/.test(pass.password);
  const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$!%*?&<>#]{8,}$/.test(pass.password);

  const isConfirmPasswordValid = pass.password === pass.confirmPassword;
  const confirmPasswordErrorMessage = 'Das Passwort stimmt nicht überein.';
  const isLowercaseValid = /^(?=.*[a-z])/.test(pass.password);
  const isUppercaseValid = /^(?=.*[A-Z])/.test(pass.password);
  const isDigitValid = /^(?=.*\d)/.test(pass.password);
  const isSpecialCharValid = /^(?=.*[@$!%*?&#<>])/.test(pass.password);
  const isLengthValid = /^.{8,}$/.test(pass.password);
  const { loading, authLoading } = useSelector((state) => state.auth);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const initial = searchParams.get('initial');
  const token = searchParams.get('token');
  // const token = window.location.search.split("=")[1];
  console.log(initial, token, "INITIAL TOKEN");
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }
  useEffect(() => {
    dispatch(verifyResetPassToken(token, history));
  }, []);
  useEffect(() => {
    const img = new Image();
    img.src = "https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK-Logo.jpg?alt=media&token=adcb3d18-9d24-40d9-a94b-72949ea29136";
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary border-0 auth-card">
          <CardBody className="px-lg-5 py-lg-5">
            <Row className="d-flex align-items-center justify-content-center mb-2">
              {" "}
              {imageLoaded ? (
              <img
                width={300}
                height={200}
                src="https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK-Logo.jpg?alt=media&token=adcb3d18-9d24-40d9-a94b-72949ea29136"
                alt="jaworski"
                className="img-fluid"
              />
            ) : (
              ""
            )}
            </Row>
            <div className="text-center text-muted mb-4">
              <small>Neues Passwort festlegen</small>
            </div>
            <Form
              role="form"
              onSubmit={async (e) => {
                e.preventDefault();
                if (isPasswordValid) {
                  if (pass.password == pass.confirmPassword) {

                    let data = {
                      password: pass.password,
                      initial: initial ? initial : ''
                    };
                    await dispatch(resetPassword(data, token, history));
                  } else {
                    alert("Das Passwort stimmt nicht überein.");
                  }
                }
                else {
                  alert("Passwort ist ungültig.");
                }

              }}
            >

              <FormGroup>

                <div className="auth-input-container">
                  <span className="auth-icon">
                    <img src={LockLoginLogo} alt="lock" className="img-fluid" />
                  </span>

                  <Input
                    className="auth-input"
                    placeholder="Passwort festlegen"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    value={pass.password}
                    name="password"
                    invalid={!isPasswordValid}
                    required
                    onChange={(e) => {
                      handleSetNewPassChange(e.target.name, e.target.value);
                    }}
                  />
                  <div
                    className="auth-password-toggle"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <i className="fas fa-eye" />
                    ) : (
                      <i className="fas fa-eye-slash" />
                    )}
                  </div>
                </div>

                <Row className='ml-2 mt-2'>
                  <h4>
                    <Badge color={isLengthValid ? 'success' : 'danger'}>8-20 zeichen</Badge>
                  </h4>
                  <h4>
                    <Badge color={isUppercaseValid ? 'success' : 'danger'}>1 Großbuchstabe</Badge>
                  </h4>
                  <h4>
                    <Badge color={isLowercaseValid ? 'success' : 'danger'}>1 Kleinbuchstabe</Badge>
                  </h4>
                  <h4>
                    <Badge color={isDigitValid ? 'success' : 'danger'}>1 Ziffer</Badge>
                  </h4>
                  <h4>
                    <Badge color={isSpecialCharValid ? 'success' : 'danger'}>Sonderzeichen</Badge>
                  </h4>
                </Row>

              </FormGroup>

              <FormGroup>
                <div className="auth-input-container">
                  <span className="auth-icon">
                    <img src={LockLoginLogo} alt="lock" className="img-fluid" />
                  </span>
                  <Input
                    className="auth-input"
                    placeholder="Passwort bestätigen"
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="new-password"
                    value={pass.confirmPassword}
                    name="confirmPassword"
                    invalid={!isConfirmPasswordValid}
                    required
                    onChange={(e) => {
                      handleSetNewPassChange(e.target.name, e.target.value);
                    }}
                  />

                  <div
                    className="auth-password-toggle"
                    onClick={toggleShowConfirmPassword}
                  >
                    {showConfirmPassword ? (
                      <i className="fas fa-eye" />
                    ) : (
                      <i className="fas fa-eye-slash" />
                    )}
                  </div>
                </div>
                {!isConfirmPasswordValid && <div style={{ color: 'red', fontSize: '11px' }}>{confirmPasswordErrorMessage}</div>}

              </FormGroup>

              <div className="text-center">
                {/* <Link to='/admin/index'> */}
                <Button className="my-4 login-button" color="primary" type="submit">
                  {loading ? <Spinner size="sm" /> : "Passwort anlegen"}
                </Button>
                {/* </Link> */}
              </div>
            </Form>
          </CardBody>
          <CardFooter className="auth-card-footer text-center">
            Powered by johnkent.de
          </CardFooter>
          {/* </LoadingOverlay> */}
        </Card>
      </Col>
    </>
  );
};

export default SetNewPassword;
