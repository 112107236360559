import { useEffect, useState } from "react";
import { Col, Row, Table } from "reactstrap";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { getInvitations } from "store/action/invitationsAction";

const Invitations = () => {
    const [showPopovers, setShowPopovers] = useState([]);
    const dispatch=useDispatch()
    const {invitations}=useSelector((state) => state.invitation);
    const handlePopoverClose = (index) => {
        // for overlay triggers
        const newShowPopovers = [...showPopovers];
        newShowPopovers[index] = false;
        setShowPopovers(newShowPopovers);
    };
    useEffect(() => {
        dispatch(getInvitations());
    }, []);
    function formatDate(dateStr) {
        const dateObj = new Date(dateStr);
        const day = dateObj.getUTCDate().toString().padStart(2, "0");
        const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
        const year = dateObj.getUTCFullYear().toString();
        const formattedDate = `${day}.${month}.${year}`;
        return formattedDate;
      }
      const getStatusInGerman = (status) => {
        switch (status) {
          case 'pending':
            return 'Ausstehend';
          case 'accepted':
            return 'Angenommen';
          case 'rejected':
            return 'Abgelehnt';
          default:
            return status; // return the original status if not recognized
        }
      };
  return (
    <>
      <Col>
        <Table className="align-items-center table-flush mt-5" responsive>
          <thead className="thead-light">
            <tr>
            <th scope="col">Datum</th>
              <th scope="col">Empfänger</th>
              <th scope="col">Status</th>
              {/* <th scope="col">Details</th> */}
            </tr>
          </thead>
          <tbody>
            {invitations &&
              invitations.map((el, index) => {
                return (
                  <tr>
                    <td>{formatDate(el.createdAt)}</td>
                    <td>{`${el?.recipentId.email}`}</td>
                    <td>{getStatusInGerman(el.status)}</td>
                    {/* <td className="text-center">
                      {el?.role !== "admin-pro" ? (
                        <OverlayTrigger
                          key={index}
                          trigger="click"
                          placement="bottom"
                          rootClose={true}
                          onHide={() => handlePopoverClose(index)}
                          show={showPopovers[index]}
                          overlay={
                            <Popover id={`popover-positioned-bottom-${index}`}>
                              <Popover.Body>
                                <Row
                                  onClick={() => {}}
                                >
                                  Löschen
                                </Row>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <i
                            style={{
                              cursor: "pointer",
                              transform: `rotate(90deg)`,
                            }}
                            className="fas fa-ellipsis-v"
                            onClick={() => {
                              const newShowPopovers = [...showPopovers];
                              newShowPopovers[index] = !showPopovers[index];
                              setShowPopovers(newShowPopovers);
                            }}
                          ></i>
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Col>
    </>
  );
};
export default Invitations;
