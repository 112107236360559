import axios from 'axios';
import linkConfig from 'config/config';

const baseURL = `${linkConfig.serverURL}`;

const getToken = () => {
	let ls = JSON.parse(localStorage.getItem('authUser'));
	if (ls && ls.token != null) {
		return `Bearer ${ls.token}`;
	} else {
		return '';
	}
};

let axiosObj;
axiosObj = axios.create({
	baseURL,
	headers: {
		Authorization: getToken(),
	},
});
// Add an interceptor to handle response errors
axiosObj.interceptors.response.use(

	(response) => {
		console.log("interceptors response",response);
	  return response;
	},
	(error) => {
	  if (error.response && error.response.status === 401) {
		// Token is expired or invalid, handle it here
		console.log("interceptors error",error);
		console.log(window.location.pathname,"window.location.pathname");
		if (window.location.pathname !== '/auth/login') {
			window.location.href = 'auth/login';
			localStorage.clear();
		  }
	
		return Promise.reject();
	  }
	  return Promise.reject(error);
	}
  );
  
  
export default axiosObj;
