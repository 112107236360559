import React, { useCallback, useEffect, useRef, useState } from "react";
import OnlyHeader from "components/Headers/OnlyHeader";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  Input,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Spinner,
  Label,
  FormGroup,
} from "reactstrap";
import { kundenImportFromalText, kundenImportInformalText } from "./DisplayText";
import {
  getCustomers,
  searchByNameCustomer,
} from "store/action/customerAction";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import { addCustomer } from "store/action/customerAction";
import { updateCustomer } from "store/action/customerAction";
import { deleteCustomer } from "store/action/customerAction";
import { getSurveys } from "store/action/surveyAction";
import { sendSurvey } from "store/action/surveyAction";
import { OverlayTrigger, Popover } from "react-bootstrap";
import customersLogo from "../assets/img/icons/costumers-blue.svg";
import questionBlue from "../assets/img/icons/questions-blue.svg";
import LockLoginLogo from "../assets/img/icons/LockLogin.svg";
import { customerCSVFileDownload } from "store/action/customerAction";
import { customerExcelFileUpload } from "store/action/customerAction";
import emojiRed from "../assets/img/icons/emoji6-0.svg";
import emojiYellow from "../assets/img/icons/emoji-7-8-yellow.svg";
import emojiGreen from "../assets/img/icons/emoji9-10.svg";
import emojiGrey from "../assets/img/icons/emoji-grey.svg";
import PreviewCSV from "./PreviewCSV";
import { deleteTempCustomers } from "store/action/customerAction";
import { transferTempCustomers } from "store/action/customerAction";
import debounce from "lodash/debounce";
import { searchCustomerNavbar } from "store/action/customerAction";
import { reactSelectStyles } from "components/selectStyle";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
function Customers() {
  const history = useHistory();
  const { user, role } = useSelector((state) => state.authUser);
  const { customers, tempCustomers, customerLoading, loading } = useSelector(
    (state) => state.customer
  );
  const aggregate = role === "admin-pro" ? true : false;
  const { surveys, surveyLoading } = useSelector((state) => state.survey);
  const {searchNavbar}=useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpendel, setIsOpendel] = useState(false);
  const [isOpenedit, setIsOpenedit] = useState(false);
  const [isOpenSendSurvey, setIsOpenSendSurvey] = useState(false);

  const [search, setSearch] = useState("");
  const [csvModal, setcsvModal] = useState(false);
  const [customerPreviewModal, setcustomerPreviewModal] = useState(false);

  const csvToggle = () => setcsvModal(!csvModal);
  const customerPreviewModalToggle = () =>
    setcustomerPreviewModal(!customerPreviewModal);
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    lastName: "",
    firm: "",
  });
  const [surveyOptions, setSuveyOptions] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [fileName, setFileName] = useState("Datenupload (.csv)");
  const fileRef = useRef(null);

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [id, setId] = useState("");
  const [showPopovers, setShowPopovers] = useState([]);
  const [excelFile, setExcelFile] = useState(null);
  const location = useLocation();
  const { newCustomerId,newCustomerName,newCustomerLastName,newCustomerEmail } = location.state || {};

  const handlePopoverClose = (index) => {
    console.log("handlePopoverClose called");
    // for overlay triggers
    const newShowPopovers = [...showPopovers];
    newShowPopovers[index] = !newShowPopovers[index];
    setShowPopovers(newShowPopovers);
  };

  const debounceSearch = useCallback(
    debounce(async (value) => {
      if (page !== 1) {
        setPage(1);
      }
      dispatch(
        getCustomers({
          limit: limit,
          page: 1,
          role: role,
          aggregate,
          search: value,
        })
      );
    }, 1000),
    []
  );

  const handleCustomerDataChange = (e) => {
    e.preventDefault();
    setCustomerData({ ...customerData, [e.target.name]: e.target.value });
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggledel = () => {
    setIsOpendel(!isOpendel);
  };
  const toggleedit = () => {
    setIsOpenedit(!isOpenedit);
  };
  const toggleSendSurvey = () => {
    setIsOpenSendSurvey(!isOpenSendSurvey);
  };
  const pageClickHandler = (e) => {
    setPage(e.selected + 1);
  };
  const clearSearch = () => {
    setSearch("")
    if (page !== 1) {
      setPage(1);
    }
    dispatch(
      getCustomers({
        limit: limit,
        page: 1,
        role: role,
        aggregate,
      })
    );
  }
  const handleSearch = (e) => {
    setSearch(e.target.value);
    // dispatch(searchCustomerNavbar(e.target.value))
    debounceSearch(e.target.value);
  };
  const clearFields = () => {
    setCustomerData({
      name: "",
      email: "",
      lastName: "",
      firm: "",
    });
  };
  useEffect(() => {
    dispatch(
      getCustomers({ limit: limit, page: page, role: role, aggregate, search: search })
    );
  }, [page, role]);
  useEffect(() => {
    if(searchNavbar){
      
      // setSearch("")
    }
    debounceSearch(searchNavbar);
  }, [searchNavbar]);
  useEffect(() => {
    dispatch(getSurveys({ limit: 1000, page: page }));
  }, []);
  useEffect(() => {
    // for overlay popovers
    if (customers?.data?.length) {
      setShowPopovers(Array(customers.data.length).fill(false));
    }
  }, [customers.data]);
  useEffect(() => {
    if (surveys?.data?.length > 0) {
      const options = surveys.data
        .map((data) => ({
          value: data._id,
          label: data.category,
        }))
        .sort((a, b) => {
          const order = ['beratung', 'service', 'schaden'];
          const indexA = order.indexOf(a.label.toLowerCase());
          const indexB = order.indexOf(b.label.toLowerCase());
          return indexA - indexB;
        });

      // Set the options state
      setSuveyOptions(options);
    }

  }, [surveys]);
  useEffect(() => {
    if(newCustomerId &&surveyOptions.length){
      setCustomerData({
        _id:newCustomerId,
        name:newCustomerName,
        lastName:newCustomerLastName,
        email:newCustomerEmail
      })
      setIsOpenSendSurvey(true);
      history.replace({
        ...location,
        state: undefined
      });
    }
  }, [surveyOptions]);
  const handleClickOutside = (event) => {
    // Check if the click is outside any popover
    if (!event.target.closest('.popover')) {
      setShowPopovers(Array(customers.data?.length).fill(false));
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div>
      <OnlyHeader />
      {/* Modal */}
      <div></div>
      <Container fluid className="mt--7 main-container">
        <Row>
          <div className="col">
            <>
              <CardHeader className="border-0">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Row className="mt-3 align-items-center justify-content-center">
                    <Col
                      md={{
                        // offset: 1,
                        size: 3,
                      }}
                    >
                      <Row className="mt-2 align-items-center justify-content-center">
                        {" "}
                        <img
                          className="mb-2"
                          src={customersLogo}
                          alt="customers"
                        ></img>{" "}
                        <p className="text-search-header ml-2">{`Kunden(${customers.total ? customers?.total : " "
                          })`}</p>
                      </Row>
                    </Col>
                    <Col md="4">
                      <div className="search-input-container">
                        <Input
                          className="search-input"
                          placeholder="Kunden durchsuchen …"
                          type="text"
                          value={search}
                          name="name"
                          required
                          onInput={(e) => {
                            handleSearch(e);
                          }}
                        />
                        {search && (
                          <span
                            className="clear-icon"
                            onClick={clearSearch}
                          >
                            <i class="fas fa-times fa-md"></i>
                          </span>
                        )}
                        <span className="search-icon">
                          <i class="fas fa-search fa-lg"></i>
                        </span>
                      </div>
                    </Col>

                    <Col
                      md="4"
                      sm="4"
                      className="d-flex align-items-end justify-content-center"
                    >
                      {(role !== "admin-pro" && <Button
                        className="mt-3 mt-md-0 add-customer-button"
                        disabled={customerLoading}
                        onClick={() => {
                          history.push("/admin/addcustomer");
                        }}
                      >
                        Kunde anlegen
                      </Button>)}
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <LoadingOverlay
                active={customerLoading}
                spinner
                text="Loading...."
              >
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Kd.-Nr</th>
                      <th scope="col">Name</th>
                      <th scope="col">Firma</th>
                      <th scope="col">Email</th>
                      <th scope="col" className="text-center">Beratung</th>
                      <th scope="col" className="text-center">Service</th>
                      <th scope="col" className="text-center">Schaden</th>
                      <th scope="col" className="text-center">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers &&
                      customers.data &&
                      customers.data
                        .map((el, index) => {
                          return (
                            <tr style={{ height: "50px" }}>
                              <td>{!customerLoading ? (index + 1) + (10 * (page - 1)) : ""}</td>
                              <td>{`${el.lastName}, ${el.name}`}</td>
                              <td>{`${el.firm ?? ''}`}</td>
                              <td>{el.email}</td>

                              {/* Beratung Survey */}
                              <td className="text-center">
                                {el.consultingSurveyResult == null ? (
                                  "" // If consultingSurveyResult is null, return an empty string
                                ) : el.consultingSurveyResult.npsScore >= 9 ? (
                                  <img src={emojiGreen} alt="green emoji" />
                                ) : el.consultingSurveyResult.npsScore >= 7 ? (
                                  <img src={emojiYellow} alt="yellow emoji" />
                                ) : (
                                  <img src={emojiRed} alt="red emoji" />
                                )}
                                { el.consultingSurveySent !==null ? <img className={`${el.consultingSurveyResult !== null ?"ml-1" :""}`} src={emojiGrey} alt="grey emoji" /> : ""}
                              </td>

                              {/* Service survery */}
                              <td className="text-center">
                                {el.serviceSurveyResult == null ? (
                                  "" // If consultingSurveyResult is null, return an empty string
                                ) :  el.serviceSurveyResult.npsScore >= 9 ? (
                                  <img src={emojiGreen} alt="green emoji" />
                                ) : el.serviceSurveyResult.npsScore >= 7 ? (
                                  <img src={emojiYellow} alt="yellow emoji" />
                                ) : (
                                  <img src={emojiRed} alt="red emoji" />
                                )}
                                { el.serviceSurveySent !==null ? <img className={`${el.serviceSurveyResult !== null ?"ml-1" :""}`} src={emojiGrey} alt="grey emoji" /> : ""}
                              </td>



                              {/* Shaden Survey */}
                              <td className="text-center">
                                {el.performanceSurveyResult == null ? (
                                  "" // If consultingSurveyResult is null, return an empty string
                                ) :  el.performanceSurveyResult.npsScore >= 9 ? (
                                  <img src={emojiGreen} alt="green emoji" />
                                ) : el.performanceSurveyResult.npsScore >= 7 ? (
                                  <img src={emojiYellow} alt="yellow emoji" />
                                ) : (
                                  <img src={emojiRed} alt="red emoji" />
                                )}
                                 { el.performanceSurveySent !==null ? <img className={`${el.performanceSurveyResult !== null ?"ml-1" :""}`} src={emojiGrey} alt="grey emoji" /> : ""}
                              </td>

                              {/* <td>
                              <UncontrolledTooltip target="send">
                                  Send Survey
                                </UncontrolledTooltip>
                                <Button
                                  id="send"
                                  size="sm"
                                  color="primary"
                                  onClick={() => {
                                    toggleSendSurvey();
									                  setId(el._id)
                                    setCustomerData({
                                      ...el
                                    })
                                  }}
                                >
                                  <i class="fas fa-paper-plane"></i>
                                </Button>
                                <UncontrolledTooltip target="edit">
                                  Edit
                                </UncontrolledTooltip>
                                <Button
                                  id="edit"
                                  size="sm"
                                  color="info"
                                  onClick={() => {
                                    toggleedit();
									                  setId(el._id)
                                    setCustomerData({
                                      ...el
                                    })
                                  }}
                                >
                                  <i className="fas fa-pen"></i>
                                </Button>
                                <UncontrolledTooltip target="delete">
                                  Delete
                                </UncontrolledTooltip>
                                <Button
                                  id="delete"
                                  size="sm"
                                  className="ml-2"
                                  color="danger"
                                  onClick={() =>
                                    dispatch(
                                      deleteCustomer(el._id, () => {
                                        dispatch(
                                          getCustomers({ limit: 10, page: page })
                                        );
                                      })
                                    )
                                  }
                                >
                                  <i className="fas fa-trash"></i>
                                </Button>
                              </td> */}
                              <td className="text-center">
                                <OverlayTrigger
                                  key={index}
                                  // trigger="click"
                                  placement="bottom"
                                  rootClose={true}
                                  rootCloseEvent="click"
                                  trigger="focus"
                                  onHide={() => handlePopoverClose(index)}
                                  show={showPopovers[index]}
                                  overlay={

                                    <Popover
                                      id={`popover-positioned-bottom-${index}`}
                                    >

                                      <Popover.Body>
                                        {(role === "admin") && <Row
                                          onClick={() => {
                                            toggleSendSurvey();
                                            setId(el._id);
                                            setCustomerData({
                                              ...el,
                                            });
                                            handlePopoverClose(index);
                                          }}
                                        >
                                          Umfrage senden
                                        </Row>}
                                        <Row onClick={() => {
                                          history.push(
                                            `/admin/view-customer/${el._id}`
                                          );
                                        }}>Anzeigen</Row>
                                        {(user._id === el.adminId && role === "admin") ? <><Row
                                          onClick={() => {
                                            if (user._id === el.adminId) {
                                              history.push(
                                                `/admin/editcustomer/${el._id}`
                                              );
                                            }
                                          }}
                                        >
                                          Bearbeiten
                                        </Row></> : <></>}
                                     
                                        {user._id === el.adminId && role === "admin" ? <><Row
                                          onClick={() => {
                                            const confirmed = window.confirm(
                                              "Die Kundeninformationen werden gelöscht, aber die Bewertungsdaten werden aufbewahrt. Sind Sie sicher?"
                                            );
                                            if (confirmed) {
                                              dispatch(
                                                deleteCustomer(el._id, () => {
                                                  setPage(1)
                                                  dispatch(
                                                    getCustomers({
                                                      limit: 10,
                                                      page: page,
                                                      aggregate
                                                    })
                                                  );
                                                })
                                              );
                                            }
                                            handlePopoverClose(index);
                                          }}
                                        >
                                          Löschen
                                        </Row></> : <></>}

                                      </Popover.Body>
                                    </Popover>

                                  }
                                >

                                  <i

                                    style={{
                                      cursor: "pointer",
                                      transform: `rotate(90deg)`,
                                    }}
                                    className="fas fa-ellipsis-v"
                                    onClick={(e) => {
                                      e.stopPropagation(); 
                                      e.preventDefault(); 
                                      e.nativeEvent.stopImmediatePropagation();
                                      const newShowPopovers = [...showPopovers];
                                      newShowPopovers[index] =
                                        !showPopovers[index];
                                      setShowPopovers(newShowPopovers);
                                    }}
                                  ></i>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          );
                        })}
                  </tbody>
                </Table>
              </LoadingOverlay>
              <CardFooter className="py-4">
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left" />}
                  nextLabel={<i className="fas fa-angle-right" />}
                  breakLabel={"..."}
                  pageCount={customers && customers.total / limit}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={pageClickHandler}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link custom-pageLink"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link custom-pageArrow"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link custom-pageArrow"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active custom-active"}
                />
              </CardFooter>
              <Row>
                {/* <Col md={2}></Col> */}
                <Col>
                  <p className="text-search-header ml-2">
                    Kundendaten Import/Export
                  </p>
                </Col>
                <Col></Col>
              </Row>
              <Row>
                {/* <Col md={2}></Col> */}
                <Col className="d-flex aling-item-center justify-content-center ml-2">
                  {user.greetings === "formal" ? <>{kundenImportFromalText}</> : <>{kundenImportInformalText}</>}
                </Col>
                {/* <Col md={2}></Col>
                <Col>
                  <p className="uploadtype-label">{fileName}</p>
                  <Button
                    className="csv-file-button m-2"
                    onClick={() => {
                      fileRef.current.click();
                    }}
                  >
                    Datei auswählen (csv.)
                    <div
                      className="auth-password-toggle"
                    // onClick={toggleShowPassword}
                    >
                      <i className="fas fa-upload" />
                    </div>
                  </Button>
                  <input
                    type="file"
                    ref={fileRef}
                    name="file"
                    accept=".xlsx, .xls, .csv"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <Button className="upload-button m-2" onClick={handleSubmit}>
                    Daten hochladen
                  </Button>

                  <Button className="format-button m-2" onClick={csvToggle}>
                    <div
                      className="button-left-icon"
                    >
                      <i className="fas fa-info-circle" />
                    </div>
                    <p className="format-button-text">Infos / Vorschau</p>
                  </Button>
                  <Button
                    onClick={async () => {
                      const csvFile = await dispatch(customerCSVFileDownload());
                      console.log(csvFile);
                      const url = URL.createObjectURL(csvFile);
                      // Create an anchor tag with the URL and trigger the download
                      const a = document.createElement("a");
                      a.href = url;
                      a.download = "customers.csv";
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }}
                    className="download-button m-2"
                  >
                    daten runterladen
                  </Button>
                </Col> */}
              </Row>
            </>
          </div>
        </Row>
      </Container>

      <div className="ml-5">
        {/* Add customer */}
        <Modal isOpen={isOpen} toggle={toggle}>
          <Form
            role="form"
            onSubmit={async (e) => {
              e.preventDefault();
              console.log(customerData, "CustomerData");
              await dispatch(
                addCustomer(customerData, () => {
                  dispatch(
                    getCustomers({
                      limit: 10,
                      page: page,
                      aggregate,
                    })
                  );
                  clearFields();
                  toggle();
                  history.push("/admin/customers");
                })
              );
            }}
          >
            <ModalHeader
              toggle={() => {
                toggle();
                clearFields();
              }}
            >
              <h2>Add Customer</h2>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Email:</label>
                  </Row>

                  <Input
                    placeholder="Email"
                    name="email"
                    required
                    type="text"
                    value={customerData.email}
                    onChange={(e) => {
                      handleCustomerDataChange(e);
                    }}
                  ></Input>
                </Col>

                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Name:</label>
                  </Row>

                  <Input
                    placeholder="Name"
                    required
                    type="text"
                    name="name"
                    value={customerData.name}
                    onChange={(e) => {
                      handleCustomerDataChange(e);
                    }}
                  ></Input>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                disabled={loading}
                onClick={() => {
                  toggle();
                  clearFields();
                }}
              >
                Schließen
              </Button>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? <Spinner size="sm" /> : "Add"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        {/* Edit modal */}
        <Modal isOpen={isOpenedit} toggle={toggleedit}>
          <Form
            role="form"
            onSubmit={async (e) => {
              e.preventDefault();
              const data = {
                email: customerData.email,
                name: customerData.name,
              };
              await dispatch(
                updateCustomer(customerData, id, () => {
                  dispatch(
                    getCustomers({
                      limit: 10,
                      page: page,
                      aggregate,
                    })
                  );
                  toggleedit();
                  clearFields();
                })
              );
            }}
          >
            <ModalHeader
              toggle={() => {
                toggleedit();
                clearFields();
              }}
            >
              <h2>Update Customer</h2>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Email:</label>
                  </Row>

                  <Input
                    placeholder="Email"
                    name="email"
                    required
                    type="text"
                    value={customerData.email}
                    onChange={(e) => {
                      handleCustomerDataChange(e);
                    }}
                  ></Input>
                </Col>
                <Col md="6" className="my-2">
                  <Row className="ml-1">
                    <label>Name:</label>
                  </Row>

                  <Input
                    placeholder="Name"
                    required
                    type="text"
                    name="name"
                    value={customerData.name}
                    onChange={(e) => {
                      handleCustomerDataChange(e);
                    }}
                  ></Input>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                disabled={loading}
                onClick={() => {
                  toggleedit();
                  clearFields();
                }}
              >
                Schließen
              </Button>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? <Spinner size="sm" /> : "Update"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        {/* Delete modal */}
        <Modal isOpen={isOpendel} toggle={toggledel}>
          <ModalHeader>
            <ModalBody>
              <h2>Are you sure you want to delete?</h2>
            </ModalBody>
          </ModalHeader>
          <ModalFooter>
            <Row className="text-right">
              <Button color="secondary" onClick={toggledel}>
                No
              </Button>

              <Button
                color="primary"
                onClick={async () => {
                  toggledel();
                }}
              >
                Yes
              </Button>
            </Row>
          </ModalFooter>
        </Modal>
        {/* Send Survey modal */}
        <Modal
          size="lg"
          centered
          isOpen={isOpenSendSurvey}
          toggle={toggleSendSurvey}
        >
          <Form
            role="form"
            onSubmit={async (e) => {
              e.preventDefault();

              const tempObj = {
                surveyId: selectedSurvey.value,
                customerId: customerData._id,
              };
              await dispatch(
                sendSurvey(tempObj, () => {
                  toggleSendSurvey();
                  clearFields();
                  dispatch(
                    getCustomers({ limit: limit, page: page, role: role, aggregate, search })
                  );
                })
              );
            }}
          >
            <ModalHeader
            // toggle={() => {
            //   toggleSendSurvey();
            //   clearFields();
            // }}
            >
              <div className="sendSurveyHeader">
                <img src={questionBlue}></img>
                <p className="sendSurveyHeading">Neue Umfrage versenden</p>
              </div>
              {/* <h2>Send Survey</h2> */}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md="12" className="my-2">
                  <Row className="mb-2">
                    <Col md={12}>
                      <p className="ss-fieldLabel">Kunde</p>
                      <FormGroup className="rounded">
                        <div className="auth-input-container">
                          <span className="auth-icon">
                            {/* <i className="ni ni-email-83" /> */}
                            <img
                              src={LockLoginLogo}
                              alt="user"
                              className="img-fluid"
                            />
                          </span>
                          <Input
                            className="auth-input"
                            placeholder=""
                            type="text"
                            autoComplete="new-email"
                            value={customerData.name + " " + customerData.lastName}
                            name="customeName"
                            required
                            onChange={(e) => {
                              // handleLoginChange(e.target.name, e.target.value);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <p className="ss-fieldLabel">E-Mail</p>
                      <FormGroup className="rounded">
                        <div className="auth-input-container">
                          <span className="auth-icon">
                            {/* <i className="ni ni-email-83" /> */}
                            <img
                              src={LockLoginLogo}
                              alt="user"
                              className="img-fluid"
                            />
                          </span>
                          <Input
                            className="auth-input"
                            placeholder=""
                            type="text"
                            autoComplete=""
                            value={customerData.email}
                            name="customerEmail"
                            required
                            onChange={(e) => {
                              // handleLoginChange(e.target.name, e.target.value);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="ml-1">
                    <label className="ss-fieldLabel">Kundenanliegen *</label>
                  </Row>

                  <Select
                    name="survey-select"
                    required
                    value={selectedSurvey}
                    options={surveyOptions}
                    onChange={setSelectedSurvey}
                    className="ss-selectField"
                    placeholder="Umfrage auswählen"
                    styles={reactSelectStyles}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="justify-content-between">
              <Button
                className="ss-closeButton"
                color="secondary"
                disabled={surveyLoading}
                onClick={() => {
                  toggleSendSurvey();
                  clearFields();
                }}
              >
                Schließen
              </Button>
              <Button
                className="ss-sendButton"
                color="primary"
                type="submit"
                disabled={surveyLoading}
              >
                {surveyLoading ? <Spinner size="sm" /> : "Senden"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal size="lg" isOpen={csvModal} toggle={csvToggle}>
          <ModalHeader toggle={csvToggle}>EXCEL Vorschau</ModalHeader>
          <ModalBody>
            <p style={{ color: 'red' }} >ACHTUNG: Damit die daten korrekt hochgeladen werden können, muss der exakte
              spaltenname wie hier gezeigt in der gleichen reihenfolge verwendet werden.</p>
            <PreviewCSV />
          </ModalBody>
        </Modal>

        <Modal
          size="lg"
          isOpen={customerPreviewModal}
          toggle={customerPreviewModalToggle}
        >
          <ModalHeader toggle={customerPreviewModalToggle}>
            Kunden Upload
          </ModalHeader>
          <ModalBody>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Kd.-Nr</th>
                  <th scope="col">Anrede</th>
                  <th scope="col">Titel</th>
                  <th scope="col">Name</th>
                  <th scope="col">Firma</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                {tempCustomers.map((el, index) => (
                  <tr>
                    <td>{el.customerNo}</td>
                    <td>{el.salutation}</td>
                    <td>{el.title}</td>
                    <td>{`${el.lastName}, ${el.name}`}</td>
                    <td>{el.firm}</td>
                    <td>{el.email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={async () => {
              await dispatch(
                transferTempCustomers(user._id));
              customerPreviewModalToggle();
              await dispatch(getCustomers({ limit: limit, page: page, aggregate }));

            }}>Confirm</Button>
            <Button color="secondary" onClick={async () => {
              await dispatch(
                deleteTempCustomers(user._id));
              customerPreviewModalToggle()
            }}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Customers;
