/* eslint-disable import/no-anonymous-default-export */
import Repository from './Repository';
const SURVEY = '/survey';
const SENDSURVEY='sendsurvey'
const SENDTESTSURVEY='sendtestsurvey'

export default {
	addSurvey(payload) {	
		return Repository.post(`${SURVEY}`, payload);
	},

	getAllSurvey(payload) {
		return Repository.get(
			`${SURVEY}?limit=${payload.limit}&page=${payload.pageNo}`
		);
	},

	deleteSurvey(payload) {
		return Repository.delete(`${SURVEY}/${payload}`, {});
	},
	updateSurvey(payload, id) {
		return Repository.put(`${SURVEY}/${id}`, payload);
	},
	sendSurvey(payload){
		return Repository.post(`${SURVEY}/${SENDSURVEY}`, payload);
	},
	sendTestSurvey(payload,id){
		return Repository.post(`${SURVEY}/${SENDTESTSURVEY}/${id}`, payload);
	},
	searchByName(payload) {
		return Repository.get(`${SURVEY}/search/${payload}`);
	},
	surveyFilter(payload) {
		return Repository.post(`${SURVEY}`, payload);
	},

};
