// GenericCard.js
import PieChart from 'components/Charts/PieChart';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Col, Card, CardHeader, CardBody, Row } from 'reactstrap';
import { calculateRelativeChange } from 'utils/chartUtils';
import { checkIfDummyData } from 'utils/chartUtils';


const ReviewSocialCard = ({ cardData, series, cardHeaderText, options1,adminReviews,startDate,endDate,comparison }) => {
  const history=useHistory()
  return (
    <Col lg={4} md={12}>
      <Card className="card-stats mb-4 mb-xl-0">
        <CardHeader className="card-stats-header d-flex justify-content-between align-items-center">
          <p style={{cursor:adminReviews?"pointer":""}} onClick={()=>{if(adminReviews) history.push(`/admin/adminstatistics?id=${adminReviews}&startdate=${startDate}&enddate=${endDate}&comparison=${comparison}`)}} className="card-stats-header__text-ubersicht">{cardHeaderText}</p>
          <span className="d-flex">
            <p className="card-feedback-footer__text-totalNps">{cardData?.averageRating?.toFixed(2)}</p>
            {cardData?.averageRatingComparison !== undefined && (
              <p className="card-feedback-footer__text-totalNpsComparison">
                /{cardData?.averageRatingComparison?.toFixed(2)}
              </p>
            )}
          </span>
        </CardHeader>
        <CardBody>
          {checkIfDummyData(cardData?.data) && (
            <Row>
              <p className="dummydata-text justify-content-center">*Noch keine Daten vorhanden</p>
            </Row>
          )}
          {cardData?.comparisonCount !== undefined && (
            <p className="compare-percentage-text">
              {calculateRelativeChange(cardData?.reviewCount, cardData?.comparisonCount).toFixed(2)}%
            </p>
          )}
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ position: 'relative' }}
          >
            {/* The div container to hold the pie chart */}
            {!checkIfDummyData(cardData.data) ? (
              <PieChart options1={options1} series1={series} pieEnabled={10} />
            ) : (
              <>
                <PieChart options1={options1} series1={[1]} pieEnabled={10} formatterValue={10} />
              </>
            )}

            {/* The absolute positioned div to display NPS scores */}
            <div className="piechart-text">
              <p className="piechartnps-text">
                {!checkIfDummyData(cardData.data) && cardData?.reviewCount}
                {/* Display the total NPS score */}
              </p>
              {(!checkIfDummyData(cardData.data) && cardData?.comparisonCount !== '') && (
                <p className="piechartnpscomparison-text">{cardData?.comparisonCount}</p>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ReviewSocialCard;
