import BarChart from "components/Charts/BarChart";
import PieChart from "components/Charts/PieChart";
import ReactApexChart from "react-apexcharts";
import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  CardHeader,
  Button,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  ModalFooter,
  FormGroup,
  ModalHeader,
  Form,
  Modal,
  ModalBody,
} from "reactstrap";
import dashboardBlue from "assets/img/icons/dashboard-blue.svg";
import LockLoginLogo from "../assets/img/icons/LockLogin.svg";
import { useDispatch, useSelector } from "react-redux";
import { getGraphData } from "store/action/dashboardAction";
import { getGoogleGraphData } from "store/action/dashboardAction";
import { getStatisticsGraphData } from "store/action/statisticsAction";
import NumbersFilter from "components/Statistics/NumbersFilter";
import ReviewCard from "components/Statistics/ReviewCard";
import { getNpsReviews } from "store/action/statisticsAction";
import { getNpsMetrics } from "store/action/statisticsAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Notes from "components/Statistics/Notes";
import CustomDatePicker from "components/Statistics/DatePickerComponet";
import { calculateRelativeChange } from "utils/chartUtils";

const ConsultingStatistics = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, role } = useSelector((state) => state.authUser);
  if (user.role === "super-admin") history.push("/admin");
  const { stackedBarChartData, pieChartData } = useSelector(
    (state) => state.dashboard
  );

  const [activeTab, setActiveTab] = useState(1); //nav tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const {
    // schadenPieChart,
    // servicePieChart,
    schadenPieChart,
    npsMetrics,
    npsReviews,
    npsReviewsTotals,
    npsLoading,
    schadenStackedBarGraph,
  } = useSelector((state) => state.statistics);
  const [comparison, setComparison] = useState("");
  const [comparisonStartDate, setComparisonStartDate] = useState("");
  const [comparisonEndDate, setComparisonEndDate] = useState("");
  const [isOpenNotes, setIsOpenNotes] = useState(false);

  
  const [options1, setOptions1] = useState({
    colors: ["#9EB37C", "#DFDF8D", "#C67684"],
    chart: {
      id: "mychart",
      width: 600,
      height: 400,
      type: "pie",
    },
    toolbar: {
      show: true,
    },
    dataLabels:{
      enabled:false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
          // type: 'none',
        },
      },
    },
    labels: ["Promotoren", "Detraktoren", "Neutral"], // Add labels here
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 990,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "45%",
          labels: {
            show: false,
            name: { show: false },
            total: {
              show: true,
              showAlways: true,
              formatter: function (w) {
                const totals = w.globals.seriesTotals;
                console.log(totals, "formatter", w);
                const promoters = totals[0];
                const detractors = totals[1];
                const totalResponses = promoters + detractors + totals[2];
                if (totalResponses) {
                  const nps = ((promoters - detractors) / totalResponses) * 100;
                  return nps.toFixed();
                }
                return 0;
              },
            },
          },
        },
      },
    },
  });

  const series2Initial = [
    {
      name: "Promotoren",
      data: [],
      type: "column",
      color: "#9EB37C",
      max: 100,
    },
    {
      name: "Neutral",
      data: [],
      type: "column",
      color: "#DFDF8D",
      max: 100,
    },
    {
      name: "Detraktoren",
      data: [],
      type: "column",
      color: "#C67684",
      max: 100,
    },

    {
      name: "NPS 1",
      data: [],
      type: "line",
      color: "#1b4079",
      max: 100,
    },
    {
      name: "NPS 2",
      data: [],
      type: "line",
      color: "#d9d9d9",
      max: 100,
    },
  ];
  const categories = [];
  const colors = series2Initial.map((item) => item.color);
  const [options2Initial, setOptions2Initial] = useState({
    chart: {
      stacked: false,
      stackType: "normal",
      type: "bar",
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
        tools: {
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          selection: true,
          reset: true,
          download: true,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
    },
    stroke: {
      width: [0, 0, 0, 4, 4],
    },

    grid: {
      borderColor: "#111",
      strokeDashArray: 7,
    },
    markers: {
      size: 4,
    },

    xaxis: {
      categories: categories,
    },

    fill: {
      opacity: 1,
      colors: colors,
    },
    colors: colors,
    legend: {
      position: "top",
      offsetX: 0,
      offsetY: 0,
    },

    xAxisScale: {
      niceMin: 0,
    },

    yaxis: [
      {
        seriesName: "Promotoren",
        min: 0,
        max: 10,
        title: {
          text: "NPS SCORE",
        },
      },
      {
        seriesName: "Detraktoren",
        show: false,
        min: 0,
        max: 10,
      },
      {
        seriesName: "Neutral",
        show: false,
        min: 0,
        max: 10,
      },

      {
        seriesName: "NPS 1",
        opposite: true,
        min: -100,
        max: 100,
        title: {
          // text: "Line series",
        },
      },
      {
        seriesName: "NPS 2",
        show: false,
        // title: {
        //   text: "Line series 2"
        // },
        // opposite: true,
        min: -100,
        max: 100,
      },
    ],
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 981,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 350,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const [schadenSeries, setSchadenSeries] = useState([0, 10, 10]);
  const [schadenStackedOptions, setSchadenStackedOptions] = useState({
    chart: {
      stacked: false,
      stackType: "normal",
      type: "bar",
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
        tools: {
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          selection: true,
          reset: true,
          download: true,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
    },
    stroke: {
      width: [0, 0, 0, 4, 4],
    },

    grid: {
      borderColor: "#111",
      strokeDashArray: 7,
    },
    markers: {
      size: 4,
    },

    xaxis: {
      categories: categories,
    },

    fill: {
      opacity: 1,
      colors: colors,
    },
    colors: colors,
    legend: {
      position: "top",
      offsetX: 0,
      offsetY: 0,
    },

    xAxisScale: {
      niceMin: 0,
    },

    yaxis: [
      {
        seriesName: "Promotoren",
        min: 0,
        max: 10,
        title: {
          text: "NPS SCORE",
        },
      },
      {
        seriesName: "Detraktoren",
        show: false,
        min: 0,
        max: 10,
      },
      {
        seriesName: "Neutral",
        show: false,
        min: 0,
        max: 10,
      },

      {
        seriesName: "NPS 1",
        opposite: true,
        min: -100,
        max: 100,
        title: {
          // text: "Line series",
        },
      },
      {
        seriesName: "NPS 2",
        show: false,
        // title: {
        //   text: "Line series 2"
        // },
        // opposite: true,
        min: -100,
        max: 100,
      },
    ],
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 981,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 350,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });


  const [schadenStackedSeries, setSchadenStackedSeries] = useState([
    {
      name: "Promotors",
      data: [],
    },
    {
      name: "Detractors",
      data: [],
    },
    {
      name: "Passives",
      data: [],
    },
  ]);


  const today = new Date();
  const createdAtDate = new Date(user.createdAt);
  const formattedStartDate = `${createdAtDate.getFullYear()}-${(createdAtDate.getMonth() + 1).toString().padStart(2, '0')}-${createdAtDate.getDate().toString().padStart(2, '0')}`;
  
  const [startDate, setStartDate] = useState(new Date(formattedStartDate));
  const [endDate, setEndDate] = useState(new Date(today.toISOString().split('T')[0]));
  const [serviceReviewsPage, setServiceReviewsPage] = useState(0);
  const [consultingReviewsPage, setConsultingReviewsPage] = useState(0);
  const [performanceReviewsPage, setPerformanceReviewPage] = useState(0);

  const handleStartDateChange = (date) => {
    if(!isNaN(date)&&date)
      setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    if(!isNaN(date)&&date)
      setEndDate(date);
  };
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [isBookMarkedSelected, setIsBookMarkedSelected] = useState(false);
  const handleBoxClick = (number) => {
    let updatedSelection;
    if (selectedNumbers.includes(number)) {
      updatedSelection = selectedNumbers.filter((n) => n !== number);
    } else {
      updatedSelection = [...selectedNumbers, number];
    }
    setSelectedNumbers(updatedSelection);
  };

  const aggregate = role === "admin-pro" ? true : false;
  // useEffect(() => {
  //   dispatch(getNpsMetrics({startDate,endDate,aggregate}))
  // },[startDate,endDate])
  const isInitialRenderPage = useRef(true);

  useEffect(() => {
    if (isInitialRenderPage.current) {
      isInitialRenderPage.current = false;
      return;
    }
    if (!performanceReviewsPage)
      dispatch(
        getNpsReviews({
          category: "performance",
          limit: 3,
          skip: performanceReviewsPage * 3,
          npsFilters: selectedNumbers.join(","),
          isBookmarked: isBookMarkedSelected,
          startDate,
          endDate,
          aggregate,
        })
      );
    if (!serviceReviewsPage)
      dispatch(
        getNpsReviews({
          category: "service",
          limit: 3,
          skip: serviceReviewsPage * 3,
          npsFilters: selectedNumbers.join(","),
          isBookmarked: isBookMarkedSelected,
          startDate,
          endDate,
          aggregate,
        })
      );
    if (!consultingReviewsPage)
      dispatch(
        getNpsReviews({
          category: "consulting",
          limit: 3,
          skip: consultingReviewsPage * 3,
          npsFilters: selectedNumbers.join(","),
          isBookmarked: isBookMarkedSelected,
          startDate,
          endDate,
          aggregate,
        })
      );
    setConsultingReviewsPage(0);
    setServiceReviewsPage(0);
    setPerformanceReviewPage(0);
  }, [selectedNumbers, isBookMarkedSelected]);

  useEffect(() => {
    // if (performanceReviewsPage || selectedNumbers.length > 0)
    dispatch(
      getNpsReviews({
        category: "performance",
        limit: 3,
        skip: performanceReviewsPage * 3,
        npsFilters: selectedNumbers.join(","),
        isBookmarked: isBookMarkedSelected,
        startDate,
        endDate,
        aggregate,
      })
    );
  }, [performanceReviewsPage]);

  useEffect(() => {
    // if (serviceReviewsPage || selectedNumbers.length > 0)
    dispatch(
      getNpsReviews({
        category: "service",
        limit: 3,
        skip: serviceReviewsPage * 3,
        npsFilters: selectedNumbers.join(","),
        isBookmarked: isBookMarkedSelected,
        startDate,
        endDate,
        aggregate,
      })
    );
  }, [serviceReviewsPage]);

  useEffect(() => {
    // if (consultingReviewsPage || selectedNumbers.length > 0)
    dispatch(
      getNpsReviews({
        category: "consulting",
        limit: 3,
        skip: consultingReviewsPage * 3,
        npsFilters: selectedNumbers.join(","),
        isBookmarked: isBookMarkedSelected,
        startDate,
        endDate,
        aggregate,
      })
    );
  }, [consultingReviewsPage]);

  const isInitialRender = useRef(true);

  useEffect(() => {
    dispatch(
      getGraphData({
        startDate,
        endDate,
        aggregate,
        comparison,
        comparisonStartDate,
        comparisonEndDate,
      })
    );
    dispatch(
      getGoogleGraphData({
        startDate,
        endDate,
        aggregate,
        comparison,
        comparisonStartDate,
        comparisonEndDate,
      })
    );
    dispatch(
      getStatisticsGraphData({
        startDate,
        endDate,
        aggregate,
        comparison,
        comparisonStartDate,
        comparisonEndDate,
      })
    );
    dispatch(getNpsMetrics({ startDate, endDate, aggregate }));
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (!performanceReviewsPage)
      dispatch(
        getNpsReviews({
          category: "performance",
          limit: 3,
          skip: performanceReviewsPage * 3,
          npsFilters: selectedNumbers.join(","),
          isBookmarked: isBookMarkedSelected,
          startDate,
          endDate,
          aggregate,
        })
      );

    setPerformanceReviewPage(0);
  }, [startDate, endDate, role, comparisonStartDate, comparisonEndDate]);
  useEffect(() => {
 
    if(comparison==="yearly"){
      if (startDate) {
        const startYearAgo = new Date(startDate);
        startYearAgo.setFullYear(startYearAgo.getFullYear() - 1);
        setComparisonStartDate(startYearAgo.toISOString().slice(0, 10)); 
      }
      if (endDate) {
        const endYearAgo = new Date(endDate);
        endYearAgo.setFullYear(endYearAgo.getFullYear() - 1);
        setComparisonEndDate(endYearAgo.toISOString().slice(0, 10));
      }

    }else if(comparison ==="period") {
      if (startDate && endDate) {

        const startDatePeriod = new Date(startDate);
        const endDatePeriod = new Date(endDate);
        const daysDifference = ((endDatePeriod -startDatePeriod) / (1000 * 3600 * 24)).toFixed(0);
        console.log(daysDifference,"daysDifference");
        startDatePeriod.setDate(startDatePeriod.getDate() - daysDifference);
        endDatePeriod.setDate(endDatePeriod.getDate() - daysDifference);
        setComparisonStartDate(startDatePeriod.toISOString().slice(0, 10));
        setComparisonEndDate(endDatePeriod.toISOString().slice(0, 10)); 
      }
    }else{
      setComparisonStartDate("");
      setComparisonEndDate("");

    }
  }, [comparison,startDate, endDate]);
  useEffect(() => {
    if (schadenPieChart.data) {
      setSchadenSeries(schadenPieChart.data);
      if(!checkIfDummyData(schadenPieChart.data))
      setOptions1((prevOptions)=>({
        ...prevOptions,
        colors: ["#8FC73C", "#FFC600", "#EF3256"],
        labels: ["Promotoren","Neutral", "Detraktoren", ],
        dataLabels:{
          enabled:true,
        },
      }))
      else
      setOptions1((prevOptions) => ({
        ...prevOptions,
        colors: ['#f0f0f0'],
        labels: [" "],
        dataLabels:{
          enabled:false,
        },
      }))
      window.dispatchEvent(new Event('resize')); 
    }
  }, [schadenPieChart]);

  useEffect(() => {
    if (schadenStackedBarGraph.data) {
      // setOptions2({...options2,xaxis:{...options2.xaxis,categories:stackedBarChartData.labels}})
      setSchadenStackedOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: { categories: schadenStackedBarGraph.labels },
      }));

      setSchadenStackedSeries(
        [
          ...schadenStackedBarGraph.data.map((val, ind) => {
            switch (ind) {
              case 0:
                return {
                  data: val,
                  name: "Promotoren",
                  type: "column",
                  color: "#8FC73C",
                };
                case 1:
                  return {
                    data: val,
                    name: "Neutral",
                    type: "column",
                    color: "#FFC600",
                  };
              case 2:
                return {
                  data: val,
                  name: "Detraktoren",
                  type: "column",
                  color: "#EF3256",
                };

              default:
                break;
            }
            // return {data:val,name:ind+1}
          }),
          {
            name: "NPS 1",
            data: schadenStackedBarGraph.npsScores,
            type: "line",
            color: "#1b4079",
            max: 100,
          },
          {
            name: "NPS 2",
            data: schadenStackedBarGraph.comparisonScores,
            type: "line",
            color: "#d9d9d9",
            max: 100,
          },
        ]
      );
    }
  }, [schadenStackedBarGraph]);



  function checkIfDummyData(arr) {
    //Just a helper function to check if the data is dummy data or not.
    if (!Array.isArray(arr)) {
      return false;
    }

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        return false;
      }
    }

    return true;
  }


//   console.log(npsReviews.performance);
  return (
    <>
      <div className="header  pb-8 pt-5 pt-md-8">
        <Container fluid>
          <Row className="header-body">
            {
              false  ? (
              <Container
                fluid
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
              >
                <Spinner color="primary" />
              </Container>
            ) : (
              <Col
                xl={{
                  offset: 2,
                  size: 8,
                }}
                lg={{
                  offset: 1,
                  size: 10,
                }}
              >

                <Row className="d-flex justify-content-between align-items-center mt-5">
                  <Col>
                    <div className="dashboard-row">
                      <img src={dashboardBlue} alt="dashboard-blue"></img>
                      <span className="dashboard-row__text">
                        Statistik NPS - Schaden
                      </span>
                    </div>
                  </Col>
                  <Col>
                  <div className="dashboard-legend d-flex align-items-center ">
                      <div className="dashboard-legend-color__promoter" />
                      <span className="dashboard-legend__text">Promotoren</span>
                      <div className="dashboard-legend-color__neutral" />
                      <span className="dashboard-legend__text">Neutral</span>
                      <div className="dashboard-legend-color__detractor" />
                      <span className="dashboard-legend__text">
                        Detraktoren
                      </span>
                    </div>
                  </Col>
                </Row>
                <Nav
                  className="border-bottom-nav mt-3"
                  style={{ display: "flex", justifyContent: "" }}
                >
                  <NavItem className="nav-item-tab">
                    <NavLink
                      className={`${
                        activeTab === 1 ? "active-tab-nav" : "inactive-tab-nav"
                      }`}
                      onClick={() => toggleTab(1)}
                    >
                      Übersicht
                    </NavLink>
                  </NavItem>
                  <NavItem className="nav-item-tab">
                    <NavLink
                      className={`${
                        activeTab === 2 ? "active-tab-nav" : "inactive-tab-nav"
                      }`}
                      onClick={() => toggleTab(2)}
                    >
                      Notizen
                    </NavLink>
                  </NavItem>
                </Nav> 
                <Row className="mt-4">
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="startDate">
                      Zeitraum von
                    </Label>
                    <CustomDatePicker selectedDate = {startDate} onChange={handleStartDateChange}></CustomDatePicker>
                    {/* <Input
                      className="dashboard-date-input"
                      type="date"
                      id="startDate"
                      value={startDate}
                      onChange={handleStartDateChange}
                    /> */}
                  </Col>
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="endDate">
                      bis
                    </Label><CustomDatePicker selectedDate = {endDate} onChange={handleEndDateChange}></CustomDatePicker>

                    {/* <Input
                      className="dashboard-date-input"
                      type="date"
                      id="endDate"
                      value={endDate}
                      onChange={handleEndDateChange}
                    /> */}
                  </Col>
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="comparison">
                      Vergleichen
                    </Label>
                    <Input
                      className="addAdminSelect"
                      name="comparison"
                      type="select"
                      value={comparison}
                      onChange={(e) => {setComparison(e.target.value)}}
                    >
                      <option value="">Vergleich auswählen</option>
                      <option value="yearly">Vorjahresvergleich</option>
                      <option value="period">
                        Mit vorherigem Zeitraum vergleichen
                      </option>
                    </Input>
                  </Col>
                </Row>
                <Row className="mt-3">
                <Col lg={4}>
                    <Label className="dashboard-date-label" for="startDate">
                    Vergleich&nbsp;Zeitraum&nbsp;von
                    </Label>
                    <Input
                      className="dashboard-date-input"
                      type="date"
                      id="startDate"
                      disabled
                      value={comparisonStartDate}
                      onChange={(e)=>{setComparisonStartDate(e.target.value)}}
                    />
                  </Col>
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="endDate">
                      bis
                    </Label>
                    <Input
                      className="dashboard-date-input"
                      type="date"
                      id="endDate"
                      disabled
                      value={comparisonEndDate}
                      onChange={(e)=>{setComparisonEndDate(e.target.value)}}
                    />
                  </Col>
                  <Col lg={4}>
                      <Button
                        className="add-customer-button-dashboard"
                        type="submit"
                        // disabled={notesLoading}
                        onClick={() => {
                          //   history.push("/admin/addcustomer");
                          setActiveTab(2);
                          setIsOpenNotes(!isOpenNotes)
                        }}
                      >
                        Notiz anlegen
                      </Button>
                    </Col>
                </Row>
               {activeTab ===1 && (<> 
                {
              false  ? (
              <Container
                fluid
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100vh" }}
              >
                <Spinner color="primary" />
              </Container>
            ) : ( <>      <Row className="mt-5">
            <Col lg={6} sm={12}>
              <Card className="card-stats mb-4 mb-xl-0">
                <CardHeader className="card-stats-header d-flex justify-content-between align-items-center">
                  <p className="card-stats-header__text-ubersicht">
                    Schaden
                  </p>
                </CardHeader>
                <CardBody>
                <Row className="justify-content-center">
                    {
                    checkIfDummyData(schadenPieChart.data) ? (
                      <p
                        style={{
                          visibility: !checkIfDummyData(
                            schadenPieChart.data
                          )
                            ? "hidden"
                            : "",
                        }}
                        className="dummydata-text mt-1"
                      >
                        *Noch keine Daten vorhanden
                      </p>
                    ) : (
                      ""
                    )}
                  </Row>
                  {schadenPieChart.comparisonNpsScore && (
                    <p className="compare-percentage-text">
                      {calculateRelativeChange(
                        schadenPieChart?.totalNpsScore,
                        schadenPieChart.comparisonNpsScore
                      ).toFixed(2)}
                      %
                    </p>
                  )}
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ position: "relative" }}
                  >
                    {/* The div container to hold the pie chart */}
                    {!checkIfDummyData(schadenPieChart.data) ? (
                      <PieChart
                        options1={options1}
                        series1={schadenSeries}
                        pieEnabled={10}
                        formatterValue={schadenPieChart?.totalNpsScore}
                      />
                    ) : (
                      <>
                        <PieChart
                          options1={options1}
                          series1={[1]}
                          pieEnabled={10}
                          formatterValue={10}
                        />
                      </>
                    )}

                    {/* The absolute positioned div to display NPS scores */}
                    <div className="piechart-text__stats">
                      <p className="piechartnps-text">
                        {!checkIfDummyData(schadenPieChart.data) &&schadenPieChart?.totalNpsScore}{" "}
                        {/* Display the total NPS score */}
                      </p>
                      {schadenPieChart?.comparisonNpsScore !== "" && (
                        <p className="piechartnpscomparison-text">
                          {schadenPieChart?.comparisonNpsScore}
                          {/* Display the comparison NPS score */}
                        </p>
                      )}
                    </div>
                  </div>

                  <Row className="align-items-center justify-content-center mt-3">
                    <p className="percentmailtext">
                      Teilnehmerquote{" "}
                      {npsMetrics.performance?.totalSent !== 0
                        ? (
                            (npsMetrics.performance?.totalResponded /
                              npsMetrics.performance?.totalSent) *
                            100
                          ).toFixed(2)
                        : 0.0}
                      %
                    </p>
                  </Row>
                  <Row className="align-items-center justify-content-center ">
                    <div
                      xs={3}
                      className="d-flex flex-column align-items-center justify-content-center mr-1"
                    >
                      {" "}
                      <p className="percentmailnumber">
                        {npsMetrics.performance?.totalSent
                          ? npsMetrics.performance?.totalSent
                          : 0}
                      </p>{" "}
                      <p className="percentmailsubtext">gesendet</p>
                    </div>
                    <div
                      xs={3}
                      className="d-flex flex-column align-items-center justify-content-center"
                    >
                      {" "}
                      <p className="percentmailnumber">
                        {npsMetrics.performance?.totalOpened
                          ? npsMetrics.performance?.totalOpened
                          : 0}
                      </p>{" "}
                      <p className="percentmailsubtext">geöffnet</p>
                    </div>
                    <div
                      xs={3}
                      className="d-flex flex-column align-items-center justify-content-center ml-1"
                    >
                      {" "}
                      <p className="percentmailnumber">
                        {npsMetrics.performance?.totalResponded
                          ? npsMetrics.performance?.totalResponded
                          : 0}
                      </p>{" "}
                      <p className="percentmailsubtext">reagiert</p>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {/* Side bar chart column */}
            <Col lg={6} md={12}>
              <Card className="card-stats mb-4 mb-xl-0">
                <CardHeader className="card-stats-header d-flex align-items-center">
                  <p className="card-stats-header__text-ubersicht">
                  Schaden
                  </p>
                </CardHeader>
                <CardBody>
                  <Row className="justify-content-center">
                    {schadenStackedBarGraph?.labels?.length === 0 ? (
                      <p className="dummydata-text">
                        *Noch keine Daten vorhanden
                      </p>
                    ) : (
                      <p
                        style={{ visibility: "hidden" }}
                        className="dummydata-text"
                      >
                        *Noch keine Daten vorhanden
                      </p>
                    )}
                    <BarChart
                      options2={
                        schadenStackedBarGraph?.labels?.length > 0
                          ? schadenStackedOptions
                          : options2Initial
                      }
                      series2={
                        schadenStackedBarGraph?.labels?.length > 0
                          ? schadenStackedSeries
                          : series2Initial
                      }
                      tab="2"
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* STACKED GRAPHS */}
          <Row className="mt-4"></Row>
          {/* Number/Bookmart fitlers */}
          <NumbersFilter
            isBookMarkedSelected={isBookMarkedSelected}
            setIsBookMarkedSelected={setIsBookMarkedSelected}
            selectedNumbers={selectedNumbers}
            handleBoxClick={handleBoxClick}
          />
          {/* <ReviewCard/> */}
          <Row className="mt-5">
            {npsLoading.performance ? (
              <Col
                className="d-flex align-items-center justify-content-center mr-1"
                style={{ height: "10vh" }}
              >
                <Spinner></Spinner>
              </Col>
            ) : (
              npsReviews.performance.map((el) => (
                <Col md={4} className="mt-2">
                  <ReviewCard
                    customerName={`${el?.customerId?.name} ${el?.customerId?.lastName}`}
                    reviewText={el.answers[0].answer}
                    npsScore={el.npsScore}
                    npsNumber={el.npsScore}
                    date={el.updatedAt}
                    bookmarked={el?.isBookmarked}
                    id={el._id}
                    category="performance"
                  />
                </Col>
              ))
            )}
          </Row>
          <Row className="mt-2 d-flex align-items-center justify-content-end">
            {npsReviews.performance.length!==npsReviewsTotals.performance &&<Button
              onClick={() => {
                setPerformanceReviewPage((prev) => prev + 1);
              }}
              className="more-button"
            >
              Mehr anzeigen
            </Button>}
          </Row> </>) }

          
                </>)} 
               {activeTab ===2 && (<>
                <Notes isOpenNotes={isOpenNotes} setIsOpenNotes={setIsOpenNotes} startDate={startDate} endDate={endDate} survey="performance" category="Schaden" /> 
               </>)}
              </Col>
            )}
          </Row>
         
        </Container>
      </div>
    </>
  );
};

export default ConsultingStatistics;
