import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../../src/assets/img/icons/calendarIcon.svg';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
registerLocale('de', de)



const CustomDatePicker = ({ selectedDate, onChange ,disabled = false}) => {
    return (
        <div className="DateContainer">
        <span className="dateInput">
          {" "}
          <DatePicker
          locale='de'
            selected={selectedDate}
            onChange={onChange}
            disabled={disabled}
            dateFormat="dd/MM/yyyy"
            dayClassName={() => "example-datepicker-day-class"}
            popperClassName="example-datepicker-class"
            todayButton="TODAY"
            strictParsing
          />
        </span>
        <span className="icon1">
        <img width={18} height={18} src={calendarIcon} alt="Calendar Icon" className="calendar-icon" />
        </span>
      </div>
    );
};




export default CustomDatePicker;
