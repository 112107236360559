import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { fetchNotifications } from "store/action/notificationAction";
import { subscribeToNotifications } from "store/action/notificationAction";
import { markNotificationAsReadOnServer } from "store/action/notificationAction";
import flag from "../assets/img/icons/somalia.png";
import notificationsLogo from "assets/img/icons/news.svg";
import moment from "moment";
import { markAllNotificationAsRead } from "store/action/notificationAction";
import { Link } from "react-router-dom";
import { getNotificationsMetrics } from "store/action/notificationAction";

function NotificationDropdown() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const handleNotificationClick = (notification) => {
    console.log("notification",notification);
    toggleNotificationDropdown()
    if (!notification.read) {
      dispatch(markNotificationAsReadOnServer(notification._id));
    }
  };
  const { notifications,notificationMetrics } = useSelector((state) => state.notification);

  useEffect(() => {
    dispatch(getNotificationsMetrics());
    dispatch(fetchNotifications());
    dispatch(subscribeToNotifications(user._id));
  }, []);

  const formatTime = (time) => {

    const duration = moment.duration(moment().diff(moment(time)));

    // Format the duration based on its length
    const formattedDuration =
      duration.asSeconds() < 60
        ? `${Math.floor(duration.asSeconds())} seconds`
        : duration.asMinutes() < 60
        ? `${Math.floor(duration.asMinutes())} minutes`
        : duration.asHours() < 24
        ? `${Math.floor(duration.asHours())} hours`
        : `${Math.floor(duration.asDays())} days`;

    return formattedDuration;
  };
  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getUTCFullYear().toString();
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }
  const [notificationDropdownOpen, setNotificationDropdownOpen] = useState(false);

  const toggleNotificationDropdown = () => setNotificationDropdownOpen((prevState) => !prevState);
  return (
    <>
      <Dropdown isOpen={notificationDropdownOpen} toggle={toggleNotificationDropdown}  nav>
        <DropdownToggle className="pr-0" nav>
          <img src={notificationsLogo} alt="notification"></img>
        </DropdownToggle>
        {/* {notifications.filter(notification => !notification.isRead).length ? (
          <div
          className="notifications-bell-number"
          >{notifications.filter(notification => !notification.isRead).length}</div>
        ) : (
          ""
        )} */}
        {
          notificationMetrics.unreadCount ? (
            <div
            className="notifications-bell-number"
            >{notificationMetrics.unreadCount}</div>
          ):""
        }

        <DropdownMenu right className="notifications" aria-labelledby="dLabel">
          <div className="notification-heading">
            <h4 className="menu-title">Notifikationen</h4>
            <p onClick={()=>{
              dispatch(markAllNotificationAsRead(()=>{
                // dispatch(fetchNotifications());
              }))
            }} className="mark-all-as-read">Alle als gelesen markieren</p>
          </div>
          <DropdownItem divider />
          <div className="notifications-wrapper">
          {/* <Link className="content" to={"/admin/notifications"}>
           {notificationMetrics.unreadCount ? (
           <div
                  className={`${"notification-item notification-not-seen"}`}
                  // onClick={() => handleNotificationClick(notification._id)}
                >
                  <div className="d-flex flex-row align-items-center">
                    <h4 className="item-title">{`Sie haben ${notificationMetrics.unreadCount} neue Notifikationen`}</h4>
                  </div>

                  <p className="notification-item-info">Umfrage-Übersicht</p>
                </div>
              ):( <div
                className={`${"notification-item notification-seen"}`}
                // onClick={() => handleNotificationClick(notification._id)}
              >
                <div className="d-flex flex-row align-items-center">
                  <h4 className="item-title">Sie haben keine neuen Benachrichtigungen</h4>
                </div>

                <p className="notification-item-info">Umfrage-Übersicht</p>
              </div>)} 
              </Link> */}

           
            {notifications && notifications.data && notifications.data?.map((notification) => (
              <Link onClick={()=>{handleNotificationClick(notification)}} className="content" to={`/admin/view-customer/${notification.sender}?activetab=2`}>
                <div
                  className={`${
                    notification.isRead
                      ? "notification-item notification-seen"
                      : "notification-item notification-not-seen"
                  }`}
                  // onClick={() => handleNotificationClick(notification._id)}
                >
                  <div className="d-flex flex-row align-items-center">
                    <h4 className="item-title">{notification.message}</h4>
                    <p className="notification-time">
                      {formatTime(notification.createdAt)} Ago
                    </p>
                  </div>

                  <p className="notification-item-info">Zur Umfragen-Übersicht</p>
                </div>
              </Link>
            ))}

            {/* <a className="content" href="#">
                    <div className="notification-item">
                      <div className="d-flex flex-row align-items-center">
                      <h4 className="item-title">
                        Omair Hassan posted a review
                      </h4>
                      <p className="notification-time">
                        6 minutes ago
                      </p>
                      </div>
                      
                      <p className="notification-item-info">
                        Survey Type
                      </p>
                    </div>
                  </a>
                  <a className="content" href="#">
                    <div className="notification-item">
                      <div className="d-flex flex-row align-items-center">
                      <h4 className="item-title">
                        Omair Hassan posted a review
                      </h4>
                      <p className="notification-time">
                        6 minutes ago
                      </p>
                      </div>
                      
                      <p className="notification-item-info">
                        Survey Type
                      </p>
                    </div>
                  </a>
                  <a className="content" href="#">
                    <div className="notification-item">
                      <div className="d-flex flex-row align-items-center">
                      <h4 className="item-title">
                        Omair Hassan posted a review
                      </h4>
                      <p className="notification-time">
                        6 minutes ago
                      </p>
                      </div>
                      
                      <p className="notification-item-info">
                        Survey Type
                      </p>
                    </div>
                  </a> */}

            {/* <DropdownItem divider /> */}
            {/* <div className="notification-footer">
                  <h4 className="menu-title">View all <i className="fas fa-angle-right"></i></h4>
                </div> */}
            {/* <div className="no-notifications">
              <img src={flag} width={45} height={45}></img>
              <p className="notification-item-info mt-3 pl-6 pr-6 text-center">
              Dies sind alle Ihre Benachrichtigungen der letzten 30 Tage.
              </p>
            </div> */}
          </div>
        </DropdownMenu>
      </Dropdown >
    </>
  );
}

export default NotificationDropdown;
