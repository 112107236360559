
import AdminStatistics from "components/Statistics/AdminStats";
import SuperAdminStatistics from "components/Statistics/SuperAdminStats";
import { useDispatch, useSelector } from "react-redux";


const Statistics =()=>{
  const { user } = useSelector((state) => state.authUser);
  return(
    
    <>
    {user.role === "super-admin" ? (<SuperAdminStatistics/>) :(<AdminStatistics /> )}
    </>
  )
}

export default Statistics;
