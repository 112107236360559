import BarChart from "components/Charts/BarChart";
import PieChart from "components/Charts/PieChart";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  CardHeader,
  CardFooter,
} from "reactstrap";
import dashboardBlue from "../../assets/img/icons/dashboard-blue.svg";
import ProgressChart from "components/Charts/ProgressChart";
import { useDispatch, useSelector } from "react-redux";
import { getGraphData } from "store/action/dashboardAction";
import { getGoogleGraphData } from "store/action/dashboardAction";
import CustomDatePicker from "components/Statistics/DatePickerComponet";
import NpsCard from "./SuperAdminCards/NpsCard";
import ReviewSocialCard from "./SuperAdminCards/ReviewSocialCard";

const SuperAdminGraphs = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const {
    pieChartData,
    bestpieChartData,
    worstPieChartData,
    googlePieChartData,
    bestGooglePieChartData,
    worstGooglePieChartData
  } = useSelector((state) => state.dashboard);
  const adminId = user._id;
  const [options1, setOptions1] = useState({
    colors: ["#8FC73C", "#FFC600", "#EF3256"],
    chart: {
      id: "mychart",
      width: 600,
      height: 400,
      type: "pie",
    },
    toolbar: {
      show: true,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
          // type: 'none',
        },
      },
    },
    labels: ["Promotoren", "Neutral", "Detraktoren"], // Add labels here
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
          donut: {
              size: "45%",
              labels: {
                show: true,
                name: { show: false },
                total: {
                  show: true,
                  showAlways: true,
                  formatter: function (w) {
                    const totals = w.globals.seriesTotals;
                    console.log(totals,"formatter",w);
                    const promoters = totals[0];
                    const detractors = totals[1];
                    const totalResponses = promoters + detractors +totals[2];
                    if (totalResponses) {
                    const nps = ((promoters - detractors) / totalResponses) * 100;
                    return nps.toFixed();
                  }
                      return 0;
                  }
                }
              }
            }
      },
    },
  });
  const [options3, setOptions3] = useState({
    colors: ["#8FC73C", "#FFC600", "#EF3256"],
    chart: {
      id: "mychart",
      width: 600,
      height: 400,
      type: "pie",
    },
    toolbar: {
      show: true,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
          // type: 'none',
        },
      },
    },
    labels: [" ", " ", " "], // Add labels here
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 150,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 2000,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
          donut: {
              size: "45%",
              labels: {
                show: true,
                name: { show: false },
                total: {
                  show: true,
                  showAlways: true,
                  formatter: function (w) {
                    const totals = w.globals.seriesTotals;
                    console.log(totals,"formatter",w);
                    const promoters = totals[0];
                    const detractors = totals[1];
                    const totalResponses = promoters + detractors +totals[2];
                    if (totalResponses) {
                    const nps = ((promoters - detractors) / totalResponses) * 100;
                    return nps.toFixed();
                  }
                      return 0;
                  }
                }
              }
            }
      },
    },
  });
  const [series1, setSeries1] = useState([1, 2, 3]);
  const [bestSeries, setBestSeries] = useState([1, 2, 3]);
  const [worstSeries, setWorstSeries] = useState([1, 2, 3]);
  const [bestGoogleSeries, setBestGoogleSeries] = useState([1, 2, 3]);
  const [worstGoogleSeries, setWorstGoogleSeries] = useState([1, 2, 3]);
  const [options2, setOptions2] = useState({
    colors: ['#f0f0f0'],
    chart: {
      id: "mychart",
      width: 600,
      height: 400,
      type: "pie",
    },
    toolbar: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.5,
          // type: 'none',
        },
      },
    },
    labels: [" "], // Add labels here
    responsive: [
      {
        breakpoint: 680,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "45%",
          labels: {
            show: false,
            name: { show: false },
            total: {
              show: true,
              showAlways: true,
              formatter: function (w) {
                const totals = w.globals.seriesTotals;
                console.log(totals, "formatter", w);
                const promoters = totals[0];
                const detractors = totals[1];
                const totalResponses = promoters + detractors + totals[2];
                if (totalResponses) {
                  const nps = ((promoters - detractors) / totalResponses) * 100;
                  return nps.toFixed();
                }
                return 0;
              },
            },
          },
        },
      },
    },
  });
  const [series3, setSeries3] = useState([1, 2, 3]);


  const today = new Date();
  const createdAtDate = new Date(user.createdAt);
  const [startDate, setStartDate] = useState(new Date(createdAtDate));
  const [endDate, setEndDate] = useState(new Date(today.toISOString().split('T')[0]));

  const [comparison, setComparision] = useState("");
  const [comparisonStartDate, setComparisonStartDate] = useState("");
  const [comparisonEndDate, setComparisonEndDate] = useState("");
  const handleStartDateChange = (date) => {
    console.log(date, "CHECK DATE");
    if (!isNaN(date) && date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    if (!isNaN(date) && date)
    setEndDate(date);
  };

  useEffect(() => {
      dispatch(getGraphData({ startDate, endDate,comparison }));
      dispatch(getGoogleGraphData({ startDate, endDate,comparison }));
  }, [startDate, endDate,comparison]);

  useEffect(() => {
    if (pieChartData.data) {
      setSeries1(pieChartData.data);
    }
    if (bestpieChartData.data) {
      setBestSeries(bestpieChartData.data);
    }
    if (worstPieChartData.data) {
      setWorstSeries(worstPieChartData.data);
    }
  }, [pieChartData,bestpieChartData,worstPieChartData]);
  useEffect(() => {
    if (googlePieChartData?.data) {
      setSeries3(googlePieChartData.data);
    }
    if (bestGooglePieChartData?.data) {
      setBestGoogleSeries(bestGooglePieChartData.data);
    }
    if (worstGooglePieChartData?.data) {
      setWorstGoogleSeries(worstGooglePieChartData.data);
    }
  }, [googlePieChartData,bestGooglePieChartData,worstGooglePieChartData]);

  useEffect(() => {

    if (comparison === "yearly") {
      if (startDate) {
        const startYearAgo = new Date(startDate);
        startYearAgo.setFullYear(startYearAgo.getFullYear() - 1);
        setComparisonStartDate(startYearAgo.toISOString().slice(0, 10));
      }
      if (endDate) {
        const endYearAgo = new Date(endDate);
        endYearAgo.setFullYear(endYearAgo.getFullYear() - 1);
        setComparisonEndDate(endYearAgo.toISOString().slice(0, 10));
      }

    } else if (comparison === "period") {
      if (startDate && endDate) {

        const startDatePeriod = new Date(startDate);
        const endDatePeriod = new Date(endDate);
        const daysDifference = ((endDatePeriod - startDatePeriod) / (1000 * 3600 * 24)).toFixed(0);
        console.log(daysDifference, "daysDifference");
        startDatePeriod.setDate(startDatePeriod.getDate() - daysDifference);
        endDatePeriod.setDate(endDatePeriod.getDate() - daysDifference);
        setComparisonStartDate(startDatePeriod.toISOString().slice(0, 10));
        setComparisonEndDate(endDatePeriod.toISOString().slice(0, 10));
      }
    } else {
      setComparisonStartDate("");
      setComparisonEndDate("");

    }
  }, [comparison, startDate, endDate]);

  return (
    <>
      <div className="header  pb-8 pt-5 pt-md-8">
        <Container fluid>
          <Row className="header-body">
            <Col
              xl={{
                offset: 2,
                size: 8,
              }}
              lg={{
                offset: 1,
                size: 10,
              }}
            >
              <Row className="d-flex justify-content-between align-items-center">
                <Col>
                  <div className="dashboard-row">
                    <img src={dashboardBlue} alt="dashboard-blue"></img>
                    <span className="dashboard-row__text" >Dashboard</span>
                  </div>
                </Col>
                <Col>
                <div className="dashboard-legend d-flex align-items-center ">
                      <div className="dashboard-legend-color__promoter" />
                      <span className="dashboard-legend__text">Promotoren</span>
                      <div className="dashboard-legend-color__neutral" />
                      <span className="dashboard-legend__text">Neutral</span>
                      <div className="dashboard-legend-color__detractor" />
                      <span className="dashboard-legend__text">
                        Detraktoren
                      </span>
                    </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={4}>
                  <Label className="dashboard-date-label" for="startDate">
                    Zeitraum von
                  </Label>
                  <CustomDatePicker selectedDate={startDate} onChange={handleStartDateChange}></CustomDatePicker>
                </Col>
                <Col lg={4}>
                  <Label className="dashboard-date-label" for="endDate">
                    bis
                  </Label>
                  <CustomDatePicker selectedDate={endDate} onChange={handleEndDateChange}></CustomDatePicker>
                </Col>
                <Col lg={4}>
                    <Label className="dashboard-date-label" for="comparison">
                      Vergleichen
                    </Label>
                    <Input
                      className="addAdminSelect"
                      name="comparison"
                      type="select"
                      value={comparison}
                      onChange={(e) => {
                        setComparision(e.target.value);
                      }}
                    >
                      <option value="">Vergleich auswählen</option>
                      <option value="yearly">Vorjahresvergleich</option>
                      <option value="period">
                        Mit vorherigem Zeitraum vergleichen
                      </option>
                    </Input>
                  </Col>
              </Row>
              <Row className="mt-3">
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="startDate">
                      Vergleich&nbsp;Zeitraum&nbsp;von
                    </Label>
                    <Input
                      className="dashboard-date-input"
                      type="date"
                      id="startDate"
                      value={comparisonStartDate}
                      disabled
                      onChange={(e) => {
                        setComparisonStartDate(e.target.value);
                      }}
                    />
                  </Col>
                  <Col lg={4}>
                    <Label className="dashboard-date-label" for="endDate">
                      bis
                    </Label>
                    <Input
                      className="dashboard-date-input"
                      type="date"
                      id="endDate"
                      value={comparisonEndDate}
                      disabled
                      onChange={(e) => {
                        setComparisonEndDate(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              <Row className="mt-5">
              <NpsCard
                cardData={pieChartData}
                series={series1}
                seriesOptions={options1}
                cardHeaderText="Übersicht"
                cardFooterText="Feedback"
              />
              <NpsCard
                cardData={bestpieChartData}
                series={bestSeries}
                seriesOptions={options1}
                cardHeaderText="Best"
                cardFooterText="Hunter"
                adminNPS={bestpieChartData.adminWithBestNPS}
                adminSurveys={bestpieChartData.adminWithMostSurveys}
                startDate={startDate}
                endDate={endDate}
                comparison={comparison}
              />
              <NpsCard
                cardData={worstPieChartData}
                series={worstSeries}
                seriesOptions={options1}
                cardHeaderText="Worst"
                cardFooterText="Lame Duck"
                adminNPS={worstPieChartData.adminWithWorstNPS}
                adminSurveys={worstPieChartData.adminWithLeastSurveys}
                startDate={startDate}
                endDate={endDate}
                comparison={comparison}
              />
                </Row>
                <Row className="mt-3">
                <ReviewSocialCard cardData={googlePieChartData} series={series3} cardHeaderText="Google Reviews" options1={options3}   />
                <ReviewSocialCard cardData={bestGooglePieChartData} series={bestGoogleSeries} cardHeaderText="Best" options1={options3} adminReviews={bestGooglePieChartData.adminWithBestAverage} startDate={startDate} endDate={endDate} comparison={comparison} />
                <ReviewSocialCard cardData={worstGooglePieChartData} series={worstGoogleSeries} cardHeaderText="Worst" options1={options3} adminReviews={worstGooglePieChartData.adminWithWorstAverage} startDate={startDate} endDate={endDate} comparison={comparison}/>
              </Row>
              <Row className="mt-3">
                <ReviewSocialCard cardData={{}} series={[1]} cardHeaderText="Facebook Reviews" options1={options2} />
                <ReviewSocialCard cardData={{}} series={[1]} cardHeaderText="Best" options1={options2} />
                <ReviewSocialCard cardData={{}} series={[1]} cardHeaderText="Worst" options1={options2} />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SuperAdminGraphs;
