import { useEffect, useState } from "react";
import { SocialIcon } from "react-social-icons";
import logo from "../assets/img/logos/jaworskiLogin.svg";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  Spinner,
  CustomInput,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import downloadLogo from "../assets/img/icons/t&c-download-logo.svg";
import handshakeLogo from "../assets/img/icons/handshake-logo.svg";
import { acceptInvite } from "store/action/invitationsAction";
import { useHistory } from "react-router-dom";
import linkConfig from "config/config";
import closeIcon from "../assets/img/icons/close.svg";

const TermsConditions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory()
  const { surveyAnswer, surveyAnswerLoading, isAnswered } = useSelector(
    (state) => state.surveyAnswer
  );
  const [isChecked, setIsChecked] = useState(false);
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const inviteId = params.get("id");
  console.log(inviteId, "INVITEID");

  return (
    <div className="auth-container d-md-block text-center survey-container overflow-hidden">
      <Row className="d-flex align-items-center justify-content-center">
        {surveyAnswerLoading ? (
          <>
            <Col
              md={{
                offset: 2,
                size: 8,
              }}
              lg={{
                offset: 3,
                size: 6,
              }}
              className="surveyform__inner-container mt--15 p-5"
            >
              <Spinner />
            </Col>
          </>
        ) : (
          <>
            <Col
              md={{
                offset: 2,
                size: 10,
              }}
              lg={{
                offset: 3,
                size: 7,
              }}
              className={`surveyform__inner-container p-2`}
            >
              <Row className="d-flex flex-column align-items-start ml-1">
                <div className="sf-header-text">
                  <img src={closeIcon} width={25} height={25} onClick={() => { window.close() }} alt="Close button" className="cancelButton"></img>
                </div>
              </Row>
              <Row className="d-flex flex-column align-items-center justify-content-center ">
                <img
                  className="mt-3"
                  src={handshakeLogo}
                  alt="handshake GDPR"
                />
                <p className="mt-3 tc-heading">Daten & Datenschutz</p>
              </Row>
              <Row>
                <Col
                  md={{
                    offset: 1,
                    size: 10,
                  }}
                  className="terms-container p-3"
                >
                  <p>
                    <strong>Feedback-Sammlung und Nutzung:</strong><br />
                    Wir sind stets daran interessiert, unser Happinet zu verbessern, um uns und Eure Kunden die bestmögliche Erfahrung zu bieten. Das von Euren Kunden bereitgestellte Feedback, einschließlich Anregungen, Kommentare und Vorschläge, soll von unserem gesamten Team zu Analysezwecken verwendet werden, um unsere Agenturprozesse stetig zu optimieren und immer besser auf die Kundenbedürfnisse auszurichten.
                  </p>

                  <p>
                    <strong>Opt-out-Möglichkeit:</strong><br />
                    Falls Du nicht wünscht, dass das Kunden-Feedback zu Analysezwecken verwendet wird, kannst Du dich jederzeit von der Teilnahme an dieser Sammlung abmelden. Bitte beachte, dass wir durch die Abmeldung weniger Feedback erhalten, auf Basis dessen wir die Verbesserungen durchführen können.
                  </p>

                  <p>
                    <strong>Feedback-Sammlung:</strong><br />
                    Wir werden Feedback, das Deine Kunden freiwillig bereitstellen, sammeln und speichern. Dies kann Vorschläge, Kommentare, Ideen oder andere Informationen zur Verbesserung unserer Agenturprozesse umfassen.
                  </p>

                  <p>
                    <strong>Analysezwecke:</strong><br />
                    Das gesammelte Feedback kann bzw. soll von uns gemeinsam analysiert werden, um systematische Erkenntnisse zu gewinnen und zukünftige Verbesserungen vorzunehmen.
                  </p>

                  <p>
                    <strong>Anonymität und Sicherheit:</strong><br />
                    Das Kundenfeedback wird in der Form verwendet, wie es der Kunde es zurückspielt, damit wir auf diesem authentischen Feedback unsere Agenturprozesse verbessern können.
                  </p>

                  <p>
                    <strong>Datenweitergabe:</strong><br />
                    Wir geben das Kunden-Feedback nicht an Dritte weiter, es sei denn, dies ist gesetzlich erforderlich.
                  </p>

                  <p>
                    <strong>Deine Wahl:</strong><br />
                    Du hast die Möglichkeit, dich von der Teilnahme an der Feedback-Analyse abzumelden. Bitte beachte, dass wir durch die Abmeldung weniger Feedback erhalten, auf Basis dessen wir unsere Agenturprozesse kontinuierlich kundenorientierter ausrichten können.
                  </p>

                </Col>
              </Row>
              <Row className="mt-5">
                <Col
                  md={{
                    offset: 1,
                    size: 10,
                  }}
                >
                  <div className="button-container">
                    <div className="sf-radio-container">
                      <Input
                        name="survey-radio"
                        className="sf-radio"
                        type="radio"
                        value={isChecked}
                        checked={isChecked}
                        onClick={(e) => {
                          setIsChecked(!isChecked);
                        }}
                      />
                      <p className="terms-radio-label">
                        Ich habe die Widerrufsbelehrung und die
                        Datenschutzbestimmungen zur Kenntnis genommen.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col
                  className="d-flex hover-pointer"
                  onClick={() => { window.open(`${linkConfig.serverURL}/invitations/pdf`, '_blank') }}
                  md={{
                    offset: 1,
                    size: 4,
                  }}
                >
                  <img
                    width={25}
                    height={30}
                    src={downloadLogo}

                    alt="Download Svg"
                  ></img>
                  <p className="terms-download-text ml-2">
                    Download <br /> Datenschutzbestimmungen
                  </p>
                </Col>
                <Col className="p-0" md={4}>
                  <Button
                    onClick={async () => {
                      if (isChecked) {
                        await dispatch(acceptInvite(inviteId), () => {
                          history.push("/auth");
                          window.close();
                        });
                        history.push("/auth");
                        // window.close();
                      }
                    }}
                    className={`mt-3 mt-md-0 terms-accept-button ${isChecked ? "" : "terms-disabled-button"
                      }`}
                  >
                    Ja, ich werde Teammitglied
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col
              md={{ size: 10 }}
              lg={{ size: 7 }}
              className="sf-footer d-flex align-items-center justify-content-center"
            >
              Powered by johnkent.de
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
export default TermsConditions;
