import react, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Spinner,
  Col,
  CardFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  forgetPassword,
  login as userLogin,
} from "../../store/action/authAction";
import { useSelector, useDispatch } from "react-redux";
import jaworskiLogo from "../../assets/img/logos/jaworskiLogin.svg";
import UserLoginLogo from "../../assets/img/icons/UserLogin.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const ForgetPassword = () => {
  const [login, setLogin] = useState({ email: "", password: "" });
  const [imageLoaded, setImageLoaded] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const history=useHistory()

  const handleLoginChange = (name, value) => {
    setLogin((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const { loading } = useSelector((state) => state.auth);
  useEffect(() => {
    const img = new Image();
    img.src =
      "https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK-Logo.jpg?alt=media&token=adcb3d18-9d24-40d9-a94b-72949ea29136";
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary border-0 auth-card">
          <CardBody className="px-lg-5 py-lg-5">
            <Row className="d-flex align-items-center justify-content-center mb-2">
              {" "}
              {imageLoaded ? (
                <img
                  width={300}
                  height={200}
                  src="https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK-Logo.jpg?alt=media&token=adcb3d18-9d24-40d9-a94b-72949ea29136"
                  alt="jaworski"
                  className="img-fluid"
                />
              ) : (
                ""
              )}
            </Row>
            <div className="text-center text-muted mb-4">
              <small>Passwort vergessen</small>
            </div>
            <Row>
              <Col sm={12} md={{ offset: 2, size: 8 }}>
                <Form
                  role="form"
                  onSubmit={async (e) => {
                    e.preventDefault();
                    let data = {
                      email: email,
                    };
                    await dispatch(forgetPassword(data,()=>{
                      history.push("/auth/login")
                    }));
                    setEmail("");
                  }}
                >
                  <FormGroup>
                    <div className="auth-input-container">
                      <span className="auth-icon">
                        <img
                          src={UserLoginLogo}
                          alt="user"
                          className="img-fluid"
                        />
                      </span>
                      <Input
                        className="auth-input"
                        placeholder="Email"
                        type="text"
                        // autoComplete='new-password'
                        value={email}
                        name="email"
                        required
                        onChange={(e) => {
                          // setLogin({ email: e.target.value });
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </FormGroup>

                  <div className="text-center">
                    {/* <Link to='/admin/index'> */}
                    <Button
                      className="my-4 login-button"
                      color="primary"
                      type="submit"
                    >
                      {loading ? <Spinner size="sm" /> : "Einreichen"}
                    </Button>
                    {/* </Link> */}
                  </div>
                </Form>
              </Col>
            </Row>

            <Col xs="6">
              <Link className="text-dark" to="/auth/login">
                <small>Zurück</small>
              </Link>
            </Col>
          </CardBody>
          <CardFooter className="auth-card-footer text-center">
            Powered by johnkent.de
          </CardFooter>
        </Card>
        <Row className="mt-3 ml-3">
          {/* <Col className='text-right' xs='6'>
						<a
							className='text-light'
							href='#pablo'
							onClick={e => e.preventDefault()}
						>
							<small>Create new account</small>
						</a>
					</Col>*/}
        </Row>
      </Col>
    </>
  );
};

export default ForgetPassword;
