import { Toast } from 'reactstrap';
import Repository from 'repository/Repository';
import { RepositoryFactory } from 'repository/RepositoryFactory';
var auth = RepositoryFactory.get('auth');

export const setAuthLoading = val => async dispatch => {
	dispatch({ type: 'SET_AUTH_LOADING', payload: val });
};

export const getAuthLoading = val => async dispatch => {
	dispatch({ type: 'GET_AUTH_LOADING', payload: val });
};

export const login = log => async dispatch => {
	await dispatch(setAuthLoading(true));
	try {
		let { data } = await auth.userLogin(log);
		if (data.success) {
			Repository.defaults.headers.Authorization = `Bearer ${data.token}`;
			setTimeout(async function () {
				await dispatch({
					type: 'LOGIN_SUCCESS',
					payload: data,
				});
				dispatch(setAuthLoading(false));
			}, 2000);
			return true;
		}
		return false;
	} catch (e) {
		dispatch(setAuthLoading(false));
		alert(e.response.data.message);
		return false;
	}
};

export const logout = id => async dispatch => {
	localStorage.clear();
	dispatch({ type: 'LOGOUT_SUCCESS' });
};
export const logoutAllDevices = () => async dispatch => {
	await dispatch(setAuthLoading(true));
	try {
		let { data } = await auth.logoutAllDevices();
		if (data.success) {
			alert(data.message);
			dispatch(setAuthLoading(false));
			dispatch({ type: 'LOGOUT_SUCCESS' });
			// history.push('/auth/login');
		}
	} catch (e) {
		dispatch(setAuthLoading(false));
		alert(e.response.data.message);
	}
};

export const forgetPassword = (payload,onSuccess = () => {}) => async dispatch => {
	await dispatch(setAuthLoading(true));
	try {
		let { data } = await auth.forgetPasswordRep(payload);
		if (data.success) {
			alert(data.message);
			onSuccess()
			dispatch(setAuthLoading(false));
		}
	} catch (e) {
		dispatch(setAuthLoading(false));
		alert(e.response.data.message);
	}
};

export const verifyResetPassToken = (payload,history) => async dispatch => {
	await dispatch(getAuthLoading(true));
	try {
		let { data } = await auth.verifyResetPassTokenRep(payload);
		if (data.success) {
			// alert(data.message);
			dispatch(getAuthLoading(false));
		}
	} catch (e) {
		dispatch(getAuthLoading(false));
		alert(e.response.data.message);
		history.push('/auth/login')
	}
};

export const resetPassword = (payload, token, history) => async dispatch => {
	await dispatch(setAuthLoading(true));
	try {
		let { data } = await auth.resetPassRep(payload, token);
		if (data.success) {
			alert(data.message);
			dispatch(setAuthLoading(false));
			history.push('/auth/login');
		}
	} catch (e) {
		dispatch(setAuthLoading(false));
		alert(e.response.data.message);
	}
};
