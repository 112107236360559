/* eslint-disable import/no-anonymous-default-export */
import Repository from './Repository';
const LOGIN = '/auth/login';
const LOGOUT_ALL_DEVICES = '/auth/logout-all';
const FORGET = '/auth/forget';
const VERIFY = '/auth/verify';
const RESET = '/auth/reset';
export default {
	userLogin(payload) {
		return Repository.post(`${LOGIN}`, payload);
	},
	logoutAllDevices(payload) {
		return Repository.get(`${LOGOUT_ALL_DEVICES}`, payload);
	},
	forgetPasswordRep(payload) {
		return Repository.post(`${FORGET}`, payload);
	},
	verifyResetPassTokenRep(token) {
		return Repository.get(`${VERIFY}/${token}`);
	},
	resetPassRep(payload, token) {
		return Repository.post(`${RESET}/${token}`, payload);
	},
};
