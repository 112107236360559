const initialData = {
	notes: [],
	note: {},
	notesLoading: false,
};

const noteReducer = (state = initialData, action) => {
	switch (action.type) {

		case 'GET_NOTES_LOADING':
			return {
				...state,
				notesLoading: action.payload,
			};
		case 'GET_ALL_NOTES':
			return {
				...state,
				notes: action.payload,
			};
		case "GET_NOTE":
			return {
				...state,
				note: action.payload,
			};
		default:
			return state;
	}
};

export default noteReducer;
