
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Dropdown,
} from "reactstrap";
import { logout } from "store/action/authAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import arrowMenuClose from "../../assets/img/icons/arrow-menu-close.svg";
import dashboardLogo from "../../assets/img/icons/dashboard.svg";
import statisticsLogo from "../../assets/img/icons/statistics.svg";
import questionLogo from "../../assets/img/icons/questions-white.svg";
import switchLogo from "../../assets/img/icons/swtich-logo.svg";
import customers from "../../assets/img/icons/costumers.svg";
import settingLogo from "../../assets/img/icons/settings.svg";
import logoutLogo from "../../assets/img/icons/logout.svg";
import arrowMenuOpen from "../../assets/img/icons/arrow-menu-open.svg";
import userLogo from "assets/img/icons/user.svg";
import notificationsLogo from "assets/img/icons/news.svg";
import jaworskiLogo from "assets/img/icons/jaworskiLogo.svg";
import { markNotificationAsReadOnServer } from "store/action/notificationAction";
import moment from "moment";
import flag from '../../assets/img/icons/somalia.png';
import questionsBlue from "../../assets/img/icons/questions-blue.svg"
import { switchAdminRole } from "store/action/adminAction";
import { useLocation } from "react-router-dom";
import NotificationDropdown from "components/NotificationDropdown";
var ps;

const Sidebar = ({ bgColor, routes, logo, isOpen, setIsOpen }) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const dispatch = useDispatch();
  const history = useHistory()
  const location = useLocation();
  const { pathname } = location;
  const { user,role } = useSelector((state) => state.authUser);
  // const [isOpen, setIsOpen] = useState(true);
  // verifies if routeName is the one active (in browser input)
  // const {role}=useSelector((state)=>state.authUser);
  const toggleAdminRole = () => {
    if (role === "admin-pro") {
      dispatch(switchAdminRole("admin"))
    }
    else if (role === "admin") {
      dispatch(switchAdminRole("admin-pro"))
    }
  }


  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const toggle = () => {
    setIsOpen((prev) => !prev);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const { notifications } = useSelector((state) => state.notification);
  const handleNotificationClick = (notification) => {
    if (!notification.read) {
      dispatch(markNotificationAsReadOnServer(notification));
    }
  };

  const formatTime = (time) => {

    const duration = moment.duration(moment().diff(moment(time)));

    // Format the duration based on its length
    const formattedDuration = duration.asSeconds() < 60
      ? `${Math.floor(duration.asSeconds())} seconds`
      : duration.asMinutes() < 60
        ? `${Math.floor(duration.asMinutes())} minutes`
        : duration.asHours() < 24
          ? `${Math.floor(duration.asHours())} hours`
          : `${Math.floor(duration.asDays())} days`;

    return formattedDuration
  }

  const [activeDropDown, setActiveDropDown] = useState(false);
  // creates the links that appear in the left menu / Sidebar	if (prop.isMenu != false && (prop.role ===user.role) || prop.commonroute)



  // console.log("Role");
  // console.log(role);
  const createLinks = (routes) => {
    return (
      <>
        <div className="d-flex flex-column">
          <div style={{ display: "contents" }}>
            <NavItem className="d-none d-md-block">
              <Row
                onClick={() => setIsOpen(!isOpen)}
                className="d-flex  align-items-center hover-pointer text-light nav-link-sidebar nav-link"
              >
                <img className="mx-2" style={{ cursor: "pointer" }} src={isOpen ? arrowMenuClose : arrowMenuOpen} alt="arrow close"></img>
                <p className={`menu-einklappen   ml-2 ${isOpen ? "" : "d-none"}`}>
                  Menü einklappen
                </p>
              </Row>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/admin" + "/index"}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
                className="text-light nav-link-sidebar "
              >
                <Row className="d-flex justify-content-center align-items-center">
                  <img className="mx-2" src={dashboardLogo} alt="dashboard"></img>
                  <p
                    className={`nav-link-sidebar-text  ml-2 ${isOpen ? "" : "d-none"
                      }`}
                  >
                    Dashboard
                  </p>
                </Row>
              </NavLink>
            </NavItem>
            {user.role==="super-admin" &&(<NavItem>
            <NavLink
              to={"/admin" + "/statistics"}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              activeClassName="active"
              className="text-light nav-link-sidebar"
            >
              <Row className="d-flex justify-content-center align-items-center">
                <img className="mx-2" src={statisticsLogo} alt="statistics"></img>
                <p
                  className={`nav-link-sidebar-text  ml-2 ${
                    isOpen ? "" : "d-none"
                  }`}
                >
                  Statistik
                </p>
              </Row>
            </NavLink>
          </NavItem>)}

      {user.role!=="super-admin" && (<UncontrolledDropdown onClick={()=>{}} nav inNavbar className={`text-light ${pathname==="/admin/statistics" || pathname==="/admin/googlestats" || pathname==="/admin/facebookstats" || pathname==="/admin/consulting" || pathname==="/admin/service" ||pathname==="/admin/performance"  ?"dropdown-sidebar" :""}`}>
          <DropdownToggle
            className="dropdown-togglenavbar text-light nav-top-border"
            nav
            caret
            onClick={() => {setIsOpen(true)
              history.push("/admin/statistics")
            } }
          >
            <Row className="d-flex justify-content-center align-items-center " style={{display:'-webkit-box'}}>
              <img className="mx-2" src={statisticsLogo} alt="statistics"></img>
              <p
                className={`nav-link-sidebar-text  ml-2 ${isOpen ? "" : "d-none"
                  }`}
                >
                    Statistik
                  </p>
                 
                </Row>
              </DropdownToggle>
              <DropdownMenu className="dropdown-togglenavbar  text-light nav-drop-togg contentclass" >
                <DropdownItem toggle={false}>
                  <Dropdown toggle={() => {}} isOpen={activeDropDown} nav inNavbar className="text-light">
                    <DropdownToggle
                      className="dropdown-togglenavbar text-light"
                      nav
                      caret
                      onClick={() => setIsOpen(true)}
                    >
                      <Row className="d-flex justify-content-between align-items-center mar-left nav-drop-row" >
                        <p
                          className={`nav-link-sidebar-text  ml-2 custom-drop-btn ${isOpen ? "" : "d-none"
                            }`}
                            style={{color:pathname==="/admin/statistics"?"#ffffff":""}}
                          onClick={() => history.push("/admin/statistics")}
                        >
                          NPS
                        </p>
                        <p
                          className={`nav-link-sidebar-text  ml-2 ${isOpen ? "" : "d-none"
                            }`}
                          onClick={() => activeDropDown ? setActiveDropDown(false) : setActiveDropDown(true)}
                        >
                          {activeDropDown ? "-" : "+"}
                        </p>
                      </Row>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-togglenavbar  text-light border-0">
                      <DropdownItem toggle={false}>
                        <NavItem>
                          <NavLink
                            to={"/admin" + "/consulting"}
                            tag={NavLinkRRD}
                            onClick={closeCollapse}
                            activeClassName="active"
                            className="text-light "
                          >
                            Beratung
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem toggle={false}>
                        <NavItem>
                          <NavLink
                            to={"/admin" + "/service"}
                            tag={NavLinkRRD}
                            onClick={closeCollapse}
                            activeClassName="active"
                            className="text-light "
                          >
                            Service
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem toggle={false}>
                        <NavItem>
                          <NavLink
                            to={"/admin" + "/performance"}
                            tag={NavLinkRRD}
                            onClick={closeCollapse}
                            activeClassName="active"
                            className="text-light "
                          >
                            Schaden
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </DropdownItem>
                <DropdownItem toggle={false}>
                  <NavItem>
                    <NavLink
                      to={`/admin/googlestats`}
                      tag={NavLinkRRD}
                      onClick={closeCollapse}
                      activeClassName="active"
                      className="text-light custom-drop-btn"
                    >
                      Google
                    </NavLink>
                  </NavItem>
                </DropdownItem>
                {/* FACEBOOK FUNCTIONALITY 4 */}
                {/* <DropdownItem toggle={false}>
                  <NavItem>
                    <NavLink
                      to={`/admin/facebookstats`}
                      tag={NavLinkRRD}
                      onClick={closeCollapse}
                      activeClassName="active"
                      className="text-light custom-drop-btn"
                    >
                      Facebook
                    </NavLink>
                  </NavItem>
                </DropdownItem> */}
                {/* <DropdownItem toggle={false}>
              <NavItem>
                <NavLink
                  to={`/admin/${user.role === "super-admin" ? "addadmin" : "addcustomer"}`}
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                  activeClassName="active"
                  className="text-light custom-drop-btn fb-drop-btn"
                >
                  Facebook
                </NavLink>
              </NavItem>
            </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>)}  

        
        <UncontrolledDropdown nav inNavbar className={`text-light  ${pathname==="/admin/customers" || pathname==="/admin/addcustomer" || pathname==="/admin/admins" || pathname==="/admin/addadmin"  ?"dropdown-sidebar" :""}`}>
          <DropdownToggle
            className="dropdown-togglenavbar text-light nav-top-border"
            nav
            caret
            onClick={() => {setIsOpen(true)
              history.push(`/admin/${user.role === "super-admin" ? "admins" : "customers"}`)
            }}
          >
            <Row className="d-flex justify-content-center align-items-center ">
              <img className="mx-2" src={customers} alt="question"></img>
              <p
                className={`nav-link-sidebar-text  ml-2 ${isOpen ? "" : "d-none"
                  }`}
                  >
                    Kunden
                  </p>
                
                </Row>
              </DropdownToggle>
              <DropdownMenu className="dropdown-togglenavbar  text-light">
                <DropdownItem toggle={false}>
                  <NavItem>
                    <NavLink
                      to={`/admin/${user.role === "super-admin" ? "admins" : "customers"}`}
                      tag={NavLinkRRD}
                      onClick={closeCollapse}
                      activeClassName="active"
                      className="text-light "
                    >
                      Übersicht
                    </NavLink>
                  </NavItem>
                </DropdownItem>
                {(role !== "admin-pro" && <DropdownItem toggle={false}>
                   <NavItem>
                    <NavLink
                      to={`/admin/${user.role === "super-admin" ? "addadmin" : "addcustomer"}`}
                      tag={NavLinkRRD}
                      onClick={closeCollapse}
                      activeClassName="active"
                      className="text-light "
                    >
                      Kunde anlegen
                    </NavLink>
                  </NavItem>
                </DropdownItem>)}
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink
                to={"/admin" + "/settings"}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
                className="text-light nav-link-sidebar nav-top-border"
              >
                <Row className="d-flex justify-content-center align-items-center">
                  <img className="mx-2" src={settingLogo} alt="setting"></img>
                  <p
                    className={`nav-link-sidebar-text  ml-2 ${isOpen ? "" : "d-none"
                      }`}
                  >
                    Einstellungen
                  </p>
                </Row>
              </NavLink>
            </NavItem>
          </div>
        </div>
        <div className="d-flex flex-column">
          <div style={{ display: "contents" }}>
            <NavItem>
              <div
                onClick={() => {
                  closeCollapse()
                  dispatch(logout())
                }}
                className="sidenav-footer-logout text-light nav-link-sidebar nav-link "
              >
                <Row className=" d-flex justify-content-center align-items-center">
                  <img className="mx-2" src={logoutLogo} alt="logoutLogo"></img>
                  <p
                    className={`logout-sidebar-text  ml-2 ${isOpen ? "" : "d-none"}`}
                  >
                    Abmelden
                  </p>
                </Row>
              </div>
            </NavItem>
            <Row className={` ${isOpen ? "" : "p-0"} m-0 text-white text-align-center nav-link-sidebar nav-top-border nav-link d-flex align-items-center justify-content-center sidenav-footer-poweredby ${isOpen ? "" : " sidenav-footer-poweredby-closed"}`}>
              {isOpen ? <p>powered by johnkent.de</p> : <p>powered <br /> by  <br /> johnkent.de</p>}
            </Row>
          </div>
        </div>
      </>
    );
  };


  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light left-Sidebar"
      expand="md"
      id="sidenav-main"
    // style={{zIndex:100}}
    >
      <Container >
        <div className="d-flex flex-row justify-content-start align-items-center">
          <Link
            className="h4 mb-0 text-white text-uppercase d-md-none "
            to="/"
          >
            <Row>
              <Col xs={3}>
                <img width={40} height={40} src={user.logoURL ? user.logoURL : "https://firebasestorage.googleapis.com/v0/b/rating-system-993c9.appspot.com/o/JK.png?alt=media&token=7544e708-a68a-491e-864b-7e8e50c1a4dc"} alt="Logo"></img>
              </Col>
              <Col xs={9}>
                {" "}
                <p className="navbar-text-angemeldet">Angemeldet als</p>
                <p className="navbar-text-admin">{user.name} {user.lastName}</p>
              </Col>
            </Row>

            {/* {props.brandText} */}
          </Link>
          {user.role === "admin-pro" ? (<>
            <img src={switchLogo} width="30px" height="33px" className="ml-1 d-md-none" onClick={toggleAdminRole}></img>
            <p onClick={toggleAdminRole} className={`switchrole_logo_text_${role === "admin" ? "A" : "AP"} d-md-none`}>{role === "admin" ? "A" : "AP"}</p>
          </>) : ""}
        </div>
        <Nav className="align-items-center d-md-none">
        <NotificationDropdown/>
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="less-padding">
              <img src={userLogo} alt="user" className="nav-icons"></img>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/admin/settings" tag={Link}>
                <i className="ni ni-single-02" />
                <span>Einstellungen</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="/#" onClick={() => dispatch(logout())}>
                <i className="ni ni-user-run" />
                <span>Abmelden</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* Toggler */}
          <button
            className="navbar-toggler less-padding"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon toggle-button" />
          </button>
        </Nav>
        {/* Collapse */}
        <Collapse className="left-Sidebar" navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          {/* Navigation */}
          <Nav style={{ minHeight: "100vh", justifyContent: "space-between" }} navbar>
            {createLinks(routes)}

          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;

{
  /* {routes.map((prop, key) => {
          if (prop.isMenu != false)
            return (
              <>
                <NavItem key={key}>
                  <NavLink
                    to={prop.layout + prop.path}
                    tag={NavLinkRRD}
                    onClick={closeCollapse}
                    activeClassName="active"
                    className="text-light"
                  >
                    <i className={prop.icon} />
                    {prop.name}
                  </NavLink>
                </NavItem>
              </>
            );
        })} */
}
