const initialState = {
  graphData: [],
  graphLoading: false,
  googleGraphLoading: false,
  googleBarChartData: {},
  googlePieChartData: {},
  facebookGraphLoading: false,
  facebookBarChartData: {},
  facebookPieChartData: {},
  bestGooglePieChartData:{},
  worstGooglePieChartData:{},
  stackedBarChartData: {},
  pieChartData: {},
  bestpieChartData:{},
  worstPieChartData:{}
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_GRAPH_DATA":
      return {
        ...state,
        stackedBarChartData: action.payload.stackedBarChartData,
        pieChartData: action.payload.pieChartData,
        bestpieChartData: action.payload.bestpieChartData ? action.payload.bestpieChartData : {},
        worstPieChartData: action.payload.worstPieChartData ? action.payload.worstPieChartData : {},
      }
    case "FETCH_GOOGLE_DATA":
      return {
        ...state,
        googleBarChartData: action.payload.googleBarChartData,
        googlePieChartData: action.payload.googlePieChartData,
        bestGooglePieChartData: action.payload.bestGooglePieChartData ? action.payload.bestGooglePieChartData : {},
        worstGooglePieChartData: action.payload.worstGooglePieChartData ? action.payload.worstGooglePieChartData : {},
      }
      case "FETCH_FACEBOOK_DATA":
        return {
          ...state,
          facebookBarChartData: action.payload.facebookBarChartData,
          facebookPieChartData: action.payload.facebookPieChartData,
          bestFacebookPieChartData: action.payload.bestFacebookPieChartData ? action.payload.bestFacebookPieChartData : {},
          worstFacebookPieChartData: action.payload.worstFacebookPieChartData ? action.payload.worstGooglePieChartData : {},
        }
    case "GRAPH_LOADING":
      return {
        ...state,
        graphLoading: action.payload,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
