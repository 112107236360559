import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { CardFooter, Col, Container, Row, Table } from "reactstrap";
import { fetchNotifications } from "store/action/notificationAction";
import { subscribeToNotifications } from "store/action/notificationAction";
import { markNotificationAsReadOnServer } from "store/action/notificationAction";
import notificationsLogo from "assets/img/icons/news.svg";
import LoadingOverlay from "react-loading-overlay";
import emojiRed from "assets/img/icons/emoji6-0.svg";
import emojiYellow from "assets/img/icons/emoji-7-8-yellow.svg";
import emojiGreen from "assets/img/icons/emoji9-10.svg";
import emojiGrey from "assets/img/icons/emoji-grey.svg";
import { markAllNotificationAsRead } from "store/action/notificationAction";
import { useHistory } from "react-router-dom";
function NotificationList() {
  const dispatch = useDispatch();
  const history=useHistory()
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const maxLength=20 //Max char length to be displayed as ...
  const [nameSearch, setNameSearch] = useState(false);
  const handleNotificationClick = (notification) => {
    if (!notification.read) {
      dispatch(markNotificationAsReadOnServer(notification));
    }
  };
  const {notifications,notificationsLoading} = useSelector((state) => state.notification);
  const pageClickHandler = (e) => {
    setPage(e.selected + 1);
  };
  console.log(notifications,"NOTIF");
  useEffect(() => {
    dispatch(fetchNotifications({ limit: limit, page: page },()=>{
      dispatch(markAllNotificationAsRead())
    }));
    // dispatch(subscribeToNotifications());
  }, [page]); 

  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getUTCFullYear().toString();
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }
  console.log(notifications);
  
  return (
    <>
     <OnlyHeader />
     <Container className="mt--7 "> 
     {/* <h1>Notifikationen</h1> */}
     <Row>
            <Col
              md={{
                offset: 1,
                size: 10,
              }}
            >
              <>
                <Row>
                  <div className="col">
                    <>
                      <Row className="mt-5">
                        <Col
                          sm={12}
                          md={8}
                          lg={6}
                          className="d-flex  align-items-center"
                        >
                          <img
                            className="mb-3 mr-2"
                            src={notificationsLogo}
                            alt="surveyAnswers"
                          ></img>{" "}
                          <p className="text-kunde-header mx-2">{`Notifikationen`}</p>{" "}

                        </Col>
                        <Col md={0} lg={2}></Col>
                        <Col sm={12} md={4} lg={3}></Col>
                      </Row>


                      <LoadingOverlay
                        active={notificationsLoading}
                        spinner
                        text="Loading...."
                      >
                        <Table className="align-items-center table-flush-notifications" responsive>
                          <thead className="thead-light">
                            <tr>
                              {/* medium-screen-cell is used as d-md-block changes the display from table cell to block which changes vertical alignment */}
                              <th className="medium-screen-cell" scope="col">Datum</th>
                              <th scope="col">Kategorie</th>
                              <th className="medium-screen-cell" scope="col">Mitarbeiter</th>
                              <th scope="col">Bewertung</th>
                              <th scope="col">Qualitative Feedback</th>
                            </tr>
                          </thead>
                          <tbody>
                            {notifications?.data?.length ? (
                              notifications.data.map((el, index) => {
                                return (
                                  <tr onClick={()=>{
                                    history.push(`/admin/view-customer/${el.survey.customerId}?activetab=2`)
                                  }} className={el.isRead ? '' : 'unread'}>
                                    <td className="medium-screen-cell">{formatDate(el.updatedAt)}</td>
                                    <td>{`${el?.survey?.surveyId?.category}`}</td>
                                    <td className=" medium-screen-cell">{`${el?.survey?.adminId?.email}`}</td>
                                    <td className="text-center">
                                      {el?.survey?.npsScore >= 9 ? (
                                        <img src={emojiGreen} alt="green emoji"></img>
                                      ) : el?.survey?.npsScore >= 7 ? (
                                        <img src={emojiYellow} alt="yellow emoji"></img>
                                      ) : (el?.survey?.npsScore >= 0 && el?.survey?.isAnswered) ? (
                                        <img src={emojiRed} alt="red emoji"></img>
                                      ) : (<img src={emojiGrey} alt="grey emoji"></img>)}
                                    </td>
                                    <td className="medium-screen-cell">{`${el.survey ? (el?.survey?.answers[0].answer.length > maxLength ? el?.survey?.answers[0].answer.slice(0, maxLength) + '...' : el?.survey?.answers[0].answer) : ""}`}</td>

                                  </tr>
                                );
                              })
                            ) : ""
                              }
                          </tbody>
                        </Table>
                      </LoadingOverlay>
                      <CardFooter className="py-4">
                        {nameSearch == false && (
                          <ReactPaginate
                            previousLabel={<i className="fas fa-angle-left" />}
                            nextLabel={<i className="fas fa-angle-right" />}
                            breakLabel={"..."}
                            pageCount={notifications && notifications.total / limit}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={pageClickHandler}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link custom-pageLink"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link custom-pageArrow"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link custom-pageArrow"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active custom-active"}
                          />
                        )}
                      </CardFooter>
                    </>
                  </div>
                </Row>
              </>
            </Col>
          </Row>
    {/* <ul>
      {notifications?.map((notification) => (
        <li
          key={notification._id}
          style={{
            cursor: "pointer",
            textDecoration: notification.read ? "line-through" : "none",
          }}
          onClick={() => handleNotificationClick(notification)}
        >
          {notification.message}
        </li>
      ))}
    </ul> */}
      </Container>
   
    </>
  );
}

export default NotificationList;
