const initialState = {
  beratungPieChart: {},
  servicePieChart: {},
  schadenPieChart: {},
  npsReviews: {
    performance: [],
    consulting: [],
    service: [],
  },
  npsReviewsTotals: {
    performance: 0,
    consulting: 0,
    service: 0,
  },
  npsLoading: {
    performance: false,
    consulting: false,
    service: false,
  },
  npsMetrics: {
    performance: {},
    consulting: {},
    service: {},
  },
  beratungStackedBarGraph: {},
  serviceStackedBarGraph: {},
  schadenStackedBarGraph: {},
  
  beratungPieChartData: {},
  bestBeratungpieChartData:{},
  worstBeratungPieChartData:{},
  servicePieChartData: {},
  bestServicepieChartData:{},
  worstServicePieChartData:{},
  schadenPieChartData: {},
  bestSchadenpieChartData:{},
  worstSchadenPieChartData:{},
  adminNPSData:{}
};

const statistcsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STATISTICS_DATA":
      return {
        ...state,
        beratungPieChart: action.payload.consultingGraphData.pieChartData,
        servicePieChart: action.payload.serviceGraphData.pieChartData,
        schadenPieChart: action.payload.performanceGraphData.pieChartData,
        beratungStackedBarGraph: action.payload.consultingGraphData.stackedBarGraphData,
        serviceStackedBarGraph: action.payload.serviceGraphData.stackedBarGraphData,
        schadenStackedBarGraph: action.payload.performanceGraphData.stackedBarGraphData
      };
      case "GET_STATISTICS_DATA_INDIVIDUAL":
      return {
        ...state,
        beratungPieChart: action.payload.consultingGraphData.pieChartData,
        servicePieChart: action.payload.serviceGraphData.pieChartData,
        schadenPieChart: action.payload.performanceGraphData.pieChartData,
        adminNPSData:action.payload.adminNPSData
      };
      case "GET_SUPERADMIN_STATISTICS_DATA":
        if(action.payload.category.toLowerCase()==="beratung"){
          return {
            ...state,
            beratungPieChartData:action.payload.data.pieChartData,
            bestBeratungpieChartData:action.payload.data.bestpieChartData,
            worstBeratungPieChartData:action.payload.data.worstPieChartData
          };
          }else if(action.payload.category.toLowerCase()==="service"){
            return {
              ...state,
              servicePieChartData:action.payload.data.pieChartData,
              bestServicepieChartData:action.payload.data.bestpieChartData,
              worstServicePieChartData:action.payload.data.worstPieChartData
            };
          }else if(action.payload.category.toLowerCase()==="schaden"){
            return {
              ...state,
              schadenPieChartData:action.payload.data.pieChartData,
              bestSchadenpieChartData:action.payload.data.bestpieChartData,
              worstSchadenPieChartData:action.payload.data.worstPieChartData
            };
          }
        return{
          ...state
        }
    case "GET_NPS_REVIEWS_LOADING":
      return {
        ...state,
        npsLoading: {
          ...state.npsLoading,
          [action.payload.category]: action.payload.loading,
        },
      };
    case "CLEAR_NPS_REVIEWS":
      return {
        ...state,
        npsReviews: {
          ...state.npsReviews,
          [action.payload.category]: [], // Clear only the specified category
        },
      };
    case "GET_NPS_REVIEWS_SUCCESS":
      console.log(action.payload, "PAYLOAD TEST", ...state.npsReviews[action.payload.category]);
      return {
        ...state,
        npsLoading: {
          ...state.npsLoading,
          [action.payload.category]: false,
        },
        error: null,
        npsReviews: {
          ...state.npsReviews,
          [action.payload.category]: state.npsReviews[action.payload.category]
            ? [...state.npsReviews[action.payload.category], ...action.payload.data]
            : [...action.payload.data],
        },
        npsReviewsTotals: {
          ...state.npsReviewsTotals,
          [action.payload.category]: action.payload.total,
        },

      };
      case "BOOKMARK_SURVEY_ANSWER":
        const { category,id } = action.payload;
        const updatedReviews = {
          ...state.npsReviews,
          [category]: state.npsReviews[category].map(review => {
            if (review._id === id) {
              return {
                ...review,
                isBookmarked: !review.isBookmarked
              };
            }
            return review;
          })
        };
        return {
          ...state,
          npsLoading: {
            ...state.npsLoading,
            [category]: false,
          },
          npsReviews: updatedReviews,
        };
        case "GET_NPS_METRICS":
          return {
            ...state,
            npsMetrics:{...action.payload}
          };
    default:
      return state;
  }
};

export default statistcsReducer;
