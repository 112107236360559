/* eslint-disable import/no-anonymous-default-export */
import Repository from './Repository';
const FACEBOOKREVIEWS = '/facebookreviews';
const METRICS='/metrics'
const BOOKMARK='/bookmark'
const VALIDATE_TOKEN = "/tokenvalidate";
const LOGOUT = "/logout";
export default{

    addInitialFacebookReview(accessToken){
        return Repository.post(`${FACEBOOKREVIEWS}`,{accessToken});
    },
    getFacebookReviews(payload) {
        const queryParams = Object.entries(payload) //create query string 
          .filter(([key, value]) => value !== undefined)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      
        const url = `${FACEBOOKREVIEWS}?${queryParams}`;
        console.log(url,"URL NPS REVIEWS");
        return Repository.get(url);
      },
      getFacebookMetrics(payload) {
        const queryParams = Object.entries(payload) //create query string 
          .filter(([key, value]) => value !== undefined)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      
        const url = `${FACEBOOKREVIEWS}${METRICS}?${queryParams}`;
        return Repository.get(url);
      },
      bookmarkFacebookReview(id) {
		return Repository.put(`${FACEBOOKREVIEWS}${BOOKMARK}/${id}`);
	  },
    validateFbToken(){
      return Repository.get(`${FACEBOOKREVIEWS}${VALIDATE_TOKEN}`)
    },
    logoutFacebook(){
      return Repository.get(`${FACEBOOKREVIEWS}${LOGOUT}`)
    },
};
