import linkConfig from 'config/config';
import { RepositoryFactory } from 'repository/RepositoryFactory';
import io from 'socket.io-client';

const socketURL = `${linkConfig.serverURL}`;
// const socketURL="https://rating-system-backend-3bp37nx75q-ey.a.run.app"
const socket = io(socketURL, {
  withCredentials: true,
  extraHeaders: {
    "my-custom-header": "abcd"
  }
});


var notification = RepositoryFactory.get('notification');

export const addNotification = (notification) => ({
    type: "ADD_NOTIFICATION",
    payload: notification,
  });
  
  export const markNotificationAsRead = (notification) =>async dispatch =>  ({
    type: "MARK_NOTIFICATION_AS_READ",
    payload: notification,
  });
  export const notificationLoading = (val) =>async dispatch =>  ({
    type: "NOTIFICATION_LOADING",
    payload: val,
  });

  

  export const subscribeToNotifications =
	(id) =>
	async dispatch => {
		try {
			socket.emit("admin connect", id);
			socket.on('notification', (notification) => {
                console.log("Notification",notification);
				dispatch(getNotificationsMetrics())
				dispatch(fetchNotifications())
                // dispatch(addNotification(notification));
              });
		} catch (e) {
			alert(e.response.data.message);
		}
	};

    export const markNotificationAsReadOnServer  =
	(id, onSuccess = () => {}) =>
	async dispatch => {
		try {
			let { data } = await notification.markNotificationAsRead(id);

			if (data.success) {
				await dispatch(getNotificationsMetrics())
				await dispatch(fetchNotifications())
                console.log("Notification Read");
			}
		} catch (e) {

			alert(e.response.data.message);
		}
	};
	export const markAllNotificationAsRead  =
	( onSuccess = () => {}) =>
	async dispatch => {
		try {
			let { data } = await notification.markNotificationsAsRead();

			if (data.success) {
				await dispatch(getNotificationsMetrics())
				onSuccess()
                console.log("Notification Read");
			}
		} catch (e) {

			alert(e.response.data.message);
		}
	};
    export const fetchNotifications  =
	(payload,onSuccess=()=>{} ) =>
	async dispatch => {
		try {
			dispatch(notificationLoading(true))
			let { data } = await notification.fetchNotifications(payload);
			if (data.success) {
                dispatch({ type: 'FETCH_NOTIFICATIONS', payload: data });
				onSuccess()
				dispatch(notificationLoading(false))
			}
		} catch (e) {
			console.log(e,"Error");
			dispatch(notificationLoading(false))
			alert(e.response.data.message);
		}
	};
	export const getNotificationsMetrics  =
	( ) =>
	async dispatch => {
		try {
			dispatch(notificationLoading(true))
			let { data } = await notification.getNotificationMetrics();
			if (data.success) {
                dispatch({ type: 'NOTIFICATIONS_METRICS', payload: data.data });
				// dispatch({ type: 'UNREAD_NOTIFICATIONS', payload: data.unreadCount });
				dispatch(notificationLoading(false))
			}
		} catch (e) {
			console.log(e,"Error");
			dispatch(notificationLoading(false))
			alert(e.response.data.message);
		}
	};