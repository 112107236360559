const initialState = {
    facebookReviewMetrics:{totalReviewCount:0,averageReviewScore:0},
    facebookReviews: [],
    facebookReviewsLoading: false,
    facebookMetricsLoading:false,
    facebookTokenPresent: false,
    facebookTokenInvalid: false,
  };
  
  const facebookReducer = (state = initialState, action) => {
    switch (action.type) {

      case "GET_FACEBOOK_REVIEWS_LOADING":
        return {
          ...state,
          facebookReviewsLoading:action.payload
        };
        case "GET_FACEBOOK_METRICS_LOADING":
        return {
          ...state,
          facebookMetricsLoading:action.payload
        };
      case "CLEAR_FACEBOOK_REVIEWS":
        return {
          ...state,
          facebookReviews:[]
        };
      case "GET_FACEBOOK_REVIEWS_SUCCESS":
        return {
          ...state,
          facebookReviews:[...state.facebookReviews,...action.payload],
        };
        case "BOOKMARK_FACEBOOK_REVIEW":
          const id  = action.payload;
          const updatedReviews = state.facebookReviews.map((review) => {
            if (review._id === id) {
              return { ...review, isBookmarked: !review.isBookmarked };
            }
            return review;
          });
          console.log(id,"REVIEW",updatedReviews);
          return {
            ...state,
            facebookReviews: updatedReviews,
          };
          case "GET_FACEBOOK_REVIEWS_METRICS":
            return {
              ...state,
              facebookReviewMetrics:{...action.payload}
            };
          case "SET_FACEBOOK_TOKEN_PRESENT":
            return {
              ...state,
              facebookTokenPresent: action.payload
            };
          case "SET_FACEBOOK_TOKEN_INVALID":
            return {
              ...state,
              facebookTokenInvalid: action.payload
            };
      default:
        return state;
    }
  };
  
  export default facebookReducer;
  