import React, { useState } from "react";
import { Row, Col } from "reactstrap";

const NumbersFilter = ({
  handleBoxClick,
  selectedNumbers,
  setIsBookMarkedSelected,
  isBookMarkedSelected,
  IsGoogle
}) => {
  const renderBox = (number) => {
    let boxColor = "#CACACA";
    let className = "numbersBox";
  
    if (selectedNumbers.includes(number)) {
      if (IsGoogle) {
        if (number <= 2) {
          boxColor = "#EF3256";
        } else if (number === 3) {
          boxColor = "#FFC600";
        } else if (number >= 4 && number <= 5) {
          boxColor = "#8FC73C";
        }
      } else {
        if (number <= 6) {
          boxColor = "#EF3256";
        } else if (number <= 8) {
          boxColor = "#FFC600";
        } else {
          boxColor = "#8FC73C";
        }
      }
    }
  
    if (number === 0) {
      className += " roundedboxleft"; // Add "rounded" class for the first box
    }
  
    return (
      <Col
        style={{ backgroundColor: boxColor }}
        onClick={() => handleBoxClick(number)}
        className={className}
        xs={IsGoogle ? 2 : 1} // Set xs={2} if IsGoogle is true, otherwise xs={1}
        key={number}
      >
        {number}
      </Col>
    );
  }
  const numbersNps=[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const googleNumbers=[1,2,3,4,5]
  const numbers=IsGoogle ?googleNumbers :numbersNps
  return (
    <Row className="mt-5">
      {numbers.map((number) => renderBox(number))}
      <Col
        onClick={() => setIsBookMarkedSelected(!isBookMarkedSelected)}
        style={{
          backgroundColor: isBookMarkedSelected ? "#364059" : "#CACACA",
        }}
        xs={IsGoogle ? 2 : 1} 
        className="numbersBox roundedboxright"
      >
        <i className="fas fa-bookmark"></i>
      </Col>
    </Row>
  );
};
export default NumbersFilter;
